import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import useStyles from "./partner_promotion_styles";
import PartnerPromotion from "../../../models/partner_promotion";
import PartnerPromotionCard from "../../../components/partner_promotion_card/partner_promotion_card";
import PartnerPromotionModel from "./partner_promotion_model";
import PartnerPromotionUpdateModel from "./partner_promotion_update_model";
import clonedeep from "lodash.clonedeep";
import { deletePartnerPromotion } from "../../../services/api_service/api_service";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";

interface Props {
  promotions: PartnerPromotion[];
  reloadData: Function;
}
export default function PartnerPromotionsGrid(props: Props) {
  const classes = useStyles();

  const [showModel, setShowModel] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);

  const [selectedItem, setSelectedItem] = useState<PartnerPromotion>();
  const [selectedItemForUpdate, setSelectedItemForUpdate] = useState<PartnerPromotion>();

  const showConfMessage = useStoreActions((actions:Actions<StoreModel>)=>actions.message.showConfirmMessage)
  const showMessage = useStoreActions((actions:Actions<StoreModel>)=>actions.message.showMessage)

  const onView = (item: PartnerPromotion) => {
    setSelectedItem(item);
    setShowModel(true);
  };

  const onUpdate = (item: PartnerPromotion) => {
    setSelectedItemForUpdate(clonedeep(item));
    setShowUpdateModel(true);
  };

  const onDelete = (item: PartnerPromotion) => {
    showConfMessage({head:'Are you sure?',body:'This action cannot be undone!',onConfirm:()=>{
      deletePartnerPromotion(item.id).then(data=>{
        if (data?.success) {
          props.reloadData();
        } else {
          showMessage({
            head: "Failed!",
            body: "Cannot remove promotion. Please try again later.",
          });
        }
      })
    }})
    
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="flex-start" spacing={2}>
          {props.promotions.map((value, index) => (
            <PartnerPromotionCard
              key={index}
              value={value}
              onView={onView}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          ))}
        </Grid>
      </Grid>

      <PartnerPromotionModel
        isShow={showModel}
        setShow={setShowModel}
        selectedPromotion={selectedItem}
      />

      <PartnerPromotionUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selectedPromotion={selectedItemForUpdate}
        reloadData={props.reloadData}
      />
    </Grid>
  );
}
