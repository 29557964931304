import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../../../services/colors";


const eventDataCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 'auto',
      width: "100%",
      marginTop: 15,
      paddingLeft: 30,
    },
    header: {
      backgroundColor: myColors.mainColor,
      color: myColors.appBarTextColor,
      fontSize: "13px",
      textTransform: "capitalize",
      fontWeight: 'bold',
      height: '30px',
    },
    oddRowColor: {
      backgroundColor: myColors.oddRowColor
    },
    tableData: {
      color: myColors.appBarColor,
      fontSize: '13px',
      textTransform: "capitalize",

    },
    minimumTextContainer: {
      maxWidth: "64vw",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    maximumTextContainer: {
      whiteSpace: 'break-spaces',
    },
    rowTitle: {
      color: myColors.appBarColor,
      fontSize: '13px',
      textTransform: "capitalize",
      cursor: 'pointer',
      fontWeight: 'bold'
    },
    success: {
      backgroundColor: "#4caf50",
    },
    error: {
      backgroundColor: "#f44336",
    },
    dataChip: {
      marginRight: 5,
      marginBottom: 5,
    },
  })
);

export default eventDataCardStyles;
