import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme) => ({
  outer: { display: "flex", flexDirection: "row", margin: "10px 15px" },
  dots: { margin: "0px 7px" },
  value: { color: myColors.drawerSideBarColor, fontWeight: "bold" },
  title: { color: myColors.drawerColor, fontWeight: "bold" },
}));

export default useStyles;
