import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import WifiLocation from "../../models/wifi_location";
import WifiModel from "./wifi_model";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import { removeWifiLocation } from "../../services/api_service/api_service";
import tableStyles from "../../common_classes/table";
import clsx from 'clsx'
import ModelBtn from "../../components/model_btn/model_btn";

interface Props {
  location: WifiLocation[];
  reloadData: Function;
}
export default function WifiTable(props: Props) {
  const [isShow, setShow] = useState(false);
  const [selected, setSelected] = useState<WifiLocation>();
  const showConfMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showConfirmMessage
  );
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );
  const tableStyle = tableStyles();

  const onView = (location: WifiLocation) => {
    setSelected(location);
    setShow(true);
  };

  const deleteWifi = (id: string) => {
    showConfMessage({
      head: "Are you sure?",
      body: "This action cannot be undone",
      onConfirm: () => {
        removeWifiLocation(id).then((data) => {
          if (data?.success) {
            props.reloadData();
            showMessage({
              head: "Success!",
              body: "Successfully removed wifi location.",
              color: "green",
            });
          } else {
            showMessage({
              head: "Failed!",
              body: "Cannot remove wifi location. Please try again later.",
            });
          }
        });
      },
    });
  };
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>City Name</TableCell>
            <TableCell
              className={tableStyle.header}
              align="center"
              width="200px"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.location.map((item, index) => (
            <TableRow className={clsx(index % 2 === 1 && tableStyle.oddRowColor)} hover key={index}>
              <TableCell className={tableStyle.tableData}>
                {item.name}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.cityName}
              </TableCell>
              <TableCell width={220} align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                <ModelBtn
                  title={"Delete"}
                  onClick={() => deleteWifi(item.id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <WifiModel isShow={isShow} setShow={setShow} selected={selected} />
    </TableContainer>
  );
}
