import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./finek_types_style";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import {
  updateAvatarType,
  uploadAssetCall,
} from "../../../services/api_service/api_service";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import LangAndText from "../../../models/lang_and_text";
import ModelBtn from "../../../components/model_btn/model_btn";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import FinekType from "../../../models/finek_type";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
  selected: FinekType | undefined;
}
export default function FinekTypeUpdateModel(props: Props) {
  const classes = useStyles();

  const [selected, setSelected] = useState<FinekType>();

  useEffect(() => {
    if (props.isShow) {
      setSelected(props.selected);
    }
  }, [props.isShow, props.selected]);

  const onIconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const value = data.data.url;
          const prev = selected;
          if (prev) {
            prev.imageUrl = value;
            setSelected({ ...prev });
          }
        }
      });
    }
  };

  const setNameHandler = (x: LangAndText[]) => {
    const prev = selected;
    if (prev) {
      prev.name = x;
      setSelected({ ...prev });
    }
  };

  const setDescHandler = (x: LangAndText[]) => {
    const prev = selected;
    if (prev) {
      prev.description = x;
      setSelected({ ...prev });
    }
  };

  const setTypeHandler = (event: any) => {
    const type = event.target.value;
    const prev = selected;
    if (prev) {
      prev.typeId = type;
      setSelected({ ...prev });
    }
  };

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const closeModal = () => {
    props.setShow(false);
  };

  const onSave = () => {
    if (selected) {
      if (!selected.imageUrl) {
        showMessage({
          head: "Failed!",
          body: "Please add image and try again!",
        });
        return;
      }
      if (!selected.typeId) {
        showMessage({
          head: "Failed!",
          body: "Please enter type id and try again!",
        });
        return;
      }
      if (selected.name.length === 0 || !selected.name[0]?.text) {
        showMessage({
          head: "Failed!",
          body: "Please enter name and try again!",
        });
        return;
      }

      if (selected.description.length === 0 || !selected.description[0]?.text) {
        showMessage({
          head: "Failed!",
          body: "Please enter description and try again!",
        });
        return;
      }
      updateAvatarType(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully updated the Type.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Update Avatar Type"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selected && (
          <div>
            <IconUploadBtn
              isEditable
              mediaType="IMAGE"
              mediaUrl={selected.imageUrl}
              onUpload={onIconUpload}
              height={100}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              label="Type Id"
              value={selected.typeId}
              onChange={setTypeHandler}
            />
            <LangAndTextEditTabs
              data={selected.name}
              isEditable={true}
              label={"Name"}
              setData={setNameHandler}
            />
            <LangAndTextEditTabs
              data={selected.description}
              isEditable={true}
              label={"Description"}
              setData={setDescHandler}
            />
          </div>
        )}
      </div>

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={onSave} />
        </div>
      </div>
    </MyModal>
  );
}
