import Asset, { emptyAsset } from "./Asset";
import LangAndText, { emptyLangAndText } from "./lang_and_text";
import FinekType from "./finek_type";
import EligibleLevel from "./eligible_level";

class FinekItem {
  id: string;
  price: number;
  iconUrl: string;
  assets: Asset[];
  name: LangAndText[];
  typeId: string | undefined;
  type: FinekType | null;
  description: LangAndText[];
  eligibleLevelItem: EligibleLevel | null;
  eligibleLevel: string | undefined;

  constructor(data: any) {
    this.id = data.id;
    this.price = data.price;
    this.iconUrl = data.iconUrl;
    
    this.assets = [];
    data.assets.forEach((x: any) => this.assets.push(new Asset(x)));
    this.name = [];
    data.name.forEach((x: any) => this.name.push(new LangAndText(x)));
    this.type = data.type ? new FinekType(data.type) : null;
    this.typeId = this.type?.id;
    this.description = [];
    data.description.forEach((x: any) =>
      this.description.push(new LangAndText(x))
    );
    this.eligibleLevelItem = data.eligibleLevel
      ? new EligibleLevel(data.eligibleLevel)
      : null;
    this.eligibleLevel = this.eligibleLevelItem?.id;
  }
}

export const emptyItem = {
  id: null,
  price: undefined,
  iconUrl: null,
  assets: [emptyAsset],
  name: [new LangAndText(emptyLangAndText)],
  type: null,
  description: [new LangAndText(emptyLangAndText)],
  eligibleLevel: null,
};
export default FinekItem;
