class RedeemCodeDetailsLevelModel {
    level: number;
    totalRedeemCodesCount: number;
    totalUsedCodeCount: number;
    totalNotUsedCodeCount: number;
    bundleMapping: string

    constructor(data: any) {
        this.level = data.level;
        this.totalRedeemCodesCount = data.totalRedeemCodesCount;
        this.totalUsedCodeCount = data.totalUsedCodeCount;
        this.totalNotUsedCodeCount = data.totalNotUsedCodeCount;
        this.bundleMapping = data.bundleMapping;
    }
}
export default RedeemCodeDetailsLevelModel;
