export default class DecorationAsset{
    id:string
    name:string
    url:string
    type:string
    enabled:boolean
    constructor(data:any){
        this.id=data.id
        this.name=data.name
        this.url=data.url
        this.type=data.type
        this.enabled=data.enabled
    }
}

export const emptyDecoAsset = {
    id:undefined,
    name:'',
    url:'',
    type:'',
    enabled:false
}