import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    row: {
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
      },
      right: {
        paddingRight: "10px",
        float: "right",
      },
      textField: {
        margin: "5px 10px",
      },
      marker: {
        height: "30px",
        width: "30px",
      },
      markerIcon: {
        position: "absolute",
        left: -15,
        top: -20,
      },
      map: {
        height: "300px",
        width: "95%",
        margin: "auto",
        borderRadius:'10px'
      },
});

export default useStyles;
