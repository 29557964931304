import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import useStyles from "../notification_template_style";
import CustomerBaseGridItem from "./customer_base_grid_item"
import CustomerBaseModal from "../../../models/customerBase";

interface Props {
    customerBase: CustomerBaseModal[]
    reloadData: Function
}
export default function CustomerBaseGrid(props: Props) {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
                <Grid container justify="flex-start" spacing={2}>
                    {props.customerBase.map((value, index) => (
                        <CustomerBaseGridItem
                            key={index}
                            value={value}
                        />
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
