import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles({
  
  themeColor:{
    height: "70px",
    width: "70px",
    margin: "auto",
          position: "relative",
  },
  editWrapper:{
    bottom: 0,
    height: '35px',
    position: 'absolute',
    right: '-2px'
  },
  outer:{ textAlign: "center" },
  title:{
    color:myColors.drawerColor,
    fontWeight:'bold',
    marginTop:'5px'
  }
});

export default useStyles;
