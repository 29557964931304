import React, { useEffect, useState } from "react";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import CommonPage from "../../components/common_page/common_page";
import Error from "../../models/error";
import { getErrorsList } from "../../services/api_service/api_service";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../services/route_name/route_name_objects";
import ErrorAddModel from "./error_add_model";
import ErrorTable from "./error_table";

export default function ErrorPage() {
  const [errors, setErrors] = useState<Error[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);

  const getErrors = () => {
    getErrorsList().then((data) => {
      if (data?.success) {
        const th: Error[] = [];
        data.data.forEach((x: any) => th.push(new Error(x)));
        setErrors(th);
      }
    });
  };

  useEffect(() => {
    getErrors();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.error}
      parentRoutes={[routeNameObjects.settings]}
    >
      <AddItemBtn title="Add New Error" onClick={showAddModel} />
      {errors.length > 0 && (
        <ErrorTable errors={errors} reloadData={getErrors} />
      )}
      <ErrorAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getErrors}
      />
    </CommonPage>
  );
}
