
import React from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import VoucherSearchPage from "./voucher_search_page";

export default function VoucherDetailsPage() {


  return (
    <CommonPage
      currentRoute={routeNameObjects.voucherDetails}
      parentRoutes={[routeNameObjects.voucherDetails]}
    >
      <VoucherSearchPage/>
    </CommonPage>
  );
}
