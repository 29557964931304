import SessionName from "./sessionName";
import RewardsName from "./rewards_name";

class QuizSessionModel {
    id: string;
    // name: string[] = [];
    name: SessionName[] = []
    // rewards: string[] = [];
    rewards: RewardsName[] = [];
    status: string;
    date: string;
    createdDate: string;
    startTime: string;
    endTime: string;


    constructor(data: any) {
        this.id = data.id;
        this.name = data.name
        this.rewards = data.rewards
        this.status = data.status;
        this.date = data.date;
        this.createdDate = data.createdDate;
        this.startTime = data.startTime;
        this.endTime = data.endTime;
    }
}
export default QuizSessionModel;
