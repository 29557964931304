import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import useStyles from "../../createQuestions/create_question_style";
import "bootstrap/dist/css/bootstrap.min.css";
import RewardTab from "../reward_tab"
import { getSingleSession } from "../../../../services/api_service/api_service";
import { useParams } from 'react-router-dom';
import QuizSessionModel from "../../../../models/quiz_Session";
import IconUploadBtn from "../../../../components/icon_upload_btn/icon_upload_btn";
import tableStyles from "../../../../common_classes/table";

interface Props {
    quizSessionDetails: QuizSessionModel | null;
    reloadData: Function;
}

export default function InfoQuizSessionDetails(props: Props) {
    const classes = useStyles();
    const tableStyle = tableStyles();

    return (
        <div>
            <div className="row">
                <div className="col-md-5">
                    <div className="row">
                        <label>Name</label>
                    </div>

                    {props.quizSessionDetails && props.quizSessionDetails.name.map((item, index) => {
                        return (
                            <>
                                <div className="row">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className={classes.inputGroup}>{item.language}</span>
                                        </div>
                                        <input type="text" value={item.message} className="form-control" />
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
                {props.quizSessionDetails && <>
                    <div className="col-auto mt-3">
                        <label>Date</label>
                        <div className="row mt-5">
                            <div className="col">
                                <label>Time</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto mt-3">
                        <input type="text" value={props.quizSessionDetails.date} className="form-control" />
                        <div className="row mt-4">
                            <div className="col">
                                <label>Start Time</label>
                                <input type="text" value={props.quizSessionDetails.startTime} className="form-control" />
                            </div>
                            <div className="col">
                                <label>End Time</label>
                                <input type="text" value={props.quizSessionDetails.endTime} className="form-control" />
                            </div>
                        </div>
                    </div>
                </>}
            </div>
            <div className="row mt-3">
                <div className="col-auto mt-3">
                    <label>Rewards</label>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    <Table stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={tableStyle.headerQuizSessionGift}>Gift Image</TableCell>
                                <TableCell className={tableStyle.header}>Gift Name</TableCell>
                                <TableCell className={tableStyle.header}>Winner Position</TableCell>
                                <TableCell className={tableStyle.header}>Number Of Winners</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.quizSessionDetails && props.quizSessionDetails.rewards.map((item, index) => (
                                <TableRow>
                                    <TableCell>
                                        <IconUploadBtn
                                            mediaUrl={(item.image)}
                                            mediaType={"IMAGE"}
                                            onUpload={null}
                                            allowVideo={false}
                                            height={120}
                                            isEditable={false}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {item.name.map((name) => (
                                            <p>{name.message}</p>
                                        ))}
                                    </TableCell>
                                    <TableCell>{item.position}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>

        </div >
    );
}
