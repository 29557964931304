import React, { useState, useEffect } from "react";
import CommonPage from "../../../components/common_page/common_page";
import FinekType from "../../../models/finek_type";
import { getFinekTypesCall } from "../../../services/api_service/api_service";
import FinekTypesTable from "./finek_types_table";
import FinekTypeAddModel from "./finek_type_add_model";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../../services/route_name/route_name_objects";

export default function FinekTypesPage() {
  const [types, setTypes] = useState<FinekType[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const getTypes = () => {
    getFinekTypesCall().then((data) => {
      if (data?.success) {
        const tps: FinekType[] = [];
        data.data.forEach((x: any) => tps.push(new FinekType(x)));
        setTypes(tps);
      }
    });
  };

  useEffect(() => {
    getTypes();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.avatarTypes}
      parentRoutes={[routeNameObjects.avatar]}
    >
      <AddItemBtn title="Add New Type" onClick={showAddModel} />
      {types.length > 0 && (
        <FinekTypesTable types={types} reloadData={getTypes} />
      )}

      <FinekTypeAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getTypes}
      />
    </CommonPage>
  );
}
