const REACT_APP_KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
const REACT_APP_KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
const REACT_APP_KEYCLOAK_CLIENTID = process.env.REACT_APP_KEYCLOAK_CLIENTID;

const keycloakConfig = {
  url: REACT_APP_KEYCLOAK_URL,
  realm: REACT_APP_KEYCLOAK_REALM,
  clientId: REACT_APP_KEYCLOAK_CLIENTID,
};

export default keycloakConfig;
