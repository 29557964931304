import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./user_categories_styles";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import { updateUserCategoryCall } from "../../../services/api_service/api_service";
import UserCategory from "../../../models/user_category";
import ModelBtn from "../../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
  selectedItem: UserCategory | undefined;
}
export default function UserCategoryUpdateModel(props: Props) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [creditLimit, setCreditLimit] = useState("");

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const closeModal = () => {
    props.setShow(false);
  };

  useEffect(() => {
    if (props.selectedItem) {
      setName(props.selectedItem.name);
      setCreditLimit(props.selectedItem.creditLimit.toString());
    }
  }, [props.selectedItem]);
  const onSave = () => {
    if (!name) {
      showMessage({
        head: "Failed!",
        body: "Please enter name and try again!",
      });
      return;
    }
    if (!creditLimit) {
      showMessage({
        head: "Failed!",
        body: "Please enter credit limit and try again!",
      });
      return;
    }

    updateUserCategoryCall(
      name,
      Number(creditLimit),
      props.selectedItem ? props.selectedItem.id : ""
    ).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully Updated User Category.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
       
      title={"update user category"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          type="number"
          label="Credit Limit"
          value={creditLimit}
          onChange={(event) => setCreditLimit(event.target.value)}
          InputProps={{inputProps:{min:0}}}
        />
      </div>

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"update"} onClick={onSave} />
        </div>
      </div>
    </MyModal>
  );
}
