import LangAndText, { emptyLangAndText } from "./lang_and_text";

export default class Error {
  id: string;
  errorCode: string;
  messages: LangAndText[] = [];
  constructor(data: any) {
    this.id = data.id;
    this.errorCode = data.errorCode;
    data.messages.forEach((x: any) => this.messages.push(new LangAndText(x)));
  }
}

export const emptyError = {
    id:'',
    errorCode:'',
    messages:[
        new LangAndText(emptyLangAndText)
    ]
}