import { Card, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "../notification_template_style";
import FadeIn from "react-fade-in";
import { MoreVert } from "@material-ui/icons";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CustomerBaseModal from "../../../models/customerBase";

interface Props {
      value: CustomerBaseModal;
}
export default function CustomerBaseGridItem(props: Props) {
    const classes = useStyles();
    const [isHover, setHover] = useState(false);

    return (
        <Grid item>
            <Card
                className={classes.messageTemplateGridCard}
            >
                <div className="row">
                    <div className="col-auto">
                        <label style={{ color: "#808080", fontWeight: "bold" }}>{props.value.listName}</label>
                        <p className="mt-3">{props.value.listName}</p>
                    </div>
                </div>
            </Card>
        </Grid>
    );
}
