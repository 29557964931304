import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        name: {
            fontFamily: "unset",
            fontSize: "18px",
            fontWeight: "bold",
            color: myColors.drawerColor,
            margin: "12px -26px 2px 0px"
        },
        cardSize: {
            padding: "20px",
        },
        gridCard: {
            width: "650px",
            margin: "21px 15px 5px 15px"
        },
        messageTemplateGridCard: {
            width: "1065px",
            padding: "20px"
        },
        title: {
            margin: "-34px 0px 0px 19px"
        },
        body: {
            margin: "1px 0px 0px 35px"
        },
        radioButton: {
            margin: "0px 0px 0px 599px;",
        }
    })
);

export default useStyles;
