import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      justifyContent: "center",

      margin: "10px 0px",
    },
    paper: {
      backgroundColor: "white",
      border: "none",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: "center",
      overflow: "auto",
      borderRadius: "10px",
      outline: "0",
    },
    addOuter: {
      // width: "500px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      marginTop: "10px",
    },
    right: {
      paddingRight: "10px",
      float: "right",
    },
    greyLabel: {
      color: "grey",
    },
    assetItem: {
      backgroundColor: "#bdbdbd40",
      borderRadius: "5px",
      margin: "5px",
      width: "150px",
    },
    assetRow: {
      display: "flex",
      flexDirection: "row",
      width: "fit-content",
      margin: "auto",
    },
    smallBtn: {
      padding: "3px",
    },
    price: {
      width: "fit-content",
      margin: "auto",
      padding: "5px 10px",
      border: "2px solid grey",
      borderRadius: "5px",
      fontSize: "20px",
      marginTop: "10px",
      fontWeight: "bold",
    },
    boldText: {
      fontWeight: "bold",
    },
    addBtn: {
      position: "fixed",
      right: "30px",
      bottom: "30px",
      backgroundColor: "#1a3248",
      color: "white",
    },
    addIcon: {
      height: "60px",
      width: "60px",
      color: "grey",
    },
    addIconBtn: {
      backgroundColor: "#80808021",
      marginTop: "10px",
    },
    tableRow: {
      alignItems: "center",
    },
    modelBtn: {
      marginRight: "3px",
    },
    root: {
      flexGrow: 1,
    },
    spaceBetween: {
      justifyContent: "space-between",
    },
    name: {
      fontFamily: "unset",
      fontSize: "18px",
      fontWeight: "bold",
      color: myColors.drawerColor,
    },
    type: {
      fontSize: "13px",
      color: myColors.tableDataText,
    },
    gridPrice: {
      fontSize: "25px",
      fontWeight: "bold",
      color: myColors.drawerSideBarColor,
    },
    line: {
      width: "100%",
      margin: "auto",
      height: "0.5px",
      backgroundColor: "#a7a7a766",
    },
    description: {
      margin: "10px 0px",
    },
    contentPadding: { padding: "0px 10px 10px 10px" },
    gridImage: {
      width: "220px",
      height: "200px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    gridCard: { width: "260px" },
    textFieldHalf: { width: "150px", margin: "0px 10px" },
    assetCard: { margin: "5px 0px", position: "relative" },
    removeBtnWrapper: {
      position: "absolute",
      bottom: "3px",
      right: "3px",
      height: "35px",
    },
    centeredRow: {
      flexDirection: "row",
      marginTop: "10px",

    },
    imageSizeText: { color: "grey" },
  })
);

export default useStyles;
