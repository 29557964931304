import AccountData, { emptyAccountData } from './account_data'

export const emptySubscriberData: SubscriberData = {
    receptionEndDate: '',
    rechargeEndDate: '',
    providerId: '',
    status: 0,
    firstCallDate: '',
    tariffModel: 0,
    inPlatform: null,
    locked: false,
    temporaryBlocked: false,
    voucherRechargingAllowed: null,
    imsi: null,
    paymentAdvanceBalance: null,
    paymentAdvanceValidityFrom: null,
    paymentAdvanceValidityTo: null,
    averageConsumption: '',
    service3GStatus: false,
    signContract3G4GStatus: '',
    signContract3GDate: '',
    service4GStatus: false,
    signContract4GDate: '',
    language: '',
    account: [emptyAccountData],
    dynamicBundlesPackage: {
        dynamicBundle: [],
    }
};

class SubscriberData {
    receptionEndDate: string;
    rechargeEndDate: string;
    providerId: string;
    status: number;
    firstCallDate: string;
    tariffModel: number;
    inPlatform: null;
    locked: boolean;
    temporaryBlocked: boolean;
    voucherRechargingAllowed: null;
    imsi: null;
    paymentAdvanceBalance: null;
    paymentAdvanceValidityFrom: null;
    paymentAdvanceValidityTo: null;
    averageConsumption: string;
    service3GStatus: boolean;
    signContract3G4GStatus: string;
    signContract3GDate: string;
    service4GStatus: boolean;
    signContract4GDate: string;
    language: string;
    account: AccountData[];
    dynamicBundlesPackage: {
        dynamicBundle: any[]
    }

    constructor(data: any) {
        this.receptionEndDate = data.receptionEndDate;
        this.rechargeEndDate = data.rechargeEndDate;
        this.providerId = data.providerId;
        this.status = data.status;
        this.firstCallDate = data.firstCallDate;
        this.tariffModel = data.tariffModel;
        this.inPlatform = data.inPlatform;
        this.locked = data.locked;
        this.temporaryBlocked = data.temporaryBlocked;
        this.voucherRechargingAllowed = data.voucherRechargingAllowed;
        this.imsi = data.imsi;
        this.paymentAdvanceBalance = data.paymentAdvanceBalance;
        this.paymentAdvanceValidityFrom = data.paymentAdvanceValidityFrom;
        this.paymentAdvanceValidityTo = data.paymentAdvanceValidityTo;
        this.averageConsumption = data.averageConsumption;
        this.signContract3G4GStatus = data.signContract3G4GStatus;
        this.service3GStatus = data.service3GStatus;
        this.signContract3GDate = data.signContract3GDate;
        this.service4GStatus = data.service4GStatus;
        this.signContract4GDate = data.signContract4GDate;
        this.language = data.language;
        this.account = data.account;
        this.dynamicBundlesPackage = data.dynamicBundlesPackage;
    }

}

export default SubscriberData;