import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./finek_types_style";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelBtn from "../../../components/model_btn/model_btn";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import FinekType from "../../../models/finek_type";

interface Props {
  isShow: boolean;
  setShow: Function;
  selected: FinekType | undefined;
}
export default function FinekTypeViewModel(props: Props) {
  const classes = useStyles();

  const [selected, setSelected] = useState<FinekType>();

  useEffect(() => {
    if (props.isShow) {
      setSelected(props.selected);
    }
  }, [props.isShow, props.selected]);


  const closeModal = () => {
    props.setShow(false);
  };

  

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Avatar Type"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selected && (
          <div>
            <IconUploadBtn
              mediaType="IMAGE"
              mediaUrl={selected.imageUrl}
              onUpload={null}
              height={100}
              isEditable={false}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              label="Type Id"
              value={selected.typeId}
              InputProps={{readOnly:true}}
            />
            <LangAndTextEditTabs
              data={selected.name}
              isEditable={false}
              label={"Name"}
              setData={null}
            />
            <LangAndTextEditTabs
              data={selected.description}
              isEditable={false}
              label={"Description"}
              setData={null}
            />
          </div>
        )}
      </div>

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
