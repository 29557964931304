import React from "react";
import useStyles from "./sim_pickup_page_style";
import clsx from "clsx";
import MyModal from "../../components/my_modal/my_modal";
import { uploadCsvCall } from "../../services/api_service/api_service";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../components/model_btn/model_btn";
import { StoreModel } from "../../store models/model";
import { Actions, useStoreActions } from "easy-peasy";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function SimPickupAddModel(props: Props) {
  const classes = useStyles();

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const closeModal = () => {
    props.setShow(false);
  };

  // const saveModel = () => {};
  const csvUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      uploadCsvCall(selectedFile).then((data) => {
        if (data?.success) {
          // const url = data.data.url;
          showMessage({head:'Success',body:'You have successfully added CSV file'})
        }
      });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Upload Eligibility List"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <IconUploadBtn
          mediaUrl={""}
          mediaType={"CSV"}
          onUpload={csvUpload}
          height={120}
          isEditable
          onlyCsv
        />

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          {/* <ModelBtn title={"save"} onClick={saveModel} /> */}
        </div>
      </div>
    </MyModal>
  );
}
