import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs, Switch } from "@material-ui/core";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import useStyles from "./notification_style";
import { Button } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import CustomerBaseModel from "../../models/customerBase";

interface Props {
    msisdnList: CustomerBaseModel[]
    reloadData: Function
    parentCallback: Function
    existingCustomerBase: CustomerBaseModel
    onChange: Function
    parentCallbackListName: Function
    parentCallBackFileURL: Function
}
export default function UserBase(props: Props) {
    const classes = useStyles();
    const [existingListName, setexistingListName] = useState("")
    let selectOptions: CustomerBaseModel[] = [{ listName: "Select Customer Base", fileUrl: "" }, ...props.msisdnList]

    const handleInputChange = (event: any) => {
        if (event.target.files.length > 0) {
            props.parentCallback(event.target.files[0])
        }
        console.log("u f", event.target.files[0])
    }

    useEffect(() => {
        if (props.existingCustomerBase) {
            setexistingListName(props.existingCustomerBase?.listName)
        }
    }, [props.existingCustomerBase]);
    useEffect(() => {
        if (props.msisdnList) {
            let value = props.msisdnList
            if (value) {
                value = value.filter(v => v.listName === existingListName)
                console.log("listName", value[0]?.listName)
                props.onChange(value[0])
            }
            console.log("value[0]?.listName", value[0]?.listName)
            props.parentCallbackListName(value[0]?.listName)
            props.parentCallBackFileURL(value[0]?.fileUrl)
        }
    }, [existingListName]);

    return (
        <div>
            <Card className={classes.cardSize}>
                <div className="row">
                    <div className="col-md-8">
                        <p>Upload New Excel Sheet</p>
                        <Button variant="contained" component="label">
                            <input type="file" onChange={handleInputChange} />
                        </Button>
                    </div>
                    <div className="col-md-4">
                        <p>Upload Existing Templates</p>
                        <select value={existingListName} onChange={(e) => { setexistingListName(e.target.value) }} className="form-select" aria-label="Default select example">
                            {selectOptions.map((item, index) => (
                                <option value={item.listName} defaultValue={item.listName} >{item.listName}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </Card >
            {/* <div className="row mt-3">
                <div className="col">
                    <AddItemBtn title="Next" onClick={() => { }} />
                </div>
            </div> */}
        </div>
    );
}



