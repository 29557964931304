import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import clsx from "clsx";
import MyWorldPlan from "../../../models/my_world_plan";
import useStyles from "./my_world_plans_style";
import MyModal from "../../../components/my_modal/my_modal";
import { typeValues, unitValues, validityValues } from "./my_world_plans_data";
import MyWorldPlugin, { emptyPlugin } from "../../../models/my_world_plugin";
import {
  getMyWorldPlugins,
  updateMyWorldPlan,
} from "../../../services/api_service/api_service";
import { Remove } from "@material-ui/icons";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import IconBtn from "../../../components/icon_btn/icon_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import LangAndText from "../../../models/lang_and_text";

interface Props {
  isShow: boolean;
  setShow: Function;
  selected: MyWorldPlan | undefined;
  reloadData: Function;
}
export default function MyWorldPlanModel(props: Props) {
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const [selected, setSelected] = useState<MyWorldPlan | undefined>();

  const [plugins, setPlugins] = useState<MyWorldPlugin[]>([]);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const getPlugins = () => {
    getMyWorldPlugins().then((data) => {
      if (data?.success) {
        const pl: MyWorldPlugin[] = [];
        data.data.forEach((x: any) => pl.push(new MyWorldPlugin(x)));
        setPlugins(pl);
      }
    });
  };

  useEffect(() => {
    if (props.isShow) {
      getPlugins();
      if (props.selected) {
        setSelected(props.selected);
      }
    }
  }, [props.selected, props.isShow]);

  function planNameChange(name: any) {
    const sl = selected;
    if (sl) {
      sl.planName = name;
      setSelected({ ...sl });
    }
  }

  function priceChange(price: any) {
    const sl = selected;
    if (sl) {
      sl.price = price;
      setSelected({ ...sl });
    }
  }

  function pointsChange(points: any) {
    const sl = selected;
    if (sl) {
      sl.exPoints = points;
      setSelected({ ...sl });
    }
  }

  function bundleIdChange(bundleId: any) {
    const sl = selected;
    if (sl) {
      sl.bundleId = Number(bundleId);
      setSelected({ ...sl });
    }
  }

  function bundleCodeChange(bundleCode: any) {
    const sl = selected;
    if (sl) {
      sl.bundleCode = bundleCode;
      setSelected({ ...sl });
    }
  }

  function starCountChange(count: any) {
    const sl = selected;
    if (sl) {
      sl.starPackage.unlockStarCount = Number(count);
      setSelected({ ...sl });
    }
  }

  function validityChange(validity: any) {
    const sl = selected;
    if (sl) {
      sl.validity = validity;
      setSelected({ ...sl });
    }
  }

  function typeChange(type: any) {
    const sl = selected;
    if (sl) {
      sl.type = type;
      setSelected({ ...sl });
    }
  }

  function limitedPluginChange(index: number, id: any) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins[index].pluginId = id;
      setSelected({ ...sl });
    }
  }

  function limitedPluginPriceChange(index: number, amt: any) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins[index].amount = Number(amt);
      sl.limitedPluginsData[index].amount = Number(amt);
      setSelected({ ...sl });
    }
  }

  function limitedPluginUnitChange(index: number, unit: any) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins[index].unit = unit;
      sl.limitedPluginsData[index].unit = unit;
      setSelected({ ...sl });
    }
  }

  function unLimitedPluginChange(index: number, id: any) {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins[index] = id;
      setSelected({ ...sl });
    }
  }

  function addLimitedPlugin() {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins.push({ pluginId: "", amount: 0, unit: "GB" });
      sl.limitedPluginsData.push({
        plan: new MyWorldPlugin(emptyPlugin),
        amount: 0,
        unit: "GB",
      });
      setSelected({ ...sl });
    }
  }

  function removeLimitedPlugin(index: number) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins.splice(index, 1);
      setSelected({ ...sl });
    }
  }

  function addUnlimitedPlugin() {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins.push("");
      setSelected({ ...sl });
    }
  }

  function removeUnLimitedPlugin(index: number) {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins.splice(index, 1);
      setSelected({ ...sl });
    }
  }

  const descriptionChange = (x: LangAndText[]) => {
    const prev = selected;
    if (prev) {
      prev.bundleDetails = x;
      setSelected({ ...prev });
    }
  };

  const successChange = (x: LangAndText[]) => {
    const prev = selected;
    if (prev) {
      prev.bundleSuccess = x;
      setSelected({ ...prev });
    }
  };

  const inboxMessagesChange = (x: LangAndText[]) => {
    const prev = selected;
    if (prev) {
      prev.inboxMessage = x;
      setSelected({ ...prev });
    }
  };

  function onSave() {
    if (selected) {
      updateMyWorldPlan(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully updated.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  }

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Update My World Plan"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <div className={classes.row}>
          <TextField
            label="Plan Name"
            value={selected?.planName}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => planNameChange(event.target.value)}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="number"
            label="Price"
            value={selected?.price}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => priceChange(event.target.value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="number"
            label="Ex Points"
            value={selected?.exPoints}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => pointsChange(event.target.value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="number"
            label="Bundle Id"
            value={selected?.bundleId}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => bundleIdChange(event.target.value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="text"
            label="Bundle Code"
            value={selected?.bundleCode}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => bundleCodeChange(event.target.value)}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="number"
            label="Star Count"
            value={selected?.starPackage.unlockStarCount}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => {
              if (Number(event.target.value) <= 5) {
                starCountChange(event.target.value);
              }
            }}
            InputProps={{ inputProps: { min: 0, max: 5 } }}
          />
        </div>

        <div className={classes.row}>
          <TextField
            label="Validity"
            value={selected?.validity}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => validityChange(event.target.value)}
            select
          >
            {validityValues.map((val, index) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className={classes.row}>
          <TextField
            label="Type"
            value={selected?.type}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => typeChange(event.target.value)}
            select
          // InputProps={{ readOnly: true }}
          >
            {typeValues.map((val, index) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <LangAndTextEditTabs
          label="Bundle Details"
          data={selected?.bundleDetails}
          setData={descriptionChange}
          isEditable={true}
        />

        <LangAndTextEditTabs
          label="Bundle Success"
          data={selected?.bundleSuccess}
          setData={successChange}
          isEditable={true}
        />

        <LangAndTextEditTabs
          label="Inbox Messages"
          data={selected?.inboxMessage}
          setData={inboxMessagesChange}
          isEditable={true}
        />

        {plugins.length > 0 && (
          <ModelFieldWrapper>
            <ModelLabel
              title={"Limited Plugins"}
              onClick={addLimitedPlugin}
              noMargin
            />
            {selected?.limitedPlugins.map((plugin, index) => (
              <div
                key={index}
                className={clsx(
                  classes.row,
                  classes.alignCenter,
                  classes.relative
                )}
              >
                <TextField
                  label="Limited Plugins"
                  value={plugin.pluginId}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "100%" }}
                  onChange={(event) =>
                    limitedPluginChange(index, event.target.value)
                  }
                  select
                >
                  {plugins.map((plug, index) => (
                    <MenuItem key={index} value={plug.id}>
                      {plug.referenceName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Amount"
                  value={plugin.amount}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "200px" }}
                  onChange={(event) =>
                    limitedPluginPriceChange(index, event.target.value)
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <TextField
                  label="Unit"
                  value={plugin.unit ? plugin.unit : ""}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "200px" }}
                  onChange={(event) =>
                    limitedPluginUnitChange(index, event.target.value)
                  }
                  select
                >
                  {unitValues.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </TextField>
                <IconBtn
                  onClick={() => removeLimitedPlugin(index)}
                  icon={Remove}
                  isCenter
                />
              </div>
            ))}
          </ModelFieldWrapper>
        )}

        {plugins.length > 0 && (
          <ModelFieldWrapper>
            <ModelLabel
              title={"Unlimited Plugins"}
              onClick={addUnlimitedPlugin}
              noMargin
            />
            {selected?.unlimitedPlugins.map((plugin, index) => (
              <div
                key={index}
                className={clsx(
                  classes.row,
                  classes.alignCenter,
                  classes.relative
                )}
              >
                <TextField
                  label="Unlimited Plugins"
                  value={plugin}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "100%" }}
                  onChange={(event) =>
                    unLimitedPluginChange(index, event.target.value)
                  }
                  select
                >
                  {plugins.map((plug, index) => (
                    <MenuItem key={index} value={plug.id}>
                      {plug.referenceName}
                    </MenuItem>
                  ))}
                </TextField>
                <IconBtn
                  onClick={() => removeUnLimitedPlugin(index)}
                  icon={Remove}
                  isCenter
                />
              </div>
            ))}
          </ModelFieldWrapper>
        )}
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Update"} onClick={onSave} />
        <ModelBtn title={"close"} onClick={closeModal} />
      </div>
    </MyModal>
  );
}
