import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import FinekItem from "../../../models/finek_item";
import FinacItemModel from "./finek_item_model";
import useStyles from "./finek_items_style";
import FinekGridItem from "../../../components/finek_grid_item/finek_grid_item";
import FinacItemUpdateModel from "./finek_item_update_model";
import clonedeep from 'lodash.clonedeep';
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import { deleteAvatarItem } from "../../../services/api_service/api_service";

interface Props {
  items: FinekItem[];
  reloadData:Function
}
export default function FinekItemsGrid(props: Props) {
  const classes = useStyles();

  const [showModel, setShowModel] = useState(false);

  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<FinekItem>();

  const showConfMessage = useStoreActions((actions:Actions<StoreModel>)=>actions.message.showConfirmMessage)
  const showMessage = useStoreActions((actions:Actions<StoreModel>)=>actions.message.showMessage)


  const onView = (item: FinekItem) => {
    
    setSelectedItem(item);
    setShowModel(true);
  };

  const onUpdate = (item: FinekItem) => {
    
    setSelectedItem(clonedeep(item));
    setShowUpdateModel(true);
  };

  const onDelete = (item: FinekItem) => {
    showConfMessage({head:'Are you sure?',body:'This action cannot be undone!',onConfirm:()=>{
      deleteAvatarItem(item.id).then(data=>{
        if (data?.success) {
          props.reloadData();
        } else {
          showMessage({
            head: "Failed!",
            body: "Cannot remove avatar item. Please try again later.",
          });
        }
      })
    }})
  };
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="flex-start" spacing={2}>
          {props.items.map((value, index) => (
            <FinekGridItem
              key={index}
              value={value}
              onView={onView}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          ))}
        </Grid>
      </Grid>

      <FinacItemModel
        isShow={showModel}
        setShow={setShowModel}
        selectedItem={selectedItem}
      />

      <FinacItemUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selected={ selectedItem}
        reloadData={props.reloadData}
      />
    </Grid>
  );
}
