import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import NetworkPlans from "../../models/network_plans";
import NetworkPlanModel from "./network_plans_model";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import NetworkPlanUpdateModel from "./network_plans_update_model";
import clonedeep from 'lodash.clonedeep';

interface Props {
  networkPlan: NetworkPlans[];
  reloadData: Function;
}

export default function NetworkPlanTable(props: Props) {
  const tableStyle = tableStyles();
  const [showModel, setShowModel] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<NetworkPlans>();

  const onView = (item: NetworkPlans) => {
    setSelectedItem(item);
    setShowModel(true);
  };

  const onUpdate = (item: NetworkPlans) => {
    setSelectedItem(clonedeep(item));
    setShowUpdateModel(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Image</TableCell>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>Price</TableCell>
            <TableCell className={tableStyle.header}>Description</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.networkPlan.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell>
                {item.imageUrl ? (
                  <img
                    height="50px"
                    width={"50px"}
                    src={item.imageUrl}
                    alt="network plan icon"
                  />
                ) : (
                  <div></div>
                )}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.name[0]?.text}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.price}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.description[0]?.text}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                <ModelBtn title={"Update"} onClick={() => onUpdate(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <NetworkPlanModel
        isShow={showModel}
        setShow={setShowModel}
        selected={selectedItem}
      />

      <NetworkPlanUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selected={selectedItem}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
