import React from "react";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import PartnerMerchant from "../../../models/partner_merchant";
import tableStyles from "../../../common_classes/table";
import clsx from "clsx";

interface Props {
  merchants: PartnerMerchant[];
  reloadData: Function;
}
export default function PartnerMerchantTable(props: Props) {
  const tableStyle = tableStyles();

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>User Name</TableCell>

            <TableCell className={tableStyle.header}>Category Name</TableCell>
            <TableCell className={tableStyle.header}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.merchants.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell className={tableStyle.tableData}>
                {item.name}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.username}
              </TableCell>

              <TableCell className={tableStyle.tableData}>
                {item.category?.name[0]?.text}
              </TableCell>
              <TableCell
                className={clsx(tableStyle.tableData, tableStyle.colored)}
              >
                {item.status.toLowerCase()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <PartnerCategoryModel
        isShow={showModel}
        setShow={setShowModel}
        selectedCategory={selectedItem}
        reloadData={props.reloadData}
      /> */}
    </TableContainer>
  );
}
