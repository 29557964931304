import React, { useEffect, useState } from "react";
import { TextField, Switch } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import useStyles from "../../../questions/createQuestions/create_question_style";
import NotificationModel from "../../../../models/notification";

interface Props {
    language: string;
    data: NotificationModel | undefined;
    onChange: Function;
}

export default function UpdateNotificationTemplateText(props: Props) {
    const classes = useStyles();
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")

    useEffect(() => {
        if (props.data) {
            setTitle(props.data?.title.filter(item => item.language === props.language)[0].text)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.title.filter(item => item.language === props.language)[0].text = title
                props.onChange(value)
            }
        }
    }, [title]);

    useEffect(() => {
        if (props.data) {
            setText(props.data?.body.filter(item => item.language === props.language)[0].text)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.body.filter(item => item.language === props.language)[0].text = text
                props.onChange(value)
            }
        }
    }, [text]);


    return (
        <div>
            {props.data && <>
                <div>
                    <div className="row mt-3">
                        <div className="col-auto">
                            <label>02.Title</label>
                        </div>
                        <div className="col-md-12">
                            <input type="text" value={title} onChange={(event) => setTitle(event.target.value)} className="form-control" placeholder="Title"></input>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-auto">
                            <label>03.Body</label>
                        </div>
                        <div className="col-md-12">
                            <textarea className="form-control" value={text} onChange={(event) => setText(event.target.value)} rows={3}></textarea>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    );
}
