import React, { useEffect, useState } from "react";
import { TextField, Switch } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import useStyles from "../questions/createQuestions/create_question_style";
import NotificationModel from "../../models/notification";

interface Props {
    language: string;
    data: NotificationModel;
    onChange: Function;
    parenttextCallBack: Function
    parentTitleCallBack: Function
}

export default function NotificationText(props: Props) {
    const classes = useStyles();
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")

    useEffect(() => {
        if (props.data) {
            setTitle(props.data?.title.filter(item => item.language === props.language)[0].text)
            setText(props.data?.body.filter(item => item.language === props.language)[0].text)
            props.parentTitleCallBack(props.data?.title.filter(item => item.language === props.language)[0].text)
            props.parenttextCallBack(props.data?.body.filter(item => item.language === props.language)[0].text)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.title.filter(item => item.language === props.language)[0].text = title
                props.onChange(value)
            }
        }
    }, [title]);

    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.body.filter(item => item.language === props.language)[0].text = text
                props.onChange(value)
            }
        }
    }, [text]);

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <label>01.Title</label>
                    <input type="text" value={title} onChange={(event) => setTitle(event.target.value)} className="form-control" placeholder="Title"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    <label>02.Body</label>
                    <textarea className="form-control" value={text} onChange={(event) => setText(event.target.value)} rows={3}></textarea>
                </div>
            </div>
        </div>
    );
}
