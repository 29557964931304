import React, { useState, useEffect } from "react";
import CommonPage from "../../../components/common_page/common_page";
import { getPromotionsByMerchantId } from "../../../services/api_service/api_service";
import PartnerPromotion from "../../../models/partner_promotion";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import { useStoreState } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import MerchantPromotionsGrid from "./merchant_promotion_grid";
import { Box } from "@material-ui/core";

export default function MerchantPromotionsPage() {
  const [promotions, setPromotions] = useState<PartnerPromotion[]>([]);

  const authUser = useStoreState(
    (state: StoreModel) => state.keyCloakUserModel.authUser
  );

  const getPromotions = (id: string) => {
    getPromotionsByMerchantId(id).then((data) => {
      if (data?.success) {
        const promo: PartnerPromotion[] = [];
        data.data.forEach((x: any) => promo.push(new PartnerPromotion(x)));
        setPromotions(promo);
      }
    });
  };
  useEffect(() => {
    if(authUser?.id){
      getPromotions(authUser.id);
    }
    
  }, [authUser]);

  return (
    <CommonPage
      currentRoute={routeNameObjects.promotions}
      parentRoutes={[routeNameObjects.promotions]}
    >
      <Box height={20} />
      {promotions.length > 0 && (
        <MerchantPromotionsGrid promotions={promotions} />
      )}
    </CommonPage>
  );
}
