import React, { useState } from "react";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import MyWorldPlugin from "../../../models/my_world_plugin";
import MyWorldPluginsModel from "./my_world_plugins_model";
import tableStyles from "../../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../../components/model_btn/model_btn";
import clonedeep from "lodash.clonedeep";
import MyWorldPluginsUpdateModel from "./my_world_plugins_update_modal";

interface Props {
  plugins: MyWorldPlugin[];
  reloadData: Function;
}
export default function MyWorldPluginTable(props: Props) {
  const tableStyle = tableStyles();
  const [showModel, setShowModel] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MyWorldPlugin>();
  const [
    selectedItemToUpdate,
    setSelectedItemToUpdate,
  ] = useState<MyWorldPlugin>();

  const onView = (item: MyWorldPlugin) => {
    setSelectedItem(item);
    setShowModel(true);
  };

  const onUpdate = (item: MyWorldPlugin) => {
    setSelectedItemToUpdate(clonedeep(item));
    setShowUpdateModel(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Image</TableCell>
            <TableCell className={tableStyle.header}>Reference Name</TableCell>
            <TableCell className={tableStyle.header}>Code</TableCell>
            <TableCell className={tableStyle.header} width={300} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.plugins.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell>
                {item.logoUrl ? (
                  <img
                    height={"50px"}
                    src={item.logoUrl}
                    alt={item.referenceName}
                  />
                ) : (
                  <div style={{ height: "50px" }} />
                )}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.referenceName}
              </TableCell>
              <TableCell className={tableStyle.tableDataRemoveCaps}>
                {item.code}
              </TableCell>
              <TableCell  align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                <ModelBtn title="Update" onClick={() => onUpdate(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <MyWorldPluginsModel
        isShow={showModel}
        setShow={setShowModel}
        selectedPlugin={selectedItem}
        reloadData={props.reloadData}
      />
      <MyWorldPluginsUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selectedPlugin={selectedItemToUpdate}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
