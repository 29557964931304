import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Chip, IconButton, Grid, Collapse, Box } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import eventDataCardStyles from "./event_data_card_styles";
import time from "../../../../services/time";

interface IProps {
    cardData: Object;
    success: string;
}
export function EventDetailsCard(props: IProps) {
    const dataCardStyle = eventDataCardStyles();
    const [data, setData] = useState<Object>({})
    const [success, setSuccess] = useState<string>('')
    const [open, setOpen] = useState<{ key: string, open: boolean }>({ key: '', open: false })


    useEffect(() => {
        setData(props.cardData)
        setSuccess(props.success)
    }, [props])


    const handleValue = (value: any) => {

        let pattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/
        let datePattern = /\d{4}-[01]\d-[0-3]\dT.*[+]/
        if (pattern.test(value)) {
            return (<img src={value} alt="" width="60px" />)
        }
        else if (datePattern.test(value)) {
            return time.timeStringToDate(value)
        }
        else {
            return handleEmpty(value)
        }
    }

    const handleObjectString = (value: string) => {
        let output: string = value
        if (!!value) {
            output = output.split(",").join(", ")
        }
        return output
    }

    const handleEmpty = (value: any) => {
        let output: any
        if (value === '' || value === null) {
            output = "-"
        }
        else if (typeof value === 'boolean') {
            if (value) {
                output = 'True'
            }
            else {
                output = 'False'
            }
        }
        else {
            output = value
        }
        return output

    }

    const formatKey = (value: string) => {
        let output: string
        if (!!value) {
            output = value.match(/([A-Z]?[^A-Z]*)/g)!.slice(0, -1).join(" ")

            if (value === "service3GStatus") {
                output = "Service 3G Status"
            }
        }
        else {
            output = "unknown"
        }

        return output

    }
    return (
        <Paper className={clsx(dataCardStyle.container, success === "SUCCESS" ? dataCardStyle.success : dataCardStyle.error)} elevation={3}>
            <TableContainer component={Paper}>
                <Table stickyHeader size="small" >
                    <TableBody>
                        {
                            Object.keys(data).map((key: string, i) => {
                                let value = Reflect.get(data, key)
                                return (
                                    <TableRow
                                        className={clsx(i % 2 === 1 && dataCardStyle.oddRowColor)}
                                        hover
                                        key={i}
                                        onClick={() => setOpen({ key: key, open: open.key === key ? !open.open : true })}
                                    >
                                        <TableCell className={dataCardStyle.rowTitle} align='left'>
                                            {formatKey(key)}
                                        </TableCell>
                                        {
                                            key === "responseBody" || key === "requestBody" ?
                                                (
                                                    <>
                                                        <TableCell className={dataCardStyle.tableData} align='left'>
                                                            <Grid container justify="space-between">
                                                                <Grid item xs={11} className={open.key === key && open.open === true ? dataCardStyle.maximumTextContainer : dataCardStyle.minimumTextContainer}>
                                                                    {handleObjectString(value)}
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <IconButton size="small" style={{ marginLeft: "20px" }}>
                                                                        {open.key === key && open.open === true ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </>
                                                )
                                                : (
                                                    <TableCell className={dataCardStyle.tableData} align='left'>
                                                        {handleValue(value)}
                                                    </TableCell>
                                                )
                                        }
                                    </TableRow>
                                )

                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    )
}

