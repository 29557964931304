import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import clsx from 'clsx'
import tableStyles from "../../../common_classes/table";
import ModelBtn from "../../../components/model_btn/model_btn";
import Wilaya from "../../../models/wilaya";
import WilayaModel from "./wilaya_model";

interface Props {
  wilayaList: Wilaya[];
  reloadData: Function;
}
export default function WilayaTable(props: Props) {
  const [isShow, setShow] = useState(false);
  const [selected, setSelected] = useState<Wilaya>();
 
 
  const tableStyle = tableStyles();

  const onView = (wilaya: Wilaya) => {
    setSelected(wilaya);
    setShow(true);
  };

  
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Wilaya Name</TableCell>
            {/* <TableCell className={tableStyle.header}>Country</TableCell>
            <TableCell className={tableStyle.header}>City</TableCell> */}
            <TableCell
              className={tableStyle.header}
              align="center"
              width="200px"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.wilayaList.map((item, index) => (
            <TableRow className={clsx(index % 2 === 1 && tableStyle.oddRowColor)} hover key={index}>
              <TableCell className={tableStyle.tableData}>
                {item.name[0].text}
              </TableCell>
              {/* <TableCell className={tableStyle.tableData}>
                {item?.country?.name[0]?.text}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
              {item?.city?.name[0]?.text}
              </TableCell> */}
              
              <TableCell width={220} align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <WilayaModel isShow={isShow} setShow={setShow} selected={selected} />
    </TableContainer>
  );
}
