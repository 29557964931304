import React, { useEffect, useState } from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import { Card, Tab, Tabs, Switch } from "@material-ui/core";
import useStyles from "./create_question_style";
import QuestionsTabs from "../questionTabs/questionTabs";
import Offer, { emptyOffer } from "../../../models/offer";
import Content from "../../../models/content";
import PageTab from "../../../components/page_tab/page_tab";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import QuizPoolModel, { emptyQuestionPool } from "../../../models/quizPool";
import {
    addQuizPool,
} from "../../../services/api_service/api_service";
import QuestionTextField from "./question_text_field"
import { StoreModel } from "../../../store models/model";
import { useStoreActions, Actions } from "easy-peasy";
import CreateQuestionOthers from "./create_other_component";

interface Props {
    onSubmit: Function
}

export default function CreateQuestion(props: Props) {
    const classes = useStyles();
    const [selected, setSelected] = useState<QuizPoolModel>(emptyQuestionPool);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    useEffect(() => {
        setSelected(emptyQuestionPool)
    }, []);

    // useEffect(() => {
    //     if (props.selected) {
    //         setSelected(props.selected);
    //         setSelectedTab(0);
    //     }
    // }, [props.selected]);

    function onSave() {
        if (!selected.question.filter(item => item.language === "EN")[0].message) {
            showMessage({
                head: "Failed!",
                body: "Please enter English quiz and try again.",
                color: "red",
            });
            return;
        }
        if (!selected.question.filter(item => item.language === "AR")[0].message) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic quiz and try again.",
                color: "red",
            });
            return;
        }
        if (!selected.question.filter(item => item.language === "FR")[0].message) {
            showMessage({
                head: "Failed!",
                body: "Please enter French quiz and try again.",
                color: "red",
            });
            return;
        }
        let x = selected?.answers.filter(item => item.id === 1)[0].answer
        let y = x.filter(lan => lan.language === "EN")[0].message
        if (!y) {
            showMessage({
                head: "Failed!",
                body: "Please enter English answer and try again.",
                color: "red",
            });
            return;
        }
        let x2 = selected?.answers.filter(item => item.id === 2)[0].answer
        let y2 = x2.filter(lan => lan.language === "EN")[0].message
        if (!y2) {
            showMessage({
                head: "Failed!",
                body: "Please enter English answer and try again.",
                color: "red",
            });
            return;
        }
        let x3 = selected?.answers.filter(item => item.id === 3)[0].answer
        let y3 = x3.filter(lan => lan.language === "EN")[0].message
        if (!y3) {
            showMessage({
                head: "Failed!",
                body: "Please enter English answer and try again.",
                color: "red",
            });
            return;
        }
        let x4 = selected?.answers.filter(item => item.id === 4)[0].answer
        let y4 = x4.filter(lan => lan.language === "EN")[0].message
        if (!y4) {
            showMessage({
                head: "Failed!",
                body: "Please enter English answer and try again.",
                color: "red",
            });
            return;
        }

        let Ar1 = selected?.answers.filter(item => item.id === 1)[0].answer
        let Ay1 = Ar1.filter(lan => lan.language === "AR")[0].message
        if (!Ay1) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic answer and try again.",
                color: "red",
            });
            return;
        }
        let ax2 = selected?.answers.filter(item => item.id === 2)[0].answer
        let ay2 = ax2.filter(lan => lan.language === "AR")[0].message
        if (!ay2) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic answer and try again.",
                color: "red",
            });
            return;
        }
        let ax3 = selected?.answers.filter(item => item.id === 3)[0].answer
        let ay3 = ax3.filter(lan => lan.language === "AR")[0].message
        if (!ay3) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic answer and try again.",
                color: "red",
            });
            return;
        }
        let ax4 = selected?.answers.filter(item => item.id === 4)[0].answer
        let ay4 = ax4.filter(lan => lan.language === "AR")[0].message
        if (!ay4) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic answer and try again.",
                color: "red",
            });
            return;
        }

        let fr1 = selected?.answers.filter(item => item.id === 1)[0].answer
        let fy1 = fr1.filter(lan => lan.language === "FR")[0].message
        if (!fy1) {
            showMessage({
                head: "Failed!",
                body: "Please enter French answer and try again.",
                color: "red",
            });
            return;
        }
        let fx2 = selected?.answers.filter(item => item.id === 2)[0].answer
        let fy2 = fx2.filter(lan => lan.language === "FR")[0].message
        if (!fy2) {
            showMessage({
                head: "Failed!",
                body: "Please enter French answer and try again.",
                color: "red",
            });
            return;
        }
        let fx3 = selected?.answers.filter(item => item.id === 3)[0].answer
        let fy3 = fx3.filter(lan => lan.language === "FR")[0].message
        if (!fy3) {
            showMessage({
                head: "Failed!",
                body: "Please enter French answer and try again.",
                color: "red",
            });
            return;
        }
        let fx4 = selected?.answers.filter(item => item.id === 4)[0].answer
        let fy4 = fx4.filter(lan => lan.language === "FR")[0].message
        if (!fy4) {
            showMessage({
                head: "Failed!",
                body: "Please enter French answer and try again.",
                color: "red",
            });
            return;
        }

        // if(!selected.level){
        //     showMessage({
        //         head: "Failed!",
        //         body: "Please enter level and try again.",
        //         color: "red",
        //     });
        //     return;
        // }
        if (selected) {
            addQuizPool(selected).then((data) => {
                console.log("data", data)
                if (data?.success) {
                    props.onSubmit(false)
                }
            })
        }
    }

    return (
        <Card className={classes.cardSize}>
            <AddItemBtn title="Back" onClick={() => { props.onSubmit() }} />
            <PageTab
                tabsData={[
                    {
                        title: "English",
                        component: <QuestionTextField language="EN" data={selected} onChange={setSelected}></QuestionTextField>
                    },
                    {
                        title: "Arabic",
                        component: <QuestionTextField language="AR" data={selected} onChange={setSelected}></QuestionTextField>
                    },
                    {
                        title: "French",
                        component: <QuestionTextField language="FR" data={selected} onChange={setSelected}></QuestionTextField>
                    },
                ]}
            />
            <CreateQuestionOthers language="FR" data={selected} onChange={setSelected}></CreateQuestionOthers>
            <div className="row mt-3">
                <div className="col-md-9">
                    <AddItemBtn title="Add Questions" onClick={onSave} />
                </div>
            </div>
        </Card >
    );
}



