
import { Add } from "@material-ui/icons";
import React from "react";
import IconBtn from "../icon_btn/icon_btn";
import useStyles from "./model_label_style";

interface Props {
  title: string;
  width?: string;
  onClick?: Function;
  noMargin?:boolean
}

export default function ModelLabel(props: Props) {
  const classes = useStyles();
  return (
    <div
      className={classes.labelOuter}
      style={{ width: props.width ? props.width : props.noMargin ? "99%": "100%", margin: props.noMargin?'1px auto':'20px 0px 0px 0px' }}
    >
      {props.title}
      {props.onClick && <IconBtn onClick={props.onClick} icon={Add}/>}
    </div>
  );
}
