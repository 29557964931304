import React, { useEffect, useState } from "react";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import CommonPage from "../../../components/common_page/common_page";
import PageTab from "../../../components/page_tab/page_tab";
import { getAllMGMPlansCall } from "../../../services/api_service/api_service";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import MGMPlansAddModel from "./mgm_plans_add_model";
import MGMPlansTable from './mgm_plans_table'
import MGMPlan from '../../../models/mgm_plan'

export default function MGMPlansPage() {
  const [plans, setPlans] = useState<MGMPlan[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  
  const showAddModel = () => {
    setShowAddModel(true);
  };

  const getPlans = () => {
    getAllMGMPlansCall().then((data) => {
      if (data?.success) {
        console.log(data.data)
        const pl: MGMPlan[] = [];
        data.data.forEach((x: any) => pl.push(new MGMPlan(x)));
        setPlans(pl);
      }
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.mgmPlans}
      parentRoutes={[routeNameObjects.myWorld]}
    >
      <AddItemBtn title="Add New MGM Plan" onClick={showAddModel} />
      

      {plans.length > 0 && (
        <PageTab
          tabsData={[
            {
              title: "Daily Plans",
              component: (
                <MGMPlansTable
                  plans={plans.filter(
                    (p) => p.validity.toLocaleLowerCase() === "daily"
                  )}
                  reloadData={getPlans}
                />
              ),
            },
            {
              title: "Weekly Plans",
              component: (
                <MGMPlansTable
                  plans={plans.filter(
                    (p) => p.validity.toLocaleLowerCase() === "weekly"
                  )}
                  reloadData={getPlans}
                />
              ),
            },
            {
              title: "Monthly Plans",
              component: (
                <MGMPlansTable
                  plans={plans.filter(
                    (p) => p.validity.toLocaleLowerCase() === "monthly"
                  )}
                  reloadData={getPlans}
                />
              ),
            },
          ]}
        />
      )}

      <MGMPlansAddModel
        reloadData={getPlans}
        isShow={isShowAddModel}
        setShow={setShowAddModel}
      />
    </CommonPage>
  );
}
