import { Done, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import IconBtn from "../icon_btn/icon_btn";
import useStyles from "./color_picker_style";
import { ChromePicker } from "react-color";
import FadeIn from "react-fade-in";
import { Card } from "@material-ui/core";

export default function MyColorPicker(props: {
  selectedColor: string | undefined;
  setColor: Function|null;
  title: string;
  isEditable: boolean;
}) {
  const [showPicker, setShowPicker] = useState(false);
  const classes = useStyles();
  const openPicker = () => {
    setShowPicker(!showPicker);
  };
  return (
    <div className={classes.outer}>
      <Card
        elevation={5}
        className={classes.themeColor}
        style={{
          backgroundColor: props.selectedColor,
        }}
      >
        {props.isEditable && (
          <div className={classes.editWrapper}>
            <IconBtn icon={showPicker ? Done : Edit} onClick={openPicker} />
          </div>
        )}
      </Card>
      <div className={classes.title}>{props.title}</div>
      {showPicker && (
        <FadeIn>
          <ChromePicker
            color={props.selectedColor}
            onChange={(color) => {
              if (props.setColor) {
                props.setColor(color.hex);
              }
            }}
          />
        </FadeIn>
      )}
    </div>
  );
}
