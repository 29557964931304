import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import MyWorldPlugin from "../../../models/my_world_plugin";
import useStyles from "./my_world_plugins_style";
import MyModal from "../../../components/my_modal/my_modal";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";
import { updateMyWorldPlugin, uploadAssetCall } from "../../../services/api_service/api_service";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
  selectedPlugin: MyWorldPlugin | undefined;
}
export default function MyWorldPluginsUpdateModel(props: Props) {
  const classes = useStyles();

  const [selectedplugin, setSelectedPlugin] = useState<MyWorldPlugin>();
  const [extraValues, setExtraValues] = useState<
    { key: string; value: string }
  >({key: "", value: ""});
  const [addNewVisible, setAddNewVisible] = useState(false)

  useEffect(() => {
    setSelectedPlugin(props.selectedPlugin);
  }, [props.selectedPlugin, props.isShow]);

  const closeModal = () => {
    props.setShow(false);
  };


  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  function nameChange(referenceName: any) {
    const sl = selectedplugin;
    if (sl) {
      sl.referenceName = referenceName;
      setSelectedPlugin({ ...sl });
    }
  }

  function codeChange(code: any) {
    const sl = selectedplugin;
    if (sl) {
      sl.code = code;
      setSelectedPlugin({ ...sl });
    }
  }

  const extraValueKeyChange = (key: string) => { 
    setExtraValues({...extraValues, key: key});
  };

  const extraValueChange = (value: any) => {
    setExtraValues({...extraValues, value: value});
  };


  const addExtraValue = () => {
    setAddNewVisible(true)
  };

  const onImageUpload = (event: any, type: string, isCheckSize: boolean) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const height = img.height;
        const width = img.width;
        if (isCheckSize) {


          if (height === 200 && width === 200) {
            uploadAssetCall(file).then((data) => {
              if (data?.success) {
                const value = data.data.url;
                const prev = selectedplugin;
                if (prev) {
                  if (type === "logo") {
                    prev.logoUrl = value;
                  } else if (type === "large") {
                    prev.largeLogoUrl = value;
                  } else if (type === "medium") {
                    prev.mediumLogoUrl = value;
                  }
                  setSelectedPlugin({ ...prev });
                }

              }
            });
          } else {
            showMessage({
              head: "Failed to upload image!",
              body: "Selected image is not 200px * 200px",
            });
          }
        } else {
          uploadAssetCall(file).then((data) => {
            if (data?.success) {
              const value = data.data.url;
              const prev = selectedplugin;
              if (prev) {
                if (type === "logo") {
                  prev.logoUrl = value;
                } else if (type === "large") {
                  prev.largeLogoUrl = value;
                } else if (type === "medium") {
                  prev.mediumLogoUrl = value;
                }
                setSelectedPlugin({ ...prev });
              }

            }
          });
        }
      };
    }
  };


  const saveBtnClicked = () => {
    if (selectedplugin) {
      updateMyWorldPlugin(selectedplugin).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully updated plugin.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }

  }
  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Update My world Plugin"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selectedplugin && (
          <div className={classes.column}>
            <IconUploadBtn
              mediaUrl={selectedplugin.logoUrl}
              mediaType={"IMAGE"}
              onUpload={(event: any) => {
                onImageUpload(event, 'logo', true)
              }}
              allowVideo={false}
              height={120}
              isEditable={true}
            />
            <p className={classes.imageTitle}>Logo</p>
            {/* <div className={classes.imageRow}>
              <div>
                <IconUploadBtn
                  mediaUrl={selectedplugin.mediumLogoUrl}
                  mediaType={"IMAGE"}
                  onUpload={(event:any)=>{
                    onImageUpload(event,'medium', false)
                  }}
                  allowVideo={false}
                  height={120}
                  isEditable={true}
                />
                <p className={classes.imageTitle}>Medium Logo</p>
              </div>
              <div>
                <IconUploadBtn
                  mediaUrl={selectedplugin.largeLogoUrl}
                  mediaType={"IMAGE"}
                  onUpload={(event:any)=>{
                    onImageUpload(event,'large', false)
                  }}
                  allowVideo={false}
                  height={120}
                  isEditable={true}
                />
                <p className={classes.imageTitle}>Large Logo</p>
              </div>
            </div> */}
            <TextField
              variant="outlined"
              className={classes.textField}
              value={selectedplugin.referenceName}
              label="Name"
              // contentEditable="false"
              onChange={(event) => nameChange(event.target.value)}
            // InputProps={{ readOnly: true }}
            />

            <TextField
              variant="outlined"
              className={classes.textField}
              value={selectedplugin.code}
              label="Code"
              // contentEditable="false"
              onChange={(event) => codeChange(event.target.value)}
            // InputProps={{ readOnly: true }}
            />

            <ModelFieldWrapper>
              <ModelLabel title={"Extra Values"} onClick={addExtraValue} noMargin />
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.greyLabel}>Key</TableCell>
                    <TableCell className={classes.greyLabel}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.selectedPlugin &&
                    Object.keys(
                      props.selectedPlugin.extraValues
                        ? props.selectedPlugin.extraValues
                        : {}
                    ).map((key, index) => (
                      <TableRow hover key={index}>
                        <TableCell>
                          <TextField
                            value={key}
                            variant="outlined"
                            onChange={(event) =>
                              extraValueKeyChange(event.target.value)
                            }
                          // InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={props.selectedPlugin?.extraValues[key]}
                            variant="outlined"
                            onChange={(event) =>
                              extraValueChange(event.target.value)
                            }
                          // InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  {addNewVisible &&
                    <TableRow hover >
                      <TableCell>
                        <TextField
                          value={extraValues.key}
                          variant="outlined"
                          onChange={(event) =>
                            setExtraValues({...extraValues, key: event.target.value})
                          }
                        // InputProps={{ readOnly: true }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={extraValues.value}
                          variant="outlined"
                          onChange={(event) =>
                            setExtraValues({...extraValues, value: event.target.value})
                          }
                        // InputProps={{ readOnly: true }}
                        />
                      </TableCell>
                    </TableRow>}
                </TableBody>
              </Table>
            </ModelFieldWrapper>
          </div>
        )}
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Close"} onClick={closeModal} />
        <ModelBtn title={"save"} onClick={saveBtnClicked} />
      </div>
    </MyModal>
  );
}
