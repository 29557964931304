import React, { useEffect, useState } from "react";
import CommonPage from "../../../../components/common_page/common_page";
import routeNameObjects from "../../../../services/route_name/route_name_objects";
import { Card, Tab, Tabs, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import useStyles from "../../createQuestions/create_question_style";
import "bootstrap/dist/css/bootstrap.min.css";
import RewardTab from "../reward_tab";
import AddItemBtn from "../../../../components/add_item_btn/add_item_btn";
import IconUploadBtn from "../../../../components/icon_upload_btn/icon_upload_btn";
import tableStyles from "../../../../common_classes/table";


interface Props {
    onSubmit: Function
}

export default function CreateQuizSession(props: Props) {
    const classes = useStyles();
    const [toggleState, setToggleState] = useState(1)
    const tableStyle = tableStyles();

    return (
        // <CommonPage
        //     currentRoute={routeNameObjects.createQuizSession}
        //     parentRoutes={[routeNameObjects.createQuizSession]}
        // >
            <Card className={classes.cardSize}>
                <div className="row">
                    <div className="col-auto">
                        <label>Name</label>
                    </div>
                    <div className="col-auto">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className={classes.inputGroup} id="basic-addon3">English</span>
                            </div>
                            <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className={classes.inputGroup} id="basic-addon3">Arabic</span>
                            </div>
                            <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className={classes.inputGroup} id="basic-addon3">French</span>
                            </div>
                            <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" />
                        </div>
                    </div>
                    <div className="col-auto">
                        <label>Date</label>
                    </div>
                    <div className="col-auto">
                        <input type="date" className="form-control" />
                    </div>
                    <div className="col-auto">
                        <label >Time</label>
                    </div>
                    <div className="col-auto">
                        <label>Start Time</label>
                        <input type="time" className="form-control" />
                    </div>
                    <div className="col-auto">
                        <label>End Time</label>
                        <input type="time" className="form-control" />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-auto">
                        <label>Upload Gift Image</label>
                        <IconUploadBtn
                            mediaUrl=""
                            mediaType={"IMAGE"}
                            onUpload={null}
                            height={120}
                            isEditable={true}
                        />
                    </div>
                    <div className="col-auto">
                        <label>Gift Name</label>
                    </div>
                    <div className="col-auto">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className={classes.inputGroup} id="basic-addon3">English</span>
                            </div>
                            <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className={classes.inputGroup} id="basic-addon3">Arabic</span>
                            </div>
                            <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className={classes.inputGroup} id="basic-addon3">French</span>
                            </div>
                            <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" />
                        </div>
                    </div>
                    <div className="col-auto">
                        <label>Winner Position</label>
                        <div className="row">
                            <div className="col-auto mt-5">
                                <label>Number Of Winner</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-1">
                        <input type="text" className="form-control" aria-describedby="basic-addon3" />
                        <div className="row">
                            <div className="col-auto mt-4">
                                <input type="text" className="form-control" aria-describedby="basic-addon3" />
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <AddItemBtn title="Add Rewards" onClick={() => { }} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <Table stickyHeader size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={tableStyle.header}>Gift Image</TableCell>
                                    <TableCell className={tableStyle.header}>Gift Name</TableCell>
                                    <TableCell className={tableStyle.header}>Winner Position</TableCell>
                                    <TableCell className={tableStyle.header}>Number Of Winners</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <IconUploadBtn
                                            mediaUrl=""
                                            mediaType={"IMAGE"}
                                            onUpload={null}
                                            height={120}
                                            isEditable={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <p>LG</p>
                                        <p>LG</p>
                                        <p>LG</p>
                                    </TableCell>
                                    <TableCell>1</TableCell>
                                    <TableCell>1</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
                {/* <div className="row mt-3">
                    <div className="col-md-4">
                        <label className={classes.label}>Time</label>
                    </div>
                    <div className="col-md-3">
                        <label>Start Time</label>
                        <input type="time" className="form-control" />
                    </div>
                    <div className="col-md-3">
                        <label>End Time</label>
                        <input type="time" className="form-control" />
                    </div>
                </div> */}
                {/* <div className="row mt-3">
                    <div className="col-md-4 mt-3">
                        <label className={classes.label}>Rewards</label>
                    </div>
                    <div className="col-md-6">
                        <RewardTab
                            tabsData={[
                                {
                                    title: "English",
                                    component: null
                                },
                                {
                                    title: "Arabic",
                                    component: null
                                },
                                {
                                    title: "French",
                                    component: null
                                },
                            ]}
                        ></RewardTab>
                    </div>
                </div> */}
                <div className="row mt-3">
                    <div className="col-md-12">
                        <AddItemBtn title="Submit" onClick={() => { }} />
                    </div>
                </div>
            </Card>
        // </CommonPage>
    );
}
