import loader, { Loader } from "./loader";
import tokenModel, { TokenModel } from "./token";
import message, { Message } from "./message";
import keyCloakUserModel, { KeyCloakUserModel } from "./key_cloak_user";

export interface StoreModel {
  loader: Loader;
  tokenModel: TokenModel;
  message: Message;
  keyCloakUserModel: KeyCloakUserModel
}

const model: StoreModel = {
  loader,
  tokenModel,
  message,
  keyCloakUserModel
};

export default model;
