import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor:myColors.drawerColor,
    overflowY:'unset'
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    height:'120px'
  },
  logo: {
    fontFamily: "cursive",
    fontWeight: "bold",
    fontSize: "3vw",
  },
  list:{
    paddingTop:'0.5px'
  },
  ibiza:{
    fontSize: '40px',
    fontWeight: 'bold',
    color: 'white',
    width: 'fit-content',
    margin: 'auto',
    cursor:'pointer',
    transition:'1s',
    "&:hover":{
      color:myColors.drawerSideBarColor
    }
  }
}));

export default useStyles;
