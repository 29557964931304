import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles({
  addIcon: {
    color: myColors.appBarTextColor,
  },
  addIconBtn: {
    backgroundColor: myColors.appBarColor,
    margin: "auto",
    marginTop: "10px",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: myColors.drawerColor,
    },
    borderRadius: "10px",
  },
  iconBtn: {
    backgroundColor: "white",
    border: `2px solid ${myColors.drawerSideBarColor}`,
    color: myColors.drawerSideBarColor,
    padding: "5px",
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 5,
    "&:focus,&:hover": {
      backgroundColor: myColors.drawerSideBarColor,
      color: "white",
      border: `2px solid white`,
    },
  },
  imageOuter: {
    position: "relative",
    width: "fit-content",
    margin: "auto",
    marginBottom: "10px",
  },
  image: {
    border: `4px solid ${myColors.appBarColor}`,
    borderRadius: "50%",
    margin: "auto",
    marginTop: "5px",
  },
  imageShadow:{
    boxShadow:'0px 0px 30px -5px #000000bf;'
  }
});

export default useStyles;
