import { Star } from "@material-ui/icons"
import PartnerCategoriesPage from "../../pages/partners/partner_categories/partner_categories_page"
import MerchantPage from "../../pages/partners/partner_merchant/partner_merchant_page"
import PromotionsPage from "../../pages/partners/partner_promotions/partner_promotions_page"
import UserCategoriesPage from "../../pages/partners/user_categories/user_categories_page"

const nestedRouteNameObject2 = {
    userCategories:{
        name: "User Categories",
        route: "/services/partners/userCategories",
        component: UserCategoriesPage,
        children: [],
        nestedLevel: 2,
        icon: Star,
      },
      promotionCategories:{
        name: "Promo Categories",
        route: "/services/partners/promotionCategories",
        component: PartnerCategoriesPage,
        children: [],
        nestedLevel: 2,
        icon: Star,
      },
      promotions: {
        name: "Promotions",
        route: "/services/partners/promotions",
        component: PromotionsPage,
        children: [],
        nestedLevel: 2,
        icon: Star,
      },
      merchant:{
        name: "Merchant",
        route: "/services/partners/merchant",
        component: MerchantPage,
        children: [],
        nestedLevel: 2,
        icon: Star,
      },
}

export default nestedRouteNameObject2