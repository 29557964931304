import HomePage from "../../pages/home/home_page";
import GamificationPage from "../../pages/gamification/gamification_page";
import NetworkPlansPage from "../../pages/network_plans/network_plans_page";
import OffersPage from "../../pages/offers/offers_page";
import SummaryPage from "../../pages/summary_stats/summary_page";
import QuestionPage from "../../pages/questions/question_page";
import CreateQuestion from "../../pages/questions/createQuestions/create_new_questions";
import EligibilityMatrix from "../../pages/eligibilityMatrix/eligibilityMatrix";
import PubgFileUpload from "../../pages/PUBG/pubgFileUpload";
import SendNotification from "../../pages/segmentation_notification/parent_notification";
import MessageTemplates from "../../pages/segmentation_notification/message_templates";

import {
  HomeOutlined,
  GamesOutlined,
  NetworkCheck,
  Face,
  SimCard,
  People,
  Receipt,
  LocalOffer,
  PhonelinkSetup,
  DonutLarge,
  Timeline,
  LocationCity,
  LocalHospital,
  Search,
  VideogameAsset,
  ViewCarousel,
  Contacts,
  Equalizer,
  HelpOutline,
  AccountBox,
  Notifications,
  Message,
  PermIdentity 
} from "@material-ui/icons";
import nestedRouteNameObject1 from "./nested_route_name_objects1";
import SimPickupPage from "../../pages/sim_pickup/sim_pickup_page";
import MerchantPromotionsPage from "../../pages/merchant_pages/merchant_promotions/merchant_promotions_page";
import VoucherDetailsPage from "../../pages/merchant_pages/voucher_details/voucher_details_page";
import AdminBloodDonationPage from "../../pages/blood_donation_pages/blood_donation/admin_blood_donation_page";
import BloodDonationSearchPage from "../../pages/blood_donation_pages/search_bd_users/blood_donation_search_page";
import DecorationAssetsPage from "../../pages/decoration_assets/decoration_assets_page";
import CarouselPage from "../../pages/carousel/carousel_page";
import customerServicePage from '../../pages/customer_service/customer_service_page';
import UpdateQuestion from "../../pages/questions/updateQuestions/update_questions";
import QuizSession from "../../pages/questions/question_session/quiz_session";
import CreateQuizSession from "../../pages/questions/question_session/create_quiz_sessions/create_quiz_session";
import ViewQuizSession from "../../pages/questions/question_session/view_sessions/view_session_details";
import CustomerBase from "../../pages/segmentation_notification/customer_base";

const routeNameObjects = {
  home: {
    name: "Home",
    route: "/",
    component: HomePage,
    children: [],
    nestedLevel: 0,
    icon: HomeOutlined,
  },
  gamification: {
    name: "Gamification",
    route: "/gamification",
    component: GamificationPage,
    children: [],
    nestedLevel: 0,
    icon: GamesOutlined,
  },
  myWorld: {
    name: "My World",
    route: "/myWorld",
    component: null,
    nestedLevel: 0,
    icon: Timeline,
    children: [
      nestedRouteNameObject1.myWorldPlugins,
      nestedRouteNameObject1.myWorldPlans,
      nestedRouteNameObject1.myWorldSuperPlans,
      nestedRouteNameObject1.mgmPlans
      // nestedRouteNameObject1.myWorldTiers,
    ],
  },
  networkPlans: {
    name: "Network Plans",
    route: "/networkPlans",
    component: NetworkPlansPage,
    children: [],
    nestedLevel: 0,
    icon: NetworkCheck,
  },
  avatar: {
    route: "/avatar",
    name: "Ibiza Avatar",
    component: null,
    nestedLevel: 0,
    icon: Face,
    children: [
      nestedRouteNameObject1.avatarItems,
      nestedRouteNameObject1.avatarLevels,
      nestedRouteNameObject1.avatarTypes,
    ],
  },
  services: {
    route: "/services",
    name: "Services",
    component: null,
    nestedLevel: 0,
    icon: DonutLarge,
    children: [
      nestedRouteNameObject1.partners,
      // nestedRouteNameObject1.wifiLocations
    ],
  },
  offers: {
    name: "Offers",
    route: "/offers",
    component: OffersPage,
    children: [],
    nestedLevel: 0,
    icon: LocalOffer,
  },


  settings: {
    name: "Settings",
    route: "/settings",
    component: null,
    children: [
      nestedRouteNameObject1.theme,
      nestedRouteNameObject1.error,
      nestedRouteNameObject1.city,
      nestedRouteNameObject1.wilaya,
      nestedRouteNameObject1.simPickup
    ],
    nestedLevel: 0,
    icon: PhonelinkSetup,
  },


  wilaya: {
    name: "Country Info",
    route: "/wilaya",
    component: null,
    children: [
      // nestedRouteNameObject1.country,
    ],
    nestedLevel: 0,
    icon: LocationCity,
  },

  // simPickup: {
  //   name: "MSISDN",
  //   route: "/msisdn",
  //   component: SimPickupPage,
  //   children: [],
  //   nestedLevel: 0,
  //   icon: SimCard,
  // },

  promotions: {
    name: "Promotions",
    route: "/promotions",
    component: MerchantPromotionsPage,
    nestedLevel: 0,
    icon: People,
    children: [],
  },

  voucherDetails: {
    name: "Voucher Details",
    route: "/voucher",
    component: VoucherDetailsPage,
    nestedLevel: 0,
    icon: Receipt,
    children: [],
  },

  bloodDonation: {
    name: "Blood Donation",
    route: "/bloodDonation",
    component: AdminBloodDonationPage,
    children: [],
    nestedLevel: 0,
    icon: LocalHospital,
  },

  searchBDUsers: {
    name: "Search Users",
    route: "/bd_search",
    component: BloodDonationSearchPage,
    children: [],
    nestedLevel: 0,
    icon: Search,
  },

  decorationAssets: {
    name: "Decoration Assets",
    route: "/deco_assets",
    component: DecorationAssetsPage,
    children: [],
    nestedLevel: 0,
    icon: VideogameAsset,
  },

  carousel: {
    name: "Carousel",
    route: "/carousel",
    component: CarouselPage,
    children: [],
    nestedLevel: 0,
    icon: ViewCarousel,
  },

  customerService: {
    name: "Customer Service",
    route: "/customerService",
    component: customerServicePage,
    children: [],
    nestedLevel: 0,
    icon: Contacts,
  },

  summaryStats: {
    name: "Summary Stats",
    route: "/summaryStats",
    component: SummaryPage,
    children: [],
    nestedLevel: 0,
    icon: Equalizer,
  },

  questionsPool: {
    name: "Question Pool",
    route: "/questionPage",
    component: QuestionPage,
    children: [],
    nestedLevel: 0,
    icon: HelpOutline,
  },
  quizSession: {
    name: "Quiz Session",
    route: "/quiz_session",
    component: QuizSession,
    children: [],
    nestedLevel: 0,
    icon: Equalizer,
  },
  createQuizSession: {
    name: "Create New Quiz Session",
    route: "/create_quiz_session",
    component: CreateQuizSession,
    children: [],
    nestedLevel: 0,
    icon: Equalizer,
  },
  viewQuizSession: {
    name: "Session Details",
    route: "/view_quiz_session/:id",
    component: ViewQuizSession,
    children: [],
    nestedLevel: 0,
    icon: Equalizer,
  },
  eligibilityMatrix: {
    name: "Eligibility Matrix",
    route: "/eligibility-matrix",
    component: EligibilityMatrix,
    children: [],
    nestedLevel: 0,
    icon: AccountBox,
  },

  pubG: {
    name: "PUBG",
    route: "/pubg",
    component: PubgFileUpload,
    children: [],
    nestedLevel: 0,
    icon: AccountBox,
  },

  notification: {
    name: "Notification",
    route: "/notification",
    component: SendNotification,
    children: [],
    nestedLevel: 0,
    icon: Notifications,
  },

  messageTemplates: {
    name: "Message Template",
    route: "/message_template",
    component: MessageTemplates,
    children: [],
    nestedLevel: 0,
    icon: Message,
  },

  customerBase: {
    name: "Customer Base",
    route: "/customer_base",
    component: CustomerBase,
    children: [],
    nestedLevel: 0,
    icon: PermIdentity,
  },
};
export default routeNameObjects;
