import React, { useState, useEffect } from "react";
import CommonPage from "../../../components/common_page/common_page";
import { getPartnerCategoriesCall } from "../../../services/api_service/api_service";
import PartnerCategory from "../../../models/partner_category";
import PartnerCategoryTable from "./partner_category_table";
import PartnerCategoryAddModel from "./partner_category_add_model";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import nestedRouteNameObject2 from "../../../services/route_name/nested_route_name_objects2";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";

export default function PartnerCategoriesPage() {
  const [categories, setCategories] = useState<PartnerCategory[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const getPartnerCategories = () => {
    getPartnerCategoriesCall().then((data) => {
      if (data?.success) {
        const cat: PartnerCategory[] = [];
        data.data.forEach((x: any) => cat.push(new PartnerCategory(x)));
        setCategories(cat);
      }
    });
  };

  useEffect(() => {
    getPartnerCategories();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage currentRoute={nestedRouteNameObject2.promotionCategories} parentRoutes={[routeNameObjects.services,nestedRouteNameObject1.partners]}>
      <AddItemBtn width={230} title='Add New Partner Category' onClick={showAddModel} />
    {categories.length > 0 && <PartnerCategoryTable categories={categories} reloadData={getPartnerCategories} />}
     
      <PartnerCategoryAddModel isShow={isShowAddModel} setShow={setShowAddModel} reloadData={getPartnerCategories} />
    </CommonPage>
  );
}
