import React, { useEffect, useState } from "react";
import { TextField, Switch } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import useStyles from "../../../questions/createQuestions/create_question_style";
import NotificationModel from "../../../../models/notification";

interface Props {
    data: NotificationModel | undefined;
    onChange: Function;
}

export default function StaticTemplateName(props: Props) {
    const classes = useStyles();

    return (
        <div className="row">
            <div className="col-auto">
                <label>01.Template Name</label>
            </div>
            <div className="col-md-12">
                <input type="text" value={props.data?.templateName} className="form-control" placeholder="Template Name"></input>
            </div>
        </div>
    );
}
