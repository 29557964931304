import React, { useState, useEffect } from "react";
import useStyles from "./carousel_style";
import clsx from "clsx";
import { useStoreActions, Actions } from "easy-peasy";
import MyModal from "../../components/my_modal/my_modal";
import { StoreModel } from "../../store models/model";
import {
  updateCarouselItemCall,
  uploadAssetCall,
} from "../../services/api_service/api_service";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../components/model_btn/model_btn";
import CarouselModel from "../../models/carousel";
import { MenuItem, Switch, TextField } from "@material-ui/core";
import { carouselTypes } from "./carousel_data";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedCarousel: CarouselModel | undefined;
  reloadData: Function;
}
export default function CarouselUpdateModel(props: Props) {
  const [selected, setSelected] = useState<CarouselModel>();

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    setSelected(props.selectedCarousel);
  }, [props.selectedCarousel]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if (selected) {
      updateCarouselItemCall(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully Updated.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  const iconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(selectedFile);
      img.onload = () => {
        const height = img.height;
        const width = img.width;
        if (height === 2200 && width === 1520) {
          uploadAssetCall(selectedFile).then((data) => {
            if (data?.success) {
              const cat = selected;
              if (cat) {
                cat.bannerUrl = data.data.url;
                setSelected({ ...cat });
              }
            }
          });
        } else {
          showMessage({
            head: "Failed to upload image!",
            body: "Selected image is not 1520px * 2200px",
          });
        }
      }
      // uploadAssetCall(selectedFile).then((data) => {
      //   if (data?.success) {
      //     const cat = selected;
      //     if (cat) {
      //       cat.bannerUrl = data.data.url;
      //       setSelected({ ...cat });
      //     }
      //   }
      // });
    }
  };

  const nameChange = (value: any) => {
    const off = selected;
    if (off) {
      off.name = value;
      setSelected({ ...off });
    }
  };

  const typeChange = (value: any) => {
    const off = selected;
    if (off) {
      off.type = value;
      setSelected({ ...off });
    }
  };

  const isActiveToggle = () => {
    const off = selected;
    if (off) {
      off.active = !off.active;
      setSelected({ ...off });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Update Carousel"}
      setShow={props.setShow}
    >
      <div className={classes.updateOuter}>
        {selected && (
          <div>
            <IconUploadBtn
              mediaUrl={selected.bannerUrl}
              mediaType={"IMAGE"}
              onUpload={iconUpload}
              height={120}
              isEditable={true}
            />
            <p className={classes.imageSizeText}>
              This image should be 1520px * 2200px
            </p>
            <TextField
              className={classes.textField}
              variant="outlined"
              label="Name"
              value={selected.name}
              style={{ width: 400 }}
              onChange={(event) => nameChange(event.target.value)}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              label="Type"
              value={selected.type}
              select
              style={{ width: 400 }}
              onChange={(event) => typeChange(event.target.value)}
            >
              {carouselTypes.map((carousel, index) => (
                <MenuItem key={index} value={carousel}>
                  {carousel}
                </MenuItem>
              ))}
            </TextField>
            <div className={classes.isActiveOuter}>
              <div>Is Active</div>
              <Switch checked={selected.active} onClick={isActiveToggle} />
            </div>
          </div>
        )}

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
