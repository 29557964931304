import React, { useState, useEffect, useCallback } from "react";
import CommonPage from "../../../components/common_page/common_page";
import {
  getPartnerCategoriesCall,
  getPromotionsCall,
  syncPromotionsCall,
} from "../../../services/api_service/api_service";
import PartnerPromotion from "../../../models/partner_promotion";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import nestedRouteNameObject2 from "../../../services/route_name/nested_route_name_objects2";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import PartnerPromotionsGrid from "./partner_promotion_grid";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import { Sync } from "@material-ui/icons";
import { ListItem, TextField } from "@material-ui/core";
import PartnerCategory from "../../../models/partner_category";
import useStyles from './partner_promotion_styles'

export default function PromotionsPage() {
  const classes = useStyles()
  const [promotions, setPromotions] = useState<PartnerPromotion[]>([]);
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const [categories, setCategories] = useState<PartnerCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const getPromotions = (type: any) => {
    getPromotionsCall(type).then((data) => {
      if (data?.success) {
        const promo: PartnerPromotion[] = [];
        console.log(data.data);
        data.data.forEach((x: any) => promo.push(new PartnerPromotion(x)));
        setPromotions(promo);
      }
    });
  };

  const getCategories = useCallback(() => {
    getPartnerCategoriesCall().then((data) => {
      if (data?.success) {
        const c: PartnerCategory[] = [];
        data.data.forEach((x: any) => c.push(new PartnerCategory(x)));
        setCategories(c);
        if (c.length > 0) {
          setSelectedCategory(c[0].id);
          getPromotions(c[0].id);
        }
      }
    });
  },[]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const syncPromo = () => {
    syncPromotionsCall().then((data) => {
      if (data?.success) {
        showMessage({
          head: "Success!",
          body: "You have successfully synced promotions!",
        });
      }
    });
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject2.promotions}
      parentRoutes={[
        routeNameObjects.services,
        nestedRouteNameObject1.partners,
      ]}
    >
      <div
        className={classes.pageUpperRow}
      >
        <TextField
          label={"Category"}
          variant="outlined"
          select
          onChange={(event) => {
            setSelectedCategory(event.target.value);
            getPromotions(event.target.value);
          }}
          value={selectedCategory}
          style={{ width: "250px" }}
        >
          {categories.map((c, index) => (
            <ListItem style={{cursor:'pointer'}} key={index} value={c.id}>
              {c.name[0]?.text}
            </ListItem>
          ))}
        </TextField>
        <AddItemBtn
          icon={<Sync />}
          title="sync promotions"
          onClick={syncPromo}
        />
      </div>

      {promotions.length > 0 && (
        <PartnerPromotionsGrid
          promotions={promotions}
          reloadData={getPromotions}
        />
      )}
    </CommonPage>
  );
}
