import React, { useRef } from "react";
import { Container, CssBaseline, Button } from "@material-ui/core";
import useStyles from "./common_page_style";
import MyAppBar from "../app_bar/app_bar";
import MyDrawer from "../drawer/my_drawer";
import { RouteNames } from "../../services/route_name/route_names";
import PageName from "../page_name/page_name";
import ShowPath from "../show_path/show_path";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface Props {
  children: any;
  currentRoute: RouteNames;
  parentRoutes: RouteNames[];
  title?: string
}

export default function CommonPage(props: Props) {
  const classes = useStyles();
  const scrollRef = useRef<HTMLInputElement>(null);

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MyAppBar
        currentRoute={props.currentRoute}
        parentRoutes={props.parentRoutes}
      />
      <MyDrawer currentRoute={props.currentRoute.route} />
      <main className={classes.content} ref={scrollRef}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <ShowPath
            currentRoute={props.currentRoute}
            parentRoutes={props.parentRoutes}
          />
          <PageName title={!!props.title ? props.title : props.currentRoute.name} />
          {props.children}
          <Button
            variant="contained"
            color="primary"
            className={classes.topButton}
            classes={{ startIcon: classes.startIcon }}
            size="large"
            onClick={scrollToTop}
            startIcon={<ExpandLessIcon />}
          >
          </Button>
        </Container>
      </main>
    </div>
  );
}
