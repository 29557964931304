import React, { useEffect, useState } from "react";
import CommonPage from "../../../../components/common_page/common_page";
import routeNameObjects from "../../../../services/route_name/route_name_objects";
import { Card, Tab, Tabs } from "@material-ui/core";
import useStyles from "../../createQuestions/create_question_style";
import PageTab from "../../../../components/page_tab/page_tab";
import InfoQuizSessionDetails from "./info_session_details";
import QuizResultTable from "./quiz_result_details";
import LeaderBoardTable from "./leaderboard";
import { getSessionResult } from "../../../../services/api_service/api_service";
import { getLeaderboardResult } from "../../../../services/api_service/api_service";
import { useParams } from 'react-router-dom';
import LeaderBoard from "../../../../models/leaderboard";
import { getSingleSession } from "../../../../services/api_service/api_service";
import QuizSessionModel from "../../../../models/quiz_Session";
import MyPaginator from "../../../../components/my_paginator/my_paginator";
import AddItemBtn from "../../../../components/add_item_btn/add_item_btn";
import * as FileSaver from 'file-saver';
import useStylesSim from "../../../sim_pickup/sim_pickup_page_style";
import clsx from "clsx";
import axios from 'axios';

interface Props {
    onSubmit: Function
    id: string
}

export default function ViewQuizSession(props: Props) {
    const classes = useStyles();
    const classes2 = useStylesSim();
    const [quizSession, setQuizList] = useState<LeaderBoard[]>([]);
    const [quizResultSession, setQuizResultList] = useState<LeaderBoard[]>([]);
    const [quizSessionDetails, setQuizSessionDetails] = useState<QuizSessionModel | null>(null);

    const [pageCount, setPageCount] = useState(0);
    const [pageCountLeaderboard, setPageCountLeaderboard] = useState(10);
    const [sessionId, setSessionId] = useState("");

    const [resultArray, setResultArray] = useState<any>([])
    const getQuizSessionLeaderboardResult = (page: number) => {
        getLeaderboardResult(props.id).then((data) => {
            setSessionId(props.id)
            const res = data?.data
            setResultArray(res)
            if (data?.success) {
                const d: LeaderBoard[] = [];
                data.data?.forEach((x: any) => d.push(new LeaderBoard(x)));
                setQuizList(d);
                setPageCountLeaderboard(data.data?.totalPages);
            }
        });
    };

    const getQuizSessionResult = (page: number) => {
        getSessionResult(props.id, page).then((data) => {
            const res = data?.data.content
            setResultArray(res)
            if (data?.success) {
                const d: LeaderBoard[] = [];
                data.data?.content?.forEach((x: any) => d.push(new LeaderBoard(x)));
                setQuizResultList(d);
                setPageCount(data.data?.totalPages);
            }
        });
    };

    const getQuizSessionDetails = () => {
        getSingleSession(props.id).then((data) => {
            if (data?.success) {
                const d: QuizSessionModel = data.data
                setQuizSessionDetails(d);
            }
        });
    };

    function generateReports(sessionId: string) {
        const url = `${process.env.REACT_APP_API_BASE_QUIZ_URL}utility/pdfreport/${sessionId}`
        fetch(url, { headers: { "template": 'LEADERBOARD', Authorization: `Bearer ${sessionStorage.getItem("ibiza_token")}` } }).then(async response => {
            const filename = response.headers.get('content-disposition')?.split('filename=')[1];
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = String(filename);
                a.click();
            })
        })
    }

    const onPageChange = (page: number) => {
        getQuizSessionResult(page - 1);
    };

    const onPageChangeLeaderboard = (page: number) => {
        getQuizSessionLeaderboardResult(page - 1)
    };

    useEffect(() => {
        getQuizSessionLeaderboardResult(0);
        getQuizSessionResult(0)
        getQuizSessionDetails()
    }, []);

    return (
        <Card className={classes.cardSize}>
            <AddItemBtn title="Back" onClick={() => { props.onSubmit() }} />
            <PageTab
                tabsData={[
                    {
                        title: "Session Details",
                        component: <InfoQuizSessionDetails quizSessionDetails={quizSessionDetails} reloadData={getSingleSession}></InfoQuizSessionDetails>
                    },
                    {
                        title: "Quiz Result",
                        component: <QuizResultTable quizResultSession={quizResultSession} reloadData={getSessionResult} onPagination={onPageChange} pageCount={pageCount}></QuizResultTable>

                    },
                    {
                        title: "Leader Board",
                        component: <LeaderBoardTable sessionID={sessionId} quizSession={quizSession} download={generateReports} reloadData={getLeaderboardResult} onPaginationLeaderboard={onPageChangeLeaderboard} pageCountLeaderboard={pageCountLeaderboard}></LeaderBoardTable>
                    },
                ]}
            />
        </Card>
    );
}
