import React, { useEffect, useState } from "react";
import { TextField, Switch } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import useStyles from "../../questions/createQuestions/create_question_style";
import NotificationModel from "../../../models/notification";

interface Props {
    data: NotificationModel;
    onChange: Function;
}

export default function AddTemplateName(props: Props) {
    const classes = useStyles();
    const [templateName, setTemplateName] = useState("")

    useEffect(() => {
        if (props.data) {
            setTemplateName(props.data?.templateName)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.templateName = templateName
                props.onChange(value)
            }
        }
    }, [templateName]);

    return (
        <div className="row">
            <div className="col-auto">
                <label>01.Template Name</label>
            </div>
            <div className="col-md-12">
                <input type="text" value={templateName} onChange={(event) => setTemplateName(event.target.value)} className="form-control" placeholder="Template Name"></input>
            </div>
        </div>
    );
}
