import Content from "./content";

class Offer {
  id: string;
  mediaType: string;
  mediaUrl: string;
  status: string;
  createdDate: Date;
  content: Content[] = [];

  constructor(data: any) {
    this.id = data.id;
    this.mediaType = data.mediaType;
    this.mediaUrl = data.mediaUrl;
    this.status = data.status;
    this.createdDate = data.createdDate;
    data.content.forEach((x: any) => this.content.push(new Content(x)));
  }
}

export const emptyOffer = {
  mediaType: undefined,
  mediaUrl: undefined,
  content: [
    {
      language: "EN",
      header: undefined,
      description: undefined,
    },
  ],
};
export default Offer;
