import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import useStyles from "./my_world_plugins_style";
import clsx from "clsx";
import { useStoreActions, Actions } from "easy-peasy";
import MyWorldPlugin, { emptyPlugin } from "../../../models/my_world_plugin";
import { StoreModel } from "../../../store models/model";
import IconBtn from "../../../components/icon_btn/icon_btn";
import { Remove, Add } from "@material-ui/icons";
import {
  uploadAssetCall,
  addNewMyWorldPlugin,
} from "../../../services/api_service/api_service";
import MyModal from "../../../components/my_modal/my_modal";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function MyWorldPluginsAddModel(props: Props) {
  const classes = useStyles();

  const [selected, setSelected] = useState<MyWorldPlugin>(
    new MyWorldPlugin(emptyPlugin)
  );
  const [extraValues, setExtraValues] = useState<
    { key: string; value: string }[]
  >([]);
  useEffect(() => {
    if (props.isShow) {
      setSelected(new MyWorldPlugin(emptyPlugin));
      setExtraValues([]);
    }
  }, [props.isShow]);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );
  const closeModal = () => {
    props.setShow(false);
  };

  const nameChange = (event: any) => {
    const prev = selected;
    prev.referenceName = event.target.value;
    setSelected({ ...prev });
  };

  const codeChange = (event: any) => {
    const prev = selected;
    prev.code = event.target.value;
    setSelected({ ...prev });
  };

  const extraValueChange = (value: any, index: number) => {
    const prev = extraValues;
    prev[index].value = value;
    setExtraValues([...prev]);
  };

  const extraValueKeyChange = (key: any, index: number) => {
    const prev = extraValues;
    prev[index].key = key;
    setExtraValues([...prev]);
  };

  const addExtraValue = () => {
    const prev = extraValues;
    prev.push({ key: "", value: "" });
    setExtraValues([...prev]);
  };

  function removeExtraValue(index: number) {
    const sl = selected;
    if (sl) {
      sl.extraValues.splice([{ key: "", value: "" }]);
      // sl.extraValues.splice(index, 1);
      setSelected({ ...sl });
    }
  }

  const onImageUpload = (event: any, type: string, isCheckSize: boolean) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const height = img.height;
        const width = img.width;
        if (isCheckSize) {
          if (height === 200 && width === 200) {
            uploadAssetCall(file).then((data) => {
              if (data?.success) {
                const value = data.data.url;
                const prev = selected;
                if (type === "logo") {
                  prev.logoUrl = value;
                } else if (type === "large") {
                  prev.largeLogoUrl = value;
                } else if (type === "medium") {
                  prev.mediumLogoUrl = value;
                }
                setSelected({ ...prev });
              }
            });
          } else {
            showMessage({
              head: "Failed to upload image!",
              body: "Selected image is not 200px * 200px",
            });
          }
        } else {
          uploadAssetCall(file).then((data) => {
            if (data?.success) {
              const value = data.data.url;
              const prev = selected;
              if (type === "logo") {
                prev.logoUrl = value;
              } else if (type === "large") {
                prev.largeLogoUrl = value;
              } else if (type === "medium") {
                prev.mediumLogoUrl = value;
              }
              setSelected({ ...prev });
            }
          });
        }
      };
    }
  };

  const saveBtnClicked = () => {
    if (!selected.logoUrl) {
      showMessage({ head: "Failed!", body: "Please select image." });
      return;
    }
    if (!selected.referenceName) {
      showMessage({ head: "Failed!", body: "Please enter name." });
      return;
    }

    const extraValueObj: any = {};

    extraValues.forEach((extraValue, index) => {
      extraValueObj[extraValue.key] = extraValue.value;
    });

    selected.extraValues = extraValueObj;

    addNewMyWorldPlugin(selected).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new plugin.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Add new Plugin"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <div className={classes.column}>
          <IconUploadBtn
            mediaUrl={selected.logoUrl}
            mediaType={"IMAGE"}
            onUpload={(event: any) => {
              onImageUpload(event, "logo", true);
            }}
            allowVideo={false}
            height={120}
            isEditable={true}
          />
          <p className={classes.imageTitle}>Logo</p>
          <p className={classes.imageSizeText}>
            This image should be 200px * 200px
          </p>
          {/* <div className={classes.imageRow}>
            <div>
              <IconUploadBtn
                mediaUrl={selected.mediumLogoUrl}
                mediaType={"IMAGE"}
                onUpload={(event: any) => {
                  onImageUpload(event, "medium",false);
                }}
                allowVideo={false}
                height={120}
                isEditable={true}
              />
              <p className={classes.imageTitle}>Medium Logo</p>
            </div>
            <div>
              <IconUploadBtn
                mediaUrl={selected.largeLogoUrl}
                mediaType={"IMAGE"}
                onUpload={(event: any) => {
                  onImageUpload(event, "large",false);
                }}
                allowVideo={false}
                height={120}
                isEditable={true}
              />
              <p className={classes.imageTitle}>Large Logo</p>
            </div>
          </div> */}
          <TextField
            variant="outlined"
            className={classes.textField}
            value={selected.referenceName}
            label="Name"
            onChange={nameChange}
          />

          <TextField
            variant="outlined"
            className={classes.textField}
            value={selected.code}
            label="Code"
            onChange={codeChange}
          />

          <ModelFieldWrapper>
            <ModelLabel
              title={"Extra Values"}
              onClick={addExtraValue}
              noMargin
            />
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow >
                  <TableCell className={classes.greyLabel}>Key</TableCell>
                  <TableCell className={classes.greyLabel}>Value</TableCell>
                  <TableCell className={classes.greyLabel}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {extraValues.map((value, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      <TextField
                        onChange={(event) =>
                          extraValueKeyChange(event.target.value, index)
                        }
                        value={value.key}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        onChange={(event) =>
                          extraValueChange(event.target.value, index)
                        }
                        value={value.value}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>
                      {/* <span style={{ position: "relative", left: "10px", height: "56px", display: "flex", justifyContent: "center", top: "10px" }}> <IconBtn
                        icon={Remove}
                        onClick={() => removeExtraValue(index)}
                        isCenter
                      /></span> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ModelFieldWrapper>
        </div>
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Close"} onClick={closeModal} />
        <ModelBtn title={"save"}  onClick={saveBtnClicked} />
      </div>
    </MyModal>
  );
}
