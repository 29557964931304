import React from "react";
import MyWorldPlugin from "../../models/my_world_plugin";
import { Card } from "@material-ui/core";
import useStyles from "./plugin_card_style";

export default function PluginCard(props: {
  plugin: { plan: MyWorldPlugin; amount?: number; unit?: string };
}) {
  const classes = useStyles();
  return (
    <Card elevation={3} className={classes.card}>
      <div className={classes.image}>
        {props.plugin.plan.logoUrl ? (
          <img
            height="100px"
            width="100px"
            src={props.plugin.plan.logoUrl}
            alt={"plugin icon"}
          />
        ) : (
          <div />
        )}
      </div>
      <div className={classes.line} />
      <div>
        <Detail keyName="Name" value={props.plugin.plan.referenceName} />
        {props.plugin.amount && (
          <Detail keyName="Value" value={props.plugin.amount} />
        )}
        {props.plugin.unit && (
          <Detail keyName="Unit" value={props.plugin.unit} />
        )}
        {props.plugin.plan.extraValues &&
          Object.keys(props.plugin.plan.extraValues).map((key, index) => (
            <Detail
              key={index}
              keyName={key}
              value={props.plugin.plan.extraValues[key]}
            />
          ))}
      </div>
    </Card>
  );
}

export function Detail(props: { keyName: string; value: any }) {
  const classes = useStyles();
  return (
    <div className={classes.detailOuter}>
      <div className={classes.keyName}>{props.keyName}</div>
      <div className={classes.colen}>{":"}</div>
      <div className={classes.valueName}>{props.value}</div>
    </div>
  );
}
