import {
  AppBar,
  Card,
  Tab,
  Tabs,
  TextareaAutosize,
  useTheme,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import languageTypes from "../../pages/finek/finek_items/language_types";
import useStyles from "./lang_and_text_edit_style";
import ModelLabel from "../model_label/model_label";
import IconBtn from "../icon_btn/icon_btn";
import SwipeableViews from "react-swipeable-views";
import clsx from "clsx";
import Content from "../../models/content";

interface Props {
  data: Content[] | undefined;
  label: string;
  setData: Function | null;
  isEditable: boolean;
}
export default function LangAndHeaderEditTabs(props: Props) {
  const classes = useStyles();

  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);

  const [isHover, setHover] = useState(false);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const addNewLine = () => {
    const prev = props.data;
    if (prev && props.setData && prev.length <= 3) {
      switch (prev.length) {
        case 0:
          prev.push(
            new Content({
              language: languageTypes[0],
              header: undefined,
              description: undefined,
            })
          );
          break;
        case 1:
          prev.push(
            new Content({
              language: languageTypes[1],
              header: undefined,
              description: undefined,
            })
          );
          break;
        case 2:
          prev.push(
            new Content({
              language: languageTypes[2],
              header: undefined,
              description: undefined,
            })
          );
          break;
      }

      props.setData(prev);
    }
  };

  const onHeaderChange = (header: any, index: number) => {
    const prev = props.data;
    if (prev && props.setData) {
      prev[index].header = header;
      props.setData(prev);
    }
  };

  const onDescriptionChange = (description: any, index: number) => {
    const prev = props.data;
    if (prev && props.setData) {
      prev[index].description = description;
      props.setData(prev);
    }
  };

  return (
    <div>
      <Card
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        elevation={isHover ? 10 : 3}
        className={classes.outerCard}
      >
        <ModelLabel title={props.label} noMargin />
        <div style={{ padding: "10px" }}>
          <AppBar className={classes.tabAppBar}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              classes={{ indicator: classes.tabSelected }}
              className={classes.tabRow}
            >
              {props.data?.map((lang, index) => (
                <Tab
                  key={index}
                  className={clsx(
                    classes.tabStyle,
                    selectedTab === index && classes.tabStyleSelected
                  )}
                  label={lang.language}
                />
              ))}
            </Tabs>
            {props.isEditable && (
              <div className={classes.addRowIconWrap}>
                <IconBtn icon={Add} onClick={addNewLine} />
              </div>
            )}
          </AppBar>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={selectedTab}
          >
            {props.data?.map((lang, index) => (
              <div
                key={index}
                className={clsx(classes.listPadding, classes.contentWrapper)}
                hidden={selectedTab !== index}
              >
                <div className={classes.textAreaLabel}>Header</div>
                <TextareaAutosize
                  className={classes.textArea}
                  value={lang.header}
                  onChange={
                    props.isEditable
                      ? (event) => onHeaderChange(event.target.value, index)
                      : (event) => {}
                  }
                  readOnly={!props.isEditable}
                />
                <div className={classes.textAreaLabel}>Description</div>
                <TextareaAutosize
                  className={classes.textArea}
                  value={lang.description}
                  onChange={
                    props.isEditable
                      ? (event) =>
                          onDescriptionChange(event.target.value, index)
                      : (event) => {}
                  }
                  readOnly={!props.isEditable}
                />
              </div>
            ))}
          </SwipeableViews>
        </div>
      </Card>
    </div>
  );
}
