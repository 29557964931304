class EligibleLevel {
  id: string;
  level: number;
  exPoints: number;
  name: string;
  tierId: string;

  constructor(data: any) {
    this.id = data.id;
    this.level = data.level;
    this.exPoints = data.exPoints;
    this.name = data.name;
    this.tierId = data.tierId;
  }
}

export const emptyLevel = {
  id: undefined,
  level: undefined,
  exPoints: undefined,
  name: undefined,
  tierId: undefined,
};

export default EligibleLevel;
