import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',

  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  root: {
    display: "flex",
    backgroundColor: '#eaeaea3d'
  },
  topButton: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
  },
  startIcon: {
    margin: 0,
  },
}));

export default useStyles