import React from "react";
import { Toolbar, AppBar, Button, Typography, Menu, MenuItem, IconButton } from "@material-ui/core";
import { ArrowDropDown, Notifications, Person } from "@material-ui/icons";
import useStyles from "./app_bar_style";
import clsx from "clsx";
import { RouteNames } from "../../services/route_name/route_names";
import MyNavigator from "../../services/navigation";
import routeNameObjects from "../../services/route_name/route_name_objects";
import KeyCloakService from "../../services/keycloak/keycloak_service";
import { StoreModel } from "../../store models/model";
import { useStoreState } from "easy-peasy";

interface Props {
  currentRoute: RouteNames;
  parentRoutes: RouteNames[];
}

export default function MyAppBar(props: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { replaceToPage } = MyNavigator();

  const handleAdminClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const {logout} = KeyCloakService()

  const Icon = props.parentRoutes[0].icon;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    
    logout(()=>{
      handleClose();
      replaceToPage(routeNameObjects.home);
    })
    
  };

  const keyCloakUser = useStoreState((state:StoreModel)=>state.keyCloakUserModel.authUser)
  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          <div className={classes.iconRow}>
            <Icon className={clsx(classes.mainIcon,classes.greyIcon)} />
            <span className={classes.greyIcon} >{props.parentRoutes[0].name}</span>
            <IconButton className={clsx(classes.greyIcon,classes.notificationIcon)}>
              <Notifications />
            </IconButton>
          </div>
        </Typography>
        {/* <SearchBar/> */}
        <Button  onClick={handleAdminClick}>
          <Person className={classes.greyIcon} />
          <span className={clsx(classes.greyIcon, classes.titleCase)}>
            {keyCloakUser?.name}
          </span>
          <ArrowDropDown className={classes.greyIcon} />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem style={{ width: "120px" }} onClick={handleLogout}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
