import { Card, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./partner_promotion_card_style";
import FadeIn from "react-fade-in";
import PartnerPromotion from "../../models/partner_promotion";
import clsx from "clsx";
import { MoreVert } from "@material-ui/icons";

interface Props {
  value: PartnerPromotion;
  onView: Function;
  onUpdate?: Function;
  onDelete?: Function;
}
export default function PartnerPromotionCard(props: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isHover, setHover] = useState(false);
  return (
    <Grid item>
      <FadeIn>
        <Card
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={classes.gridCard}
          elevation={isHover ? 10 : 2}
        >
          <IconButton className={classes.addIcon} onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                props.onView(props.value);
              }}
            >
              View
            </MenuItem>
            {props.onUpdate && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  if (props.onUpdate) {
                    props.onUpdate(props.value);
                  }
                }}
              >
                Update
              </MenuItem>
            )}
            {props.onDelete && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  if (props.onDelete) {
                    props.onDelete(props.value);
                  }
                }}
              >
                Delete
              </MenuItem>
            )}
          </Menu>
          <div className={classes.userType}>{props.value.userType?.name}</div>
          <div >
            <div
            onClick={() => props.onView(props.value)}
              style={{
                backgroundImage: `url('${props.value.imageUrl}')`,
              }}
              className={classes.gridImage}
            />

            <div className={classes.contentPadding}>
              <div className={classes.name}>{props.value.name[0]?.text}</div>

              <div className={classes.line} />
              <div className={classes.description}>
                {props.value.description[0]?.text}
              </div>
            </div>
            <div className={classes.newRow}>
              {props.value.category && (
                <div
                  className={clsx(
                    classes.nestedRoundItem,
                    classes.categoryName
                  )}
                >
                  {props.value.category.name[0]?.text}
                </div>
              )}
              {props.value.merchant && (
                <div
                  className={clsx(classes.nestedRoundItem, classes.merchatName)}
                >
                  {props.value.merchant.name}
                </div>
              )}
            </div>
          </div>
        </Card>
      </FadeIn>
    </Grid>
  );
}
