import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./mgm_plans_style";
import MyModal from "../../../components/my_modal/my_modal";
import { unitValues, validityValues } from "../my_world_plans/my_world_plans_data";
import MyWorldPlugin, { emptyPlugin } from "../../../models/my_world_plugin";
import {
  addMGMPlansItemCall,
  getMyWorldPlugins,
} from "../../../services/api_service/api_service";
import { Remove } from "@material-ui/icons";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import IconBtn from "../../../components/icon_btn/icon_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";
import MGMPlan, { emptyMGMPlan } from "../../../models/mgm_plan";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function MGMPlansAddModel(props: Props) {
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const [selected, setSelected] = useState<MGMPlan>(
    new MGMPlan(emptyMGMPlan)
  );

  const [plugins, setPlugins] = useState<MyWorldPlugin[]>([]);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const getPlugins = () => {
    getMyWorldPlugins().then((data) => {
      if (data?.success) {
        const pl: MyWorldPlugin[] = [];
        data.data.forEach((x: any) => pl.push(new MyWorldPlugin(x)));
        setPlugins(pl);
      }
    });
  };

  useEffect(() => {
    if (props.isShow) {
      getPlugins();
      setSelected(new MGMPlan(emptyMGMPlan));
    }
  }, [props.isShow]);

  function planNameChange(name: any) {
    const sl = selected;
    if (sl) {
      sl.planName = name;
      setSelected({ ...sl });
    }
  }

 

  function pointsChange(points: any) {
    const sl = selected;
    if (sl) {
      sl.exPoints = points;
      setSelected({ ...sl });
    }
  }

  function bundleIdChange(bindleId: any) {
    const sl = selected;
    if (sl) {
      sl.bundleId = bindleId;
      setSelected({ ...sl });
    }
  }

  function bundleCodeChange(bundleCode: any) {
    const sl = selected;
    if (sl) {
      sl.bundleCode = bundleCode;
      setSelected({ ...sl });
    }
  }

 
  function validityChange(validity: any) {
    const sl = selected;
    if (sl) {
      sl.validity = validity;
      setSelected({ ...sl });
    }
  }

 

  function limitedPluginChange(index: number, id: any) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins[index].pluginId = id;
      setSelected({ ...sl });
    }
  }

  function limitedPluginPriceChange(index: number, amt: any) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins[index].amount = Number(amt);
      sl.limitedPluginsData[index].amount = Number(amt);
      setSelected({ ...sl });
    }
  }

  function limitedPluginUnitChange(index: number, unit: any) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins[index].unit = unit;
      sl.limitedPluginsData[index].unit = unit;
      setSelected({ ...sl });
    }
  }

  function unLimitedPluginChange(index: number, id: any) {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins[index] = id;
      setSelected({ ...sl });
    }
  }

  function addLimitedPlugin() {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins.push({ pluginId: "", amount: 0, unit: "GB" });
      sl.limitedPluginsData.push({ plan: new MyWorldPlugin(emptyPlugin), amount: 0, unit: "GB" });
      setSelected({ ...sl });
    }
  }

  function removeLimitedPlugin(index: number) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins.splice(index, 1);
      sl.limitedPluginsData.splice(index, 1);
      setSelected({ ...sl });
    }
  }

  function addUnlimitedPlugin() {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins.push("");
      sl.unLimitedPluginsData.push(new MyWorldPlugin(emptyPlugin) );
      setSelected({ ...sl });
    }
  }

  function removeUnLimitedPlugin(index: number) {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins.splice(index, 1);
      sl.unLimitedPluginsData.splice(index, 1);
      setSelected({ ...sl });
    }
  }

  function onSave() {
    if (selected) {
      if (!selected.planName) {
        showMessage({
          head: "Failed!",
          body: "Please enter plan name",
          color: "red",
        });
        return;
      }
     
      if (!selected.exPoints) {
        showMessage({
          head: "Failed!",
          body: "Please enter ex points",
          color: "red",
        });
        return;
      }

      if (!selected.bundleId) {
        showMessage({
          head: "Failed!",
          body: "Please enter Bundle Id",
          color: "red",
        });
        return;
      }

      if (!selected.bundleCode) {
        showMessage({
          head: "Failed!",
          body: "Please enter Bundle Code",
          color: "red",
        });
        return;
      }
      if (!selected.validity) {
        showMessage({
          head: "Failed!",
          body: "Please select validity",
          color: "red",
        });
        return;
      }
    
      addMGMPlansItemCall(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully added new mgm plan.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  }

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Add New MGM Plan"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <div className={classes.row}>
          <TextField
            label="Plan Name"
            value={selected?.planName}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => planNameChange(event.target.value)}
          />
        </div>


        <div className={classes.row}>
          <TextField
            type="number"
            label="Ex Points"
            value={selected?.exPoints}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => pointsChange(event.target.value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="number"
            label="Bundle Id"
            value={selected?.bundleId}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => bundleIdChange(event.target.value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="text"
            label="Bundle Code"
            value={selected?.bundleCode}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => bundleCodeChange(event.target.value)}
          />
        </div>

       

        <div className={classes.row}>
          <TextField
            label="Validity"
            value={selected?.validity}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => validityChange(event.target.value)}
            select
          >
            {validityValues.map((val, index) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            ))}
          </TextField>
        </div>

       
        {plugins.length > 0 && (
          <ModelFieldWrapper>
            <ModelLabel
              title={"Limited Plugins"}
              onClick={addLimitedPlugin}
              noMargin
            />

            {selected?.limitedPlugins.map((plugin, index) => (
              <div
                key={index}
                className={clsx(
                  classes.row,
                  classes.alignCenter,
                  classes.relative
                )}
              >
                <TextField
                  label="Limited Plugins"
                  value={plugin.pluginId}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "100%" }}
                  onChange={(event) =>
                    limitedPluginChange(index, event.target.value)
                  }
                  select
                >
                  {plugins.map((plug, index) => (
                    <MenuItem key={index} value={plug.id}>
                      {plug.referenceName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Amount"
                  value={plugin.amount}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "200px" }}
                  onChange={(event) =>
                    limitedPluginPriceChange(index, event.target.value)
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <TextField
                  label="Unit"
                  value={plugin.unit}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "200px" }}
                  onChange={(event) => {
                    limitedPluginUnitChange(index, event.target.value);
                  }}
                  select
                >
                 {unitValues.map((uni, index) => (
                    <MenuItem key={index} value={uni}>
                      {uni}
                    </MenuItem>
                  ))}
                </TextField>
                <IconBtn
                  icon={Remove}
                  onClick={() => removeLimitedPlugin(index)}
                  isCenter
                />
              </div>
            ))}
          </ModelFieldWrapper>
        )}
        {plugins.length > 0 && (
          <ModelFieldWrapper>
            <ModelLabel
              title={"Unlimited Plugins"}
              onClick={addUnlimitedPlugin}
              noMargin
            />
            {selected?.unlimitedPlugins.map((plugin, index) => (
              <div
                key={index}
                className={clsx(
                  classes.row,
                  classes.alignCenter,
                  classes.relative
                )}
              >
                <TextField
                  label="Unlimited Plugins"
                  value={plugin}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "100%" }}
                  onChange={(event) =>
                    unLimitedPluginChange(index, event.target.value)
                  }
                  select
                >
                  {plugins.map((plug, index) => (
                    <MenuItem key={index} value={plug.id}>
                      {plug.referenceName}
                    </MenuItem>
                  ))}
                </TextField>
                <IconBtn
                  icon={Remove}
                  onClick={() => removeUnLimitedPlugin(index)}
                  isCenter
                />
              </div>
            ))}{" "}
          </ModelFieldWrapper>
        )}
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Save"} onClick={onSave} />
        <ModelBtn title={"close"} onClick={closeModal} />
      </div>
    </MyModal>
  );
}
