import React, { useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import dataCardStyles from "./bundle_data_card_styles";
import time from "../../../../services/time";

interface IProps {
    data: Object;
}
export function BundleDataCard(props: IProps) {
    const dataCardStyle = dataCardStyles();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [dialogData, setDialogData] = useState<{ head: string, data: Object[] }>({ head: '', data: [{}] })

    const handleClose = () => {
        setDialogOpen(false)
        setDialogData({ head: '', data: [{}] })
    }

    const handleValue = (value: any) => {

        let pattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/
        let datePattern = /\d{4}-[01]\d-[0-3]\dT.*[+]/
        if (pattern.test(value)) {
            return (<img src={value} alt="" width="60px" />)
        }
        else if (datePattern.test(value)) {
            return time.timeStringToDate(value)
        }
        else {
            return handleEmpty(value)
        }
    }

    const handleEmpty = (value: any) => {
        let output: any
        if (value === '' || value === null) {
            output = "-"
        }
        else if (typeof value === 'boolean') {
            if (value) {
                output = 'True'
            }
            else {
                output = 'False'
            }
        }
        else {
            output = value
        }
        return output

    }

    const formatKey = (value: string) => {
        let output: string
        if (!!value) {
            output = value.match(/([A-Z]?[^A-Z]*)/g)!.slice(0, -1).join(" ")
            output = output.replace('I D', 'ID')
            output = output.replace('4 G', ' 4G')
            output = output.replace('3 G', ' 3G')

            if (value === "limitedAccountListInformations") {
                output = "Limited Account List"
            }

            if (value === "unlimitedAccountListInformations") {
                output = "Unlimited Account List"
            }

            if (value === "purchaseDate") {
                output = "Purchase Date and Time"
            }

            if (value === "endTime") {
                output = "Subscription End Date and Time"
            }
        }
        else {
            output = "unknown"
        }

        return output

    }

    const displayData = (key: string, value: any) => {
        if (key === "unlimitedAccountListInformations") {
            let bundle = Reflect.get(value, "unlimitedAccountListInformation")
            return (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            setDialogData({ head: key, data: bundle })
                            setDialogOpen(true)
                        }}
                    >
                        Show
                    </Button> </>
            )
        }
    }
    return (
        <Paper className={dataCardStyle.container} elevation={3}>
            <TableContainer component={Paper}>
                <Table stickyHeader size="small" >
                    <TableBody>
                        {
                            Object.keys(props.data).map((key: string, i) => {
                                let value = Reflect.get(props.data, key)
                                if (key === "unlimitedAccountListInformations" || key === "limitedAccountListInformations") {
                                    let bundle = Reflect.get(value, key === "unlimitedAccountListInformations" ? "unlimitedAccountListInformation" : 'limitedaccountInformation')
                                    return (
                                        <TableRow
                                            hover
                                            key={i}
                                        >
                                            <TableCell className={dataCardStyle.rowTitle} align='left'>
                                                {formatKey(key)}
                                            </TableCell>
                                            <TableCell className={dataCardStyle.tableData} align='center'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        setDialogData({ head: key, data: bundle })
                                                        setDialogOpen(true)
                                                    }}
                                                >
                                                    Show
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                else {
                                    return (
                                        <TableRow
                                            hover
                                            key={i}
                                        >
                                            <TableCell className={dataCardStyle.rowTitle} align='left'>
                                                {formatKey(key)}
                                            </TableCell>
                                            <TableCell className={dataCardStyle.tableData} >
                                                {handleValue(value)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }


                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={dialogOpen}
                onClose={handleClose}
            >
                <DialogTitle >{formatKey(dialogData.head)}</DialogTitle>
                <DialogContent>
                    {
                        dialogData.data.length === 0 ?
                            <div className={dataCardStyle.emptyTable}>
                                This Field Is Empty
                            </div>

                            :
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {
                                            Object.keys(dialogData.data[0]).map(key => {
                                                return (
                                                    <TableCell className={dataCardStyle.rowTitle}>{formatKey(key)}</TableCell>
                                                )

                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogData.data.map((row: Object, i) => (
                                        <TableRow key={i}>
                                            {
                                                Object.keys(row).map(key => {
                                                    return (
                                                        <TableCell className={dataCardStyle.tableData}>
                                                            {handleValue(Reflect.get(row, key))}
                                                        </TableCell>
                                                    )

                                                })
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleClose()

                    }} color="primary" autoFocus>
                        ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

