import React, { useState, useEffect } from "react";
import useStyles from "./partner_category_style";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import PartnerCategory from "../../../models/partner_category";
import {
  uploadAssetCall,
  updatePartnerCategory,
} from "../../../services/api_service/api_service";
import LangAndText from "../../../models/lang_and_text";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedCategory: PartnerCategory | undefined;
  reloadData: Function;
}
export default function PartnerCategoryUpdateModel(props: Props) {
  const [selectedCategory, setSelectedCategory] = useState<PartnerCategory>();

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    setSelectedCategory(props.selectedCategory);
  }, [props.selectedCategory]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if (selectedCategory) {
      updatePartnerCategory(selectedCategory).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully Updated partner Category.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  const iconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const cat = selectedCategory;
          if (cat) {
            cat.imageUrl = data.data.url;
            setSelectedCategory({ ...cat });
          }
        }
      });
    }
  };

  const nameChange = (x: LangAndText[]) => {
    const prev = selectedCategory;
    if (prev) {
      prev.name = x;
      setSelectedCategory({ ...prev });
    }
  };

  const descriptionChange = (x: LangAndText[]) => {
    const prev = selectedCategory;
    if (prev) {
      prev.description = x;
      setSelectedCategory({ ...prev });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
       
      title={"Update partner Category"}
      setShow={props.setShow}
    >
      <div>
        {selectedCategory && (
          <IconUploadBtn
            mediaUrl={selectedCategory.imageUrl}
            mediaType={"IMAGE"}
            onUpload={iconUpload}
            allowVideo={false}
            height={120}
            isEditable={true}
          />
        )}

        <LangAndTextEditTabs
          data={selectedCategory?.name}
          label="Name"
          isEditable={true}
          setData={nameChange}
        />
        <LangAndTextEditTabs
          data={selectedCategory?.description}
          label="Description"
          isEditable={true}
          setData={descriptionChange}
        />

        <div className={clsx(classes.row, classes.right)}>
          
          <ModelBtn title={'Close'} onClick={closeModal} />
        <ModelBtn title={'save'} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
