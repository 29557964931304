export default class EligibilityMatrixServiceAccountList {
    id: string;
    accountId: number | null;
    accountName: string;
    active: boolean;

    constructor(data: any) {
        this.id = data.id;
        this.accountId = data.accountId;
        this.accountName = data.accountName;
        this.active = data.active;
    }

}
export const emptyServiceList = {
    accountId: null,
    accountName: "",
    active: false
};
