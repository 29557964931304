import React, { useEffect, useState } from "react";
import useStyles from "../questions/question_style";
import MyModal from "../../components/my_modal/my_modal";
import { Button } from "@material-ui/core";
import { StoreModel } from "../../store models/model";
import { Actions, useStoreActions } from "easy-peasy";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import MyNavigator from "../../services/navigation";
import { MockData } from "./mockData";
import * as XLSX from "xlsx";
import TemplatesGrid from "./templates_grid"

interface Props {
    isShow: boolean;
    setShow: Function;
    reloadData: Function;
    onChange: Function
}
export default function PickFromTemplates(props: Props) {
    const classes = useStyles();

    return (
        <MyModal
            isShow={props.isShow}
            onClose={null}
            title={"Pick Notification Templates"}
            setShow={props.setShow}
        >
           <TemplatesGrid onChange={props.onChange}></TemplatesGrid> 
        </MyModal>
    ); 
}