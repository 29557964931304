import React, { useState, useEffect } from "react";
// import useStyles from "../../offers_style";
import { Tab, Tabs, TextField, useTheme, Switch } from "@material-ui/core";
import MyModal from "../../components/my_modal/my_modal";
import PageTab from "../../components/page_tab/page_tab";
import ViewQuestionField from "./view_Questions"
import QuizPoolModel from "../../models/quizPool";
import useStyles from './question_style';
import { getQuizPool, getSingleQuizPool } from "../../services/api_service/api_service";
import { cursorTo } from "readline";

interface Props {
    isShow: boolean;
    setShow: Function;
    selected: QuizPoolModel | undefined;
}
export default function QuestionsViewModel(props: Props) {
    const classes = useStyles();
    const theme = useTheme();

    const closeModal = () => {
        props.setShow(false);
    };
    const [selected, setSelected] = useState<QuizPoolModel | undefined>();
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        if (props.selected) {
            setSelected(props.selected);
            setSelectedTab(0);
        }
    }, [props.selected]);


    return (
        <MyModal
            isShow={props.isShow}
            onClose={closeModal}
            title={""}
            setShow={props.setShow}
        >
            <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                variant="fullWidth"
                classes={{ indicator: classes.tabSelected }}
            >
                <Tab className={classes.tabStyle} label="English" />
                <Tab className={classes.tabStyle} label="Arabic" />
                <Tab className={classes.tabStyle} label="French" />
            </Tabs>
            <div className={classes.listPadding} hidden={selectedTab !== 0}>
                <English selected={selected} />
            </div>
            <div className={classes.listPadding} hidden={selectedTab !== 1}>
                <Arabic selected={selected} />
            </div>
            <div className={classes.listPadding} hidden={selectedTab !== 2}>
                <French selected={selected} />
            </div>
        </MyModal>
    );
}

function English(props: { selected: QuizPoolModel | undefined }) {
    console.log("en", props.selected?.answers[0].answer[0])
    let enAnswer1 = "";
    let enAnswer2 = "";
    let enAnswer3 = "";
    let enAnswer4 = "";
    props.selected?.answers.forEach((item, index) => {
        if (item.id == 1) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "EN") {
                    enAnswer1 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 2) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "EN") {
                    enAnswer2 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 3) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "EN") {
                    enAnswer3 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 4) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "EN") {
                    enAnswer4 = item.answer[ans].message;
                }
            }
        }
    })
    let enMsg = "";
    props.selected?.question.forEach((item, index) => {
        if (item.language === "EN") {
            enMsg = item.message
        }
        // for (var en in item.answer) {
        //     if (item.answer[en].language === "EN") {
        //         enMsg = item.answer[en].message
        //     }
        // }
    })
    const classes = useStyles();
    return (
        <div>
            <div className="row">
                <div className="col-md-3">
                    <label>Question</label>
                </div>
                <div className="col-md-6" >
                    <strong><textarea value={enMsg} className="form-control" name="description" rows={3}></textarea></strong>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Answer</label>
                </div>
                <div className="col-auto">
                    <p>1</p>
                    <p className="mt-4">2</p>
                    <p className="mt-4">3</p>
                    <p className="mt-4">4</p>
                </div>
                <div className="col-md-6">
                    <input type="text" value={enAnswer1} className="form-control"></input>
                    <input type="text" value={enAnswer2} className="form-control mt-2"></input>
                    <input type="text" value={enAnswer3} className="form-control mt-2"></input>
                    <input type="text" value={enAnswer4} className="form-control mt-2"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Correct Answer</label>
                </div>
                <div className="col-md-6">
                    <input type="text" value={props.selected?.correctAnswer} className="form-control"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Level</label>
                </div>
                <div className="col-md-6">
                    <input type="text" value={props.selected?.level} className="form-control"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Status</label>
                </div>
                <div className="col-auto">
                    <Switch checked={Boolean(props.selected?.status)} />
                </div>
            </div>
        </div>
    );
}

function Arabic(props: { selected: QuizPoolModel | undefined }) {
    let arAnswer1 = "";
    let arAnswer2 = "";
    let arAnswer3 = "";
    let arAnswer4 = "";
    props.selected?.answers.forEach((item, index) => {
        console.log("id", item.id)
        if (item.id == 1) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "AR") {
                    arAnswer1 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 2) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "AR") {
                    arAnswer2 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 3) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "AR") {
                    arAnswer3 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 4) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "AR") {
                    arAnswer4 = item.answer[ans].message;
                }
            }
        }
    })
    let arMsg = "";
    props.selected?.question.forEach((item, index) => {
        if (item.language === "AR") {
            arMsg = item.message
        }
        // for (var ar in item.answer) {
        //     if (item.answer[ar].language === "AR") {
        //         arMsg = item.answer[ar].message
        //     }
        // }

    })
    const classes = useStyles();
    return (
        <div>
            <div className="row">
                <div className="col-md-3">
                    <label>Question</label>
                </div>
                <div className="col-md-6">
                    <textarea value={arMsg} className="form-control" name="description" rows={3}></textarea>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Answer</label>
                </div>
                <div className="col-auto">
                    <p>1</p>
                    <p className="mt-4">2</p>
                    <p className="mt-4">3</p>
                    <p className="mt-4">4</p>
                </div>
                <div className="col-md-6">
                    <input type="text" value={arAnswer1} className="form-control"></input>
                    <input type="text" value={arAnswer2} className="form-control mt-2"></input>
                    <input type="text" value={arAnswer3} className="form-control mt-2"></input>
                    <input type="text" value={arAnswer4} className="form-control mt-2"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Correct Answer</label>
                </div>
                <div className="col-md-6">
                    <input type="text" value={props.selected?.correctAnswer} className="form-control"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Level</label>
                </div>
                <div className="col-md-6">
                    <input type="text" value={props.selected?.level} className="form-control"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Status</label>
                </div>
                <div className="col-auto">
                    <Switch checked={Boolean(props.selected?.status)} />
                </div>
            </div>
        </div>
    );
}

function French(props: { selected: QuizPoolModel | undefined }) {
    let frAnswer1 = "";
    let frAnswer2 = "";
    let frAnswer3 = "";
    let frAnswer4 = "";
    props.selected?.answers.forEach((item, index) => {
        console.log("id", item.id)
        if (item.id == 1) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "FR") {
                    frAnswer1 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 2) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "FR") {
                    frAnswer2 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 3) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "FR") {
                    frAnswer3 = item.answer[ans].message;
                }
            }
        }
        if (item.id == 4) {
            for (var ans in item.answer) {
                if (item.answer[ans].language === "FR") {
                    frAnswer4 = item.answer[ans].message;
                }
            }
        }
    })
    const classes = useStyles();
    let frMsg = "";
    props.selected?.question.forEach((item, index) => {
        if (item.language === "FR") {
            frMsg = item.message
        }
        // for (var fr in item.) {
        //     if (item.answer[fr].language === "FR") {
        //         frMsg = item.answer[fr].message
        //     }
        // }
    })
    return (
        <div>
            <div className="row">
                <div className="col-md-3">
                    <label>Question</label>
                </div>
                <div className="col-md-6">
                    <textarea value={frMsg} className="form-control" name="description" rows={3}></textarea>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Answer</label>
                </div>
                <div className="col-auto">
                    <p>1</p>
                    <p className="mt-4">2</p>
                    <p className="mt-4">3</p>
                    <p className="mt-4">4</p>
                </div>
                <div className="col-md-6">
                    <input type="text" value={frAnswer1} className="form-control"></input>
                    <input type="text" value={frAnswer2} className="form-control mt-2"></input>
                    <input type="text" value={frAnswer3} className="form-control mt-2"></input>
                    <input type="text" value={frAnswer4} className="form-control mt-2"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Correct Answer</label>
                </div>
                <div className="col-md-6">
                    <input type="text" value={props.selected?.correctAnswer} className="form-control"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Level</label>
                </div>
                <div className="col-md-6">
                    <input type="text" value={props.selected?.level} className="form-control"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Status</label>
                </div>
                <div className="col-auto">
                    <Switch checked={Boolean(props.selected?.status)} />
                </div>
            </div>
        </div>
    );
}
