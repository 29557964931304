import React, { useState, useEffect } from "react";
import CommonPage from "../../components/common_page/common_page";
import NetworkPlans from "../../models/network_plans";
import NetworkPlanTable from "./network_plans_table";
import { getNetworkPlans } from "../../services/api_service/api_service";
import NetworkPlanSaveModel from "./network_plan_save_model";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import routeNameObjects from "../../services/route_name/route_name_objects";

export default function NetworkPlansPage() {
  const [networkPlan, setNetworkPlans] = useState<NetworkPlans[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);

  const getNetworks = () => {
    getNetworkPlans().then((data) => {
      if (data?.success) {
        const pln: NetworkPlans[] = [];
        data.data.forEach((x: any) => pln.push(new NetworkPlans(x)));
        setNetworkPlans(pln);
      }
    });
  };

  useEffect(() => {
    getNetworks();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage currentRoute={routeNameObjects.networkPlans} parentRoutes={[routeNameObjects.networkPlans]}>
      <AddItemBtn title="Add New Plan" onClick={showAddModel} />
      {networkPlan.length > 0 && (
        <NetworkPlanTable networkPlan={networkPlan} reloadData={getNetworks} />
      )}

      <NetworkPlanSaveModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getNetworks}
      />
    </CommonPage>
  );
}
