import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React, { useState } from "react";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import Error from "../../models/error";
import ErrorModel from "./error_model";
import ErrorUpdateModel from "./error_update_model";
import clonedeep from 'lodash.clonedeep';

interface Props {
  errors: Error[];
  reloadData: Function;
}

export default function ErrorTable(props: Props) {
  const tableStyle = tableStyles();
  const [showModel, setShowModel] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Error>();

  const onView = (item: Error) => {
    setSelectedItem(item);
    setShowModel(true);
  };

  const onUpdate = (item: Error) => {
    setSelectedItem(clonedeep(item));
    setShowUpdateModel(true);
  };
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Error Code</TableCell>

            <TableCell className={tableStyle.header}>Error Message</TableCell>

            <TableCell width={'220px'} className={tableStyle.header} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.errors.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell className={tableStyle.tableData}>
                {item.errorCode}
              </TableCell>

              <TableCell className={tableStyle.tableData} >
              {item.messages.length > 0 ? item.messages[0]?.text : ""}
              </TableCell>
             
              <TableCell align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                <ModelBtn title={"Update"} onClick={() => onUpdate(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ErrorModel
        isShow={showModel}
        setShow={setShowModel}
        selectedError={selectedItem}
        reloadData={props.reloadData}
      />
      
      <ErrorUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selectedError={selectedItem}
        reloadData={props.reloadData}
      />
      
    </TableContainer>
  );
}
