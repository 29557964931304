import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs, Switch } from "@material-ui/core";
import PageTab from "../../components/page_tab/page_tab";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import { useStoreActions, Actions } from "easy-peasy";
import useStyles from "./notification_style";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import NotificationText from "./notification_text_field"
import NumberListUploadModel from "./number_list_upload_model"
import NotificationModel, { emptyNotificationModel } from "../../models/notification";
import {
    sendNotification,
} from "../../services/api_service/api_service";
import { StoreModel } from "../../store models/model";

interface Props {
    fileName: string
    item: NotificationModel
    save: Function
    listName: string
}
export default function SendNotification(props: Props) {
    const classes = useStyles();
    const [selected, setSelected] = useState<NotificationModel>(emptyNotificationModel);
    const [cleardata, setclearData] = useState("");

    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    useEffect(() => {
        console.log("li name", props.listName)
    });

    return (
        <div>
            <Card className={classes.cardSize}>
                <div className="row">
                    <p>Notification</p>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <p style={{ fontWeight: "bold" }}>EN</p>
                    </div>
                    <div className="col-md-11">
                        <input type="text" disabled value={props.item.title.filter(title => title.language === "EN")[0].text} className="form-control" placeholder="Title"></input>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <textarea disabled value={props.item.body.filter(title => title.language === "EN")[0].text} className="form-control" rows={3}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-auto">
                        <p style={{ fontWeight: "bold" }}>FR</p>
                    </div>
                    <div className="col-md-11">
                        <input type="text" disabled value={props.item.title.filter(title => title.language === "FR")[0].text} className="form-control" placeholder="Title"></input>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <textarea disabled value={props.item.body.filter(title => title.language === "FR")[0].text} className="form-control" rows={3}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-auto">
                        <p style={{ fontWeight: "bold" }}>AR</p>
                    </div>
                    <div className="col-md-11">
                        <input type="text" disabled value={props.item.title.filter(title => title.language === "AR")[0].text} className="form-control" placeholder="Title"></input>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <textarea disabled value={props.item.body.filter(title => title.language === "AR")[0].text} className="form-control" rows={3}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-auto">
                        <label>Customer Base</label>
                    </div>
                    <div className="col-auto">
                        <p className="mr-2" style={{ fontWeight: "bold" }}>{props.fileName || props.listName}</p>
                    </div>
                </div>
            </Card >
            <div className="row mt-3">
                <div className="col-md-12">
                    <AddItemBtn title="Send Notification" onClick={props.save} />
                </div>
            </div>
        </div>
    );
}