import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./error_style";
import clsx from "clsx";
import MyModal from "../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelBtn from "../../components/model_btn/model_btn";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import Error, { emptyError } from "../../models/error";
import LangAndText from "../../models/lang_and_text";
import { addNewError } from "../../services/api_service/api_service";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function ErrorAddModel(props: Props) {
  const [selected, setSelected] = useState<Error>(new Error(emptyError));

  useEffect(()=>{
    setSelected(new Error(emptyError))
  },[props.isShow])

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if(!selected.errorCode){
      showMessage({
        head: "Failed!",
        body: "Please enter error code",
        color: "red",
      });
      return
    }
    if(selected.messages.length === 0 || !selected.messages[0]?.text){
      showMessage({
        head: "Failed!",
        body: "Please enter error message",
        color: "red",
      });
      return
    }
    addNewError(selected).then(data=>{
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new Error.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    })
  };

  const setErrorMessage = (message: LangAndText[]) => {
    const prev = selected;
    prev.messages = message;
    setSelected({ ...prev });
  };

  const changeErrorCode = (event: any) => {
    const prev = selected;
    prev.errorCode = event.target.value;
    setSelected({ ...prev });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
       
      title={"Add New Application Error"}
      setShow={props.setShow}
    >
      <div>
        <div className={classes.modelOuter}>
          <div className={classes.row}>
            <TextField
              label="Error Code"
              value={selected.errorCode}
              variant="outlined"
              className={classes.textField}
              style={{ width: "100%" }}
              onChange={changeErrorCode}
            />
          </div>

          <LangAndTextEditTabs
            data={selected.messages}
            isEditable={true}
            label={"Error Message"}
            setData={setErrorMessage}
          />
        </div>

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"Save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
