import { Action, action } from "easy-peasy";

export const MessageTypes = {
  message:'message',
  confirmation:'confirmation'
}

export interface Message {
  isOpen: boolean;
  head: string;
  body: string;
  type: string;
  color:string;
  onConfirm: Function;
  showMessage: Action<Message, { head: string; body: string, color?:string, isReLogin?:boolean }>;
  showConfirmMessage: Action<Message, { head: string, body: string, onConfirm:Function, color?:string }>;
  hideMessage: Action<Message>;
}

const message: Message = {
  isOpen: false,
  head: "",
  body: "",
  type: MessageTypes.message,
  color: 'black',
  onConfirm: ()=>{},
  showMessage: action((state, payload) => {
    state.type = MessageTypes.message;
    state.isOpen = true;
    state.color = payload.color?payload.color:'black'
    state.head = payload.head;
    state.body = payload.body;
    state.onConfirm = ()=>{}
  }),
  showConfirmMessage: action((state, payload) => {
    state.type = MessageTypes.confirmation;
    state.isOpen = true;
    state.color = payload.color?payload.color:'black'
    state.head = payload.head;
    state.body = payload.body;
    state.onConfirm = payload.onConfirm
  }),
  hideMessage: action((state) => {
    state.isOpen = false;
    state.head = "";
    state.body = "";
    state.type = MessageTypes.message;
    state.onConfirm = ()=>{}
  }),
};

export default message;
