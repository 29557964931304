import React from "react";
import GoogleMapReact from "google-map-react";
import useStyles from './wifi_page_styles'

interface Props {
  lat: number;
  lon: number;
}

export default function SimpleMap(props: Props) {
  const classes = useStyles()
  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
      position: { lat: props.lat, lng: props.lon },
      map,
      title: "Location",
    });
    return marker;
  };

  const googleMapKey : string = process.env.REACT_APP_MAP_KEY?process.env.REACT_APP_MAP_KEY:''

  return (
    <div className={classes.viewModelMap}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapKey }}
        defaultCenter={{
          lat: props.lat,
          lng: props.lon,
        }}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals
        draggable={true}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        options={{
          fullscreenControl: false,
          zoomControl: false,
        }}
      ></GoogleMapReact>
    </div>
  );
}
