import LangAndText, { emptyLangAndText } from "./lang_and_text";
import MyWorldPlugin from "./my_world_plugin";
import StarPackage from "./star_package";

export default class MyWorldPlan {
  id: string;
  price: number;
  exPoints: number;
  bundleId: number;
  bundleCode: string;
  planName: string;
  bundleDetails: LangAndText[] = [];
  bundleSuccess: LangAndText[] = [];
  inboxMessage: LangAndText[] = [];
  limitedPluginsData: { plan: MyWorldPlugin; amount: number, unit: string }[] = [];
  unLimitedPluginsData: MyWorldPlugin[] = [];
  limitedPlugins: { pluginId: string; amount: number, unit: string }[] = [];
  unlimitedPlugins: string[] = [];
  type: string;
  status: string;
  validity: string;
  starPackage: StarPackage;

  constructor(data: any) {
    this.id = data.id;
    this.price = data.price;
    this.exPoints = data.exPoints;
    this.bundleId = data.bundleId;
    this.bundleCode = data.bundleCode;
    this.planName = data.planName;
    data.bundleDetails ? data.bundleDetails.forEach((x: any) =>
      this.bundleDetails.push(new LangAndText(x))
    ) : this.bundleDetails.push(new LangAndText(emptyLangAndText))
    // new add bundleSuccess
    data.bundleSuccess ? data.bundleSuccess.forEach((x: any) =>
      this.bundleSuccess.push(new LangAndText(x))
    ) : this.bundleSuccess.push(new LangAndText(emptyLangAndText))
    // inbox message
    data.inboxMessage ? data.inboxMessage.forEach((x: any) =>
      this.inboxMessage.push(new LangAndText(x))
    ) : this.inboxMessage.push(new LangAndText(emptyLangAndText))
    data.limitedPlugins?.forEach((x: any) => {
      this.limitedPluginsData.push({
        plan: new MyWorldPlugin(x.plan),
        amount: x.value,
        unit: x.unit
      });
      this.limitedPlugins.push({ pluginId: x.plan.id, amount: x.value, unit: x.unit });
    });
    data.unLimitedPlugins?.forEach((x: any) => {
      this.unlimitedPlugins.push(x.id);
      this.unLimitedPluginsData.push(new MyWorldPlugin(x));
    });
    this.type = data.type;
    this.status = data.status;
    this.validity = data.validity;
    this.starPackage = new StarPackage(data.starPackage);
  }
}

export const emptyPlan = {
  id: undefined,
  price: undefined,
  exPoints: undefined,
  bundleId: undefined,
  bundleCode: undefined,
  planName: undefined,
  bundleDetails: [new LangAndText(emptyLangAndText)],
  type: undefined,
  status: undefined,
  validity: undefined,
  starPackage: {
    unlockStarCount: 0,
  },
};
