import React, { useEffect, useState } from "react";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import CommonPage from "../../components/common_page/common_page";
import CarouselModel from "../../models/carousel";
import { getAllCarouselCall } from "../../services/api_service/api_service";
import routeNameObjects from "../../services/route_name/route_name_objects";
import CarouselAddModel from "./carousel_add_model";
import CarouselTable from "./carousel_table";

export default function CarouselPage() {
  const [carousels, setCarousels] = useState<CarouselModel[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);

  const getCarousels = () => {
    getAllCarouselCall().then((data) => {
      if (data?.success) {
        const car: CarouselModel[] = [];
        data.data.forEach((x: any) => car.push(new CarouselModel(x)));
        setCarousels(car);
      }
    });
  };

  useEffect(() => {
    getCarousels();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage currentRoute={routeNameObjects.carousel} parentRoutes={[routeNameObjects.carousel]}>
      <AddItemBtn title="Add New Carousel" onClick={showAddModel} />
      {carousels.length > 0 && (
        <CarouselTable carousels={carousels} reloadData={getCarousels} />
      )}
      <CarouselAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getCarousels}
      />
    </CommonPage>
  );
}
