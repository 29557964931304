import LangAndText, { emptyLangAndText } from "./lang_and_text";

export default class WilayaCity {
  id: string;
  name: LangAndText[] = [];
  constructor(data: any) {
    this.id = data.id;
    data.name?.forEach((x: any) => this.name.push(new LangAndText(x)));
  }
}

export const emptyWilayaCity = {
  id: undefined,
  name: [new LangAndText(emptyLangAndText)],
};
