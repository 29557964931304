import React, { useState, useEffect } from "react";
import useStyles from "./offers_style";
import clsx from "clsx";
import { useStoreActions, Actions } from "easy-peasy";
import MyModal from "../../components/my_modal/my_modal";
import { StoreModel } from "../../store models/model";
import {
  addNewOffer,
  uploadAssetCall,
} from "../../services/api_service/api_service";
import Content from "../../models/content";
import Offer, { emptyOffer } from "../../models/offer";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../components/model_btn/model_btn";
import LangAndHeaderEditTabs from "../../components/lang_and_text_edit/lang_and_header_edit_tabs";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function OffersAddModel(props: Props) {
  const classes = useStyles();
  const [addOffer, setAddOffer] = useState<Offer>(new Offer(emptyOffer));

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    setAddOffer(new Offer(emptyOffer));
  }, [props.isShow]);

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if (!addOffer.mediaUrl) {
      showMessage({
        head: "Failed!",
        body: "Please select an image or video and try again.",
        color: "red",
      });
      return;
    }
    if (!addOffer.content[0].header) {
      showMessage({
        head: "Failed!",
        body: "Please enter header and try again.",
        color: "red",
      });
      return;
    }
    if (!addOffer.content[0].description) {
      showMessage({
        head: "Failed!",
        body: "Please enter description and try again.",
        color: "red",
      });
      return;
    }
    addNewOffer(addOffer).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new Offer.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };
  const iconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const selectedFileType = selectedFile.type.split("/")[0].toUpperCase();
      const off = addOffer;
      if (off) {
        off.mediaType = selectedFileType;
        setAddOffer({ ...off });
      }

      if (selectedFileType === 'IMAGE') {
        let img = new Image();
        img.src = window.URL.createObjectURL(selectedFile);
        img.onload = () => {
          const height = img.height;
          const width = img.width;

          if (height === 426 && width === 640) {
            uploadAssetCall(selectedFile).then((data) => {
              if (data?.success) {
                const off = addOffer;
                if (off) {
                  off.mediaUrl = data.data.url;
                  setAddOffer({ ...off });
                }
              }
            });
          } else {
            showMessage({
              head: "Failed to upload image!",
              body: "Selected image is not 640px * 426px",
            });
          }
        }
      }
      else {
        uploadAssetCall(selectedFile).then((data) => {
          if (data?.success) {
            const off = addOffer;
            if (off) {
              off.mediaUrl = data.data.url;
              setAddOffer({ ...off });
            }
          }
        });
      }

    }
  };

  const contentChange = (content: Content[]) => {
    const off = addOffer;
    if (off) {
      off.content = content;
      setAddOffer({ ...off });
    }
  };
  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Add New Offer"}
      setShow={props.setShow}
    >
      <div>
        <IconUploadBtn
          mediaUrl={addOffer.mediaUrl}
          mediaType={addOffer.mediaType}
          onUpload={iconUpload}
          allowVideo={true}
          height={120}
          isEditable={true}
        />
        <p className={classes.imageSizeText}>
          This image should be 640px * 426px
        </p>

        <LangAndHeaderEditTabs
          data={addOffer.content}
          isEditable={true}
          label={"Content"}
          setData={contentChange}
        />

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
