export default class MyWorldPlugin {
  id: string;
  referenceName: string;
  logoUrl: string;
  extraValues: any;
  code: string;
  mediumLogoUrl: string;
  largeLogoUrl: string;

  constructor(data: any) {
    this.id = data.id;
    this.referenceName = data.referenceName;
    this.logoUrl = data.logoUrl;
    this.extraValues = data.extraValues;
    this.code = data.code;
    this.mediumLogoUrl = data.mediumLogoUrl;
    this.largeLogoUrl = data.largeLogoUrl;
  }
}

export const emptyPlugin = {
  id: undefined,
  referenceName: undefined,
  logoUrl: undefined,
  extraValues: undefined,
  code: undefined,
  mediumLogoUrl: undefined,
  largeLogoUrl: undefined,
};
