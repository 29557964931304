import React from "react";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import MyWorldTier from "../../../models/my_world_tier";
import tableStyles from "../../../common_classes/table";
import clsx from 'clsx'

interface Props {
  tiers: MyWorldTier[];
}
export default function MyWorldTiersTable(props: Props) {
  const tableStyle = tableStyles();
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tiers.map((item, index) => (
            <TableRow className={clsx(index % 2 === 1 && tableStyle.oddRowColor)} hover key={index}>
              <TableCell className={tableStyle.tableData}>
                {item.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
