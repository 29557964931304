import LangAndText, { emptyLangAndText } from "./lang_and_text";

export default class Wilaya {
  id: string;
  // city: WilayaCity | undefined;
  // country: WilayaCountry | undefined;
  // cityId: string = "";
  // countryId: string = "";
  name: LangAndText[] = [];
  // point: {
  //   x: number;
  //   y: number;
  //   lat?: number;
  //   long?: number;
  // };

  constructor(data: any) {
    this.id = data.id;
    // this.city = data.city ? new WilayaCity(data.city) : undefined;
    // this.country = data.country ? new WilayaCountry(data.country) : undefined;
    data.name
      ? data.name.forEach((x: any) => this.name.push(new LangAndText(x)))
      : (this.name = []);
    // this.point = {
    //   x: data.point.x,
    //   y: data.point.y,
    // };
  }
}

export const emptyWilaya = {
  id: undefined,
  // city: new WilayaCity(emptyWilayaCity),
  // country: new WilayaCountry(emptyWilayaCountry),
  name: [new LangAndText(emptyLangAndText)],
  // point: {
  //   x: 0,
  //   y: 0,
  // },
};
