import React, { useEffect, useState } from "react";
import CommonPage from "../../../components/common_page/common_page";
import { getFinekItemsCall } from "../../../services/api_service/api_service";
import FinekItem from "../../../models/finek_item";
// import FinekItemsTable from "./finek_items_table";
import FinekItemAddModel from "./finek_item_add_modal";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import FinekItemsGrid from "./finek_items_grid";

export default function FinekItemsPage() {
  const [items, setItems] = useState<FinekItem[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const getFinekItems = () => {
    getFinekItemsCall().then((data) => {
      if (data?.success) {
        const itms: FinekItem[] = [];
        data?.data.forEach((x: any) => itms.push(new FinekItem(x)));
        setItems(itms);
      }
    });
  };
  useEffect(() => {
    getFinekItems();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage
    currentRoute={nestedRouteNameObject1.avatarItems}
    parentRoutes={[routeNameObjects.avatar]}>
      <AddItemBtn title='Add New Item' onClick={showAddModel} />
      { items.length > 0 && <FinekItemsGrid items={items} reloadData={getFinekItems}/>}
     
      <FinekItemAddModel isShow={isShowAddModel} setShow={setShowAddModel} reloadData={getFinekItems} />
    </CommonPage>
  );
}
