import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import MessageTemplatesGrid from "./Message_Templates/message_templates_grid"
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
    getMessageTemplate,
} from "../../services/api_service/api_service";
import NotificationModel from "../../models/notification";
import AddMessageTemplateModal from "./Message_Templates/add_messageTemplate_modal";

export default function MessageTemplates() {

    const [templates, settemplates] = useState<NotificationModel[]>([]);
    const [isShowAddModel, setShowAddModel] = useState(false);

    const addNotification = () => {
        setShowAddModel(true)
    };

    const getTemplates = () => {
        getMessageTemplate().then((data) => {
            if (data?.success) {
                const notificationTemplates: NotificationModel[] = [];
                data?.data.forEach((x: any) => notificationTemplates.push(new NotificationModel(x)));
                settemplates(notificationTemplates);
            }
        });
    };
    useEffect(() => {
        getTemplates();
    }, []);


    return (
        <CommonPage
            currentRoute={routeNameObjects.messageTemplates}
            parentRoutes={[routeNameObjects.messageTemplates]}

        >
            <AddItemBtn title="Add Message Template" onClick={addNotification} />
            <MessageTemplatesGrid messageTemplates={templates} reloadData={getTemplates}></MessageTemplatesGrid>
            <AddMessageTemplateModal
                reloadData={() => { }}
                isShow={isShowAddModel}
                setShow={setShowAddModel}
            />
        </CommonPage >
    );
}
