import LangAndText from "./lang_and_text";

class PartnerCategory {
  id: string;
  name: LangAndText[] = [];
  description: LangAndText[] = [];
  imageUrl: string;
  constructor(data: any) {
    this.id = data.id;
    data.name.forEach((x: any) => this.name.push(new LangAndText(x)));
    data.description.forEach((x: any) =>
      this.description.push(new LangAndText(x))
    );
    this.imageUrl = data.imageUrl;
  }
}

export const emptyPartnerCategory = {
  id: undefined,
  name: [
    {
      language: "EN",
      text: undefined,
    },
  ],
  description: [
    {
      language: "EN",
      text: undefined,
    },
  ],
  imageUrl: undefined,
};

export default PartnerCategory;
