import React, { useState, useEffect } from "react";
import useStyles from "./decoration_assets_style";
import clsx from "clsx";
import { useStoreActions, Actions } from "easy-peasy";
import MyModal from "../../components/my_modal/my_modal";
import { StoreModel } from "../../store models/model";
import {
  updateDecorationAssetCall,
  uploadAssetCall,
} from "../../services/api_service/api_service";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../components/model_btn/model_btn";
import DecorationAsset from "../../models/decoration_asset";
import {
  FormControlLabel,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { decorationAssetTypes } from "./asset_types";

interface Props {
  isShow: boolean;
  setShow: Function;
  selected: DecorationAsset | undefined;
  reloadData: Function;
}
export default function DecorationAssetUpdateModal(props: Props) {
  const [selected, setSelected] = useState<DecorationAsset>();

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if (selected) {
      updateDecorationAssetCall(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully Updated.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  const iconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      uploadAssetCall(selectedFile).then((data) => {
        if (data?.success) {
          const cat = selected;
          if (cat) {
            cat.url = data.data.url;
            setSelected({ ...cat });
          }
        }
      });
    }
  };

  const nameChange = (event: any) => {
    const prev = selected;
    if (prev) {
      prev.name = event.target.value;
      setSelected({ ...prev });
    }
  };

  const typeChange = (event: any) => {
    const prev = selected;
    if (prev) {
      prev.type = event.target.value;
      setSelected({ ...prev });
    }
  };

  const enableChange = (event: any) => {
    const prev = selected;
    if (prev) {
      prev.enabled = event.target.value === "true";
      setSelected({ ...prev });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={() => {}}
      title={"Update Asset"}
      setShow={props.setShow}
    >
      {selected && (
        <div className={classes.addOuter}>
          <IconUploadBtn
            mediaUrl={selected.url}
            mediaType={"FILE"}
            onUpload={iconUpload}
            height={120}
            isEditable
            allowAnyFile
          />
          <div className={classes.column}>
            <TextField
              variant="outlined"
              className={classes.textField}
              value={selected.name}
              label="Name"
              onChange={nameChange}
            />

            <TextField
              variant="outlined"
              className={classes.textField}
              value={selected.type}
              label="Type"
              onChange={typeChange}
              select
            >
              {decorationAssetTypes.map((t, index) => (
                <ListItem key={index} value={t}>
                  {t}
                </ListItem>
              ))}
            </TextField>

            <RadioGroup
              name="enabled"
              value={selected.enabled}
              onChange={enableChange}
              style={{
                display: "flex",
                flexDirection: "row",
                width: "fit-content",
                margin: "10px auto auto auto",
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Enable"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Disable"
              />
            </RadioGroup>
          </div>
        </div>
      )}
      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Close"} onClick={closeModal} />
        <ModelBtn title={"save"} onClick={saveModel} />
      </div>
    </MyModal>
  );
}
