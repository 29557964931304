class NetworkValidity {
  unit: String;
  count: number;

  constructor(data: any) {
    this.unit = data.unit;
    this.count = data.count;
  }
}
export default NetworkValidity;
