import { Card, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "../notification_template_style";
import FadeIn from "react-fade-in";
import { MoreVert } from "@material-ui/icons";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CustomerBaseModal from "../../../models/customerBase";
import { Button } from "@material-ui/core";

interface Props {
    data: CustomerBaseModal;
    parentCallbackFile: Function
    parentCallBackListName: Function
    onChange: Function
}
export default function AddCustomerModal(props: Props) {
    const classes = useStyles();
    const [isHover, setHover] = useState(false);
    const [listName, setListName] = useState("")

    const handleInputChange = (event: any) => {
        if (event.target.files.length > 0) {
            props.parentCallbackFile(event.target.files[0])
        }
    }

    useEffect(() => {
        if (props.data) {
            setListName(props.data?.listName)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.listName = listName
                props.onChange(value)
            }
        }
        props.parentCallBackListName(listName)
    }, [listName]);

    return (
        <Grid item>
            <Card className={classes.cardSize}>
                <div className="row">
                    <div className="col-auto">
                        <label>List Name</label>
                    </div>
                    <div className="col">
                        <input type="text" value={listName} onChange={(event) => setListName(event.target.value)} className="form-control" placeholder="List Name"></input>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-auto">
                        <label>File  URL</label>
                    </div>
                    <div className="col-auto">
                        <Button variant="contained" onChange={handleInputChange} component="label">
                            <input type="file" />
                        </Button>
                    </div>
                </div>
            </Card>
        </Grid>
    );
}
