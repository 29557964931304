export const carouselTypes = [
  "ESTORM",
  "HAYA_IQRAA",
  "WIFI",
  "GAMING",
  "BLOOD_DONATION",
  "AVATAR",
  "ATC",
  "PRAYER",
  "ANAZIK",
  "ANAFLIX",
  "PROMOTIONS",
  "STORMLY",
  "QUIZ",
  "MGM",
  "SNAPCHAT_ON",
  "SNAPCHAT_OFF",
  "RAMADAN"
];

export const carouselTypeColors: any = {
  ESTORM: "rgb(240 75 254)",
  HAYA_IQRAA: "rgb(143 75 254)",
  WIFI: "rgb(75 115 254)",
  GAMING: "rgb(75 231 254)",
  BLOOD_DONATION: "rgb(75 254 146)",
  AVATAR: "rgb(254 173 75)",
  ATC: "rgb(61 151 118)",
  PRAYER: "rgb(91 61 151)",
  ANAZIK: "rgb(151 61 61)",
  ANAFLIX: "rgb(91 61 151)",
  PROMOTIONS: "rgb(168 92 92)",
  STORMLY: "rgb(210 75 54)",
  QUIZ: "#FF66B2"
};
