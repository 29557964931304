import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import useStyles from "./finek_levels_style";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import Tier from "../../../models/tier";
import {
  getTiersCall,
  addNewLevelCall,
} from "../../../services/api_service/api_service";
import ModelBtn from "../../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function FinekLevelAddModel(props: Props) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [exPoints, setExPoints] = useState("");
  const [tierId, setTierId] = useState("");

  const [tiers, setTiers] = useState<Tier[]>([]);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  // const getTiers = () => {
  //   getTiersCall().then((data) => {
  //     if (data?.success) {
  //       const trs: Tier[] = [];
  //       data.data.forEach((x: any) => trs.push(new Tier(x)));
  //       setTiers(trs);
  //     }
  //   });
  // };

  // const onTierChange = (tier: Tier) => {
  //   setTierId(tier.id);
  // };
  useEffect(() => {
    if (props.isShow) {
      // getTiers();
      setName("");
      setId("");
      setExPoints("");
      // setTierId("");
    }
  }, [props.isShow]);

  const closeModal = () => {
    props.setShow(false);
  };

  const onSave = () => {
    if (!name) {
      showMessage({
        head: "Failed!",
        body: "Please enter name and try again!",
      });
      return;
    }
    if (!id) {
      showMessage({ head: "Failed!", body: "Please enter id and try again!" });
      return;
    }
    if (!exPoints) {
      showMessage({
        head: "Failed!",
        body: "Please enter Ex Points and try again!",
      });
      return;
    }
    // if (!tierId) {
    //   showMessage({
    //     head: "Failed!",
    //     body: "Please select a tier and try again!",
    //   });
    //   return;
    // }

    addNewLevelCall(name, Number(id), Number(exPoints)).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new Level.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Add new Avatar Level"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          type="number"
          label="Level ID"
          value={id}
          onChange={(event) => setId(event.target.value)}
          InputProps={{ inputProps: { min: 0 } }}
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          type="number"
          label="Ex Points"
          value={exPoints}
          onChange={(event) => setExPoints(event.target.value)}
          InputProps={{ inputProps: { min: 0 } }}
        />
        {/* <FormControl className={classes.textField}>
          <InputLabel style={{ marginLeft: "10px" }}>Select Tier</InputLabel>
          <Select
            style={{ width: "100%" }}
            value={tierId}
            label={"Tier"}
            variant="outlined"
          >
            {tiers.map((tier, index) => (
              <MenuItem
                key={index}
                value={tier.id}
                onClick={() => onTierChange(tier)}
              >
                {tier.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={onSave} />
        </div>
      </div>
    </MyModal>
  );
}
