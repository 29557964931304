import React, { useState } from "react";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import EligibleLevel from "../../../models/eligible_level";
import tableStyles from "../../../common_classes/table";
import clsx from 'clsx'
import clonedeep from "lodash.clonedeep";
import ModelBtn from "../../../components/model_btn/model_btn";
import FinekLevelUpdateModel from "./finek_level_update_model";

interface Props {
  levels: EligibleLevel[];
  reloadData: Function;
}
export default function FinekLevelsTable(props: Props) {
  const tableStyle = tableStyles();
  const [isShowUpdateModel, setShowUpdateModel] = useState(false);
  const [selected, setSelected] = useState<EligibleLevel>();

  const onUpdate = (item: EligibleLevel) => {
    setSelected(clonedeep(item));
    setShowUpdateModel(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Level</TableCell>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>Ex Points</TableCell>
            <TableCell className={tableStyle.header} align='center'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.levels.map((item, index) => (
            <TableRow className={clsx(index % 2 === 1 && tableStyle.oddRowColor)} hover key={index}>
              <TableCell className={tableStyle.tableData}>
                {item.level}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.name}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.exPoints}
              </TableCell>
              <TableCell align='center'>
                <ModelBtn title="Update" onClick={() => onUpdate(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <FinekLevelUpdateModel
        isShow={isShowUpdateModel}
        setShow={setShowUpdateModel}
        selected={selected}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
