import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs, Switch } from "@material-ui/core";
import PageTab from "../../components/page_tab/page_tab";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import { useStoreActions, Actions } from "easy-peasy";
import useStyles from "./notification_style";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import NotificationText from "./notification_text_field"
import NumberListUploadModel from "./number_list_upload_model"
import NotificationModel, { emptyNotificationModel } from "../../models/notification";
import {
    sendNotification, getMessageTemplate
} from "../../services/api_service/api_service";
import { StoreModel } from "../../store models/model";
import PickFromTemplateModal from "./pick_from_templates_Modal"

interface Props {
    callBackSelectedItem: Function
}
export default function Notification(props: Props) {
    const classes = useStyles();
    const [isShowAddModel, setShowAddModel] = useState(false);
    const [isShowTemplate, setShowTemplate] = useState(false);
    const [selected, setSelected] = useState<NotificationModel>(emptyNotificationModel);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [text, setText] = useState("");
    const [title, setTitle] = useState("");
    const [templates, setTemplates] = useState<NotificationModel[]>([]);

    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    const pickNotificationTemplates = () => {
        setShowTemplate(true)
    };

    const getTemplates = () => {
        getMessageTemplate().then((data) => {
            if (data?.success) {
                const notificationTemplates: NotificationModel[] = [];
                data?.data.forEach((x: any) => notificationTemplates.push(new NotificationModel(x)));
                setTemplates(notificationTemplates);
            }
        });
    };

    useEffect(() => {
        setSelected(emptyNotificationModel)
        getTemplates()
    }, []);

    useEffect(() => {
        props.callBackSelectedItem(selected)
    }, [selected]);

    const handleCallback = (child: any) => {
        setFile(child)
        setFileName(child.name)
    }

    const handleJsonCallback = (childText: any) => {
        setText(childText)
    }

    const handleJsonTitleCallback = (childTitle: any) => {
        setTitle(childTitle)
    }

    return (
        <div>
            <Card className={classes.cardSize}>
                <PageTab
                    onClick={pickNotificationTemplates}
                    nextButtonPickActive={true}
                    tabsData={[
                        {
                            title: "English",
                            component: <NotificationText language="EN" data={selected} onChange={setSelected} parenttextCallBack={handleJsonCallback} parentTitleCallBack={handleJsonTitleCallback}></NotificationText>
                        },
                        {
                            title: "Arabic",
                            component: <NotificationText language="AR" data={selected} onChange={setSelected} parenttextCallBack={handleJsonCallback} parentTitleCallBack={handleJsonTitleCallback}></NotificationText>
                        },
                        {
                            title: "French",
                            component: <NotificationText language="FR" data={selected} onChange={setSelected} parenttextCallBack={handleJsonCallback} parentTitleCallBack={handleJsonTitleCallback}></NotificationText>
                        },
                    ]}

                />
                <NumberListUploadModel
                    reloadData={() => { }}
                    isShow={isShowAddModel}
                    setShow={setShowAddModel}
                    parentCallback={handleCallback}
                />
                <PickFromTemplateModal
                    isShow={isShowTemplate}
                    setShow={setShowTemplate}
                    reloadData={() => { }}
                    onChange={setSelected}
                />
            </Card >
            {/* <div className="row mt-3">
                <div className="col-auto" >
                    <AddItemBtn title="Pick From Templates" onClick={pickNotificationTemplates} />
                </div>
            </div> */}
        </div>
    );
}



