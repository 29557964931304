import React, { useState, useEffect } from "react";
import CommonPage from "../../../components/common_page/common_page";
import MyWorldTier from "../../../models/my_world_tier";
import { getMyWorldTiers } from "../../../services/api_service/api_service";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import MyWorldTiersTable from "./my_world_tiers_table";
import MyWorldTiersAddModel from "./my_world_tiers_add_model";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../../services/route_name/route_name_objects";

export default function MyWorldTiersPage() {
  const [tiers, setTiers] = useState<MyWorldTier[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const showAddModel = () => {
    setShowAddModel(true);
  };

  const getTiers = () => {
    getMyWorldTiers().then((data) => {
      if (data?.success) {
        const tr: MyWorldTier[] = [];
        data.data.forEach((x: any) => tr.push(new MyWorldTier(x)));
        setTiers(tr);
      }
    });
  };

  useEffect(() => {
    getTiers();
  }, []);

  return <CommonPage currentRoute={nestedRouteNameObject1.myWorldTiers} parentRoutes={[routeNameObjects.myWorld]}>
      <AddItemBtn title='Add New Tier' onClick={showAddModel} />
      {tiers.length > 0 && <MyWorldTiersTable tiers={tiers} />}
      <MyWorldTiersAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getTiers}
      />
    </CommonPage>
  
}
