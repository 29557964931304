import React, { useState } from "react";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import UserCategory from "../../../models/user_category";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import { deleteUserCategoryCall } from "../../../services/api_service/api_service";
import UserCategoryUpdateModel from "./user_category_update_model";
import tableStyles from "../../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../../components/model_btn/model_btn";
import UserCategoryViewModel from "./user_category_view_model";
import clonedeep from 'lodash.clonedeep';

interface Props {
  Categories: UserCategory[];
  reloadData: Function;
}
export default function UserCategoryTable(props: Props) {
  const tableStyle = tableStyles();
  const showConfMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showConfirmMessage
  );
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const [isShowModel, setShowModel] = useState(false);
  const [isShowViewModel, setShowViewModel] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<UserCategory>();

  const onDelete = (item: UserCategory) => {
    showConfMessage({
      head: "Are you sure?",
      body: "This action cannot be undone.",
      onConfirm: () => {
        deleteUserCategoryCall(item.id).then((data) => {
          if (data?.success) {
            props.reloadData();
          } else {
            showMessage({
              head: "Failed!",
              body: "Cannot delete user category. Please try again later.",
            });
          }
        });
      },
    });
  };

  const onUpdate = (selected: UserCategory) => {
    setShowModel(true);
    setSelectedCategory(clonedeep(selected));
  };

  const onView = (selected: UserCategory) => {
    setShowViewModel(true);
    setSelectedCategory(selected);
  };

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>Credit Limit</TableCell>
            <TableCell
              width="320px"
              align="center"
              className={tableStyle.header}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.Categories.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell className={tableStyle.tableData}>
                {item.name}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.creditLimit}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                <ModelBtn title={"update"} onClick={() => onUpdate(item)} />
                <ModelBtn title={"delete"} onClick={() => onDelete(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <UserCategoryUpdateModel
        isShow={isShowModel}
        setShow={setShowModel}
        reloadData={props.reloadData}
        selectedItem={selectedCategory}
      />

      <UserCategoryViewModel
        isShow={isShowViewModel}
        setShow={setShowViewModel}
        selectedItem={selectedCategory}
      />
    </TableContainer>
  );
}
