import { makeStyles } from "@material-ui/core";
import myColors from "../../../services/colors";

const useStyles = makeStyles((theme) => ({
  button: {
    // backgroundColor: myColors.drawerSideBarColor,
    color: "black",
    marginBottom: "15px",
    float: "right",
    
    fontSize:'12px',
    height:'40px',
    fontWeight:'bold',
    "&:hover":{
      color:myColors.drawerSideBarColor,
      backgroundColor:'transparent',
      border:`2px solid ${myColors.drawerSideBarColor}`
    }
  },
}));

export default useStyles;
