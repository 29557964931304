import VoucherUser from "./voucher_user"

export default class VoucherDetail{
    voucherId:string
    userId:number
    redeem:boolean
    promotionId:string
    expireTimesStamp:number
    qrCode:string
    user:VoucherUser 
    constructor(data:any){
        this.voucherId=data.voucherId
        this.userId=data.userId
        this.redeem=data.redeem
        this.promotionId=data.promotionId
        this.expireTimesStamp=data.expireTimesStamp
        this.qrCode=data.qrCode
        this.user =  new VoucherUser(data.user)
    }
}