import { Grid, InputBase, Button } from "@material-ui/core";
import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "./search_bar_styles";

interface IProps {
  onSearch: Function;
  placeholder?: string;
  clearEnabled?: boolean;
}

export default function SearchBar(props: IProps) {
  const classes = useStyles();
  const [searchText, setsearchText] = useState<string>('')

  const handleSearch = (event: any) => {
    if (event.key === 'Enter' && !!searchText) {
      props.onSearch(searchText)
      // props.onSearch('213562000128')
    }
  }

  const handleClear = () => {
    if (searchText) {
      setsearchText('')
      props.onSearch("clear")
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsearchText(event.target.value)
  }
  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={8}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={!!props.placeholder ? props.placeholder : "Search"}
              value={searchText}
              type="text"
              onChange={handleChange}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onKeyDown={handleSearch}
            />
          </div>
        </Grid>
        {
          props?.clearEnabled &&
          <Grid item xs={2}>
            <Button
              variant="contained"
              onClick={handleClear}
              color="primary"
              disabled={searchText ? false : true}
            >
              Clear
            </Button>
          </Grid>
        }

      </Grid>

    </div>

  );
}
