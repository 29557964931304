import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      marginTop: "10px",
      height: "30px",
      overflowY: "hidden",
    },
    root: {
      flexGrow: 1,
    },
    spaceBetween: {
      justifyContent: "space-between",
    },
    name: {
      fontFamily: "unset",
      fontSize: "18px",
      fontWeight: "bold",
      color: myColors.drawerColor,
      width: "200px",
      height: "30px",
      overflowX: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    type: {
      fontSize: "13px",
      color: myColors.tableDataText,
      width: "200px",
      height: "20px",
      overflowX: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    gridPrice: {
      display: 'flex',
      alignItems: 'center',
      fontSize: "23px",
      fontWeight: "bold",
      color: myColors.drawerSideBarColor,
      float: "right",
      border: " 2px solid",
      borderRadius: "5px",
      padding: " 0px 5px",
      marginBottom: "10px",
      transition: '1s',
      cursor: 'pointer',
      '&:hover': {
        color: myColors.appBarColor
      }
    },
    line: {
      width: "100%",
      margin: "auto",
      height: "0.5px",
      backgroundColor: "#a7a7a766",
    },
    description: {
      margin: "10px 0px",
      height: "55px",
      overflowY: "hidden",
      fontSize: "13px",
      color: myColors.tableDataText,
    },
    contentPadding: { padding: "0px 10px 10px 10px" },
    gridImage: {
      width: "220px",
      height: "160px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    gridCard: {
      width: "220px",
      cursor: 'pointer',
      transition: "0.2s",
      "&:hover": {
        backgroundColor: myColors.oddRowColor,
      },
    },
    addIcon: {
      position: "absolute",
      right: "5px",
      top: "4px",
      height: "25px",
      width: "25px",
      border: `2px solid ${myColors.drawerSideBarColor}`,
      backgroundColor: "white",
      marginTop: '10px',
      marginRight: '10px',
      color: myColors.drawerSideBarColor,
      "&:hover": {
        backgroundColor: myColors.drawerSideBarColor,
        color: "white",
        border: `2px solid white`,
      },
    },
  })
);

export default useStyles;
