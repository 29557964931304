import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./finek_levels_style";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import {
  updateFinekLevel,
} from "../../../services/api_service/api_service";
import ModelBtn from "../../../components/model_btn/model_btn";
import EligibleLevel from "../../../models/eligible_level";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
  selected: EligibleLevel | undefined;
}
export default function FinekLevelUpdateModel(props: Props) {
  const classes = useStyles();

  const [selected, setSelected] = useState<EligibleLevel>();

  useEffect(() => {
    if (props.isShow) {
      setSelected(props.selected);
    }
  }, [props.isShow, props.selected]);


  const setNameHandler = (x: string) => {
    const prev = selected;
    if (prev) {
      prev.name = x;
      setSelected({ ...prev });
    }
  };

  const setExPointHandler = (x: number) => {
    const prev = selected;
    if (prev) {
      prev.exPoints = x;
      setSelected({ ...prev });
    }
  };

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const closeModal = () => {
    props.setShow(false);
  };

  const onSave = () => {
    if (selected) {
      if (!selected.name) {
        showMessage({
          head: "Failed!",
          body: "Please enter name and try again!",
        });
        return;
      }
      if (!selected.exPoints) {
        showMessage({
          head: "Failed!",
          body: "Please enter Ex Points and try again!",
        });
        return;
      }
      updateFinekLevel(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully updated the Avatar Level.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Update Avatar Level"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selected && (
          <>
            <TextField
              className={classes.textField}
              variant="outlined"
              label="Name"
              value={selected?.name}
              onChange={(event) => setNameHandler(event.target.value)}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              type="number"
              label="Ex Points"
              value={selected?.exPoints}
              onChange={(event) => setExPointHandler(event.target.value as unknown as number)}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </>
        )}
      </div>

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={onSave} />
        </div>
      </div>
    </MyModal>
  );
}
