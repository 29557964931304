import React, { useState, useEffect } from "react";
import { Card } from "@material-ui/core";
import useStyles from "./theme_style";
import clsx from "clsx";
import MyModal from "../../components/my_modal/my_modal";
import {
  updateTheme,
  uploadAssetCall,
} from "../../services/api_service/api_service";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import Theme from "../../models/theme";
import LangAndTextEditTabs from "../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import LangAndText from "../../models/lang_and_text";
import ModelLabel from "../../components/model_label/model_label";
import ModelBtn from "../../components/model_btn/model_btn";
import MyColorPicker from "../../components/color_picker/color_picker";
import { v4 } from "uuid";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import IconBtn from "../../components/icon_btn/icon_btn";
import { Remove } from "@material-ui/icons";
import ModelFieldWrapper from "../../components/model_field_wrapper/model_field_wrapper";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedTheme: Theme | undefined;
  reloadData: Function;
}
export default function ThemeUpdateModel(props: Props) {
  const [selected, setSelected] = useState<Theme>();

  const id = v4();

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    setSelected(props.selectedTheme);
  }, [props.selectedTheme]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if (selected && selected.theme) {
      const dataToSend: any = {};
      dataToSend.id = selected.id;
      dataToSend.name = selected.themeName;
      dataToSend.themeColor = selected.theme.themeColor;
      dataToSend.themeDarkColor = selected.theme.themeDarkColor;
      dataToSend.backgroundImages = selected.theme.backgroundImages;
      dataToSend.assets = selected.assets;
      updateTheme(dataToSend).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully Updated.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  const setThemeNameHandler = (name: LangAndText[]) => {
    const prev = selected;
    if (prev) {
      prev.themeName = name;
      setSelected({ ...prev });
    }
  };

  const onIconUploadIos = (event: any) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const value = data.data.url;
          const newItem = selected;
          if (newItem) {
            newItem.assets[0].ios.url = value;
            setSelected({ ...newItem });
          }
        }
      });
    }
  };

  const onIconUploadAndroid = (event: any) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const value = data.data.url;
          const newItem = selected;
          if (newItem) {
            newItem.assets[0].android.url = value;
            setSelected({ ...newItem });
          }
        }
      });
    }
  };

  const onThemeColorChange = (color: string) => {
    const value = color;
    const newItem = selected;
    if (newItem && newItem.theme) {
      newItem.theme.themeColor = value;
      setSelected({ ...newItem });
    }
  };

  const onThemeDarkColorChange = (color: string) => {
    const value = color;
    const newItem = selected;
    if (newItem && newItem.theme) {
      newItem.theme.themeDarkColor = value;
      setSelected({ ...newItem });
    }
  };

  const onImageUpload = () => {
    document.getElementById(id)?.click();
  };

  const addBackgroundImage = (event: any) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const value = data.data.url;
          const newItem = selected;
          if (newItem && newItem.theme) {
            newItem.theme.backgroundImages.push(value);
            setSelected({ ...newItem });
          }
        }
      });
    }
  };

  const removeBackgroundImage = (index: number) => {
    const newItem = selected;
    if (newItem && newItem.theme) {
      newItem.theme.backgroundImages.splice(index, 1);
      setSelected({ ...newItem });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Update Theme"}
      setShow={props.setShow}
    >
      <div>
        {selected && (
          <LangAndTextEditTabs
            data={selected.themeName}
            isEditable={true}
            label={"Theme Name"}
            setData={setThemeNameHandler}
          />
        )}

        <ModelFieldWrapper>
          <ModelLabel title={"Theme"} noMargin />

          <div
            className={classes.row}
            style={{ justifyContent: "space-around" }}
          >
            <MyColorPicker
              title={"Theme Colour"}
              selectedColor={selected?.theme?.themeColor}
              isEditable
              setColor={onThemeColorChange}
            />
            <MyColorPicker
              title={"Theme Dark Colour"}
              selectedColor={selected?.theme?.themeDarkColor}
              isEditable
              setColor={onThemeDarkColorChange}
            />
          </div>
        </ModelFieldWrapper>

        <ModelFieldWrapper>
          <ModelLabel
            title={"Background Images"}
            onClick={onImageUpload}
            noMargin
          />

          <input
            style={{ display: "none" }}
            type="file"
            id={id}
            accept={`.jpg,.png,.jpeg,.svg`}
            onChange={(event) => {
              addBackgroundImage(event);
            }}
          />

          <div className={classes.imageRow}>
            {selected?.theme?.backgroundImages.map((image, index) => (
              <Card key={index} elevation={3} className={classes.imageCard}>
                <img
                  height="100px"
                  width="100px"
                  src={image}
                  alt="background"
                />
                <IconBtn
                  icon={Remove}
                  onClick={() => removeBackgroundImage(index)}
                />
              </Card>
            ))}
          </div>
        </ModelFieldWrapper>

        <ModelFieldWrapper>
          <ModelLabel title={"Assets"} noMargin />
          {selected?.assets.map((item, index) => (
            <Card key={index} elevation={5} className={classes.assetCard}>
              <div className={classes.assetRow}>
                <div className={classes.assetItem}>
                  <IconUploadBtn
                    mediaUrl={item.ios.url}
                    mediaType={"FILE"}
                    onUpload={onIconUploadIos}
                    allowVideo={false}
                    height={70}
                    isEditable={true}
                    allowAnyFile
                  />
                  <div className={classes.boldText}>IOS</div>
                </div>

                <div className={classes.assetItem}>
                  <IconUploadBtn
                    mediaUrl={item.android.url}
                    mediaType={"FILE"}
                    onUpload={onIconUploadAndroid}
                    allowVideo={false}
                    height={70}
                    isEditable={true}
                    allowAnyFile
                  />
                  <div className={classes.boldText}>ANDROID</div>
                </div>
              </div>
            </Card>
          ))}
        </ModelFieldWrapper>

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
