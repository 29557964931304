import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import tableStyles from "../../../../common_classes/table";
import useStyles from "../../question_style";
import { Switch } from "@material-ui/core";
import { getLeaderboardResult } from "../../../../services/api_service/api_service";
import { useParams } from 'react-router-dom';
import LeaderBoard from "../../../../models/leaderboard";
import AddItemBtn from "../../../../components/add_item_btn/add_item_btn";
import MyPaginator from "../../../../components/my_paginator/my_paginator";
import * as moment from 'moment'
import 'moment-timezone';
var TimeFormat = require('hh-mm-ss')

interface Props {
    quizSession: LeaderBoard[];
    reloadData: Function;
    onPaginationLeaderboard: Function
    pageCountLeaderboard: number
    download: Function
    sessionID: string
}

export default function LeaderBoardTable(props: Props) {
    const tableStyle = tableStyles();
    const classes = useStyles()
    const [currentPage, setCurrentPage] = useState(1);
    const [sessionId, setSessionId] = useState("")

    function msToTime(s: number) {
        function pad(n: number, z: number) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        return pad(hrs, 2) + ':' + pad(mins, 2) + ':' + pad(secs, 2);
    }

    const handlePagination = (page: number) => {
        props.onPaginationLeaderboard(page)
        setCurrentPage(page)
    }
    return (
        <div>
            <TableContainer component={Paper}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={tableStyle.header}>Rank</TableCell>
                            <TableCell className={tableStyle.header}>MSISDN</TableCell>
                            <TableCell className={tableStyle.header}>Score</TableCell>
                            <TableCell className={tableStyle.header}>Correct</TableCell>
                            <TableCell className={tableStyle.header}>Time Taken(HH:MM:SS)</TableCell>
                            <TableCell className={tableStyle.header}>Timestamp</TableCell>
                            <TableCell className={tableStyle.header}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.quizSession.map((item, index) => (
                            <TableRow>
                                <TableCell>
                                    {((currentPage - 1) * 10) + 1 + index}
                                </TableCell>
                                <TableCell>
                                    <p>{item.msisdn}</p>
                                </TableCell>
                                <TableCell>
                                    <div className={classes.typeChip}>{item.score}</div>                                </TableCell>
                                <TableCell>
                                    <p>{item.correctQuiz + "/" + item.totalQuiz}</p>
                                </TableCell>
                                <TableCell>
                                    {msToTime(Number(item.elapsedTimeApp))}
                                </TableCell>
                                <TableCell>
                                    <p>{item.submitTime}</p>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.quizSession.length > 0 && (
                <MyPaginator pageCount={props.pageCountLeaderboard} onChange={handlePagination} />
            )}
            <div style={{ float: "left", marginTop: 4 }}>
                <AddItemBtn title="Download Pdf" onClick={() => props.download(props.sessionID)} />
            </div>
        </div>
    );
}
