import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  modelOuter: {
    // width: "500px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  right: {
    paddingRight: "10px",
    float: "right",
  },
  textField: {
    margin: "5px 10px",
  },
});

export default useStyles;
