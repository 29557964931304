class Content {
  language: string;
  header: string;
  description: string;

  constructor(data: any) {
    this.language = data.language;
    this.header = data.header;
    this.description = data.description;
  }
}
export default Content;
