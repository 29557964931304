import React, { useState, useEffect } from "react";
import useStyles from "./offers_style";
import clsx from "clsx";
import MyModal from "../../components/my_modal/my_modal";
import Offer from "../../models/offer";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../components/model_btn/model_btn";
import LangAndHeaderEditTabs from "../../components/lang_and_text_edit/lang_and_header_edit_tabs";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedOffer: Offer | undefined;
}
export default function OffersModel(props: Props) {
  const [selected, setSelected] = useState<Offer>();

  useEffect(() => {
    setSelected(props.selectedOffer);
  }, [props.selectedOffer]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Offer"}
      setShow={props.setShow}
    >
      <div>
        {selected && (
          <div>
            <IconUploadBtn
              mediaUrl={selected.mediaUrl}
              mediaType={selected.mediaType}
              onUpload={null}
              allowVideo={true}
              height={120}
              isEditable={false}
            />
            <LangAndHeaderEditTabs
              data={selected.content}
              isEditable={false}
              label={"Content"}
              setData={null}
            />
          </div>
        )}

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
