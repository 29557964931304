import { Tab, Tabs, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import useStyles from "./page_tab_styles";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../store models/model";

export default function PageTab(props: {
  tabsData: { title: string; component: any; }[];
  nextButtonActive?: boolean;
  nextButtonPickActive?: boolean;
  onClick?: Function;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [error, setError] = useState(false)

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const onNextClick = () => {
    if (props.tabsData.length - 1 > selectedTab) {
      setSelectedTab(selectedTab + 1);
    }
  }

  return (
    <div>
      <div className={classes.spacer} />
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        variant="fullWidth"
        classes={{ indicator: classes.tabSelected }}
      >
        {props.tabsData.map((t, index) => (
          <Tab key={index} className={classes.tabStyle} label={t.title} />
        ))}
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={selectedTab}
      >
        {props.tabsData.map((t, index) => (
          <div
            key={index}
            className={classes.listPadding}
            hidden={selectedTab !== index}
          >
            {t.component}
          </div>
        ))}
      </SwipeableViews>
      {/* {props.tabsData.length - 1 !== selectedTab && props.nextButtonActive && <AddItemBtn title="Next" onClick={onNextClick} />} */}
      <div className="row">
        <div className="col-auto" >
          {props.tabsData.length - 1 !== selectedTab && props.tabsData.length - 2 !== selectedTab && props.nextButtonPickActive && props.onClick && <AddItemBtn title="Pick From Templates" onClick={props.onClick} />}
        </div>
        <div className="col" >
          {props.tabsData.length - 1 !== selectedTab && props.nextButtonActive && <AddItemBtn title="Next" onClick={onNextClick} />}
        </div>
      </div>
    </div>
  );
}
