import React, { useEffect, useState } from "react";
import useStyles from "../questions/question_style";
import MyModal from "../../components/my_modal/my_modal";
import { Button } from "@material-ui/core";
import { StoreModel } from "../../store models/model";
import { Actions, useStoreActions } from "easy-peasy";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import MyNavigator from "../../services/navigation";
import { MockData } from "./mockData";
import * as XLSX from "xlsx";

interface Props {
    isShow: boolean;
    setShow: Function;
    reloadData: Function;
    parentCallback: Function
}
export default function NumberListUploadModel(props: Props) {
    const classes = useStyles();
    const [selectFile, setSelectedFile] = useState();

    const { pushNamed } = MyNavigator()
    const pushNotification = () => {
        pushNamed("/notification")
    };

    const closeModal = () => {
        props.setShow(false);
    };

    const download = () => {
        var wb = XLSX.utils.book_new()
        var ws = XLSX.utils.json_to_sheet(MockData)

        XLSX.utils.book_append_sheet(wb, ws, "MSISDNS")
        XLSX.writeFile(wb, "MSISDN.xlsx")
    }

    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    let selectedFile = useState("")
    const handleInputChange = (event: any) => {
        if (event.target.files.length > 0) {
            props.parentCallback(event.target.files[0])
        }
    }

    const downloadFile = () => {
        var file_path = './MSISDNS.xlsx';
        var a = document.createElement('a');
        a.href = file_path;
        a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
    }

    return (
        <MyModal
            isShow={props.isShow}
            onClose={null}
            title={"Upload Mobile Numbers"}
            setShow={props.setShow}
        >
            <div className="row">
                <div className="col-auto">
                    <Button variant="contained" component="label">
                        <input type="file" onChange={handleInputChange} />
                    </Button>
                </div>
                <div className="col-auto">
                    <a className="btn" onClick={download}>Download sample file</a>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-auto ml-2">
                    <AddItemBtn title="Upload" onClick={closeModal} />
                </div>
            </div>
        </MyModal>
    );
}
