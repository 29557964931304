import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import useStyles from "./partner_merchant_style";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import {
  addPartnerMerchantCall,
  getPartnerCategoriesCall,
} from "../../../services/api_service/api_service";
import PartnerCategory from "../../../models/partner_category";
import ModelBtn from "../../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function PartnerMerchantAddModel(props: Props) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    if (props.isShow) {
      setName("");
      setUserName("");
      setCategoryId("");
      setEmail("");
    }
  }, [props.isShow]);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const [partnerCategories, setCategories] = useState<PartnerCategory[]>([]);

  const getCategory = () => {
    getPartnerCategoriesCall().then((data) => {
      if (data?.success) {
        const cat: PartnerCategory[] = [];
        data.data.forEach((x: any) => cat.push(new PartnerCategory(x)));
        setCategories(cat);
      }
    });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const closeModal = () => {
    props.setShow(false);
  };

  const onSave = () => {
    if (!name) {
      showMessage({
        head: "Failed!",
        body: "Please enter name and try again!",
      });
      return;
    }
    if (!email) {
      showMessage({
        head: "Failed!",
        body: "Please enter email and try again!",
      });
      return;
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      showMessage({
        head: "Failed!",
        body: "Please enter valid email and try again!",
      });
      return;
    }
    if (!userName) {
      showMessage({
        head: "Failed!",
        body: "Please enter user name and try again!",
      });
      return;
    }
    if (!categoryId) {
      showMessage({
        head: "Failed!",
        body: "Please select category id and try again!",
      });
      return;
    }

    addPartnerMerchantCall(name, userName, categoryId, email).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new partner Merchant.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"add new merchant"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          label="E-Mail"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          label="User Name"
          value={userName}
          onChange={(event) => setUserName(event.target.value)}
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Partner Category"
          value={categoryId}
          onChange={(event) => setCategoryId(event.target.value)}
          select
        >
          {partnerCategories.map((cat, index) => (
            <MenuItem key={index} value={cat.id}>
              {cat.name[0]?.text}
            </MenuItem>
          ))}
          <MenuItem></MenuItem>
        </TextField>
      </div>

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={onSave} />
        </div>
      </div>
    </MyModal>
  );
}
