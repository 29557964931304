import { Action, action } from 'easy-peasy';

export interface Loader {
  isOpen: boolean;
  showLoader: Action<Loader, boolean>;
  isReLogin:boolean
  setReLogin:Action<Loader, boolean>;
}

const loader: Loader = {
  isOpen:false,

  showLoader: action((state, payload) => {
    state.isOpen = payload;
  }),
 
  isReLogin:true,
  setReLogin:action((state,payload)=>{
    state.isReLogin = payload
  })
};

export default loader;
