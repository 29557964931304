import UserInformation, { emptyUserInformation } from './user_information'
import SubscriberData, { emptySubscriberData } from './subscriber_data'
import UserDetails, { emptyUserDetails } from './user_deatails'


export const emptyBasicUserDetails: BasicUserDetails = {
    userInformation: emptyUserInformation,
    subscriberDataResponse: emptySubscriberData,
    userDetails: emptyUserDetails,
};

class BasicUserDetails {
    userInformation: UserInformation;
    subscriberDataResponse: SubscriberData;
    userDetails: UserDetails;

    constructor(data: any) {
        this.userInformation = data.userInformation;
        this.subscriberDataResponse = data.subscriberDataResponse;
        this.userDetails = data.userDetails;
    }
}

export default BasicUserDetails;
