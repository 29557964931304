import React, { useEffect, useState } from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import WilayaCity from "../../../models/wilaya_city";
import WilayaCityTable from "./wilaya_city_table";
import { getWilayaCities } from "../../../services/api_service/api_service";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import WilayaCityAddModel from "./wilaya_city_add_model";

export default function WilayaCityPage() {
  const [cities, setCities] = useState<WilayaCity[]>([]);

  const [showAddModel, setShowAddModel] = useState(false);

  const viewAddModel = () => {
    setShowAddModel(true)
  }

  const getCities = () => {
    getWilayaCities().then((data) => {
      if (data?.success) {
        const cit: WilayaCity[] = [];
        data.data.forEach((x: any) => cit.push(new WilayaCity(x)));
        setCities(cit);
      }
    });
  };

  useEffect(() => {
    getCities();
  }, []);
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.city}
      parentRoutes={[routeNameObjects.settings]}
    >
      <AddItemBtn title={"Add New City"} onClick={viewAddModel} />
      {cities.length > 0 && (
        <WilayaCityTable cities={cities} reloadData={getCities} />
      )}
      <WilayaCityAddModel
        isShow={showAddModel}
        setShow={setShowAddModel}
        reloadData={getCities}
      />
    </CommonPage>
  );
}
