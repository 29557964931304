import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles({
  btn: {
    border: `2px solid ${myColors.drawerSideBarColor}`,
    fontSize: "12px",
    padding: "3px",
    fontWeight: "bold",
    color: myColors.drawerSideBarColor,
    transition:'0.5s',
    
    "&:hover":{
        backgroundColor:myColors.drawerSideBarColor,
        color:'white',
        border: `2px solid ${myColors.appBarColor}`,
        boxShadow:'-1px 5px 7px -3px #515151'
    },
    margin:'1px 2px'
  },
});

export default useStyles;
