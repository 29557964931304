export default class MyWorldTier {
  id: string;
  name: string;
  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
  }
}

export const emptyTier = {
  id: undefined,
  name: undefined,
};
