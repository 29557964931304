import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      justifyContent: "center",
      margin: "20px 0px",
    },
    paper: {
      backgroundColor: "white",
      border: "none",
      boxShadow: theme.shadows[5],
      paddingBottom: theme.spacing(2),
      textAlign: "center",
      // overflow: "auto",
      borderRadius: "10px",
      outline: "0",
      // height: "fit-content",
      // maxHeight: "100%",
      alignSelf: "center",
    },
    scrollOuter: {
      overflow: "auto",
      maxHeight: "80vh",
      marginTop:'5px'
    },
    padding: {
      padding: theme.spacing(2),
    },
    header: {
      height: "60px",
      backgroundColor: myColors.appBarColor,
      padding: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderTopLeftRadius:'10px',
      borderTopRightRadius:'10px'
    },
    headerName: {
      fontSize: "18px",
      fontWeight: "bold",
      textAlign: "left",
      marginTop: "10px",
      color: myColors.appBarTextColor,
      textTransform: "capitalize",
      cursor: "pointer",
      transition: "1s",
      "&:hover": {
        color: myColors.drawerSideBarColor,
      },
    },
    closeBtn: {
      color: myColors.appBarTextColor,
      "&:hover": {
        color: myColors.drawerSideBarColor,
      },
    },
  })
);

export default useStyles;
