import { Grid, Paper} from "@material-ui/core";
import React from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import clsx from 'clsx'
import useStyles from './home_page_style'
import Chart from "./chart";

export default function HomePage() {
  const classes = useStyles()
  return (
    <CommonPage
      currentRoute={routeNameObjects.home}
      parentRoutes={[routeNameObjects.home]}
    >
      <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={clsx(classes.paper, classes.fixedHeight)}>
                <Chart />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            {/* <Grid item xs={12} md={4} lg={3}>
              <Paper className={clsx(classes.paper, classes.fixedHeight)}>
                <Deposits />
              </Paper>
            </Grid> */}
            {/* Recent Orders */}
            {/* <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
            </Grid> */}
          </Grid>
    </CommonPage>
  );
}

