class UserCategory {
  id: string;
  name: string;
  creditLimit: number;
  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.creditLimit = data.creditLimit;
  }
}

export default UserCategory;
