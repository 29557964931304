import React from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./user_categories_styles";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import UserCategory from "../../../models/user_category";
import ModelBtn from "../../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedItem: UserCategory | undefined;
}
export default function UserCategoryViewModel(props: Props) {
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"User Category"}
      setShow={props.setShow}
    >
      {props.selectedItem && (
        <div className={classes.addOuter}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Name"
            value={props.selectedItem.name}
            InputProps={{readOnly:true}}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            type="number"
            label="Credit Limit"
            value={props.selectedItem.creditLimit}
            InputProps={{readOnly:true}}
          />
        </div>
      )}

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
