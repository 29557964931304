import React, { useEffect, useState } from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import Wilaya from "../../../models/wilaya";
import { getWilayaListCall } from "../../../services/api_service/api_service";
import WilayaTable from "./wilaya_table";
import WilayaAddModel from "./wilaya_add_model";
export default function WilayaPage() {
  const [wilayaList, setWilayaList] = useState<Wilaya[]>([]);

  const [showAddModel, setShowAddModel] = useState(false);

  const viewAddModel = () => {
    setShowAddModel(true)
  }

  const getWilayaList = () => {
    getWilayaListCall().then((data) => {
      if (data?.success) {
        console.log(data.data)
        const cit: Wilaya[] = [];
        data.data.forEach((x: any) => cit.push(new Wilaya(x)));
        setWilayaList(cit);
      }
    });
  };

  useEffect(() => {
    getWilayaList();
  }, []);
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.wilaya}
      parentRoutes={[routeNameObjects.settings]}
    >
      <AddItemBtn title={"Add New Wilaya"} onClick={viewAddModel} />
      {wilayaList.length > 0 && (
        <WilayaTable wilayaList={wilayaList} reloadData={getWilayaList} />
      )}
      <WilayaAddModel
        isShow={showAddModel}
        setShow={setShowAddModel}
        reloadData={getWilayaList}
      />
    </CommonPage>
  );
}
