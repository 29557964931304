import { ColorizeOutlined, ErrorOutline, LocalHospital, People, SimCard, Star, Wifi } from "@material-ui/icons";
import BloodDonationPage from "../../pages/blood_donation/blood_donation_page";
import ErrorPage from "../../pages/errors/error_page";
import FinekItemsPage from "../../pages/finek/finek_items/finek_items_page";
import FinekLevelsPage from "../../pages/finek/finek_levels/finek_levels_page";
import FinekTypesPage from "../../pages/finek/finek_types/finek_types_page";
import MGMPlansPage from "../../pages/my_world/mgm_plans/mgm_Plans_page";
import MyWorldPlansPage from "../../pages/my_world/my_world_plans/my_world_Plans_page";
import MyWorldPluginsPage from "../../pages/my_world/my_world_plugins/my_world_plugins_page";
import MyWorldSuperPlansPage from "../../pages/my_world/my_world_super_plans/my_world_super_Plans_page";
import MyWorldTiersPage from "../../pages/my_world/my_world_tiers/my_world_tiers_page";
import SimPickupPage from "../../pages/sim_pickup/sim_pickup_page";
import ThemePage from "../../pages/theme/theme_page";
import WifiPage from "../../pages/wifi/wifi_page";
import WilayaCityPage from "../../pages/wilaya/city/wilaya_city_page";
import WilayaCountryPage from "../../pages/wilaya/country/wilaya_country_page";
import WilayaPage from "../../pages/wilaya/wilaya/wilaya_page";
import nestedRouteNameObject2 from "./nested_route_name_objects2";

const nestedRouteNameObject1 = {
  myWorldPlans: {
    name: "Plans",
    route: "/myWorld/plans",
    component: MyWorldPlansPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  myWorldSuperPlans: {
    name: "Super Plans",
    route: "/myWorld/super_plans",
    component: MyWorldSuperPlansPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  myWorldPlugins: {
    name: "Plugins",
    route: "/myWorld/plugins",
    component: MyWorldPluginsPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  myWorldTiers: {
    name: "Tiers",
    route: "/myWorld/tiers",
    component: MyWorldTiersPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  mgmPlans: {
    name: "MGM Plans",
    route: "/myWorld/mgm_plans",
    component: MGMPlansPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  avatarItems: {
    name: "Avatar Items",
    route: "/avatar/items",
    component: FinekItemsPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  avatarLevels: {
    name: "Avatar Levels",
    route: "/avatar/levels",
    component: FinekLevelsPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  avatarTypes: {
    name: "Avatar Types",
    route: "/avatar/types",
    component: FinekTypesPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  partners: {
    name: "Partners",
    route: "/services/partners",
    component: null,
    nestedLevel: 1,
    icon: People,
    children: [
      nestedRouteNameObject2.promotions,
      nestedRouteNameObject2.userCategories,
      nestedRouteNameObject2.promotionCategories,
      // nestedRouteNameObject2.merchant
    ],
  },
  wifiLocations: {
    name: "Wifi Locations",
    route: "/services/wifiLocations",
    component: WifiPage,
    children: [],
    nestedLevel: 1,
    icon: Wifi,
  },
  bloodDonation: {
    name: "Blood Donation",
    route: "/services/bloodDonation",
    component: BloodDonationPage,
    children: [],
    nestedLevel: 1,
    icon: LocalHospital,
  },
  simPickup: {
    name: "Eligibility Numbers",
    route: "/wilaya/msisdn",
    component: SimPickupPage,
    children: [],
    nestedLevel: 0,
    icon: SimCard,
  },
  country: {
    name: "Country",
    route: "/wilaya/country",
    component: WilayaCountryPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  city: {
    name: "City",
    route: "/settings/city",
    component: WilayaCityPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  wilaya: {
    name: "Wilaya",
    route: "/settings/wilaya",
    component: WilayaPage,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },
  theme: {
    name: "Theme",
    route: "/settings/themes",
    component: ThemePage,
    children: [],
    nestedLevel: 1,
    icon: ColorizeOutlined,
  },
  error: {
    name: "Application Erros",
    route: "/settings/errors",
    component: ErrorPage,
    children: [],
    nestedLevel: 1,
    icon: ErrorOutline,
  },
};

export default nestedRouteNameObject1;
