import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./finek_types_style";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import {
  addNewTypeCall,
  uploadAssetCall,
} from "../../../services/api_service/api_service";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import LangAndText, { emptyLangAndText } from "../../../models/lang_and_text";
import ModelBtn from "../../../components/model_btn/model_btn";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function FinekTypeAddModel(props: Props) {
  const classes = useStyles();

  const [imageUrl, setImageUrl] = useState("");
  const [typeId, setTypeId] = useState("");
  const [name, setName] = useState([new LangAndText(emptyLangAndText)]);
  const [description, setDescription] = useState([
    new LangAndText(emptyLangAndText),
  ]);

  useEffect(() => {
    if (props.isShow) {
      setTypeId("");
      setName([new LangAndText(emptyLangAndText)]);
      setDescription([new LangAndText(emptyLangAndText)]);
    }
  }, [props.isShow]);

  const onIconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const height = img.height;
        const width = img.width;
        if (height === 200 && width === 200) {
          uploadAssetCall(event.target.files[0]).then((data) => {
            if (data?.success) {
              const value = data.data.url;
              setImageUrl(value);
            }
          });
        } else {
          showMessage({
            head: "Failed to upload image!",
            body: "Selected image is not 200px * 200px",
          });
        }
      }
    };
  };

  const setNameHandler = (x: LangAndText[]) => {
    setName([...x]);
  };

  const setDescHandler = (x: LangAndText[]) => {
    setDescription([...x]);
  };

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const closeModal = () => {
    props.setShow(false);
  };

  const onSave = () => {
    if (!imageUrl) {
      showMessage({
        head: "Failed!",
        body: "Please add image and try again!",
      });
      return;
    }
    if (!typeId) {
      showMessage({
        head: "Failed!",
        body: "Please enter type id and try again!",
      });
      return;
    }
    if (name.length === 0 || !name[0]?.text) {
      showMessage({
        head: "Failed!",
        body: "Please enter name and try again!",
      });
      return;
    }

    if (description.length === 0 || !description[0]?.text) {
      showMessage({
        head: "Failed!",
        body: "Please enter description and try again!",
      });
      return;
    }
    addNewTypeCall(imageUrl, name, description, typeId).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new Type.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Add new Avatar Type"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <IconUploadBtn
          isEditable
          mediaType="IMAGE"
          mediaUrl={imageUrl}
          onUpload={onIconUpload}
          height={100}
        />
        <p className={classes.imageSizeText}>
          This image should be 200px * 200px
        </p>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Type Id"
          value={typeId}
          onChange={(event) => setTypeId(event.target.value)}
        />
        <LangAndTextEditTabs
          data={name}
          isEditable={true}
          label={"Name"}
          setData={setNameHandler}
        />
        <LangAndTextEditTabs
          data={description}
          isEditable={true}
          label={"Description"}
          setData={setDescHandler}
        />
      </div>

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={onSave} />
        </div>
      </div>
    </MyModal>
  );
}
