import React, { useEffect, useState } from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import {  getWilayaCountries } from "../../../services/api_service/api_service";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import WilayaCountry from "../../../models/wilaya_country";
import WilayaCountryTable from "./wilaya_country_table";
import WilayaCountryAddModel from "./wilaya_country_add_model";

export default function WilayaCountryPage() {
  const [countries, setCountries] = useState<WilayaCountry[]>([]);

  const [showAddModel, setShowAddModel] = useState(false);

  const viewAddModel = ()=>{
    setShowAddModel(true)
  }

  const getCountries = () => {
    getWilayaCountries().then((data) => {
      if (data?.success) {
        const contr: WilayaCountry[] = [];
        data.data.forEach((x: any) => contr.push(new WilayaCountry(x)));
        setCountries(contr);
      }
    });
  };

  useEffect(() => {
    getCountries();
  }, []);
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.country}
      parentRoutes={[routeNameObjects.wilaya]}
    >
      <AddItemBtn title={"Add New Country"} onClick={viewAddModel} />
      {countries.length > 0 && (
        <WilayaCountryTable countries={countries} reloadData={getCountries} />
      )}
      <WilayaCountryAddModel
        isShow={showAddModel}
        setShow={setShowAddModel}
        reloadData={getCountries}
      />
    </CommonPage>
  );
}
