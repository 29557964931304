import { fade } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: myColors.mainColor,
    },
    inputRoot: {
      color: myColors.mainColor,
      width: "100%"
    },
    inputInput: {
      color: myColors.mainColor,
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        // width: "20ch",
      },
    },
    search: {
      position: "relative",
      borderRadius: "17px",
      backgroundColor: fade(myColors.appBarColor, 0.1),
      "&:hover": {
        backgroundColor: fade(myColors.appBarColor, 0.18),
      },
      width: "100%",
    },

  }));

export default useStyles;
