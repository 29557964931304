import { useState } from "react";
import Keycloak from "keycloak-js";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import keycloakConfig from "./keycloak_config";
import KeyCloakUser from "../../models/key_cloak_user";

const key_cloak = Keycloak(keycloakConfig);

export default function KeyCloakService() {
  const showLoader = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );

  const [keycloak, setKeycloak] = useState<{
    keycloak: Keycloak.KeycloakInstance | null;
    authenticated: boolean;
  }>({ keycloak: null, authenticated: false });

  const setKeyCloakUser = useStoreActions(
    (actions: Actions<StoreModel>) => actions.keyCloakUserModel.setAuthUser
  );

  const checkKeyCloak = async () => {
    showLoader(true);
    key_cloak
      .init({ onLoad: "login-required" })
      .success((authenticated: boolean) => {
        const token = key_cloak.token ? key_cloak.token : "";
        sessionStorage.setItem("ibiza_token", token);
        showLoader(false);
        setKeycloak({ keycloak: key_cloak, authenticated: authenticated });
        setKeyCloakUser(new KeyCloakUser(key_cloak.tokenParsed));
      })
      .error((err: any) => {
        showLoader(false);
      });
  };

  const logout = (onLogout: Function) => {
    key_cloak.logout().success((result: any) => {
      onLogout();
    });
  };
  return {
    checkKeyCloak: checkKeyCloak,
    keycloak: keycloak,
    logout: logout,
  };
}
