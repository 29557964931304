import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@material-ui/core";
import useStyles from "./summary_table_style";
import time from "../../../../services/time";

interface IProps {
    data: Object[];
}
export function SummaryTable(props: IProps) {
    const useStyle = useStyles();
    useEffect(() => {

    }, [props])

    const handleValue = (value: any) => {

        let pattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/
        let datePattern = /\d{4}-[01]\d-[0-3]\dT.*[+]/
        if (pattern.test(value)) {
            return (<img src={value} alt="" width="60px" />)
        }
        else if (datePattern.test(value)) {
            return time.timeStringToDate(value)
        }
        else {
            return handleEmpty(value)
        }
    }

    const handleEmpty = (value: any) => {
        let output: any
        if (value === '' || value === null || value === -1) {
            output = "-"
        }
        else if (typeof value === 'boolean') {
            if (value) {
                output = 'True'
            }
            else {
                output = 'False'
            }
        }
        else {
            output = value
        }
        return output

    }

    const formatKey = (value: string) => {
        let output: string
        if (!!value) {
            output = value.match(/([A-Z]?[^A-Z]*)/g)!.slice(0, -1).join(" ")
            output = output.replace('I D', 'ID')
            output = output.replace('4 G', ' 4G')
            output = output.replace('3 G', ' 3G')

            if (value === "phone1") {
                output = "Phone"
            }
        }
        else {
            output = "unknown"
        }

        return output

    }
    return (
        <Paper className={useStyle.container} elevation={3}>
            <TableContainer component={Paper}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {props.data && props.data.length > 0 && props.data[0] &&
                                Object.keys(props.data[0]).map((key: string, index) => {
                                    if (key === "statsCollectedTimeStamp") {
                                        //skip
                                    }
                                    else {
                                        return (
                                            <TableCell className={useStyle.header} align="center" key={"head" + index}>
                                                {formatKey(key)}
                                            </TableCell>
                                        )
                                    }

                                })
                            }

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data && props.data.length > 0 &&
                            props.data.map((row: Object, i: number) => {
                                return (
                                    <TableRow
                                        className={clsx(i % 2 === 1 && useStyle.oddRowColor)}
                                        hover
                                        key={"row" + i}
                                    >
                                        {
                                            Object.keys(row).map((key: string, j) => {
                                                let value = Reflect.get(row, key)
                                                if (key === "statsCollectedTimeStamp") {
                                                    //skip
                                                }
                                                else {
                                                    return (
                                                        <TableCell className={clsx(useStyle.tableData)} align="center" key={"cell" + j}>
                                                            {handleValue(value)}
                                                        </TableCell>
                                                    )
                                                }

                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

