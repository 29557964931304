import React from "react";
import clsx from "clsx";
import useStyles from "./wilaya_style";
import MyModal from "../../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelBtn from "../../../components/model_btn/model_btn";
import Wilaya from "../../../models/wilaya";

interface Props {
  isShow: boolean;
  setShow: Function;
  selected: Wilaya | undefined;
}
export default function WilayaModel(props: Props) {
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Wilaya"}
      setShow={props.setShow}
    >
      <div>
        <LangAndTextEditTabs
          data={props.selected?.name}
          isEditable={false}
          label={"Name"}
          setData={null}
        />

        {/* <LangAndTextEditTabs
          data={props.selected?.city?.name}
          isEditable={false}
          label={"City"}
          setData={null}
        />

        <LangAndTextEditTabs
          data={props.selected?.country?.name}
          isEditable={false}
          label={"Country"}
          setData={null}
        />

        <ModelFieldWrapper>
          <ModelLabel title={"Point"} noMargin />
          <WilayaAddMap
            lat={props.selected?.point?.x}
            lon={props.selected?.point?.y}
            setLat={null}
            setLon={null}
          />
        </ModelFieldWrapper> */}

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
