import React from "react";
import { Button } from "@material-ui/core";
import useStyles from "./question_style";

interface Props {
  title: string;
  onClick: Function;
  width?: number;
  icon?: any;
}
export default function UploadItemBtn(props: Props) {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={classes.uploadButton}
      style={{
        width: props.width ? props.width : "200px",
      }}
      onClick={() => props.onClick()}
    >
      {props.icon && props.icon}
      {props.icon && <span style={{ marginRight: "5px" }} />}
      {props.title}
    </Button>
  );
}
