import React, { useState, useEffect } from "react";
import CommonPage from "../../../components/common_page/common_page";
import MyWorldPlugin from "../../../models/my_world_plugin";
import { getMyWorldPlugins } from "../../../services/api_service/api_service";
import MyWorldPluginTable from "./my_world_plugins_table";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import MyWorldPluginsAddModel from "./my_world_plugins_add_model";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../../services/route_name/route_name_objects";

export default function MyWorldPluginsPage() {
  const [plugins, setPlugins] = useState<MyWorldPlugin[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const showAddModel = () => {
    setShowAddModel(true);
  };

  const getPlugins = () => {
    getMyWorldPlugins().then((data) => {
      if (data?.success) {
        const p: MyWorldPlugin[] = [];
        data.data.forEach((x: any) => p.push(new MyWorldPlugin(x)));
        setPlugins(p);
      }
    });
  };

  useEffect(() => {
    getPlugins();
  }, []);

  return (
    <CommonPage currentRoute={nestedRouteNameObject1.myWorldPlugins} parentRoutes={[routeNameObjects.myWorld]}>
      <AddItemBtn title="Add New Plugin" onClick={showAddModel} />
      {plugins.length > 0 && (
        <MyWorldPluginTable plugins={plugins} reloadData={getPlugins} />
      )}
      <MyWorldPluginsAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getPlugins}
      />
    </CommonPage>
  );
}
