import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./my_world_super_plans_style";
import MyModal from "../../../components/my_modal/my_modal";
import { validityValues } from "./my_world_super_plans_data";
import MyWorldPlugin from "../../../models/my_world_plugin";
import {
  addNewMyWorldSuperPlan,
  getMyWorldPlugins,
  uploadAssetCall,
} from "../../../services/api_service/api_service";
import { Remove } from "@material-ui/icons";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import IconBtn from "../../../components/icon_btn/icon_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";
import MyWorldSuperPlan, {
  emptySuperPlan,
} from "../../../models/my_world_super_plan";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function MyWorldSuperPlansAddModel(props: Props) {
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const [selected, setSelected] = useState<MyWorldSuperPlan>(
    new MyWorldSuperPlan(emptySuperPlan)
  );

  const [plugins, setPlugins] = useState<MyWorldPlugin[]>([]);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const getPlugins = () => {
    getMyWorldPlugins().then((data) => {
      if (data?.success) {
        const pl: MyWorldPlugin[] = [];
        data.data.forEach((x: any) => pl.push(new MyWorldPlugin(x)));
        setPlugins(pl);
      }
    });
  };

  useEffect(() => {
    if (props.isShow) {
      getPlugins();
      setSelected(new MyWorldSuperPlan(emptySuperPlan));
    }
  }, [props.isShow]);

  function planNameChange(name: any) {
    const sl = selected;
    if (sl) {
      sl.planName = name;
      sl.name = name;
      setSelected({ ...sl });
    }
  }

  function priceChange(price: any) {
    const sl = selected;
    if (sl) {
      sl.price = price;
      setSelected({ ...sl });
    }
  }

  function pointsChange(points: any) {
    const sl = selected;
    if (sl) {
      sl.exPoints = points;
      setSelected({ ...sl });
    }
  }



  function validityChange(validity: any) {
    const sl = selected;
    if (sl) {
      sl.validity = validity;
      if (validity === "DAILY") {
        sl.bundleOfferId = 380
      }
      if (validity === "WEEKLY") {
        sl.bundleOfferId = 381
      }
      if (validity === "MONTHLY") {
        sl.bundleOfferId = 382
      }
      setSelected({ ...sl });
    }
  }


  function limitedPluginChange(index: number, id: any) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins[index].pluginId = id;
      setSelected({ ...sl });
    }
  }

  function limitedPluginPriceChange(index: number, amt: any) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins[index].amount = Number(amt);
      setSelected({ ...sl });
    }
  }

  function unLimitedPluginChange(index: number, id: any) {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins[index] = id;
      setSelected({ ...sl });
    }
  }

  function addLimitedPlugin() {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins.push({ pluginId: "", amount: 0 });
      setSelected({ ...sl });
    }
  }

  function removeLimitedPlugin(index: number) {
    const sl = selected;
    if (sl) {
      sl.limitedPlugins.splice(index, 1);
      setSelected({ ...sl });
    }
  }

  function addUnlimitedPlugin() {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins.push("");
      setSelected({ ...sl });
    }
  }

  function removeUnLimitedPlugin(index: number) {
    const sl = selected;
    if (sl) {
      sl.unlimitedPlugins.splice(index, 1);
      setSelected({ ...sl });
    }
  }

  const onImageUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const height = img.height;
        const width = img.width;
        if (height === 250 && width === 250) {
          uploadAssetCall(file).then((data) => {
            if (data?.success) {
              const value = data.data.url;
              const prev = selected;
              prev.logoUrl = value;
              setSelected({ ...prev });
            }
          });
        } else {
          showMessage({
            head: "Failed to upload image!",
            body: "Selected image is not 250px * 250px",
          });
        }
      }
    };
  };

  function onSave() {
    if (selected) {
      if (!selected.logoUrl) {
        showMessage({
          head: "Failed!",
          body: "Please select image",
          color: "red",
        });
        return;
      }
      if (!selected.planName) {
        showMessage({
          head: "Failed!",
          body: "Please enter plan name",
          color: "red",
        });
        return;
      }
      if (!selected.price) {
        showMessage({
          head: "Failed!",
          body: "Please enter price",
          color: "red",
        });
        return;
      }
      if (!selected.exPoints) {
        showMessage({
          head: "Failed!",
          body: "Please enter ex points",
          color: "red",
        });
        return;
      }

      if (!selected.validity) {
        showMessage({
          head: "Failed!",
          body: "Please select validity",
          color: "red",
        });
        return;
      }

      addNewMyWorldSuperPlan(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully added new plan.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  }

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Add New Super Plan"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selected && (
          <>
            <IconUploadBtn
              mediaUrl={selected.logoUrl}
              mediaType={"IMAGE"}
              // onUpload={onImageUpload}
              onUpload={(event: any) => {
                onImageUpload(event);
              }}
              allowVideo={false}
              height={120}
              isEditable={true}
            />
            <p className={classes.imageSizeText}>
              This image should be 250px * 250px
            </p>
          </>
        )}
        <div className={classes.row}>
          <TextField
            label="Plan Name"
            value={selected?.planName}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => planNameChange(event.target.value)}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="number"
            label="Price"
            value={selected?.price}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => priceChange(event.target.value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </div>

        <div className={classes.row}>
          <TextField
            type="number"
            label="Ex Points"
            value={selected?.exPoints}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => pointsChange(event.target.value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </div>

        <div className={classes.row}>
          <TextField
            label="Validity"
            value={selected?.validity}
            variant="outlined"
            className={classes.textField}
            style={{ width: "100%" }}
            onChange={(event) => validityChange(event.target.value)}
            select
          >
            {validityValues.map((val, index) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            ))}
          </TextField>
        </div>


        {plugins.length > 0 && (
          <ModelFieldWrapper>
            <ModelLabel
              title={"Limited Plugins"}
              onClick={addLimitedPlugin}
              noMargin
            />

            {selected?.limitedPlugins.map((plugin, index) => (
              <div
                key={index}
                className={clsx(
                  classes.row,
                  classes.alignCenter,
                  classes.relative
                )}
              >
                <TextField
                  label="Limited Plugins"
                  value={plugin.pluginId}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "100%" }}
                  onChange={(event) =>
                    limitedPluginChange(index, event.target.value)
                  }
                  select
                >
                  {plugins.map((plug, index) => (
                    <MenuItem key={index} value={plug.id}>
                      {plug.referenceName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Amount"
                  value={plugin.amount}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "100px" }}
                  onChange={(event) =>
                    limitedPluginPriceChange(index, event.target.value)
                  }
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <IconBtn
                  icon={Remove}
                  onClick={() => removeLimitedPlugin(index)}
                  isCenter
                />
              </div>
            ))}
          </ModelFieldWrapper>
        )}
        {plugins.length > 0 && (
          <ModelFieldWrapper>
            <ModelLabel
              title={"Unlimited Plugins"}
              onClick={addUnlimitedPlugin}
              noMargin
            />
            {selected?.unlimitedPlugins.map((plugin, index) => (
              <div
                key={index}
                className={clsx(
                  classes.row,
                  classes.alignCenter,
                  classes.relative
                )}
              >
                <TextField
                  label="Unlimited Plugins"
                  value={plugin}
                  variant="outlined"
                  className={classes.textField}
                  style={{ width: "100%" }}
                  onChange={(event) =>
                    unLimitedPluginChange(index, event.target.value)
                  }
                  select
                >
                  {plugins.map((plug, index) => (
                    <MenuItem key={index} value={plug.id}>
                      {plug.referenceName}
                    </MenuItem>
                  ))}
                </TextField>
                <IconBtn
                  icon={Remove}
                  onClick={() => removeUnLimitedPlugin(index)}
                  isCenter
                />
              </div>
            ))}{" "}
          </ModelFieldWrapper>
        )}
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Save"} onClick={onSave} />
        <ModelBtn title={"close"} onClick={closeModal} />
      </div>
    </MyModal>
  );
}
