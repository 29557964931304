import React from "react";
import "./App.css";
import { StoreProvider,  } from "easy-peasy";
import store from "./store models/store_model";
import Routings from "./routings/routings";

function App() {
  return (
    <StoreProvider store={store}>
      <Routings />
    </StoreProvider>
  );
}

export default App;
