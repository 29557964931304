import { makeStyles } from "@material-ui/core";
import myColors from "../../../services/colors";

const useStyles = makeStyles({
  addBtn: {
    position: "fixed",
    right: "30px",
    bottom: "30px",
    backgroundColor: "#1a3248",
    color: "white",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  right: {
    paddingRight: "10px",
    float: "right",
  },
  textField: {
    margin: "5px 10px",
  },
  modelBtn: {
    marginRight: "3px",
  },
  addOuter: {
    width: "590px",
  },
  viewModelOuter: {
    display: "flex",
    flexDirection: "column",
    width: "600px",
  },
  addIcon: {
    height: "60px",
    width: "60px",
    color: "grey",
  },
  addIconBtn: {
    backgroundColor: "#80808021",
    margin: "auto",
    marginTop: "10px",
    width: "100px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  relativeLabel: {
    position: "relative",
  },
  labelAddBtn: {
    height: "30px",
    width: "30px",
    position: "absolute",
    right: "5px",
    top: "0px",
    color: "white",
    backgroundColor: "#9c9c9c",
  },
  itemRemoveBtn: {
    height: "30px",
    width: "30px",
    right: "5px",
    color: "white",
    backgroundColor: "#9c9c9c",
  },
  alignCenter: {
    alignItems: "center",
  },
  tabSelected: { backgroundColor: myColors.drawerSideBarColor },
  tabStyle: {
    color: myColors.appBarColor,
    fontSize: "10px",
    fontWeight: "bold",
  },
  noDataLabel:{
    padding:'100px 0px',
    color:myColors.appBarTextColor
  },
  listPadding:{
    padding:'10px',
    height:'400px',
    overflowY:'auto'
  },
  relative:{
    position:'relative',
    paddingRight:'30px'
  }
});

export default useStyles;
