import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs, Switch } from "@material-ui/core";
import PageTab from "../../../../components/page_tab/page_tab";
import AddItemBtn from "../../../../components/add_item_btn/add_item_btn";
import { useStoreActions, Actions } from "easy-peasy";
import useStyles from "../../notification_style";
import NotificationModel from "../../../../models/notification";
import MyModal from "../../../../components/my_modal/my_modal";
import {
    updateMessageTemplates,
} from "../../../../services/api_service/api_service";
import { StoreModel } from "../../../../store models/model";
import UpdateNotificationTemplateText from "./update_messageTemplate_textField"
import StaticTemplateName from "./static_template_name"

interface Props {
    isShow: boolean;
    setShow: Function;
    reloadData: Function;
    selectedTemplate: NotificationModel | undefined;
}
export default function UpdateMessageTemplateModal(props: Props) {
    const classes = useStyles();
    const [selected, setSelected] = useState<NotificationModel | undefined>(undefined);

    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    useEffect(() => {
        console.log("sec", props.selectedTemplate)
        setSelected(props.selectedTemplate)

    }, [props.selectedTemplate, props.isShow]);

    function onSave() {
        if (selected) {
            updateMessageTemplates(selected).then((data) => {
              if (data?.success) {
                props.setShow(false);
                props.reloadData();
                showMessage({
                  head: "Success!",
                  body: "Successfully updated.",
                  color: "green",
                });
              } else {
                // showMessage({
                //   head: "Failed!",
                //   body: data?.data.title,
                //   color: "red",
                // });
              }
            });
          }
    }

    return (
        <MyModal
            isShow={props.isShow}
            onClose={null}
            title={"Add Notification Templates"}
            setShow={props.setShow}
        >
            <Card className={classes.cardSize}>
                <StaticTemplateName data={selected} onChange={setSelected}></StaticTemplateName>
                <PageTab
                    tabsData={[
                        {
                            title: "English",
                            component: <UpdateNotificationTemplateText language="EN" data={selected} onChange={setSelected}></UpdateNotificationTemplateText>
                        },
                        {
                            title: "Arabic",
                            component: <UpdateNotificationTemplateText language="AR" data={selected} onChange={setSelected}></UpdateNotificationTemplateText>
                        },
                        {
                            title: "French",
                            component: <UpdateNotificationTemplateText language="FR" data={selected} onChange={setSelected}></UpdateNotificationTemplateText>
                        },
                    ]}
                />
            </Card >
            <div className="row mt-3">
                <div className="col-auto">
                    <AddItemBtn title="Save To Templates" onClick={onSave} />
                </div>
            </div>
        </MyModal>
    );
}



