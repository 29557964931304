import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./my_world_tiers_style";
import clsx from "clsx";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import { addNewMyWorldTier } from "../../../services/api_service/api_service";
import MyModal from "../../../components/my_modal/my_modal";
import MyWorldTier, { emptyTier } from "../../../models/my_world_tier";
import ModelBtn from "../../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function MyWorldTiersAddModel(props: Props) {
  const classes = useStyles();

  const [selected, setSelected] = useState<MyWorldTier>(
    new MyWorldTier(emptyTier)
  );

  useEffect(() => {
    setSelected(new MyWorldTier(emptyTier));
  }, [props.isShow]);
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );
  const closeModal = () => {
    props.setShow(false);
  };

  const nameChange = (event: any) => {
    const prev = selected;
    prev.name = event.target.value;
    setSelected({ ...prev });
  };

  const saveBtnClicked = () => {
    if (!selected.name) {
      showMessage({ head: "Failed!", body: "Please enter name." });
      return;
    }

    addNewMyWorldTier(selected).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new Tier.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
       
      title={"Add new tier"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <div className={classes.column}>
          <TextField
            variant="outlined"
            className={classes.textField}
            value={selected.name}
            label="Name"
            onChange={nameChange}
          />
        </div>
      </div>

      <div className={clsx(classes.row, classes.right)}>
        
        <ModelBtn title={'Close'} onClick={closeModal} />
        <ModelBtn title={'save'} onClick={saveBtnClicked} />
        
      </div>
    </MyModal>
  );
}
