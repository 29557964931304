import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import tableStyles from "../../../../common_classes/table";
import useStyles from "../../question_style";
import { Switch } from "@material-ui/core";
import { getSessionResult } from "../../../../services/api_service/api_service";
import { useParams } from 'react-router-dom';
import LeaderBoard from "../../../../models/leaderboard";
import MyPaginator from "../../../../components/my_paginator/my_paginator";
import { carouselTypeColors } from "../../../carousel/carousel_data";
import * as moment from 'moment'
var TimeFormat = require('hh-mm-ss')

interface Props {
    quizResultSession: LeaderBoard[];
    reloadData: Function;
    onPagination: Function
    pageCount: number
}

export default function QuizResultTable(props: Props) {
    const tableStyle = tableStyles();
    const classes = useStyles()
    // const {pushNamed} = MyNavigator()
    //   const updateQuestion = () => {
    //       pushNamed("/update_question")
    //   };
    const [showUpdateModel, setShowUpdateModel] = useState(false);
    const [isShowViewModel, setShowViewModel] = useState(false);
    const [quizSessionResult, setQuizList] = useState<LeaderBoard[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [resultArray, setResultArray] = useState<any>([])

    const { id }: { id: string } = useParams()

    // let sessionArray: any[] = []
    // const getQuizSessionResult = (page: number) => {
    //     getSessionResult(id, page).then((data) => {
    //         const res = data?.data.content
    //         setResultArray(res)
    //         let rid = ''
    //         for (var i in data?.data.content) {
    //             rid = data?.data.content[i].id
    //             if (data?.success) {
    //                 if (data?.data.content[i].id == id) {
    //                     sessionArray.push(resultArray)
    //                     const d: LeaderBoard[] = [];
    //                     data.data?.content?.forEach((x: any) => d.push(new LeaderBoard(x)));
    //                     setQuizList(d);
    //                     setPageCount(data.data?.totalPages);
    //                 }
    //             }
    //         }
    //         console.log("res Array", sessionArray)
    //     });
    // };

    // const msToTime = (myDuration: number) => {
    //     var result = Math.floor(myDuration / (1000 * 60 * 60)) + ":" + Math.floor(myDuration / (1000 * 60)) % 60 + ":" + Math.floor(myDuration / 1000) % 60;
    //     return result
    // }

    
    function msToTime(s: number) {
        // Pad to 2 or 3 digits, default is 2
        function pad(n: number, z: number) {
          z = z || 2;
          return ('00' + n).slice(-z);
        }
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
      
        return pad(hrs, 2) + ':' + pad(mins,2) + ':' + pad(secs,2);
      }


    const handlePagination = (page: number) => {
        props.onPagination(page)
        setCurrentPage(page)
    }
    return (
        <div>
            <TableContainer component={Paper}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={tableStyle.header}>ID</TableCell>
                            <TableCell className={tableStyle.header}>MSISDN</TableCell>
                            <TableCell className={tableStyle.header}>Score</TableCell>
                            <TableCell className={tableStyle.header}>Correct</TableCell>
                            <TableCell className={tableStyle.header}>Time Taken(HH:MM:SS)</TableCell>
                            <TableCell className={tableStyle.header}>Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.quizResultSession.map((item, index) => (
                            <TableRow>
                                <TableCell className={tableStyle.tableData}>
                                    {((currentPage - 1) * 10) + 1 + index}
                                </TableCell>
                                <TableCell className={tableStyle.tableData}>
                                    {item.msisdn}
                                </TableCell>
                                <TableCell className={tableStyle.tableData}>
                                    <div className={classes.typeChip}>{item.score}</div>
                                </TableCell>
                                <TableCell className={tableStyle.tableData}>
                                    <p>{item.correctQuiz + "/" + item.totalQuiz}</p>
                                </TableCell>
                                <TableCell className={tableStyle.tableData}>
                                    {msToTime(Number(item.elapsedTimeApp))}
                                </TableCell>
                                <TableCell className={tableStyle.tableData}>
                                    <p>{item.submitTime}</p>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.quizResultSession.length > 0 && (
                <MyPaginator pageCount={props.pageCount} onChange={handlePagination} />
            )}
        </div>
    );
}
