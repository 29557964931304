export default class KeyCloakUser {
    id: string
    family_name: string
    given_name: string
    name: string
    preferred_username: string
    realm_access: string[] = []
    isMerchant: boolean
    isCustomerCare: boolean
    isAnalyzer: boolean
    isBloodDonationAdmin: boolean
    isQuizHandler: boolean
    isEligibilityHandler: boolean | undefined
    isPUBGHandler: boolean
    isSegmentationNotification: boolean
    constructor(data: any) {
        console.log("data", data)
        this.id = data.sub
        this.family_name = data.family_name
        this.given_name = data.given_name
        this.name = data.name
        this.preferred_username = data.preferred_username
        data.realm_access?.roles.forEach((x: any) => this.realm_access.push(x))
        this.isMerchant = this.realm_access.includes('ibiza-merchant')
        this.isCustomerCare = this.realm_access.includes('customercare_support')
        this.isAnalyzer = this.realm_access.includes('report_service')
        this.isBloodDonationAdmin = this.realm_access.includes('blood_donation')
        this.isQuizHandler = this.realm_access.includes('quiz-manage')
        this.isEligibilityHandler = this.realm_access.includes('migration-eligibility-manage')
        this.isPUBGHandler = this.realm_access.includes('pubg-redeem-code-manage')
        this.isSegmentationNotification = this.realm_access.includes('segmented-notification-manage')
    }
}