import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { EventDetailsCard } from './components/event_data_card/CS_event_data_card'
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Alert } from "@material-ui/lab";

interface IProps {
  data: Object[]
  startDate: Date;
  endDate: Date;
  setStartDate: Function;
  setEndDate: Function;
}

export default function CSeventsData(props: IProps) {
  const [data, setData] = useState<Object[]>([{}])

  useEffect(() => {
    setData(props.data)
  }, [props.data]);

  const handleStartDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
    props.setStartDate(date)
  }

  const handleEndDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
    props.setEndDate(date)
  }

  return (
    <Grid container spacing={2}>

      <Grid
        container
        item
        xs={12}
        spacing={5}
        direction="row"
        justify="center"
        alignItems="flex-start"
        style={{ marginTop: 10, }}
      >
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              inputVariant="outlined"
              ampm={true}
              label="Start Date"
              value={props.startDate}
              onChange={handleStartDateChange}
              onError={console.log}
              disableFuture
              format="dd/MM/yyyy HH:mm"
            />
          </MuiPickersUtilsProvider>

        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              inputVariant="outlined"
              ampm={true}
              label="End Date"
              value={props.endDate}
              onChange={handleEndDateChange}
              onError={console.log}
              disableFuture
              format="dd/MM/yyyy HH:mm"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      {typeof data[0] !== "undefined" ?
        data.map((item, index) => {
          return (
            <Grid container item xs={12} direction="column" key={index}>
              <EventDetailsCard
                cardData={item}
                success={Reflect.get(item, "status") !== "undefined" ? Reflect.get(item, "status") : ''} />
            </Grid>
          )
        })
        :
        <Grid item xs={12} direction="column" style={{ textAlign: "center" }}>
          <Alert severity="error" style={{ margin: "20px" }}>No data available for the selected time period</Alert>
        </Grid>
      }
    </Grid>
  );
}
