import PartnerCategory from "./partner_category";

export default class PartnerMerchant {
  id: string;
  name: string;
  username: string;
  category: PartnerCategory | undefined;
  status: string;
  createdDate: string;
  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.username = data.username;
    this.category = data.category
      ? new PartnerCategory(data.category)
      : undefined;
    this.status = data.status;
    this.createdDate = data.createdDate;
  }
}
