import React, { useState } from "react";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import PartnerCategory from "../../../models/partner_category";
import PartnerCategoryModel from "./partner_category_model";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import { deletePartnerCategoryCall } from "../../../services/api_service/api_service";
import tableStyles from "../../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../../components/model_btn/model_btn";
import PartnerCategoryUpdateModel from "./partner_category_update_model";
import clonedeep from "lodash.clonedeep";

interface Props {
  categories: PartnerCategory[];
  reloadData: Function;
}
export default function PartnerCategoryTable(props: Props) {
  const tableStyle = tableStyles();
  const [showModel, setShowModel] = useState(false);

  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PartnerCategory>();

  const showConfMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showConfirmMessage
  );
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const onView = (item: PartnerCategory) => {
    setSelectedItem(item);
    setShowModel(true);
  };

  const onUpdate = (item: PartnerCategory) => {
    setSelectedItem(clonedeep(item));
    setShowUpdateModel(true);
  };

  const onDelete = (item: PartnerCategory) => {
    showConfMessage({
      head: "Are you sure?",
      body: "This action cannot be undone.",
      onConfirm: () => {
        deletePartnerCategoryCall(item.id).then((data) => {
          if (data?.success) {
            props.reloadData();
          } else {
            showMessage({
              head: "Failed!",
              body:
                "Unable to delete partner category. Please try again later.",
            });
          }
        });
      },
    });
  };
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Image</TableCell>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>Description</TableCell>
            <TableCell
              width="320px"
              className={tableStyle.header}
              align="center"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.categories.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell>
                <img
                  height="50px"
                  width={"50px"}
                  src={item.imageUrl}
                  alt="partner category icon"
                />
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.name[0]?.text}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.description[0]?.text}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                <ModelBtn title={"Update"} onClick={() => onUpdate(item)} />
                <ModelBtn title={"delete"} onClick={() => onDelete(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PartnerCategoryModel
        isShow={showModel}
        setShow={setShowModel}
        selectedCategory={selectedItem}
      />

      <PartnerCategoryUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selectedCategory={selectedItem}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
