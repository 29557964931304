import { Grid } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { getSummaryStats } from '../../../../services/api_service/api_service';
import time from '../../../../services/time';
import { SummaryTable } from '../summary_table/summary_table'
import DateFnsUtils from '@date-io/date-fns';
import { Alert } from '@material-ui/lab';


export default function SummaryTab() {
    const [startDate, handleStartDateChange] = useState<Date | null>(time.getYesterday());
    const [endDate, handleEndDateChange] = useState<Date | null>(time.getToday());
    const [data, setData] = useState<Object[]>([]);

    const fetchStats = () => {
        getSummaryStats(time.formatDate(startDate), time.formatDate(endDate)).then((data) => {
            if (data?.success) {
                const values: Object[] = data.data
                setData(values)
            }
        });
    };

    useEffect(() => {
        fetchStats()
    }, [startDate, endDate])

    useEffect(() => {
        fetchStats()
    }, [])
    return (
        <div>
            <Grid container spacing={2}>
                <Grid
                    container
                    item
                    xs={12}
                    spacing={5}
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                label="Start Date"
                                value={startDate}
                                disableFuture
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>

                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="End Date"
                                format="MM/dd/yyyy"
                                value={endDate}
                                disableFuture
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                {typeof data[0] !== "undefined" ?
                    <Grid item xs={12}>
                        <SummaryTable data={data} />
                    </Grid>
                    :
                    <Grid item xs={12} direction="column" style={{ textAlign: "center" }}>
                        <Alert severity="error" style={{ margin: "20px" }}>No data available for the selected time period</Alert>
                    </Grid>
                }

            </Grid>
        </div>
    )
}
