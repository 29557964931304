import React, { useState, useEffect } from "react";
import { TextField, Select, MenuItem, Card, Box } from "@material-ui/core";
import useStyles from "./finek_items_style";
import FinekItem, { emptyItem } from "../../../models/finek_item";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import {
  uploadAssetCall,
  getFinekTypesCall,
  getFinekLevelsCall,
  addNewItemCall,
} from "../../../services/api_service/api_service";
import LangAndText from "../../../models/lang_and_text";
import FinekType from "../../../models/finek_type";
import EligibleLevel from "../../../models/eligible_level";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import Asset, { emptyAsset } from "../../../models/Asset";
import IconBtn from "../../../components/icon_btn/icon_btn";
import { Remove } from "@material-ui/icons";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function FinekItemAddModel(props: Props) {
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState<FinekItem>(
    new FinekItem(emptyItem)
  );

  const [allTypes, setAllTypes] = useState<FinekType[]>([]);
  const [allLevels, setAllLevels] = useState<EligibleLevel[]>([]);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const getAllTypes = () => {
    getFinekTypesCall().then((data) => {
      if (data?.success) {
        const tps: FinekType[] = [];
        data.data.forEach((x: any) => tps.push(new FinekType(x)));
        setAllTypes(tps);
      }
    });
  };

  const getAllLevels = () => {
    getFinekLevelsCall().then((data) => {
      if (data?.success) {
        const tps: EligibleLevel[] = [];
        data.data.forEach((x: any) => tps.push(new EligibleLevel(x)));
        setAllLevels(tps);
      }
    });
  };

  useEffect(() => {
    if (props.isShow) {
      getAllTypes();
      getAllLevels();
      setSelectedItem(new FinekItem(emptyItem));
    }
  }, [props.isShow]);
  const closeModal = () => {
    props.setShow(false);
  };

  const onIconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const height = img.height;
        const width = img.width;
        if (height === 250 && width === 250) {
          uploadAssetCall(event.target.files[0]).then((data) => {
            if (data?.success) {
              const value = data.data.url;
              const newItem = selectedItem;
              newItem.iconUrl = value;
              setSelectedItem({ ...newItem });
            }
          });
        } else {
          showMessage({
            head: "Failed to upload image!",
            body: "Selected image is not 250px * 250px",
          });
        }
      }
    };
  };

  const onIconUploadIos = (event: any, index: number) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const value = data.data.url;
          const newItem = selectedItem;
          newItem.assets[index].ios.url = value;
          setSelectedItem({ ...newItem });
        }
      });
    }
  };

  const onIconUploadAndroid = (event: any, index: number) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const value = data.data.url;
          const newItem = selectedItem;
          newItem.assets[index].android.url = value;
          setSelectedItem({ ...newItem });
        }
      });
    }
  };

  const onPriceChange = (event: any) => {
    const value = event.target.value;
    const newItem = selectedItem;
    newItem.price = value;
    setSelectedItem({ ...newItem });
  };

  const onAssetNameChange = (event: any, index: number) => {
    const value = event.target.value;
    const newItem = selectedItem;
    newItem.assets[index].assetName = value;
    setSelectedItem({ ...newItem });
  };

  const onAssetVersionChange = (event: any, index: number) => {
    const value = event.target.value;
    const newItem = selectedItem;
    newItem.assets[index].version = value;
    setSelectedItem({ ...newItem });
  };

  const onAssetTypeChange = (event: any, index: number) => {
    const value = event.target.value;
    const newItem = selectedItem;
    newItem.assets[index].type = value;
    setSelectedItem({ ...newItem });
  };

  const nameChange = (x: LangAndText[]) => {
    const prev = selectedItem;
    prev.name = x;
    setSelectedItem({ ...prev });
  };

  const descriptionChange = (x: LangAndText[]) => {
    const prev = selectedItem;
    prev.description = x;
    setSelectedItem({ ...prev });
  };
  const onTypeChange = (type: FinekType) => {
    const newItem = selectedItem;
    newItem.type = type;
    newItem.typeId = type.id;
    setSelectedItem({ ...newItem });
  };

  const onLevelChange = (level: EligibleLevel) => {
    const newItem = selectedItem;
    newItem.eligibleLevelItem = level;
    newItem.eligibleLevel = level.id;
    setSelectedItem({ ...newItem });
  };

  const addNewAssetRow = () => {
    const newItem = selectedItem;
    newItem.assets.push(new Asset(emptyAsset));
    setSelectedItem({ ...newItem });
  };

  const removeAssetRow = (index: number) => {
    const newItem = selectedItem;
    newItem.assets.splice(index, 1);
    setSelectedItem({ ...newItem });
  };

  const onSave = () => {
    if (!selectedItem.iconUrl) {
      showMessage({
        head: "Failed!",
        body: "Please select an icon and try again.",
      });
      return;
    }
    if (
      !selectedItem.assets[0].ios.url ||
      !selectedItem.assets[0].android.url
    ) {
      showMessage({
        head: "Failed!",
        body: "Please select asset icons and try again.",
      });
      return;
    }

    if (!selectedItem.typeId) {
      showMessage({
        head: "Failed!",
        body: "Please select a type and try again.",
      });
      return;
    }
    if (!selectedItem.eligibleLevel) {
      showMessage({
        head: "Failed!",
        body: "Please select an eligible level and try again.",
      });
      return;
    }

    addNewItemCall(selectedItem).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new item.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Add new Avatar Item"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <IconUploadBtn
          mediaUrl={selectedItem.iconUrl}
          mediaType={"IMAGE"}
          onUpload={onIconUpload}
          allowVideo={false}
          height={100}
          isEditable={true}
        />
        <p className={classes.imageSizeText}>
          This image should be 250px * 250px
        </p>
        <ModelFieldWrapper>
          <ModelLabel title={"Coins"} noMargin />
          <div className={classes.price}>
            <TextField
              onChange={onPriceChange}
              value={selectedItem?.price}
              style={{ width: "100px" }}
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </div>
        </ModelFieldWrapper>

        <ModelLabel title={"Assets"} onClick={addNewAssetRow} />
        {selectedItem?.assets.map((item, index) => (
          <Card key={index} elevation={3} className={classes.assetCard}>
            <div className={classes.centeredRow}>
              <TextField
                label={"Asset Name"}
                onChange={(event) => onAssetNameChange(event, index)}
                value={item.assetName}
                className={classes.textFieldHalf}
                variant="outlined"
              />
              <TextField
                label={"Asset Type"}
                onChange={(event) => onAssetTypeChange(event, index)}
                value={item.type}
                className={classes.textFieldHalf}
                select
                variant="outlined"
              >
                {allTypes.map((type, idx) => (
                  <MenuItem key={idx} value={type.typeId}>
                    {type.typeId}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={"Version"}
                type="number"
                onChange={(event) => onAssetVersionChange(event, index)}
                value={item.version}
                className={classes.textFieldHalf}
                variant="outlined"
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </div>

            <div key={index} className={classes.assetRow}>
              <div className={classes.assetItem}>
                <IconUploadBtn
                  mediaUrl={item.ios.url}
                  mediaType={"FILE"}
                  onUpload={onIconUploadIos}
                  allowVideo={false}
                  height={70}
                  isEditable={true}
                  index={index}
                  allowAnyFile
                />
                <div className={classes.boldText}>IOS</div>
              </div>

              <div className={classes.assetItem}>
                <IconUploadBtn
                  mediaUrl={item.android.url}
                  mediaType={"FILE"}
                  onUpload={onIconUploadAndroid}
                  allowVideo={false}
                  height={70}
                  isEditable={true}
                  index={index}
                  allowAnyFile
                />
                <div className={classes.boldText}>ANDROID</div>
              </div>
            </div>
            <div className={classes.removeBtnWrapper}>
              <IconBtn icon={Remove} onClick={() => removeAssetRow(index)} />
            </div>
          </Card>
        ))}

        <LangAndTextEditTabs
          label="Name"
          data={selectedItem.name}
          setData={nameChange}
          isEditable={true}
        />
        <LangAndTextEditTabs
          label="Description"
          data={selectedItem.description}
          setData={descriptionChange}
          isEditable={true}
        />

        <ModelFieldWrapper>
          <ModelLabel title={"Category"} noMargin />
          <Box height={20} />
          <Select
            style={{ width: "300px" }}
            value={selectedItem.type?.typeId}
            variant="outlined"
          >
            {allTypes.map((type, index) => (
              <MenuItem
                key={index}
                value={type.id}
                onClick={() => onTypeChange(type)}
              >
                {type.name[0]?.text}
              </MenuItem>
            ))}
          </Select>
        </ModelFieldWrapper>

        <ModelFieldWrapper>
          <ModelLabel title={"Eligible Level"} noMargin />
          <Box height={20} />
          <Select
            style={{ width: "300px" }}
            value={selectedItem.eligibleLevelItem?.id}
            variant="outlined"
          >
            {allLevels.map((level, index) => (
              <MenuItem
                key={index}
                value={level.id}
                onClick={() => onLevelChange(level)}
              >
                {level.name}
              </MenuItem>
            ))}
          </Select>
        </ModelFieldWrapper>
        <div>
          <div className={clsx(classes.row, classes.right)}>
            <ModelBtn title={"Close"} onClick={closeModal} />
            <ModelBtn title={"save"} onClick={onSave} />
          </div>
        </div>
      </div>
    </MyModal>
  );
}
