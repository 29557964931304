import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
// import { useStoreActions, Actions } from "easy-peasy";
import clsx from 'clsx'
import WilayaCity from "../../../models/wilaya_city";
import tableStyles from "../../../common_classes/table";
// import { StoreModel } from "../../../store models/model";
import ModelBtn from "../../../components/model_btn/model_btn";
import WilayaCityModel from "./wilaya_city_model";

interface Props {
  cities: WilayaCity[];
  reloadData: Function;
}
export default function WilayaCityTable(props: Props) {
  const [isShow, setShow] = useState(false);
  const [selected, setSelected] = useState<WilayaCity>();
 
 
  const tableStyle = tableStyles();

  const onView = (city: WilayaCity) => {
    setSelected(city);
    setShow(true);
  };

  
  
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>City Name</TableCell>
            <TableCell
              className={tableStyle.header}
              align="center"
              width="200px"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.cities.map((item, index) => (
            <TableRow className={clsx(index % 2 === 1 && tableStyle.oddRowColor)} hover key={index}>
              <TableCell className={tableStyle.tableData}>
                {item.name[0]?.text}
              </TableCell>
              
              <TableCell width={220} align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <WilayaCityModel isShow={isShow} setShow={setShow} selected={selected} />
    </TableContainer>
  );
}
