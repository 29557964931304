import { Card, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import FinekItem from "../../models/finek_item";
import useStyles from "./notification_template_style";
import FadeIn from "react-fade-in";
import { MoreVert } from "@material-ui/icons";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Radio, FormControlLabel } from "@material-ui/core";
import NotificationModel from "../../models/notification";
import NotificationTitle from "../../models/notificationHeader";
import NotificationBody from "../../models/notificationMessage";

interface Props {
    messageTemplates: NotificationModel;
    reloadData: Function
}
export default function NotificationTemplateGridItem(props: Props) {
    const classes = useStyles();
    const [isHover, setHover] = useState(false);
    const [selected, setSelected] = useState<NotificationModel>();
    const [selectedValue, setSelectedValue] = useState("");

    useEffect(() => {
        setSelected(props.messageTemplates);
    }, [props.messageTemplates]);

    const getTitleEN = (items: NotificationTitle[]) => {
        let title = items.find(item => item.language === "EN")
        return title?.text
    }

    const getTitleFR = (items: NotificationTitle[]) => {
        let title = items.find(item => item.language === "FR")
        return title?.text
    }

    const getTitleAR = (items: NotificationTitle[]) => {
        let title = items.find(item => item.language === "AR")
        return title?.text
    }

    const getBodyEN = (items: NotificationBody[]) => {
        let title = items.find(item => item.language === "EN")
        return title?.text
    }

    const getBodyFR = (items: NotificationBody[]) => {
        let title = items.find(item => item.language === "FR")
        return title?.text
    }

    const getBodyAR = (items: NotificationBody[]) => {
        let title = items.find(item => item.language === "AR")
        return title?.text
    }

    return (
        <Grid item>
            <Card
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={classes.gridCard}
                elevation={isHover ? 10 : 2}
            >
                <div className="row">
                    <div className="col-md-12">
                        <FormControlLabel value={selected?.id} label="" control={<Radio className={classes.radioButton} color="primary" />} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <p className={classes.name}>EN</p>
                    </div>
                </div>
                <div className="row" style={{ margin: "0px 0px 0px 50px" }}>
                    <div className="col-md-2">
                        <label>01.Title</label>
                    </div>
                </div>
                <div className="row" style={{ margin: "4px 0px 4px 64px" }}>
                    <div className="col-md-12">
                        <input type="text" value={getTitleEN(props.messageTemplates.title)} className="form-control" placeholder=""></input>
                    </div>
                </div>
                <div className="row" style={{ margin: "0px 0px 0px 50px" }}>
                    <div className="col-md-2">
                        <label >02.Body</label>
                    </div>
                </div>
                <div className="row" style={{ margin: "4px 0px 4px 64px" }}>
                    <div className="col-md-12">
                        <textarea value={getBodyEN(props.messageTemplates.body)} className="form-control" rows={3}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <p className={classes.name}>FR</p>
                    </div>
                </div>
                <div className="row" style={{ margin: "0px 0px 0px 50px" }}>
                    <div className="col-md-2">
                        <label>01.Title</label>
                    </div>
                </div>
                <div className="row" style={{ margin: "4px 0px 4px 64px" }}>
                    <div className="col-md-12">
                        <input type="text" value={getTitleFR(props.messageTemplates.title)} className="form-control" placeholder=""></input>
                    </div>
                </div>
                <div className="row" style={{ margin: "0px 0px 0px 50px" }}>
                    <div className="col-md-2">
                        <label >02.Body</label>
                    </div>
                </div>
                <div className="row" style={{ margin: "4px 0px 4px 64px" }}>
                    <div className="col-md-12">
                        <textarea className="form-control" value={getBodyFR(props.messageTemplates.body)} rows={3}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <p className={classes.name}>AR</p>
                    </div>
                </div>
                <div className="row" style={{ margin: "0px 0px 0px 50px" }}>
                    <div className="col-md-2">
                        <label>01.Title</label>
                    </div>
                </div>
                <div className="row" style={{ margin: "4px 0px 4px 64px" }}>
                    <div className="col-md-12">
                        <input type="text" value={getTitleAR(props.messageTemplates.title)} className="form-control" placeholder=""></input>
                    </div>
                </div>
                <div className="row" style={{ margin: "0px 0px 0px 50px" }}>
                    <div className="col-md-2">
                        <label >02.Body</label>
                    </div>
                </div>
                <div className="row" style={{ margin: "4px 0px 4px 64px" }}>
                    <div className="col-md-12">
                        <textarea className="form-control" value={getBodyAR(props.messageTemplates.body)} rows={3}></textarea>
                    </div>
                </div>
            </Card>
        </Grid>
    );
}
