import React, { useEffect, useState } from "react";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import CommonPage from "../../../components/common_page/common_page";
import PageTab from "../../../components/page_tab/page_tab";
import MyWorldPlan from "../../../models/my_world_plan";
import { getAllMyWorldPlans } from "../../../services/api_service/api_service";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import MyWorldPlansAddModel from "./my_world_plans_add_model";
import MyWorldPlansTable from "./my_world_plans_table";

export default function MyWorldPlansPage() {
  const [plans, setPlans] = useState<MyWorldPlan[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const showAddModel = () => {
    setShowAddModel(true);
  };

  const getPlans = () => {
    getAllMyWorldPlans().then((data) => {
      if (data?.success) {
        console.log(data.data)
        const pl: MyWorldPlan[] = [];
        data.data.forEach((x: any) => pl.push(new MyWorldPlan(x)));
        setPlans(pl);
      }
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.myWorldPlans}
      parentRoutes={[routeNameObjects.myWorld]}
    >
      <AddItemBtn title="Add New Plan" onClick={showAddModel} />
      

      {plans.length > 0 && (
        <PageTab
          tabsData={[
            {
              title: "Daily Plans",
              component: (
                <MyWorldPlansTable
                  plans={plans.filter(
                    (p) => p.validity.toLocaleLowerCase() === "daily"
                  )}
                  reloadData={getPlans}
                />
              ),
            },
            {
              title: "Weekly Plans",
              component: (
                <MyWorldPlansTable
                  plans={plans.filter(
                    (p) => p.validity.toLocaleLowerCase() === "weekly"
                  )}
                  reloadData={getPlans}
                />
              ),
            },
            {
              title: "Monthly Plans",
              component: (
                <MyWorldPlansTable
                  plans={plans.filter(
                    (p) => p.validity.toLocaleLowerCase() === "monthly"
                  )}
                  reloadData={getPlans}
                />
              ),
            },
          ]}
        />
      )}

      <MyWorldPlansAddModel
        reloadData={getPlans}
        isShow={isShowAddModel}
        setShow={setShowAddModel}
      />
    </CommonPage>
  );
}
