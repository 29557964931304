export const emptyAccountData: AccountData = {
    account: '',
    balance: null,
    unit: '',
    expiryDate: null,
};

class AccountData {
    account: string;
    balance: null | string;
    unit: string;
    expiryDate: null | string;
    constructor(data: any) {
        this.account = data.account;
        this.balance = data.balance;
        this.unit = data.unit;
        this.expiryDate = data.expiryDate;
    }
}

export default AccountData;