import Questions from "./questions";
import Answer2 from "./quiz_pool_answer2";
class QuizPoolModel {
    id?: string;
    question: Questions[] = [];
    answers: Answer2[] = [];
    correctAnswer: number;
    level: string;
    status: string;

    constructor(data: any) {
        this.id = data.id;
        this.question = data.question;
        this.answers = data.answers;
        this.correctAnswer = data.correctAnswer;
        this.level = data.level;
        this.status = data.status;
    }
}

export const emptyQuestionPool: QuizPoolModel = {
    question: [{ language: "EN", message: "" }, { language: "AR", message: "" }, { language: "FR", message: "" }],
    answers: [{
        id: 1,
        answer: [
            {
                language: "EN",
                message: ""
            },
            {
                language: "AR",
                message: ""
            },
            {
                language: "FR",
                message: ""
            }
        ]
    },
    {
        id: 2,
        answer: [
            {
                language: "EN",
                message: ""
            },
            {
                language: "AR",
                message: ""
            },
            {
                language: "FR",
                message: ""
            }
        ]
    },
    {
        id: 3,
        answer: [
            {
                language: "EN",
                message: ""
            },
            {
                language: "AR",
                message: ""
            },
            {
                language: "FR",
                message: ""
            }
        ]
    },
    {
        id: 4,
        answer: [
            {
                language: "EN",
                message: ""
            },
            {
                language: "AR",
                message: ""
            },
            {
                language: "FR",
                message: ""
            }
        ]
    }
    ],
    correctAnswer: 1,
    level: "",
    status: "",
};

export default QuizPoolModel;
