import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import cloneDeep from "lodash.clonedeep";
import useStyles from './question_style';
import { Switch } from "@material-ui/core";
import { questionTypeColors } from "./question_data";
import QuestionsViewModel from "./questions_view_modal";
import MyNavigator from "../../services/navigation";
import QuizPoolModel from "../../models/quizPool";
import Questions from "../../models/questions"
import { updateQuizStatus } from "../../services/api_service/api_service";
import { render } from "@testing-library/react";


interface Props {
  quizPool: QuizPoolModel[];
  reloadData: Function;
  currentPage: any;
  editOpen: Function
}

export default function QuestionTable(props: Props) {
  const tableStyle = tableStyles();
  const classes = useStyles()
  const { pushNamed } = MyNavigator()

  const updateQuestion = (item: QuizPoolModel) => {
    props.editOpen(item)
  };
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<QuizPoolModel>();
  const [message, setMessage] = useState("")
  const [isShowViewModel, setShowViewModel] = useState(false);
  const [status, setStatus] = useState("")

  const viewQuestions = (item: QuizPoolModel) => {
    setSelectedItem(item)
    setShowViewModel(true)
  };


  const getMessage = (items: Questions[]) => {
    let message = items.find(item => item.language === "EN")
    return message?.message
  }

  const updateStatus = (id: string | undefined, status: string) => {
    let newStatus: string = status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
    if (id) {
      updateQuizStatus(id, newStatus).then((data) => {
        console.log("data", data)
        if (data?.success) {
        }
      });
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>ID</TableCell>
            <TableCell className={tableStyle.header}>Question</TableCell>
            <TableCell className={tableStyle.header}>Correct Answer</TableCell>
            <TableCell className={tableStyle.header}>Level</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Status
            </TableCell>
            <TableCell className={tableStyle.header}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.quizPool.map((item, index) => (
            console.log(item.status),
            <TableRow className={clsx(index % 2 === 1 && tableStyle.oddRowColor)} hover key={index}>
              <TableCell>
                {((props.currentPage - 1) * 10) + 1 + index}
              </TableCell>
              <TableCell>
                {getMessage(item.question)}
              </TableCell>
              <TableCell>
                {item.correctAnswer}
              </TableCell>
              <TableCell>
                <p>{item.level}</p>
              </TableCell>
              <TableCell align="center">
                <Switch key={item.id} id={item.id} defaultChecked={item.status === "ACTIVE" ? true : false} onClick={() => updateStatus(item?.id, String(item.status))} />
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"View"} onClick={() => viewQuestions(item)} />
                <ModelBtn title={"Update"} onClick={() => updateQuestion(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <QuestionsViewModel
        isShow={isShowViewModel}
        setShow={setShowViewModel}
        selected={selectedItem}
      />
    </TableContainer>
  );
}
