import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      marginTop: "65px",
      width:'100%',
      display:'flex',
      flexDirection:'row'
    },
    selectField :{
      minWidth: "100px",
      marginRight:'10px',
      marginBottom:'5px'
    },
    dateShow:{
      width: "260px",
      border: "1px solid #b0b0b0",
      borderRadius: "20px",
      padding: "5px 20px",
      display:'flex',
      flexDirection:'row',
      alignItems:'flex-end',
      margin:'10px 0px',
      cursor:'pointer'
    },
    datePicker:{
      position:'absolute',
      zIndex:100
    },
    filterRow1:{ width: "100%", display:'flex', flexDirection:'row' }
  })
);

export default useStyles;
