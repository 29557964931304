import React, { useState, useEffect } from "react";
import useStyles from "./partner_category_style";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import PartnerCategory from "../../../models/partner_category";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedCategory: PartnerCategory | undefined;
}
export default function PartnerCategoryModel(props: Props) {
  const [selectedCategory, setSelectedCategory] = useState<PartnerCategory>();

  useEffect(() => {
    setSelectedCategory(props.selectedCategory);
  }, [props.selectedCategory]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };


  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
       
      title={"Partner Category"}
      setShow={props.setShow}
    >
      <div>
        {selectedCategory && (
          <IconUploadBtn
            mediaUrl={selectedCategory.imageUrl}
            mediaType={"IMAGE"}
            onUpload={null}
            allowVideo={false}
            height={120}
            isEditable={false}
          />
        )}

        <LangAndTextEditTabs
          data={selectedCategory?.name}
          label="Name"
          isEditable={false}
          setData={null}
        />
        <LangAndTextEditTabs
          data={selectedCategory?.description}
          label="Description"
          isEditable={false}
          setData={null}
        />

        <div className={clsx(classes.row, classes.right)}>
          
          <ModelBtn title={'Close'} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
