import React, { useState, useEffect } from "react";
import useStyles from "./decoration_assets_style";
import clsx from "clsx";
import { useStoreActions, Actions } from "easy-peasy";
import MyModal from "../../components/my_modal/my_modal";
import { StoreModel } from "../../store models/model";
import {
  addNewDecorationAssetCall,
  uploadAssetCall,
} from "../../services/api_service/api_service";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../components/model_btn/model_btn";
import DecorationAsset, { emptyDecoAsset } from "../../models/decoration_asset";
import {
  FormControlLabel,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { decorationAssetTypes } from "./asset_types";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function DecorationAssetAddModal(props: Props) {
  const classes = useStyles();
  const [addAsset, setAddAsset] = useState<DecorationAsset>(
    new DecorationAsset(emptyDecoAsset)
  );

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    setAddAsset(new DecorationAsset(emptyDecoAsset));
  }, [props.isShow]);

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if (!addAsset.url) {
      showMessage({
        head: "Failed!",
        body: "Please select an asset and try again.",
        color: "red",
      });
      return;
    }
    if (!addAsset.name) {
      showMessage({
        head: "Failed!",
        body: "Please enter name and try again.",
        color: "red",
      });
      return;
    }
    if (!addAsset.type) {
      showMessage({
        head: "Failed!",
        body: "Please enter select type and try again.",
        color: "red",
      });
      return;
    }
    addNewDecorationAssetCall(addAsset).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new asset.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };
  const iconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const off = addAsset;
      if (off) {
        setAddAsset({ ...off });
      }
      uploadAssetCall(selectedFile).then((data) => {
        if (data?.success) {
          const off = addAsset;
          if (off) {
            off.url = data.data.url;
            setAddAsset({ ...off });
          }
        }
      });
    }
  };

  const nameChange = (event: any) => {
    const prev = addAsset;
    prev.name = event.target.value;
    setAddAsset({ ...prev });
  };

  const typeChange = (event: any) => {
    const prev = addAsset;
    prev.type = event.target.value;
    setAddAsset({ ...prev });
  };

  const enableChange = (event: any) => {
    const prev = addAsset;
    prev.enabled = event.target.value === 'true';
    setAddAsset({ ...prev });
  };
  return (
    <MyModal
      isShow={props.isShow}
      onClose={() => {}}
      title={"Add New Asset"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <IconUploadBtn
          mediaUrl={addAsset.url}
          mediaType={"FILE"}
          onUpload={iconUpload}
          height={120}
          isEditable
          allowAnyFile
        />
        <div className={classes.column}>
          <TextField
            variant="outlined"
            className={classes.textField}
            value={addAsset.name}
            label="Name"
            onChange={nameChange}
          />

          <TextField
            variant="outlined"
            className={classes.textField}
            value={addAsset.type}
            label="Type"
            onChange={typeChange}
            select
          >
            {decorationAssetTypes.map((t, index) => (
              <ListItem key={index} value={t}>
                {t}
              </ListItem>
            ))}
          </TextField>

          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={addAsset.enabled}
            onChange={enableChange}
            style={{ display: "flex", flexDirection: "row" , width:'fit-content', margin:'10px auto auto auto'}}
          >
            <FormControlLabel value={true} control={<Radio />} label="Enable" />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Disable"
            />
          </RadioGroup>
        </div>
      </div>
      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Close"} onClick={closeModal} />
        <ModelBtn title={"save"} onClick={saveModel} />
      </div>
    </MyModal>
  );
}
