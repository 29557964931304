import { Button, IconButton } from "@material-ui/core";
import {
  AddAPhotoRounded,
  AttachFileOutlined,
  Edit,
  FileCopyRounded,
  FileCopySharp,
  VideoLibraryRounded,
} from "@material-ui/icons";
import React from "react";
import useStyles from "./icon_upload_btn_style";
import { v4 } from "uuid";
import clsx from 'clsx'

interface Props {
  mediaUrl: string;
  mediaType: string;
  onUpload: Function | null;
  allowVideo?: boolean;
  isEditable?: boolean;
  height?: number;
  index?: number;
  allowAnyFile?: boolean;
  onlyCsv?:boolean
}

export default function IconUploadBtn(props: Props) {
  const id = v4();
  const classes = useStyles();

  const AddIcon = props.onlyCsv ? FileCopySharp: props.allowAnyFile
    ? AttachFileOutlined
    : props.allowVideo
    ? VideoLibraryRounded
    : AddAPhotoRounded;
  return (
    <div>
      {props.mediaUrl ? (
        <div className={classes.imageOuter}>
          {props.mediaType === "IMAGE" ? (
            <img
              height={props.height ? props.height : 100}
              width={props.height ? props.height : 100}
              className={clsx(classes.image,classes.imageShadow)}
              src={props.mediaUrl}
              alt={"icon"}
            />
          ) : props.mediaType === "VIDEO" ? (
            <video
              height={props.height ? props.height : 100}
              width={props.height ? props.height : 100}
              className={clsx(classes.image,classes.imageShadow)}
              src={props.mediaUrl}
              autoPlay
              loop
              muted
            />
          ) : (
            <FileCopyRounded
              style={{
                height: props.height ? props.height : 100,
                width: props.height ? props.height : 100,
              }}
              className={classes.image}
            />
          )}
          {props.isEditable && (
            <IconButton
              className={classes.iconBtn}
              style={{
                height: props.height ? props.height / 3 : "30px",
                width: props.height ? props.height / 3 : "30px",
              }}
              onClick={() => {
                document.getElementById(id)?.click();
              }}
            >
              <Edit
                style={{
                  height: props.height ? props.height / 4 : "30px",
                  width: props.height ? props.height / 4 : "30px",
                }}
              />
            </IconButton>
          )}
        </div>
      ) : (
        <Button
          onClick={() => {
            if (props.isEditable) {
              document.getElementById(id)?.click();
            }
          }}
          className={classes.addIconBtn}
        >
          <AddIcon
            style={{
              height: props.height ? props.height : "80px",
              width: props.height ? props.height : "80px",
            }}
            className={classes.addIcon}
          />
        </Button>
      )}

      <input
        style={{ display: "none" }}
        type="file"
        id={id}
        accept={
          props.onlyCsv ? '.csv' :
          props.allowAnyFile
            ? "*"
            : props.allowVideo
            ? "video/*,image/*"
            : "image/*"
        }
        onChange={(event) => {
          if (props.onUpload) {
            props.onUpload(event, props.index);
          }
        }}
      />
    </div>
  );
}
