import React, { useEffect, useState } from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import MyNavigator from "../../../services/navigation";
import QuizSessionTable from "./quiz_session_table";
import QuizSessionModel from "../../../models/quiz_Session";
import MyPaginator from "../../../components/my_paginator/my_paginator";
import { getQuizSession } from "../../../services/api_service/api_service";
import CreateQuizSession from "./create_quiz_sessions/create_quiz_session";
import ViewQuizSession from "./view_sessions/view_session_details";

export default function QuizSession() {
    const [isShowAddModel, setShowAddModel] = useState(false);
    const [quizSessionList, setQuizList] = useState<QuizSessionModel[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [createOpen, setCreateOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);
    const [viewId, setViewId] = useState("")

    const { pushNamed } = MyNavigator()
    const showAddQuizSession = () => {
        pushNamed("/create_quiz_session")
    };

    const uploadQuestions = () => {
        setShowAddModel(true)
    };

    const getQuizSessionList = (page: number) => {
        getQuizSession(page).then((data) => {
            console.log("data", data)
            if (data?.success) {
                const d: QuizSessionModel[] = [];
                data.data?.content?.forEach((x: any) => d.push(new QuizSessionModel(x)));
                setQuizList(d);
                setPageCount(data.data?.totalPages);
            }
        });
    };
    const onPageChange = (page: number) => {
        getQuizSessionList(page - 1);
    };

    useEffect(() => {
        getQuizSessionList(0);
    }, []);

    const handleViewOpen = (id: string) => {
        setViewOpen(true)
        setViewId(id)
        console.log("id")
    }

    const handleViewClose = () => {
        setViewOpen(false)
        setViewId("")
    } 

    return (
        <CommonPage
            currentRoute={routeNameObjects.quizSession}
            parentRoutes={[routeNameObjects.quizSession]}
            title={createOpen ? "Create New Quiz Session" : viewOpen ? "Session Details" : "Question Session"}

        >
            {!createOpen && !viewOpen && <>
                <AddItemBtn title="Add Quiz Session" onClick={() => {setCreateOpen(true)}} />
                {quizSessionList.length > 0 && (
                    <QuizSessionTable editOpen={handleViewOpen} quizSession={quizSessionList} reloadData={getQuizSession} />
                )}
                {quizSessionList.length > 0 && (
                    <MyPaginator pageCount={pageCount} onChange={onPageChange} />
                )}
            </>}
            {createOpen && <CreateQuizSession onSubmit={setCreateOpen}/>}
            {viewOpen && <ViewQuizSession onSubmit={handleViewClose} id={viewId}></ViewQuizSession>}
        </CommonPage >
    );
}
