import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import MyWorldPlugin from "../../../models/my_world_plugin";
import useStyles from "./my_world_plugins_style";
import MyModal from "../../../components/my_modal/my_modal";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
  selectedPlugin: MyWorldPlugin | undefined;
}
export default function MyWorldPluginsModel(props: Props) {
  const classes = useStyles();

  const [selectedplugin, setSelectedPlugin] = useState<MyWorldPlugin>();

  useEffect(() => {
    setSelectedPlugin(props.selectedPlugin);
  }, [props.selectedPlugin, props.isShow]);

  const closeModal = () => {
    props.setShow(false);
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"My world Plugin"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selectedplugin && (
          <div className={classes.column}>
            <IconUploadBtn
              mediaUrl={selectedplugin.logoUrl}
              mediaType={"IMAGE"}
              onUpload={null}
              allowVideo={false}
              height={120}
              isEditable={false}
            />
            <p className={classes.imageTitle}>Logo</p>
            {/* <div className={classes.imageRow}>
              <div>
                <IconUploadBtn
                  mediaUrl={selectedplugin.mediumLogoUrl}
                  mediaType={"IMAGE"}
                  onUpload={null}
                  allowVideo={false}
                  height={120}
                  isEditable={false}
                />
                <p className={classes.imageTitle}>Medium Logo</p>
              </div>
              <div>
                <IconUploadBtn
                  mediaUrl={selectedplugin.largeLogoUrl}
                  mediaType={"IMAGE"}
                  onUpload={null}
                  allowVideo={false}
                  height={120}
                  isEditable={false}
                />
                <p className={classes.imageTitle}>Large Logo</p>
              </div>
            </div> */}
            <TextField
              variant="outlined"
              className={classes.textField}
              value={selectedplugin.referenceName}
              label="Name"
              contentEditable="false"
              InputProps={{ readOnly: true }}
            />

            <TextField
              variant="outlined"
              className={classes.textField}
              value={selectedplugin.code}
              label="Code"
              contentEditable="false"
              InputProps={{ readOnly: true }}
            />

            <ModelFieldWrapper>
              <ModelLabel title={"Extra Values"} noMargin />
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.greyLabel}>Key</TableCell>
                    <TableCell className={classes.greyLabel}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.selectedPlugin &&
                    Object.keys(
                      props.selectedPlugin.extraValues
                        ? props.selectedPlugin.extraValues
                        : {}
                    ).map((key, index) => (
                      <TableRow hover key={index}>
                        <TableCell>
                          <TextField
                            value={key}
                            variant="outlined"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={props.selectedPlugin?.extraValues[key]}
                            variant="outlined"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </ModelFieldWrapper>
          </div>
        )}
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Close"} onClick={closeModal} />
      </div>
    </MyModal>
  );
}
