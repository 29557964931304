import { Card } from "@material-ui/core";
import React from "react";
import QRCode from "qrcode.react";

export default function QRCodeView(props: { code: string }) {
  return (
    <Card elevation={5} style={{ margin: "10px", padding: "10px" }}>
      <QRCode
        value={props.code}
        height={"200px"}
        width={"200px"}
        style={{
          height: "200px",
          width: "200px",
        }}
      />
    </Card>
  );
}
