import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  addBtn: {
    position: "fixed",
    right: "30px",
    bottom: "30px",
    backgroundColor: "#1a3248",
    color: "white",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  right: {
    paddingRight: "10px",
    float: "right",
  },
  textField: {
    margin: "5px 10px",
  },
  modelBtn: {
    marginRight: "3px",
  },
  addOuter: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
  },
  greyLabel: {
    color: "grey",
  },
  mainIcon: {
    height: "150px",
    width: "150px",
    borderRadius: "10px",
    margin: "auto",
    marginBottom: "10px",
    position: "relative",
  },
  iconEdit: {
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: "grey",
    color: "white",
  },
  smallBtn: {
    padding: "3px",
  },
  addPhotoIcon: {
    height: "120px",
    width: "120px",
    color: "grey",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },

  addIcon: {
    height: "60px",
    width: "60px",
    color: "grey",
  },
  addIconBtn: {
    backgroundColor: "#80808021",
    margin: "auto",
    marginTop: "10px",
    width: "100px",
  },
  themeColor: {
    height: "30px",
    width: "30px",
  },
  assetItem: {
    padding: "5px",
    backgroundColor: "#bdbdbd40",
    borderRadius: "5px",
    margin: "5px",
    width: "150px",
  },
  assetRow: {
    display: "flex",
    flexDirection: "row",
    width: "fit-content",
    margin: "auto",
  },
  boldText: {
    fontWeight: "bold",
  },
  imageRow: {
    width: "100%",
    padding: "10px",
    overflowX: "auto",
    display: "flex",
    flexDirection: "row",
  },
  imageCard: {
    margin: "3px",
    borderRadius: "5px",
    height: "100px",
    width: "100px",
    position: "relative",
  },
  assetCard: { margin: "10px", position: "relative" },
  removeBtnWrapper: {
    position: "absolute",
    bottom: "3px",
    right: "3px",
    height: "35px",
  },
});

export default useStyles;
