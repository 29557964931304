import { makeStyles, createStyles, Theme } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      position: "relative",
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    deleteBtn: {
      color: "white",
      position: "absolute",
      right: 3,
      top: 3,
      zIndex: 5,
      backgroundColor: "red",
      padding: "5px",
    },
    textField: {
      margin: "5px 10px",
    },
    selectCity: {
      width:'100%',
      marginBottom:'5px'
    },
    noLocations:{
      width:'100%',
      paddingTop:'50px',
      color:myColors.appBarTextColor,
      textAlign:'center'
    },
    modelBtn: {
      marginRight: "3px",
    },
    addOuter: {
      display: "flex",
      flexDirection: "column",
      width: "500px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      marginTop: "10px",
    },
    right: {
      paddingRight: "10px",
      float: "right",
    },
    addBtn: {
      position: "fixed",
      right: "30px",
      bottom: "30px",
      backgroundColor: "#1a3248",
      color: "white",
    },
    marker: {
      height: "30px",
      width: "30px",
    },
    markerIcon: {
      position: "absolute",
      left: -15,
      top: -20,
    },
    map: {
      height: "300px",
      width: "480px",
      margin: "auto",
      borderRadius:'10px'
    },
    viewModelMap:{ height: "400px", width: "500px" }
  })
);

export default useStyles;
