export default class ThemeItem{
    themeColor:string
    themeDarkColor:string
    backgroundImages:string[]=[]
    constructor(data:any){
        this.themeColor=data.themeColor
        this.themeDarkColor=data.themeDarkColor
        data.backgroundImages.forEach((x:any)=>this.backgroundImages.push(x))
    }
}

export const emptyThemeItem = {
    themeColor:'#FFFFFF',
    themeDarkColor:'#B9B9B9',
    backgroundImages:[]
}