import { makeStyles } from "@material-ui/core";
import myColors from "../../../services/colors";

const useStyles = makeStyles({
  searchOuter:{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField:{
    width:'300px',
    margin:'15px'
  },
  searchRow:{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center'
  },
  btnWrapper:{
    display:'flex',
    height:'40px',
    marginLeft:'10px'
  },
  placeHolder:{
    textAlign: 'center',
    padding: '100px',
    color:myColors.appBarTextColor
  },
  cardMsidn:{
    fontSize: '25px',
    fontWeight: 'bold',
    color:myColors.drawerSideBarColor
  },
  card:{ display: "flex", flexDirection: "row", padding: "20px" },
  cardNameColumn:{display:'flex', flexDirection:'column', flexGrow:1},
  expiresIn:{
    color:myColors.tableDataText,
    fontWeight:'bold'
  }
});
export default useStyles;
