import LocationData from "./location";

class WifiLocation {
  id: string;
  name: String;
  cityId: String;
  cityName: string;
  location: LocationData;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.cityId = data.cityId;
    this.cityName = data.cityName;
    this.location = new LocationData(data.location);
  }
}

export default WifiLocation;
