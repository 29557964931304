import { Action, action } from "easy-peasy";
import Token from "../../models/token";

export interface TokenModel {
  token: Token | null;
  setToken: Action<TokenModel, Token | null>;
  removeToken: Action<TokenModel>;
}

const tokenModel: TokenModel = {
  token: sessionStorage.getItem("ibizaToken")
    ? JSON.parse(sessionStorage.getItem("ibizaToken") || "")
    : null,
  setToken: action((state, token) => {
    sessionStorage.setItem("ibizaToken", JSON.stringify(token));
    state.token = token;
  }),
  removeToken: action((state)=>{
    sessionStorage.clear()
    state.token = null
  })
};

export default tokenModel;
