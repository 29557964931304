import React, { useEffect, useState } from "react";
import { Tab, Tabs, TextField, useTheme } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./mgm_plans_style";
import MyModal from "../../../components/my_modal/my_modal";
import ModelBtn from "../../../components/model_btn/model_btn";
import SwipeableViews from "react-swipeable-views";
import PluginCard from "../../../components/plugin_card/plugin_card";
import MGMPlan from "../../../models/mgm_plan";

interface Props {
  isShow: boolean;
  setShow: Function;
  selected: MGMPlan | undefined;
}
export default function MGMPlanViewModel(props: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const closeModal = () => {
    props.setShow(false);
  };

  const [selected, setSelected] = useState<MGMPlan | undefined>();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected);
      setSelectedTab(0);
    }
  }, [props.selected]);

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"MGM Plan"}
      setShow={props.setShow}
    >
      <div className={classes.viewModelOuter}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          variant="fullWidth"
          classes={{ indicator: classes.tabSelected }}
        >
          <Tab className={classes.tabStyle} label="Plan" />
          <Tab className={classes.tabStyle} label="Limited Plugins" />
          <Tab className={classes.tabStyle} label="Unlimited Plugins" />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={selectedTab}
        >
          <div className={classes.listPadding} hidden={selectedTab !== 0}>
            <Plan selected={selected} />
          </div>
          <div className={classes.listPadding} hidden={selectedTab !== 1}>
            <LimitedPlugins selected={selected} />
          </div>
          <div className={classes.listPadding} hidden={selectedTab !== 2}>
            <UnLimitedPlugins selected={selected} />
          </div>
        </SwipeableViews>
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"close"} onClick={closeModal} />
      </div>
    </MyModal>
  );
}

function Plan(props: { selected: MGMPlan | undefined }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.row}>
        <TextField
          label="Plan Name"
          value={props.selected?.planName}
          variant="outlined"
          className={classes.textField}
          style={{ width: "100%" }}
          InputProps={{ readOnly: true }}
        />
      </div>


      <div className={classes.row}>
        <TextField
          type="number"
          label="Ex Points"
          value={props.selected?.exPoints}
          variant="outlined"
          className={classes.textField}
          style={{ width: "100%" }}
          InputProps={{ readOnly: true }}
        />
      </div>

      <div className={classes.row}>
        <TextField
          type="number"
          label="Bundle Id"
          value={props.selected?.bundleId}
          variant="outlined"
          className={classes.textField}
          style={{ width: "100%" }}
          InputProps={{ readOnly: true }}
        />
      </div>

      <div className={classes.row}>
        <TextField
          label="Bundle Code"
          value={props.selected?.bundleCode}
          variant="outlined"
          className={classes.textField}
          style={{ width: "100%" }}
          InputProps={{ readOnly: true }}
        />
      </div>
      <div className={classes.row}>
        <TextField
          label="Validity"
          value={props.selected?.validity}
          variant="outlined"
          className={classes.textField}
          style={{ width: "100%" }}
          InputProps={{ readOnly: true }}
        />
      </div>

    

     
    </div>
  );
}

function LimitedPlugins(props: { selected: MGMPlan | undefined }) {
  const classes = useStyles();

  return (
    <div>
      {props.selected?.limitedPluginsData &&
      props.selected?.limitedPluginsData.length > 0 ? (
        props.selected?.limitedPluginsData.map((plugin, index) => (
          <PluginCard
            key={index}
            plugin={{
              plan: plugin.plan,
              amount: plugin.amount,
              unit: plugin.unit,
            }}
          />
        ))
      ) : (
        <div className={classes.noDataLabel}>No Limited Plugins</div>
      )}
    </div>
  );
}

function UnLimitedPlugins(props: { selected: MGMPlan | undefined }) {
  const classes = useStyles();
  return (
    <div>
      {props.selected?.unLimitedPluginsData &&
      props.selected?.unLimitedPluginsData.length > 0 ? (
        props.selected?.unLimitedPluginsData.map((plugin, index) => (
          <PluginCard key={index} plugin={{ plan: plugin }} />
        ))
      ) : (
        <div className={classes.noDataLabel}>No Unlimited Plugins</div>
      )}
    </div>
  );
}
