import React, { useState, useEffect } from "react";
import CommonPage from "../../../components/common_page/common_page";
import { getPartnerMerchantsCall } from "../../../services/api_service/api_service";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import PartnerMerchant from "../../../models/partner_merchant";
import PartnerMerchantTable from "./partner_merchant_table";
import PartnerMerchantAddModel from "./partner_merchant_add_model";
import nestedRouteNameObject2 from "../../../services/route_name/nested_route_name_objects2";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";

export default function MerchantPage() {
  const [merchants, setMerchants] = useState<PartnerMerchant[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const getPartnerMerchants = () => {
    getPartnerMerchantsCall().then((data) => {
      if (data?.success) {
        const mer: PartnerMerchant[] = [];
        data.data.forEach((x: any) => mer.push(new PartnerMerchant(x)));
        setMerchants(mer);
      }
    });
  };

  useEffect(() => {
    getPartnerMerchants();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage currentRoute={nestedRouteNameObject2.merchant} parentRoutes={[routeNameObjects.services,nestedRouteNameObject1.partners]}>
      <AddItemBtn title='Add New Merchant' onClick={showAddModel} />
      {merchants.length > 0 && <PartnerMerchantTable merchants={merchants} reloadData={getPartnerMerchants} />}
     
      <PartnerMerchantAddModel isShow={isShowAddModel} setShow={setShowAddModel} reloadData={getPartnerMerchants} />
    </CommonPage>
  );
}
