import NotificationHeader from "./notificationHeader";
import NotificationMessage from "./notificationMessage";
class NotificationModel {
    id: string;
    templateName: string;
    title: NotificationHeader[] = [];
    body: NotificationMessage[] = [];

    constructor(data: any) {
        this.id = data.id;
        this.templateName = data.templateName;
        this.title = data.title;
        this.body = data.body;
    }
}

export const emptyNotificationModel: NotificationModel = {
    id: "",
    templateName: "",
    body: [{ language: "EN", text: "" }, { language: "AR", text: "" }, { language: "FR", text: "" }],
    title: [{ language: "EN", text: "" }, { language: "AR", text: "" }, { language: "FR", text: "" }],
};

export default NotificationModel;
