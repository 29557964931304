import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../components/search_bar/search_bar";
import CommonPage from "../../components/common_page/common_page";
import PageTab from "../../components/page_tab/page_tab";
import routeNameObjects from "../../services/route_name/route_name_objects";
import CSbasicData from "./customer_service_basic_data";
import CSeventData from "./customer_service_events_data";
import { getUserDetailsByMobile, getUserEventsByMobile, getUserTransactionsByMobile } from "../../services/api_service/api_service";
import BasicUserDetails, { emptyBasicUserDetails } from "../../models/user_details/basic_user_details";
import time from "../../services/time";
import { Alert } from "@material-ui/lab";

export default function CustomerServicePage() {
  const [basicDetails, setbasicDetails] = useState<BasicUserDetails>(emptyBasicUserDetails)
  const [userEvents, setUserEvents] = useState<Object[]>([])
  const [userTransactions, setUserTransactions] = useState<Object[]>([])
  const [selectedStartDate, handleStartDateChange] = useState<Date>(time.getYesterday());
  const [selectedEndDate, handleEndDateChange] = useState<Date>(time.getToday());
  const [mobileNumber, setmobileNumber] = useState<string>('');
  const [isSearchComplete, setisSearchComplete] = useState<boolean>(false);
  const [isValidNumber, setisValidNumber] = useState<boolean>(false);

  const fetchBasicData = async (mobile: string) => {
    await getUserDetailsByMobile(formatMobile(mobile)).then((data) => {
      if (data?.success) {
        if (data.data) {
          const values: BasicUserDetails = data.data
          setisValidNumber(true)
          setbasicDetails(values)
        }
        else {
          setisValidNumber(false)
          // setbasicDetails(emptyBasicUserDetails)
        }
      } else {
        setisValidNumber(false)
      }
    });
  };


  const fetchUserEvents = async (mobile: string) => {
    await getUserEventsByMobile(formatMobile(mobile), time.formatDateTime(selectedStartDate), time.formatDateTime(selectedEndDate)).then((data) => {
      if (data?.success) {
        const values: Object[] = data.data
        setUserEvents(values)
      }
    });
  };

  const fetchUserTransactions = async (mobile: string) => {
    await getUserTransactionsByMobile(formatMobile(mobile), time.formatDateTime(selectedStartDate), time.formatDateTime(selectedEndDate)).then((data) => {
      if (data?.success) {
        const values: Object[] = data.data
        setUserTransactions(values)
      }
    });
    setisSearchComplete(true)
  };

  const fetchSearch = async (mobile: string) => {
    setisSearchComplete(false)
    await getUserDetailsByMobile(formatMobile(mobile)).then(async (data) => {
      if (data?.success) {
        if (data.data) {
          const values: BasicUserDetails = data.data
          setisValidNumber(true)
          setbasicDetails(values)
          await fetchUserEvents(mobile)
          await fetchUserTransactions(mobile)
        }
        else {
          setisValidNumber(false)
          // setbasicDetails(emptyBasicUserDetails)
        }
      } else {
        setisValidNumber(false)
      }
      setisSearchComplete(true)
    });
  };

  const formatMobile = (mobile: string) => {
    let formatted: string = mobile.replace(/\s/g, '')
    formatted = formatted.split("-").join("")

    return formatted
  };
  const handleSearch = async (mobile: string) => {
    // let temp: string = '213540100391'
    // let temp: string = '213540999203'

    if (mobile === "clear") {
      setisSearchComplete(false)
      setmobileNumber('')
      setbasicDetails(emptyBasicUserDetails)
      setUserEvents([])
      setUserTransactions([])
    }
    else {
      if (mobileNumber !== mobile) {
        setisSearchComplete(false)
        await setmobileNumber(mobile)
        await fetchSearch(mobile)
      }
    }

  };

  useEffect(() => {
    if (mobileNumber) {
      fetchUserEvents(mobileNumber)
      fetchUserTransactions(mobileNumber)
    }
  }, [selectedStartDate, selectedEndDate])

  return (
    <CommonPage
      currentRoute={routeNameObjects.customerService}
      parentRoutes={[routeNameObjects.customerService]}
    >
      <div style={{ marginTop: 50, justifyContent: "center", display: "flex" }} >
        <SearchBar onSearch={handleSearch} placeholder="Enter Mobile Number" clearEnabled={true} />
      </div>
      {console.log(isSearchComplete, isValidNumber)}
      {
        isSearchComplete ? isValidNumber ?
          (
            <PageTab
              tabsData={[
                {
                  title: "Basic Details",
                  component: (
                    <CSbasicData data={basicDetails} />
                  ),
                },
                {
                  title: "User Events",
                  component: (
                    <CSeventData
                      data={userEvents}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      setStartDate={handleStartDateChange}
                      setEndDate={handleEndDateChange}
                    />
                  ),
                },
                {
                  title: "User Transactions",
                  component: (
                    <CSeventData
                      data={userTransactions}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      setStartDate={handleStartDateChange}
                      setEndDate={handleEndDateChange}
                    />
                  ),
                },
              ]}
            />
          )
          : <Alert severity="error" style={{ margin: "20px" }}>No data available for the selected MSISDN number</Alert>
          : <></>
      }

    </CommonPage>
  );
}
