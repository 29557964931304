import React, { useEffect, useState } from "react";
import MyModal from "../../components/my_modal/my_modal";
import useStyles from "./network_plans_styles";
import NetworkPlans from "../../models/network_plans";
import { Box} from "@material-ui/core";
import clsx from "clsx";
import LangAndTextEditTabs from "../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelLabel from "../../components/model_label/model_label";
import ModelBtn from "../../components/model_btn/model_btn";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelFieldWrapper from "../../components/model_field_wrapper/model_field_wrapper";
import DetailItem from "../../components/detail_item/detail_item";

interface Props {
  isShow: boolean;
  setShow: Function;
  selected: NetworkPlans | undefined;
}
export default function NetworkPlanModel(props: Props) {
  const [selected, setSelected] = useState<NetworkPlans>();

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"View Network Plan"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selected && (
          <IconUploadBtn
            mediaType={"IMAGE"}
            mediaUrl={selected.imageUrl}
            onUpload={null}
            isEditable={false}
            allowVideo={false}
            height={120}
          />
        )}
        {selected && (
          <div className={classes.column}>
            <LangAndTextEditTabs
              label="Name"
              data={selected.name}
              setData={null}
              isEditable={false}
            />

            <LangAndTextEditTabs
              label="Sub Heading"
              data={selected.subHeading}
              setData={null}
              isEditable={false}
            />

            <LangAndTextEditTabs
              label="Description"
              data={selected.description}
              setData={null}
              isEditable={false}
            />

            <ModelFieldWrapper>
              <ModelLabel title={"Other Fields"} noMargin />
              <Box height={15} />
              <DetailItem title="Plan Id" value={selected.planId} />
              <DetailItem title="Price" value={selected.price} />
              <DetailItem
                title="Validity"
                value={`${selected.validity.count} ${selected.validity.unit}`}
              />
            </ModelFieldWrapper>
          </div>
        )}
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Close"} onClick={closeModal} />
      </div>
    </MyModal>
  );
}
