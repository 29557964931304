import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs } from "@material-ui/core";
import { TextField, Switch } from "@material-ui/core";
import ModelBtn from "../../components/model_btn/model_btn";
import CancelItemBtn from "./button/cancelButton"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import EligibilityMatrixModel from "../../models/EligibiliyMetrix";
import ModelLabel from "../../components/model_label/model_label";
import useStyles from "./matrixTableStyle";
import EligibilityMatrixServiceAccountList, { emptyServiceList } from "../../models/EligibiliyMetrixServiceAccountList";
import { StoreModel } from "../../store models/model";
import { useStoreActions, Actions } from "easy-peasy";
import { removeEligibilityMatrix, addServiceList } from "../../services/api_service/api_service";

interface Props {
    onSubmit: Function
    updateServiceList: Function
    onChange: Function
    onChangeServiceList: Function
    data: EligibilityMatrixModel[] | null
    serviceListData: EligibilityMatrixServiceAccountList[]
    reloadData: Function
}

export default function UpdateEligibilityMatrixPage(props: Props) {
    const tableStyle = tableStyles();
    const [selected, setSelected] = useState<EligibilityMatrixServiceAccountList>(new EligibilityMatrixServiceAccountList(emptyServiceList)
    )
    const showConfMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showConfirmMessage
    );
    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );
    const [selectedItem, setSelectedItem] = useState<EligibilityMatrixServiceAccountList>(new EligibilityMatrixServiceAccountList(emptyServiceList))
    const [serviceList, setServiceList] = useState<EligibilityMatrixServiceAccountList[]>([])

    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(0)
    const [active, setActive] = useState(false)
    const [serviceAccountMinValue, setServiceAccountMinValue] = useState(0)
    const [serviceAccountMaxValue, setServiceAccountMaxValue] = useState(0)
    const [serviceAccountActive, setServiceAccountActive] = useState(false)
    const [serviceAccountRangeActive, setServiceAccountRangeActive] = useState(false)
    const [serviceAccountListActive, setServiceAccountListActive] = useState(false)
    const [ageRangeId, setAgeRange] = useState("")
    const [serviceAccountRangeId, setServiceAccountRangeId] = useState("")
    const [serviceAccountListId, setServiceAccountListId] = useState("")
    const [accountId, setAccountId] = useState("")
    const [accountName, setAccountName] = useState("")
    const [serviceListActive, setServiceListActive] = useState(false)
    const [addNewVisible, setAddNewVisible] = useState(false)
    const [addAccountId, setAddAccountId] = useState("")
    const [addAccountName, setAddAccountName] = useState("")

    const classes = useStyles();

    const addNewAssetRow = () => {
        setAddNewVisible(true)
    };

    useEffect(() => {
        if (props.data) {
            setAgeRange(props.data?.filter(item => item.eligibilityCriteria === "AGE_RANGE")[0].id)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "AGE_RANGE")[0].id = ageRangeId
                console.log("val", value)
                // props.onChange(value)
            }
        }
    }, [ageRangeId]);

    useEffect(() => {
        if (props.data) {
            setServiceAccountRangeId(props.data?.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].id)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].id = serviceAccountRangeId
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [serviceAccountRangeId]);

    useEffect(() => {
        if (props.data) {
            setMinValue(props.data?.filter(item => item.eligibilityCriteria === "AGE_RANGE")[0].minValue)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "AGE_RANGE")[0].minValue = minValue
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [minValue]);

    useEffect(() => {
        if (props.data) {
            setMaxValue(props.data?.filter(item => item.eligibilityCriteria === "AGE_RANGE")[0].maxValue)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "AGE_RANGE")[0].maxValue = maxValue
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [maxValue]);

    useEffect(() => {
        if (props.data) {
            setServiceAccountMinValue(props.data?.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].minValue)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].minValue = serviceAccountMinValue
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [serviceAccountMinValue]);

    useEffect(() => {
        if (props.data) {
            setServiceAccountMaxValue(props.data?.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].maxValue)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].maxValue = serviceAccountMaxValue
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [serviceAccountMaxValue]);

    useEffect(() => {
        if (props.data) {
            setServiceAccountActive(props.data?.filter(item => item.eligibilityCriteria === "AGE_RANGE")[0].active)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "AGE_RANGE")[0].active = serviceAccountActive
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [serviceAccountActive]);

    useEffect(() => {
        if (props.data) {
            setServiceAccountRangeActive(props.data?.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].active)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].active = serviceAccountRangeActive
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [serviceAccountRangeActive]);

    useEffect(() => {
        if (props.data) {
            setServiceAccountListActive(props.data?.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_LIST")[0].active)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_LIST")[0].active = serviceAccountListActive
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [serviceAccountListActive]);

    useEffect(() => {
        if (props.serviceListData) {
            setServiceList(props.serviceListData)
        }
    }, [props.serviceListData]);

    const AddAccountIDChange = (event: any) => {
        const value = event.target.value;
        let newItem = selectedItem;
        newItem.accountId = value;
        setSelectedItem({ ...newItem });
    };

    const AddAccountNameChange = (event: any) => {
        const value = event.target.value;
        let newItem = selectedItem;
        newItem.accountName = value;
        setSelectedItem({ ...newItem });
    };

    const AddAccountStatusChange = (event: any) => {
        const value = event.target.checked;
        let newItem = selectedItem;
        newItem.active = value;
        setSelectedItem({ ...newItem });
    };

    function onSave() {
        if (selectedItem) {
            let data = selectedItem
            data.accountId = data.accountId as number
            addServiceList([data]).then((response) => {
                console.log("A test", response)
                if (response?.success) {
                    props.reloadData(0)
                    setSelectedItem(new EligibilityMatrixServiceAccountList(emptyServiceList))
                    setAddNewVisible(false)
                }
            })
        }
    }

    const onAccountIdChange = (event: any, id: string) => {
        const value = event.target.value;
        let data = serviceList
        if (data) {
            data.map((serviceData) => {
                if (serviceData.id === id) {
                    serviceData.accountId = value
                }
            })
            setServiceList([...data])
        }
    };

    const onAccountNameChange = (event: any, id: string) => {
        const value = event.target.value;
        let data = serviceList
        if (data) {
            data.map((serviceData) => {
                if (serviceData.id === id) {
                    serviceData.accountName = value
                }
            })
            setServiceList([...data])
        }
    };

    const onAccountStatusChange = (event: any, id: string) => {
        const value = event.target.checked;
        let data = serviceList
        if (data) {
            data.map((serviceData) => {
                if (serviceData.id === id) {
                    serviceData.active = value
                }
            })
            setServiceList([...data])
        }
    };

    const onDelete = (id: string) => {
        showConfMessage({
            head: "Are you sure?",
            body: "This action cannot be undone.",
            onConfirm: () => {
                removeEligibilityMatrix(id).then((data) => {
                    if (data?.success) {
                        props.reloadData(0);
                    } else {
                        showMessage({
                            head: "Failed!",
                            body: "Cannot delete eligibility matrix. Please try again later.",
                        });
                    }
                });
            },
        });
    };

    const switchValidation = () => {
        if (props.data?.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE")[0].active === true) {
            return <div className="col-auto">
                <Switch checked={serviceAccountListActive} disabled={true} onChange={() => { setServiceAccountListActive(!serviceAccountListActive) }} />
            </div>
        } else {
            return <div className="col-auto">
                <Switch checked={serviceAccountRangeActive} onChange={() => { setServiceAccountRangeActive(!serviceAccountRangeActive) }} />
            </div>
        }
        // else if (props.data?.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_LIST")[0].active === true) {
        //     return <div className="col-auto">
        //         <Switch checked={serviceAccountListActive} onChange={() => { setServiceAccountListActive(!serviceAccountListActive) }} />
        //     </div>
        // } else {
        //     return <div className="col-auto">
        //         <Switch checked={serviceAccountRangeActive} disabled={true} onChange={() => { setServiceAccountRangeActive(!serviceAccountRangeActive) }} />
        //     </div>
        // }
    }

    const serviceListSwitchValidation = () => {
        if (props.data?.filter(item => item.eligibilityCriteria === "SERVICE_ACCOUNT_LIST")[0].active === true) {
            return <div className="col-auto">
                <Switch checked={serviceAccountRangeActive} disabled={true} onChange={() => { setServiceAccountRangeActive(!serviceAccountRangeActive) }} />
            </div>
        } else {
            return <div className="col-auto">
                <Switch checked={serviceAccountListActive} onChange={() => { setServiceAccountListActive(!serviceAccountListActive) }} />
            </div>
        }
    }
    return (
        <div>
            <div className="row mt-5">
                <div className="col-auto">
                    <p style={{ fontWeight: "bold" }}>01</p>
                </div>
                <div className="col-auto">
                    <p style={{ fontWeight: "bold" }}>Age Range</p>
                </div>
                <div className="col-auto">
                    <Switch checked={serviceAccountActive} onChange={() => { setServiceAccountActive(!serviceAccountActive) }} />
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <label className="label-text">From</label>
                    <input type="text" className="form-control" id={ageRangeId} value={minValue} onChange={(e) => { setMinValue(Number(e.target.value)) }} name="subscriptionCode" ></input>
                </div>
                <div className="col-auto">
                    <label className="label-text">To</label>
                    <input type="text" className="form-control" id={ageRangeId} value={maxValue} onChange={(e) => { setMaxValue(Number(e.target.value)) }} name="subscriptionCode"></input>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mt-2">
                                <div className="col-auto">
                                    <p style={{ fontWeight: "bold" }}>02</p>
                                </div>
                                <div className="col-auto">
                                    <p style={{ fontWeight: "bold" }}>Service Account Range</p>
                                </div>
                                <div className="col-auto">
                                    <Switch checked={serviceAccountRangeActive} onChange={() => { setServiceAccountRangeActive(!serviceAccountRangeActive) }} />
                                </div>
                                {/* {switchValidation()} */}
                            </div>
                            <div className="row">
                                <div className="col-auto">
                                    <label className="label-text">From</label>
                                    <input type="text" className="form-control" value={serviceAccountMinValue} onChange={(e) => { setServiceAccountMinValue(Number(e.target.value)) }} name="subscriptionCode" ></input>
                                </div>
                                <div className="col-auto">
                                    <label className="label-text">To</label>
                                    <input type="text" className="form-control" value={serviceAccountMaxValue} onChange={(e) => { setServiceAccountMaxValue(Number(e.target.value)) }} name="subscriptionCode"></input>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-auto">
                                    <p style={{ fontWeight: "bold" }}>03</p>
                                </div>
                                <div className="col-auto">
                                    <p style={{ fontWeight: "bold" }}>Service Account List</p>
                                </div>
                                <div className="col-auto">
                                    <Switch checked={serviceAccountListActive} onChange={() => { setServiceAccountListActive(!serviceAccountListActive) }} />
                                </div>
                                {/* {serviceListSwitchValidation()} */}
                            </div>
                            <TableContainer component={Paper}>
                                <ModelLabel title={"Service Account List"} onClick={addNewAssetRow} />
                                <Table stickyHeader size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.greyLabel} align="center">Account ID</TableCell>
                                            <TableCell className={classes.greyLabel} align="center">Account Name</TableCell>
                                            <TableCell className={classes.greyLabel} align="center">
                                                Status
                                            </TableCell>
                                            <TableCell className={classes.greyLabel}></TableCell>
                                            <TableCell className={classes.greyLabel}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {serviceList.map((item, index) => (
                                            <TableRow className={clsx(tableStyle.oddRowColor)} hover >
                                                <TableCell align="center">
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <input type="text" className="form-control" value={item.accountId ? item.accountId : ""} onChange={(event) => onAccountIdChange(event, item.id)} name="subscriptionCode" ></input>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <input type="text" className="form-control" value={item.accountName} onChange={(event) => onAccountNameChange(event, item.id)} name="subscriptionCode" ></input>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <Switch defaultChecked={item.active} checked={item.active} onChange={(e) => onAccountStatusChange(e, item.id)} />
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <ModelBtn title={"Update"} onClick={props.updateServiceList} />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <ModelBtn title={"Delete"} onClick={() => onDelete(item.id)} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {addNewVisible &&
                                            <TableRow className={clsx(tableStyle.oddRowColor)} hover >
                                                <TableCell align="center">
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <input type="text" className="form-control" name="subscriptionCode" value={selectedItem.accountId ? selectedItem.accountId : ""} onChange={(e) => { AddAccountIDChange(e) }}></input>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <input type="text" className="form-control" name="subscriptionCode" value={selectedItem.accountName} onChange={(e) => { AddAccountNameChange(e) }}></input>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <Switch defaultChecked={selectedItem.active} onChange={(e) => AddAccountStatusChange(e)} />
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <ModelBtn title={"Create"} onClick={onSave} disabled={!selectedItem.accountId || !selectedItem.accountName} />
                                                </TableCell>
                                                <TableCell align="center">
                                                </TableCell>
                                            </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="row mt-4">
                                <div className="col-9">
                                    <AddItemBtn title="Save" onClick={props.onSubmit} />
                                </div>
                                <div className="col-3">
                                    <CancelItemBtn title="Cancel" onClick={() => { }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
