import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import CustomerBaseGrid from "./Customer_Base/customer_base_grid"
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
    getCustomerBase,
} from "../../services/api_service/api_service";
import CustomerBaseModal from "../../models/customerBase";
import AddCustomerBaseModal from "./Customer_Base/add_customer_modal_item";


export default function CustomerBase() {
    const [customerBase, setCustomerBase] = useState<CustomerBaseModal[]>([]);
    const [isShowAddModel, setShowAddModel] = useState(false);

    const addCustomerBase = () => {
        setShowAddModel(true)
    };

    const getCustomer = () => {
        getCustomerBase().then((data) => {
            if (data?.success) {
                const customerBase: CustomerBaseModal[] = [];
                data?.data.forEach((x: any) => customerBase.push(new CustomerBaseModal(x)));
                setCustomerBase(customerBase);
            }
        });
    };
    useEffect(() => {
        getCustomer();
    }, []);

    return (
        <CommonPage
            currentRoute={routeNameObjects.customerBase}
            parentRoutes={[routeNameObjects.customerBase]}
        >
            <AddItemBtn title="Add Customer Base" onClick={addCustomerBase} />
            <CustomerBaseGrid customerBase={customerBase} reloadData={getCustomer}></CustomerBaseGrid>
            <AddCustomerBaseModal
                reloadData={() => { }}
                isShow={isShowAddModel}
                setShow={setShowAddModel}
            />
        </CommonPage >
    );
}
