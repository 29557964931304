import UserCategory from "./user_category";
import PartnerCategory from "./partner_category";
import LangAndText from "./lang_and_text";
import PartnerMerchant from "./partner_merchant";

class PartnerPromotion {
  id: string;
  userType: UserCategory | null;
  category: PartnerCategory | null;
  merchant: PartnerMerchant | null;
  name: LangAndText[] = [];
  description: LangAndText[] = [];
  imageUrl: string;
  validityPeriod: number;
  createdDate: Date;
  featured: boolean;
  userTypeId: string = "";
  partnerCategoryId: string = "";
  merchantId: string = "";
  status: string;
  minAmount: number;
  promotionAmount: number;
  promotionPercentage: number;
  promotionSubcategory: string;
  promotionType: string;

  constructor(data: any) {
    this.id = data.id;
    this.userType = data.userType ? new UserCategory(data.userType) : null;
    this.category = data.category ? new PartnerCategory(data.category) : null;
    data.name.forEach((x: any) => this.name.push(new LangAndText(x)));
    data.description.forEach((x: any) =>
      this.description.push(new LangAndText(x))
    );
    this.imageUrl = data.imageUrl;
    this.validityPeriod = data.validityPeriod;
    this.createdDate = data.createdDate;
    this.featured = data.featured;
    this.merchant = data.merchant ? new PartnerMerchant(data.merchant) : null;
    this.status = data.status;
    this.minAmount = data.minAmount;
    this.promotionAmount = data.promotionAmount;
    this.promotionPercentage = data.promotionPercentage;
    this.promotionSubcategory = data.promotionSubcategory;
    this.promotionType = data.promotionType;
  }
}

export const emptyPartnerPromotion = {
  id: undefined,
  userType: undefined,
  category: undefined,
  merchant: undefined,
  name: [
    {
      language: "EN",
      text: undefined,
    },
  ],
  description: [
    {
      language: "EN",
      text: undefined,
    },
  ],
  imageUrl: undefined,
  validityPeriod: undefined,
  createdDate: undefined,
  featured: false,
};

export default PartnerPromotion;
