import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    row: {
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
      },
      right: {
        paddingRight: "10px",
        float: "right",
      },
});

export default useStyles;
