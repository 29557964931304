import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useStyles from "./wilaya_style";
import MyModal from "../../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelBtn from "../../../components/model_btn/model_btn";
import LangAndText from "../../../models/lang_and_text";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import {
  addNewWilaya,
} from "../../../services/api_service/api_service";
import Wilaya, { emptyWilaya } from "../../../models/wilaya";


interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function WilayaAddModel(props: Props) {
  const [selected, setSelected] = useState(new Wilaya(emptyWilaya));
  // const [cities, setCities] = useState<WilayaCity[]>([]);
  // const [countries, setCountries] = useState<WilayaCountry[]>([]);

  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  // const getAllCountries = () => {
  //   getWilayaCountries().then((data) => {
  //     if (data?.success) {
  //       const co: WilayaCountry[] = [];
  //       data.data.forEach((x: any) => co.push(new WilayaCountry(x)));
  //       setCountries(co);
  //     }
  //   });
  // };

  // const getAllCities = () => {
  //   getWilayaCities().then((data) => {
  //     if (data?.success) {
  //       const ct: WilayaCity[] = [];
  //       data.data.forEach((x: any) => ct.push(new WilayaCity(x)));
  //       setCities(ct);
  //     }
  //   });
  // };

  useEffect(() => {
    if (props.isShow) {
      setSelected(new Wilaya(emptyWilaya));
      // getAllCountries();
      // getAllCities();
    }
  }, [props.isShow]);

  const nameChange = (name: LangAndText[]) => {
    const prev = selected;
    if (prev) {
      prev.name = name;
      setSelected({ ...prev });
    }
  };

  // const countryChange = (countryId: string) => {
  //   const prev = selected;
  //   if (prev) {
  //     prev.countryId = countryId;
  //     setSelected({ ...prev });
  //   }
  // };

  // const cityChange = (cityId: string) => {
  //   const prev = selected;
  //   if (prev) {
  //     prev.cityId = cityId;
  //     setSelected({ ...prev });
  //   }
  // };

  // const setLat = (lat:any) => {
  //   const prev = selected;
  //   if (prev) {
  //     prev.point.x = lat;
  //     prev.point.lat = lat;
  //     setSelected({ ...prev });
  //   }
  // };

  // const setLon = (lon:any) => {
  //   const prev = selected;
  //   if (prev) {
  //     prev.point.y = lon;
  //     prev.point.long = lon;
  //     setSelected({ ...prev });
  //   }
  // };

  const saveModel = () => {
    if (selected.name.length === 0 || !selected.name[0].text) {
      showMessage({ head: "Failed!", body: "Please enter name and try again" });
      return;
    }

    // if (!selected.countryId) {
    //   showMessage({ head: "Failed!", body: "Please select country" });
    //   return;
    // }

    // if (!selected.cityId) {
    //   showMessage({ head: "Failed!", body: "Please select city" });
    //   return;
    // }


    // if (!selected.point.x) {
    //   showMessage({ head: "Failed!", body: "Please select location on map" });
    //   return;
    // }

    
    addNewWilaya(selected).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new wilaya.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Add New Wilaya"}
      setShow={props.setShow}
    >
      <div>
        <LangAndTextEditTabs
          data={selected.name}
          isEditable={true}
          label={"Name"}
          setData={nameChange}
        />

{/*         

        <ModelFieldWrapper>
          <ModelLabel title={"Other Fields"} noMargin />
          <div className={classes.row}>
            <TextField
              label="Country"
              value={selected.countryId}
              variant="outlined"
              className={classes.textField}
              style={{ width: "100%" }}
              onChange={(event) => countryChange(event.target.value)}
              select
            >
              {countries.map((val, index) => (
                <MenuItem key={index} value={val.id}>
                  {val.name[0]?.text}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className={classes.row}>
            <TextField
              label="City"
              value={selected.cityId}
              variant="outlined"
              className={classes.textField}
              style={{ width: "100%" }}
              onChange={(event) => cityChange(event.target.value)}
              select
            >
              {cities.map((val, index) => (
                <MenuItem key={index} value={val.id}>
                  {val.name[0]?.text}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </ModelFieldWrapper>

        <ModelFieldWrapper>
          <ModelLabel title={"Point"} noMargin />
          <WilayaAddMap lat={selected.point?.x} lon={selected.point?.y} setLat={setLat} setLon={setLon}/>
        </ModelFieldWrapper> */}

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"Save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
