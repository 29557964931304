import { Divider, Grid, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AccountData, { emptyAccountData } from "../../../../models/user_details/account_data";
import time from '../../../../services/time';
import dataCardStyles from './account_data_card_styles'

interface IProps {
    data: AccountData;
}
export default function AccountDataCard(props: IProps) {
    const dataCardStyle = dataCardStyles()
    const [state, setstate] = useState<AccountData>(emptyAccountData)

    useEffect(() => {
        setstate(props.data)
    }, [props.data])

    const handleValue = (value: any) => {

        let pattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/
        let datePattern = /\d{4}-[01]\d-[0-3]\dT.*[+]/
        if (pattern.test(value)) {
            return (<img src={value} alt="" width="60px" />)
        }
        else if (datePattern.test(value)) {
            return time.timeStringToDate(value)
        }
        else {
            return handleEmpty(value)
        }
    }

    const handleEmpty = (value: any) => {
        let output: any
        if (value === '' || value === null) {
            output = "-"
        }
        else if (typeof value === 'boolean') {
            if (value) {
                output = 'True'
            }
            else {
                output = 'False'
            }
        }
        else {
            output = value
        }
        return output

    }

    const formatKey = (value: string) => {
        let output: string
        if (!!value) {
            output = value.match(/([A-Z]?[^A-Z]*)/g)!.slice(0, -1).join(" ")
            output = output.replace('I D', 'ID')

            if (value === "service3GStatus") {
                output = "Service 3G Status"
            }

            if (value === "OnPeakAccountID") {
                output = "Total Credit"
            }

        }
        else {
            output = "unknown"
        }

        return output

    }

    return (
        <Paper className={dataCardStyle.container}>
            <div className={dataCardStyle.header}>
                {formatKey(state.account)}
            </div>

            <div className={dataCardStyle.amount}>
                {state.balance}
                {" "}
                {state.unit}
            </div>
            <Divider />

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{
                    marginTop: 10,
                }}
            >
                <Grid item>
                    Expires on
                </Grid>
                <Grid item>
                    {handleValue(state.expiryDate)}
                </Grid>
            </Grid>

        </Paper>
    )
}
