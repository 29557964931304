import React from "react";
import useStyles from "./detail_item_style";

export default function DetailItem(props: { title: string; value: any }) {
  const classes = useStyles();
  return (
    <div className={classes.outer}>
      <div className={classes.title}>{props.title}</div>
      <div className={classes.dots}>:</div>
      <div className={classes.value}>{props.value}</div>
    </div>
  );
}
