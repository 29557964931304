import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import cloneDeep from "lodash.clonedeep";
import { Switch } from "@material-ui/core";
import MyNavigator from "../../services/navigation";
import { render } from "@testing-library/react";
import RedeemCodeDetailsModel from "../../models/redeemCodeDetailsModel";
import moment from "moment";
import RedeemCodeDetailsLevelModel from "../../models/redeemCodeDetailsLevelModel";

interface Props {
    redeemCodeDetailsLevel: RedeemCodeDetailsLevelModel[];
    reloadData: Function;
}

export default function RedeemCodeDetailsLevelTable(props: Props) {
    const tableStyle = tableStyles();

    useEffect(() => {
        console.log("da", props.redeemCodeDetailsLevel)
    });
    return (
        <TableContainer component={Paper}>
            <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell className={tableStyle.header} align="center">Level</TableCell>
                        <TableCell className={tableStyle.header} align="center">Total Redeem Codes Count</TableCell>
                        <TableCell className={tableStyle.header} align="center">Total Used Code Count</TableCell>
                        <TableCell className={tableStyle.header} align="center">Total Not Used Code Count</TableCell>
                        <TableCell className={tableStyle.header} align="center">Bundle Mapping</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.redeemCodeDetailsLevel.map((item, index) => (
                        <TableRow className={clsx(tableStyle.oddRowColor)} hover>
                            <TableCell align="center">
                                {item.level}
                            </TableCell>
                            <TableCell align="center">
                                {item.totalRedeemCodesCount}
                            </TableCell>
                            <TableCell align="center">
                                {item.totalUsedCodeCount}
                            </TableCell>
                            <TableCell align="center">
                                {item.totalNotUsedCodeCount}
                            </TableCell>
                            <TableCell align="center">
                                {item.bundleMapping}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
