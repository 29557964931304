class LangAndText {
  language: string;
  text: string;
  constructor(data: any) {
    this.language = data.language;
    this.text = data.text;
  }
}

export const emptyLangAndText = {
  language:'EN',
  text:undefined
}
export default LangAndText;
