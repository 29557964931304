import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React, { useState } from "react";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import cloneDeep from "lodash.clonedeep";
import DecorationAsset from "../../models/decoration_asset";
import DecorationAssetUpdateModal from "./decoration_assets_update_model";

interface Props {
  assets: DecorationAsset[];
  reloadData: Function;
}

export default function DecorationAssetsTable(props: Props) {
  const tableStyle = tableStyles();
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DecorationAsset>();

  const onUpdate = (item: DecorationAsset) => {
    setSelectedItem(cloneDeep(item));
    setShowUpdateModel(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {/* <TableCell className={tableStyle.header}>Asset</TableCell> */}

            <TableCell className={tableStyle.header}>Name</TableCell>

            <TableCell className={tableStyle.header}>Type</TableCell>
            <TableCell className={tableStyle.header}>Enabled</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.assets.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell className={tableStyle.tableData}>
                {item.name}
              </TableCell>

              <TableCell className={tableStyle.tableData}>
                {item.type}
              </TableCell>
              <TableCell
                className={clsx(tableStyle.tableData, tableStyle.colored)}
              >
                {item.enabled.toString().toLowerCase()}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"Update"} onClick={() => onUpdate(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <DecorationAssetUpdateModal
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selected={selectedItem}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
