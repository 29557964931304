import React from "react";
import { Modal, Fade, Backdrop, Button } from "@material-ui/core";
import useStyles from "./my_modal_style";
import { Close } from "@material-ui/icons";

interface Props {
  isShow: boolean;
  children: any;
  onClose: any;
  title: string;
  setShow: Function;
}
export default function MyModal(props: Props) {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isShow}
      closeAfterTransition
      onClose={props.onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isShow}>
        <div className={classes.paper}>
          <div className={classes.header}>
            {props.title && (
              <div className={classes.headerName}>{props.title}</div>
            )}
            <Button
              onClick={() => props.setShow(false)}
              className={classes.closeBtn}
            >
              <Close />
            </Button>
          </div>
          <div className={classes.scrollOuter}>
            <div className={classes.padding}>{props.children}</div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
