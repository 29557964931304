import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import cloneDeep from "lodash.clonedeep";
import { Switch } from "@material-ui/core";
import MyNavigator from "../../services/navigation";
import { render } from "@testing-library/react";
import RedeemCodeDetailsModel from "../../models/redeemCodeDetailsModel";
import moment from "moment";

interface Props {
    redeemCodeDetails: RedeemCodeDetailsModel | undefined;
    reloadData: Function;
}

export default function PubgStaticTable(props: Props) {
    const tableStyle = tableStyles();

    useEffect(() => {
        console.log("da", props.redeemCodeDetails?.usedCodeCount)
    });
    return (
        <TableContainer component={Paper}>
            <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell className={tableStyle.header} align="center">Total Redeem Codes Count</TableCell>
                        <TableCell className={tableStyle.header} align="center">Used Code Count</TableCell>
                        <TableCell className={tableStyle.header} align="center">Not Used Code Count</TableCell>
                        <TableCell className={tableStyle.header} align="center">Last Updated Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={clsx(tableStyle.oddRowColor)} hover>
                        <TableCell align="center">
                            {props.redeemCodeDetails?.totalRedeemCodesCount}
                        </TableCell>
                        <TableCell align="center">
                            {props.redeemCodeDetails?.usedCodeCount}
                        </TableCell>
                        <TableCell align="center">
                            {props.redeemCodeDetails?.notUsedCodeCount}
                        </TableCell>
                        <TableCell align="center">
                            {moment.utc(props.redeemCodeDetails?.lastUpdatedDate).local().format("DD-MM-YYYY HH:mm:ss")}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
