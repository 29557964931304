import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import MyNavigator from "../../services/navigation";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import PageTab from "../../components/page_tab/page_tab";
import UploadItemBtn from "../questions/upload_btn";
import PubgStaticTable from "./pubgStaticTable"
import PubGFileUploadModel from "./pubgFileUploadModel";
import RedeemCodeDetailsModel from "../../models/redeemCodeDetailsModel";
import { getRedeemCodeDetails, getRedeemCodeLevel } from "../../services/api_service/api_service";
import RedeemCodeDetailsLevelTable from "./redeemcodedetailsLevelTable";
import RedeemCodeDetailsLevelModel from "../../models/redeemCodeDetailsLevelModel";

export default function PubgFileUpload() {
    const [isShowAddModel, setShowAddModel] = useState(false);
    const [redeemCodeDetailsList, setredeemCodeDetails] = useState<RedeemCodeDetailsModel | undefined>();
    const [redeemCodeDetailsLevel, setRedeemCodeDetailsLevel] = useState<RedeemCodeDetailsLevelModel[]>([]);

    const uploadFile = () => {
        setShowAddModel(true)
    };

    const getRedeemCodeDetailsList = () => {
        getRedeemCodeDetails().then((data) => {
            if (data?.success) {
                const d = data.data
                setredeemCodeDetails(d)
            }
        });
    };

    const getRedeemCodeLevelList = () => {
        getRedeemCodeLevel().then((data) => {
            if (data?.success) {
                const d: RedeemCodeDetailsLevelModel[] = [];
                data.data.forEach((x: any) => d.push(new RedeemCodeDetailsLevelModel(x)));
                setRedeemCodeDetailsLevel(d);
            }
        });
    };

    useEffect(() => {
        getRedeemCodeDetailsList();
    }, []);

    useEffect(() => {
        getRedeemCodeLevelList();
    }, []);

    return (
        <CommonPage
            currentRoute={routeNameObjects.pubG}
            parentRoutes={[routeNameObjects.pubG]}
        >
            <UploadItemBtn title="Upload File" onClick={uploadFile} />
            <div>
                <PubgStaticTable reloadData={getRedeemCodeDetails} redeemCodeDetails={redeemCodeDetailsList} />
            </div>
            <div className="mt-4">
                <RedeemCodeDetailsLevelTable reloadData={getRedeemCodeLevel} redeemCodeDetailsLevel={redeemCodeDetailsLevel}></RedeemCodeDetailsLevelTable>
            </div>
            <PubGFileUploadModel
                isShow={isShowAddModel}
                setShow={setShowAddModel}
            />
        </CommonPage>
    );
}
