import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React, { useState } from "react";
import tableStyles from "../../common_classes/table";
import Offer from "../../models/offer";
import OffersModel from "./offers_model";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import { deleteOffer, updateOfferStatus } from "../../services/api_service/api_service";
import OffersUpdateModel from "./offers_update_model";
import cloneDeep from 'lodash.clonedeep';

interface Props {
  offers: Offer[];
  reloadData: Function;
}

export default function OffersTable(props: Props) {
  const tableStyle = tableStyles();
  const [showModel, setShowModel] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Offer>();

  const showConfMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showConfirmMessage
  );

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const onView = (item: Offer) => {
    setSelectedItem(item);
    setShowModel(true);
  };

  const onUpdate = (item: Offer) => {
    setSelectedItem(cloneDeep(item));
    setShowUpdateModel(true);
  };

  const onDeleteOffer = (id: string) => {
    deleteOffer(id).then(data => {
      props.reloadData()
      if (data?.success) {
        showMessage({
          head: "Success!",
          body: "Successfully deleted the offer.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    })
  }

  // const onDelete = (item: Offer) => {
  //   showConfMessage({
  //     head: "Are you sure?",
  //     body: "This action cannot be undone!",
  //     onConfirm: () => {
  //       onDeleteOffer(item.id)
  //     },
  //   });
  // };

  const updateStatus = (id: string, status: string) => {
    let newStatus: string = status === "ACTIVE" ? "INVALID" : "ACTIVE"
    updateOfferStatus(id, newStatus).then((data) => {
      if (data?.success) {
        props.reloadData();
      } else {
        showMessage({
          head: "Failed!",
          body: "Cannot update status. Please try again later.",
        });
      }
    });
  }
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Media</TableCell>

            <TableCell className={tableStyle.header}>Header</TableCell>

            <TableCell className={tableStyle.header}>Description</TableCell>
            <TableCell className={tableStyle.header}>Status</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.offers.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell>
                {item.mediaType === "IMAGE" ? (
                  item.mediaUrl ? (
                    <img
                      height="50px"
                      width={"50px"}
                      src={item.mediaUrl}
                      alt="offers media"
                    />
                  ) : (
                    <div></div>
                  )
                ) : item.mediaType === "VIDEO" ? (
                  item.mediaUrl ? (
                    <video
                      height="50px"
                      width={"50px"}
                      src={item.mediaUrl}
                      autoPlay
                      loop
                      muted
                    />
                  ) : (
                    <div></div>
                  )
                ) : (
                  <div />
                )}
              </TableCell>

              <TableCell className={tableStyle.tableData}>
                {item.content.length > 0 ? item.content[0].header : ""}
              </TableCell>

              <TableCell className={tableStyle.tableData}>
                {item.content.length > 0 ? item.content[0].description : ""}
              </TableCell>
              <TableCell
                className={clsx(tableStyle.tableData, tableStyle.colored)}
                onClick={() => { updateStatus(item.id, item.status) }}
              >
                {item.status.toLowerCase()}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                <ModelBtn title={"Update"} onClick={() => onUpdate(item)} />
                {/* <ModelBtn title={"Delete"} onClick={() => onDelete(item)} /> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <OffersModel
        isShow={showModel}
        setShow={setShowModel}
        selectedOffer={selectedItem}
      />

      <OffersUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selectedOffer={selectedItem}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
