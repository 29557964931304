import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  paper: {
    padding: '2px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
});

export default useStyles;
