import { Box, Card, TextField } from "@material-ui/core";
import { Actions, useStoreActions } from "easy-peasy";
import React, { useState } from "react";
import ModelBtn from "../../../components/model_btn/model_btn";
import VoucherDetail from "../../../models/voucher_detail";
import {
  generateQRCode,
  getVoucherDetails,
} from "../../../services/api_service/api_service";
import { StoreModel } from "../../../store models/model";
import useStyles from "./voucher_details_styles";
import Countdown from "react-countdown";
import FadeIn from "react-fade-in";
import QRCodeView from "../../../components/qr_code/qr_code";

export default function VoucherSearchPage() {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [voucherId, setVoucherId] = useState("");

  const [voucher, setVoucher] = useState<VoucherDetail>();
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const search = () => {
    if (!phoneNumber) {
      showMessage({ head: "Failed!", body: "Please enter phone number" });
      return;
    }
    if (!voucherId) {
      showMessage({ head: "Failed!", body: "Please enter Voucher Id" });
      return;
    }
    getVoucherDetails(phoneNumber, voucherId).then((data) => {
      if (data?.success) {
        setVoucher(new VoucherDetail(data.data));
      } else {
        setVoucher(undefined);
      }
    });
  };
  return (
    <div>
      <Box height={'50px'}/>
      <div className={classes.searchRow}>
        <TextField
          label="Phone Number"
          variant="outlined"
          className={classes.textField}
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
        <TextField
          label="Voucher Id"
          variant="outlined"
          className={classes.textField}
          value={voucherId}
          onChange={(event) => setVoucherId(event.target.value)}
        />
        <div className={classes.btnWrapper}>
          <ModelBtn title={"Search"} onClick={search} />
        </div>
      </div>
      <Box height={'50px'}/>
      <div>
        {voucher ? (
          <VoucherCard voucher={voucher} setVoucher={setVoucher}/>
        ) : (
          <div className={classes.placeHolder}>Please search for voucher</div>
        )}
      </div>
    </div>
  );
}

function VoucherCard(props: { voucher: VoucherDetail, setVoucher:Function }) {
  const [hover, setHover] = useState(false);
  const classes = useStyles();

  const generateQr = () => {
    generateQRCode(props.voucher.user.msisdn, props.voucher.voucherId).then(
      (data) => {
        if (data?.success) {
          props.setVoucher(new VoucherDetail(data.data))
        }
      }
    );
  };

  const renderer = (data: any) => {
    const completed: boolean = data.completed;
    if (completed) {
      return <span></span>;
    }
    const days = data.days;
    const hours = data.hours;
    const minutes = data.minutes;
    const seconds = data.seconds;

    return (
      <span className={classes.expiresIn}>{`Expires in : ${
        days ? `${days} days ` : ""
      } ${hours ? `${hours} hours ` : ""} ${
        minutes ? `${minutes} minutes ` : ""
      } ${seconds ? `${seconds} seconds ` : ""}`}</span>
    );
  };
  return (
    <FadeIn>
      <Card
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        elevation={hover ? 10 : 3}
        className={classes.card}
      >
        <div className={classes.cardNameColumn}>
          <div className={classes.cardMsidn}>{props.voucher.user?.msisdn}</div>
          <Countdown
            renderer={renderer}
            date={props.voucher.expireTimesStamp}
          />
        </div>
        {props.voucher.qrCode ? (
          <QRCodeView code={props.voucher.qrCode} />
        ) : (
          <ModelBtn
            title={"Generate QR"}
            onClick={generateQr}
            width={"150px"}
          />
        )}
      </Card>
    </FadeIn>
  );
}
