import React from "react";
import clsx from "clsx";
import WilayaCity from "../../../models/wilaya_city";
import useStyles from './wilaya_city_style';
import MyModal from "../../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelBtn from "../../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  selected: WilayaCity | undefined;
}
export default function WilayaCityModel(props: Props) {

 

  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };


  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Wilaya City"}
      setShow={props.setShow}
    >
      <div>
  
      
          <LangAndTextEditTabs
            data={props.selected?.name}
            isEditable={false}
            label={"Name"}
            setData={null}
          />
      

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
