import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React, { useState } from "react";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import Theme from "../../models/theme";
import useStyles from "./theme_style";
import ThemeModel from "./theme_model";
import ThemeUpdateModel from "./theme_update_model";
import cloneDeep from "lodash.clonedeep";

interface Props {
  themes: Theme[];
  reloadData: Function;
}

export default function ThemesTable(props: Props) {
  const classes = useStyles();
  const tableStyle = tableStyles();
  const [showModel, setShowModel] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Theme>();
  const [selectedItemForUpdate, setSelectedItemForUpdate] = useState<Theme>();

  const onView = (item: Theme) => {
    setSelectedItem(item);
    setShowModel(true);
  };

  const onUpdate = (item: Theme) => {
    setSelectedItemForUpdate(cloneDeep(item));
    setShowUpdateModel(true);
  };
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Name</TableCell>

            <TableCell className={tableStyle.header}>Theme Colour</TableCell>

            <TableCell className={tableStyle.header}>Dark Colour</TableCell>
            <TableCell
              width={"220px"}
              className={tableStyle.header}
              align="center"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.themes.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell className={tableStyle.tableData}>
                {item.themeName.length > 0 ? item.themeName[0]?.text : ""}
              </TableCell>

              <TableCell className={tableStyle.tableData}>
                <div
                  className={classes.themeColor}
                  style={{
                    backgroundColor: item.theme?.themeColor,
                  }}
                />
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                <div
                  className={classes.themeColor}
                  style={{
                    backgroundColor: item.theme?.themeDarkColor,
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                <ModelBtn title={"Update"} onClick={() => onUpdate(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ThemeModel
        isShow={showModel}
        setShow={setShowModel}
        selectedTheme={selectedItem}
      />

      <ThemeUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selectedTheme={selectedItemForUpdate}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
