import { Action, action } from 'easy-peasy';
import KeyCloakUser from '../../models/key_cloak_user';

export interface KeyCloakUserModel {
  authUser: KeyCloakUser | undefined;
  setAuthUser: Action<KeyCloakUserModel,KeyCloakUser>;
}

const keyCloakUserModel: KeyCloakUserModel = {
  authUser: undefined,

  setAuthUser: action((state, payload) => {
    state.authUser = payload;
  }),
 
};

export default keyCloakUserModel;
