import KeyCloakUser from "../../models/key_cloak_user";
import { analyzerRoutNames, bloodDonationRoutNames, customerCareRoutNames, merchantRouteNames, routeNames, quizRouteNames, eligibilityMatrix, pubG, notification } from "./route_names";

const routeUsers = {
  ibizaAdmin: {
    routes: routeNames,
    firstRoute: "/avatar/items",
  },
  ibizaMerchant: {
    routes: merchantRouteNames,
    firstRoute: "/promotions",
  },
  ibizaBDAdmin: {
    routes: bloodDonationRoutNames,
    firstRoute: "/bloodDonation",
  },
  ibizaBDCustomerCare: {
    routes: customerCareRoutNames,
    firstRoute: "/customerService",
  },
  ibizaBDAnalyzer: {
    routes: analyzerRoutNames,
    firstRoute: "/summaryStats",
  },
  ibizaQuizHandler: {
    routes: quizRouteNames,
    firstRoute: "/questionPage"
  },
  ibizaEligibilityHandler: {
    routes: eligibilityMatrix,
    firstRoute: "/eligibility-matrix"
  },
  ibizaPubGHandler: {
    routes: pubG,
    firstRoute: "/pubg"
  },
  ibizaNotificationHandler: {
    routes: notification,
    firstRoute: "/notification"
  }
};

export const getRouteUser = (authUser: KeyCloakUser | undefined) => {
  if (authUser?.isMerchant) {
    return routeUsers.ibizaMerchant;
  }
  else if (authUser?.isBloodDonationAdmin) {
    return routeUsers.ibizaBDAdmin;
  }
  else if (authUser?.isCustomerCare) {
    return routeUsers.ibizaBDCustomerCare;
  }
  else if (authUser?.isAnalyzer) {
    return routeUsers.ibizaBDAnalyzer;
  }
  else if (authUser?.isQuizHandler) {
    return routeUsers.ibizaQuizHandler;
  }
  else if (authUser?.isEligibilityHandler) {
    return routeUsers.ibizaEligibilityHandler;
  } 
  else if (authUser?.isPUBGHandler) {
    return routeUsers.ibizaPubGHandler;
  }
  else if (authUser?.isSegmentationNotification) {
    return routeUsers.ibizaNotificationHandler;
  }
  else {
    return routeUsers.ibizaAdmin;
  }
};

export default routeUsers;
