import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../services/colors";


const tableStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: myColors.mainColor,
      color: myColors.appBarTextColor,
      fontSize: "13px",
      textTransform: "capitalize",
      fontWeight: 'bold',
      height: '50px',
      cursor: 'pointer'
    },
    headerQuizSessionGift: {
      backgroundColor: myColors.mainColor,
      color: myColors.appBarTextColor,
      fontSize: "13px",
      textTransform: "capitalize",
      fontWeight: 'bold',
      height: '50px',
      cursor: 'pointer',
      textAlign: 'center'
    },
    tableData: {
      color: myColors.appBarColor,
      fontSize: '13px',
      textTransform: "capitalize",
      cursor: 'pointer'
    },
    tableDataRemoveCaps: {
      color: myColors.appBarColor,
      fontSize: '13px',
      cursor: 'pointer'
    },
    colored: {
      color: `${myColors.drawerSideBarColor} !important`,
      fontWeight: 'bold'
    },
    redColored: {
      color: `#d3190c !important`,
      fontWeight: 'bold'
    },
    oddRowColor: {
      backgroundColor: myColors.oddRowColor
    }
  })
);

export default tableStyles;
