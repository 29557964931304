import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../../../services/colors";


const dataCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 'auto',
      width: "100%",
      marginTop: 15,
    },
    header: {
      backgroundColor: myColors.mainColor,
      color: myColors.appBarTextColor,
      fontSize: "13px",
      textTransform: "capitalize",
      fontWeight: 'bold',
      height: '30px',
    },
    content: {
      padding: 0,
    },
    oddRowColor: {
      backgroundColor: myColors.oddRowColor
    },
    tableData: {
      color: myColors.appBarColor,
      fontSize: '13px',
      textTransform: "capitalize",
      cursor: 'pointer'
    },
    rowTitle: {
      color: myColors.appBarColor,
      fontSize: '13px',
      textTransform: "capitalize",
      cursor: 'pointer',
      fontWeight: 'bold'
    },
    emptyTable: {
      color: myColors.appBarColor,
      fontSize: '13px',
      textTransform: "capitalize",
      textAlign: "center"
    },
  })
);

export default dataCardStyles;
