import React, { useEffect, useState } from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import { Card, Tab, Tabs } from "@material-ui/core";
import useStyles from "../createQuestions/create_question_style";
import Content from "../../../models/content";
import PageTab from "../../../components/page_tab/page_tab";
import QuizPoolModel, { emptyQuestionPool } from "../../../models/quizPool";
import { TextField, Switch } from "@material-ui/core";
import { updateQuizPool } from "../../../services/api_service/api_service";
interface Props {
    language: string;
    data: QuizPoolModel | null
    onChange: Function
}

export default function CreateQuestionOthers(props: Props) {
    const classes = useStyles();
    const [correctAnswer, setCorrectAnswer] = useState(1)
    const [level, setLevel] = useState("EASY")
    const [status, setStatus] = useState("")
    useEffect(() => {
        if (props.data) {
            setCorrectAnswer(props.data?.correctAnswer)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.correctAnswer = correctAnswer
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [correctAnswer]);

    useEffect(() => {
        if (props.data) {
            setLevel(props.data?.level)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.level = level
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [level]);

    useEffect(() => {
        if (props.data) {
            setCorrectAnswer(props.data?.correctAnswer)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.correctAnswer = correctAnswer
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [correctAnswer]);

    useEffect(() => {
        if (props.data) {
            setLevel(props.data?.level)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.level = level
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [level]);

    useEffect(() => {
        if (props.data) {
            setStatus(props.data?.status)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.status = status
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [status]);


    const isActiveToggle = () => {
        var off = props.data;
        if (off) {
          off.status = off.status === "ACTIVE" ? "INACTIVE" :  "ACTIVE"
          console.log("switch", off)
          props.onChange({...off});
        }
      };

    return (
        <div>
        <div className="row mt-3">
                <div className="col-md-3">
                    <label>Correct Answer</label>
                </div>
                <div className="col-md-6">
                    <select defaultValue={1} value={correctAnswer} onChange={(e) => { setCorrectAnswer(Number(e.target.value)) }} className="form-select mb-4" aria-label="Default select example">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                    </select>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Level</label>
                </div>
                <div className="col-md-6">
                    <select value={level} onChange={(e) => { setLevel(e.target.value) }} className="form-select mb-4" aria-label="Default select example">
                        <option selected>EASY</option>
                        <option>MEDIUM</option>
                        <option>HARD</option>
                        <option>VERYHARD</option>
                    </select>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Status</label>
                </div>
                <div className="col-md-6">
                    <Switch defaultChecked={props.data?.status === "ACTIVE" ? true : false} checked={props.data?.status === "ACTIVE" ? true : false} onClick={isActiveToggle}/>
                </div>
            </div>
        </div>
    );
}
