import LangAndText, { emptyLangAndText } from "./lang_and_text";
import NetworkValidity from "./network_validity";

class NetworkPlans {
  id: string;
  price: number;
  name: LangAndText[] = [];
  planName: LangAndText[] = [];
  subHeading: LangAndText[] = [];
  planId: string;
  imageUrl: string;
  description: LangAndText[] = [];
  validity: NetworkValidity;

  constructor(data: any) {
    this.id = data.id;
    data.name?.forEach((x: any) => this.name.push(new LangAndText(x)))
    
    data.subHeading.forEach((x: any) =>
      this.subHeading.push(new LangAndText(x))
    );
    this.planId = data.planId;
    this.price = data.price;
    this.imageUrl = data.imageUrl;
    data.description.forEach((x: any) =>
      this.description.push(new LangAndText(x))
    );
    this.validity = new NetworkValidity(data.validity);
  }
}

export const emptyNetworkPlans = {
  id: undefined,
  name: [emptyLangAndText],
  planName: undefined,
  subHeading: [emptyLangAndText],
  planId: undefined,
  price: undefined,
  imageUrl: undefined,
  description: [emptyLangAndText],
  validity: [
    {
      unit: "HOURS",
      count: 0,
    },
  ],
};
export default NetworkPlans;
