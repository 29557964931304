import React, { useEffect, useState } from "react";
import useStyles from "./question_style";
import MyModal from "../../components/my_modal/my_modal";
import UploadQuestionModalBtn from "./upload_question_model_button";
import { Button } from "@material-ui/core";
import { uploadQuizCsvCall } from "../../services/api_service/api_service";
import { StoreModel } from "../../store models/model";
import { Actions, useStoreActions } from "easy-peasy";

interface Props {
    isShow: boolean;
    setShow: Function;
    reloadData: Function;
}
export default function QuestionUploadModel(props: Props) {
    const classes = useStyles();
    // const [selectedFile, setSelectedFile] = useState();

    const closeModal = () => {
        props.setShow(false);
    };

    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    let selectedFile = useState("")
    const handleInputChange = (event: any) => {
        if (event.target.files.length > 0) {
            selectedFile = event.target.files[0];
        }
    }

    const csvUpload = () => {
        console.log("dd", selectedFile)

        uploadQuizCsvCall(selectedFile).then((data) => {
            console.log("d", data)
            // if (data?.success) {
            // showMessage({ head: 'Success', body: 'You have successfully added CSV file' })
            // }
        });
    };

    const downloadFile = () => {
        window.location.href = "E://sampleCsv"
    }

    return (
        <MyModal
            isShow={props.isShow}
            onClose={null}
            title={"Upload Questions"}
            setShow={props.setShow}
        >

            <UploadQuestionModalBtn title="Upload Questions" onClick={csvUpload} />
            <div className={classes.text}>
                <a href="" onClick={downloadFile}>Download sample file</a>
            </div>
            <Button variant="contained" component="label" className={classes.fileUpload}>
                <input type="file" onChange={handleInputChange} />
            </Button>

        </MyModal>
    );
}
