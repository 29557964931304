import React, { useEffect, useState } from "react";
import { TextField, Switch } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import useStyles from "./create_question_style";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import QuizPoolModel, { emptyQuestionPool } from "../../../models/quizPool";
import CreateQuestionOthers from "./create_other_component";
import {
    addQuizPool,
} from "../../../services/api_service/api_service";

interface Props {
    language: string;
    data: QuizPoolModel;
    onChange: Function
}


export default function QuestionTextField(props: Props) {
    const [isShowAddModel, setShowAddModel] = useState(false);
    const classes = useStyles();
    // const [addQuiz, setAddQuiz] = useState<QuizPoolModel>(emptyQuestionPool);
    const [question, setQuestion] = useState("")
    const [answer1, setAnswer1] = useState("")
    const [answer2, setAnswer2] = useState("")
    const [answer3, setAnswer3] = useState("")
    const [answer4, setAnswer4] = useState("")
    const [correctAnswer, setCorrectAnswer] = useState(1)
    const [level, setLevel] = useState("EASY")
    const [status, setStatus] = useState("")

    useEffect(() => {
        if (props.data) {
            setQuestion(props.data?.question.filter(item => item.language === props.language)[0].message)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.question.filter(item => item.language === props.language)[0].message = question
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [question]);

    useEffect(() => {
        if (props.data) {
            let x = props.data?.answers.filter(item => item.id === 1)[0].answer
            let y = x.filter(lan => lan.language === props.language)[0].message
            setAnswer1(y)
            console.log(y)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                let x = value.answers.filter(item => item.id === 1)[0].answer
                let y = x.filter(lan => lan.language === props.language)[0].message = answer1
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [answer1]);

    useEffect(() => {
        if (props.data) {
            let x = props.data?.answers.filter(item => item.id === 2)[0].answer
            let y = x.filter(lan => lan.language === props.language)[0].message
            setAnswer2(y)
            console.log(y)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                let x = value.answers.filter(item => item.id === 2)[0].answer
                let y = x.filter(lan => lan.language === props.language)[0].message = answer2
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [answer2]);

    useEffect(() => {
        if (props.data) {
            let x = props.data?.answers.filter(item => item.id === 3)[0].answer
            let y = x.filter(lan => lan.language === props.language)[0].message
            setAnswer3(y)
            console.log(y)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                let x = value.answers.filter(item => item.id === 3)[0].answer
                let y = x.filter(lan => lan.language === props.language)[0].message = answer3
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [answer3]);

    useEffect(() => {
        if (props.data) {
            let x = props.data?.answers.filter(item => item.id === 4)[0].answer
            let y = x.filter(lan => lan.language === props.language)[0].message
            setAnswer4(y)
            console.log(y)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                let x = value.answers.filter(item => item.id === 4)[0].answer
                let y = x.filter(lan => lan.language === props.language)[0].message = answer4
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [answer4]);

    useEffect(() => {
        if (props.data) {
            setCorrectAnswer(props.data?.correctAnswer)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.correctAnswer = correctAnswer
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [correctAnswer]);

    useEffect(() => {
        if (props.data) {
            setLevel(props.data?.level)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.level = level
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [level]);

    useEffect(() => {
        if (props.data) {
            setStatus(props.data?.status)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.data) {
            let value = props.data
            if (value) {
                value.status = status
                console.log("val", value)
                props.onChange(value)
            }
        }
    }, [status]);

 

    

    return (
        <div>
            <div className="row">
                <div className="col-md-3">
                    <label>Question</label>
                </div>
                <div className="col-md-6">
                    <input type="text" name="quizMessage" onChange={(event) => setQuestion(event.target.value)} className="form-control" placeholder="Question"></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <label>Answer</label>
                </div>
                <div className="col-auto">
                    <p>1</p>
                    <p className="mt-4">2</p>
                    <p className="mt-4">3</p>
                    <p className="mt-4">4</p>
                </div>
                <div className="col-md-6">
                    <input type="text" value={answer1} onChange={(e) => { setAnswer1(e.target.value) }} className="form-control"></input>
                    <input type="text" value={answer2} onChange={(e) => { setAnswer2(e.target.value) }} className="form-control mt-2"></input>
                    <input type="text" value={answer3} onChange={(e) => { setAnswer3(e.target.value) }} className="form-control mt-2"></input>
                    <input type="text" value={answer4} onChange={(e) => { setAnswer4(e.target.value) }} className="form-control mt-2"></input>
                </div>
            </div>
        </div>
    );
}
