import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs, Switch } from "@material-ui/core";
import PageTab from "../../components/page_tab/page_tab";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import { useStoreActions, Actions } from "easy-peasy";
import useStyles from "../questions/createQuestions/create_question_style";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import NotificationText from "./notification_text_field"
import NumberListUploadModel from "./number_list_upload_model"
import NotificationModel, { emptyNotificationModel } from "../../models/notification";
import CustomerBaseModel, { emptyCustomerBase } from "../../models/customerBase";
import Notification from "./notification";
import {
    sendNotification, getExistingTemplates
} from "../../services/api_service/api_service";
import { StoreModel } from "../../store models/model";
import UserBase from "./user_base"
import SendNotification from "./save_notifications"
import axios from "axios";

interface Props {
    onSubmit: Function
}
export default function ParentNotification(props: Props) {
    const classes = useStyles();
    const [isShowAddModel, setShowAddModel] = useState(false);
    const [selected, setSelected] = useState<NotificationModel>(emptyNotificationModel);
    const [selectedCustomerBase, setSelectedCustomerBase] = useState<CustomerBaseModel[]>([])
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState()
    const [listName, setListName] = useState("")
    const [fileUrl, setFileUrl] = useState("")
    const [existingCustomerBase, setExistingCustomerBase] = useState<CustomerBaseModel>(emptyCustomerBase)

    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    useEffect(() => {
        setSelected(emptyNotificationModel)
        setExistingCustomerBase(emptyCustomerBase)
    }, []);

    const handleCallback = (child: any) => {
        setFile(child)
        setFileName(child.name)
    }

    const handleJsonCallback = (childListName: any) => {
        setListName(childListName)
    }

    const handleGetFileURL = (childFileURL: any) => {
        setFileUrl(childFileURL)
    }

    const getMsisdnList = () => {
        getExistingTemplates().then((data) => {
            if (data?.success) {
                const exstingCustomerBase: CustomerBaseModel[] = [];
                data?.data.forEach((x: any) => exstingCustomerBase.push(new CustomerBaseModel(x)));
                setSelectedCustomerBase(exstingCustomerBase);
            }
        });
    };
    useEffect(() => {
        getMsisdnList();
    }, []);

    const onSave = async () => {
        if (!selected.body.filter(item => item.language === "EN")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter English content and try again",
                color: "red",
            });
            return;
        }
        if (!selected.body.filter(item => item.language === "AR")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic content and try again",
                color: "red",
            });
            return;
        }
        if (!selected.body.filter(item => item.language === "FR")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter French content and try again",
                color: "red",
            });
            return;
        }

        if (!selected.title.filter(item => item.language === "EN")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter English content and try again",
                color: "red",
            });
            return;
        }
        if (!selected.title.filter(item => item.language === "AR")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic content and try again",
                color: "red",
            });
            return;
        }
        if (!selected.title.filter(item => item.language === "FR")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter French content and try again",
                color: "red",
            });
            return;
        }

        if (selected) {
            if (file === undefined) {
                let blob = await fetch(fileUrl).then(r => r.blob());
                const _file: any = new File([blob], `${listName}.xlsx`, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                setSelectedFile(_file)
                console.log("_file", _file)
                if (_file.name === "undefined.xlsx") {
                    showMessage({
                        head: "Failed!",
                        body: "Please Upload a File OR Select a Customer Base",
                        color: "red",
                    });
                    return;
                }
                sendNotification(selected, _file).then((data) => {
                    if (data?.success) {
                        showMessage({ head: 'Success', body: 'Notification send successfully' })
                        window.location.reload()
                    } else {
                        showMessage({ head: 'Failed', body: 'Please Select Existing File' })
                    }
                })
            } else {
                if (file === undefined) {
                    showMessage({
                        head: "Failed!",
                        body: "Please Upload a File OR Select a Customer Base",
                        color: "red",
                    });
                    return;
                }
                sendNotification(selected, file).then((data) => {
                    if (data?.success) {
                        showMessage({ head: 'Success', body: 'Notification send successfully' })
                        window.location.reload()
                    } else {
                        showMessage({ head: 'Failed', body: 'Please Upload File' })
                    }
                })
            }

        }
    }

    return (
        <CommonPage
            currentRoute={routeNameObjects.notification}
            parentRoutes={[routeNameObjects.notification]}
        >
            <PageTab
                nextButtonActive={true}
                tabsData={[
                    {
                        title: "Notification",
                        component: <Notification callBackSelectedItem={setSelected}></Notification>
                    },
                    {
                        title: "User Base",
                        component: <UserBase parentCallBackFileURL={handleGetFileURL} existingCustomerBase={existingCustomerBase} onChange={setExistingCustomerBase} parentCallback={handleCallback} parentCallbackListName={handleJsonCallback} msisdnList={selectedCustomerBase} reloadData={getMsisdnList}></UserBase>
                    },
                    {
                        title: "Send Notification",
                        component: <SendNotification fileName={fileName} listName={listName} item={selected} save={onSave}></SendNotification>
                    },
                ]}
            />
        </CommonPage>
    );
}



