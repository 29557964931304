const format = (x: number) => {
    if (x > 9) {
        return x;
    }

    return "0" + x;
};

const formatTimeString = (timeString: string) => {
    let datePattern = /\d{4}-[01]\d-[0-3]\dT.*[+]/
    if (datePattern.test(timeString)) {
        if (timeString.slice(-3, -2) !== ":") {
            timeString = timeString.slice(0, -2) + ":" + timeString.slice(-2)
        }
    }
    return timeString
}

const timeStringToDate = (timeString: string) => {
    timeString = formatTimeString(timeString)
    const date = new Date(timeString)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    const hours = date.getHours()
    const minutes = date.getMinutes()

    const dateString = `${format(day)}/${format(month)}/${format(year)} ${format(hours)}:${format(minutes)}`;
    return dateString
}

const getToday = () => {
    const date = new Date()
    return date
}

const getYesterday = () => {
    let date = new Date()
    date.setDate(date.getDate() - 1)
    return date
}

const formatDate = (date: Date | null) => {
    if (date) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const dateString = `${format(year)}-${format(month)}-${format(day)} `;

        return dateString
    }
    else {
        return "-"
    }

}

const formatDateTime = (date: Date | null) => {
    if (date) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hours = date.getHours()
        const minutes = date.getMinutes()

        const dateString = `${format(year)}-${format(month)}-${format(day)} ${format(hours)}:${format(minutes)}:00`;
        return dateString
    }
    else {
        return "-"
    }

}

export default {
    timeStringToDate,
    getToday,
    getYesterday,
    formatDate,
    formatDateTime,
}