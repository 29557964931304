import axios, { Method, AxiosResponse } from "axios";
import store from "../../store models/store_model";
import CommonResponse from "../../models/common_response";
import FinekItem from "../../models/finek_item";
import PartnerCategory from "../../models/partner_category";
import PartnerPromotion from "../../models/partner_promotion";
import NetworkPlans from "../../models/network_plans";
import MyWorldPlugin from "../../models/my_world_plugin";
import MyWorldTier from "../../models/my_world_tier";
import Offer from "../../models/offer";
import MyWorldPlan from "../../models/my_world_plan";
import LangAndText from "../../models/lang_and_text";
import Error from "../../models/error";
import WilayaCity from "../../models/wilaya_city";
import WilayaCountry from "../../models/wilaya_country";
import Wilaya from "../../models/wilaya";
import FinekType from "../../models/finek_type";
import MyWorldSuperPlan from "../../models/my_world_super_plan";
import DecorationAsset from "../../models/decoration_asset";
import EligibleLevel from "../../models/eligible_level";
import QuizPoolModel from "../../models/quizPool";
import NotificationModel from "../../models/notification";
import EligibilityMatrixServiceAccountList from "../../models/EligibiliyMetrixServiceAccountList"
import CustomerBaseModel from "../../models/customerBase";

var connectivity = require("connectivity");

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const userBaseUrl = process.env.REACT_APP_API_BASE_USER_URL;

const quizBaseUrl = process.env.REACT_APP_API_BASE_QUIZ_URL

const showAlertDialog = store.dispatch.message.showMessage;

const setReLogin = store.dispatch.loader.setReLogin;


const headers = {
  noHeaders: {},
  appliJson: {
    "Content-Type": "application/json",
  },
  csvHeaders: {
    "Content-disposition": "attachment",
    "Content-Type": "multipart/form-data",
  },
  notificationHeaders: {
    "Content-Type": "multipart/form-data",
  }
};

const performRequest = async (
  method: Method,
  url: string,
  data?: any,
  headers?: any,
  needLoading?: boolean,
  newBaseUrl?: string,
) => {
  const setLoading = store.dispatch.loader.showLoader;
  var isConnected: Boolean = connectivity();
  headers.Authorization = `Bearer ${sessionStorage.getItem("ibiza_token")}`;

  if (isConnected) {
    if (needLoading) {
      setLoading(true);
    }

    return await axios({
      baseURL: newBaseUrl ? newBaseUrl : baseUrl,
      url: url,
      method: method,
      data: data,
      headers: headers,
    })
      .then((response: AxiosResponse) => {
        setLoading(false);
        console.log(response.data);
        if (response.status === 200) {
          return new CommonResponse({
            success: true,
            data: response.data,
            message: response.statusText,
            status: response.status,
          });
        }
        showAlertDialog({
          head: response.data.title,
          body: response.data.message,
        });
        // alert(response.statusText);
        return new CommonResponse({
          success: false,
          data: response.data,
          message: response.statusText,
          status: response.status,
        });
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          reLogin();
          return;
        }

        if (err.response && err.response.status === 404) {

          showAlertDialog({
            head: "Not Found!",
            body: `Path : ${err.response.data.path}`,
          });

          return;
        }

        if (err.response && err.response.status === 500 && err.response.data.message) {

          showAlertDialog({
            head: "Not Found!",
            body: `${err.response.data.message}`,
          });

          return;
        }
        if (err.response && err.response.data.title) {
          console.log(err.response.data);
          showAlertDialog({
            head: err.response.data.title,
            body: err.response.data.message,
          });
        } else {
          showAlertDialog({ head: "Something went wrong!", body: err.message });
          return;
        }

        return new CommonResponse({
          success: false,
          data: err.response
            ? err.response.data
            : { title: "Something went wrong!" },
          message: err,
          status: err.response ? err.response.status : 500,
        });
      });
  } else {
    showAlertDialog({
      head: "No Internet Connection",
      body: "Please connect to internet and try again!",
    });
    return;
  }
};

const reLogin = () => {
  setReLogin(true)
};

export const getFinekItemsCall = async () => {
  return await performRequest(
    "GET",
    "/store/items",
    null,
    headers.noHeaders,
    true
  );
};

export const getFinekLevelsCall = async () => {
  return await performRequest(
    "GET",
    "/store/levels",
    null,
    headers.noHeaders,
    true
  );
};

export const getFinekTypesCall = async () => {
  return await performRequest(
    "GET",
    "/store/items/type",
    null,
    headers.noHeaders,
    true
  );
};

export const uploadAssetCall = async (file: any) => {
  const data = new FormData();
  data.append("file", file);
  return await performRequest(
    "POST",
    "/content/upload",
    data,
    headers.noHeaders,
    true
  );
};

export const uploadCsvCall = async (file: any) => {
  const data = new FormData();
  data.append("file", file);
  return await performRequest(
    "POST",
    "/eligibilitylist",
    data,
    headers.noHeaders,
    true,
    userBaseUrl
  );
};

export const getTiersCall = async () => {
  return await performRequest(
    "GET",
    "/store/tiers",
    null,
    headers.noHeaders,
    true
  );
};

export const addNewLevelCall = async (
  name: string,
  id: number,
  exPoints: number,
  // tierId: string
) => {
  return await performRequest(
    "POST",
    "/store/levels",
    {
      name: name,
      id: id,
      exPoints: exPoints,
      tierId: "5e958b51-9f7d-4a58-b599-4233d7b40a4b",
    },
    headers.appliJson,
    true
  );
};

export const addNewTypeCall = async (
  imageUrl: string,
  name: LangAndText[],
  description: LangAndText[],
  typeId: string
) => {
  return await performRequest(
    "POST",
    "/store/items/type",
    {
      imageUrl: imageUrl,
      name: name,
      description: description,
      typeId: typeId,
    },
    headers.appliJson,
    true
  );
};

export const addNewItemCall = async (data: FinekItem) => {
  return await performRequest(
    "POST",
    "/store/items",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const updateFinekItem = async (data: FinekItem) => {
  return await performRequest(
    "PUT",
    `/store/items/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const updateFinekLevel = async (data: EligibleLevel) => {
  return await performRequest(
    "PUT",
    `/store/levels/${data.id}`,
    JSON.stringify(
      {
        name: data.name,
        id: data.level,
        exPoints: data.exPoints,
        tierId: data.tierId,
      },
    ),
    headers.appliJson,
    true
  );
};

export const getUserCategoriesCall = async () => {
  return await performRequest(
    "GET",
    "/promotion/user/category",
    null,
    headers.noHeaders,
    true
  );
};

export const getPartnerCategoriesCall = async () => {
  return await performRequest(
    "GET",
    "/promotion/category",
    null,
    headers.noHeaders,
    true
  );
};

export const syncPromotionsCall = async () => {
  return await performRequest(
    "GET",
    `/promotion/sync`,
    null,
    headers.noHeaders,
    true
  );
};

export const getPromotionsCall = async (categoryId: any) => {
  return await performRequest(
    "GET",
    `/promotion/?type=${categoryId}`,
    null,
    headers.noHeaders,
    true
  );
};

export const addUserCategoryCall = async (
  name: string,
  creditLimit: number
) => {
  return await performRequest(
    "POST",
    "/promotion/user/category",
    {
      name: name,
      creditLimit: creditLimit,
    },
    headers.appliJson,
    true
  );
};

export const updateUserCategoryCall = async (
  name: string,
  creditLimit: number,
  id: string
) => {
  return await performRequest(
    "PUT",
    `/promotion/user/category/${id}`,
    {
      name: name,
      creditLimit: creditLimit,
    },
    headers.appliJson,
    true
  );
};

export const deleteUserCategoryCall = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/promotion/user/category/${id}`,
    null,
    headers.noHeaders,
    true
  );
};

export const deletePartnerCategoryCall = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/promotion/category/${id}`,
    null,
    headers.noHeaders,
    true
  );
};

export const updatePartnerCategory = async (data: PartnerCategory) => {
  return await performRequest(
    "PUT",
    `/promotion/category/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const deletePartnerPromotion = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/partner/promotion/${id}`,
    null,
    headers.noHeaders,
    true
  );
};
export const addPartnerCategory = async (data: PartnerCategory) => {
  return await performRequest(
    "POST",
    "/promotion/category",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getPartnerMerchantsCall = async () => {
  return await performRequest(
    "GET",
    "/partner/merchant",
    null,
    headers.noHeaders,
    true
  );
};

export const addPartnerMerchantCall = async (
  name: string,
  userName: string,
  categoryId: string,
  email: string
) => {
  return await performRequest(
    "POST",
    "/partner/merchant",
    {
      name: name,
      username: userName,
      categoryId: categoryId,
      email: email,
    },
    headers.appliJson,
    true
  );
};

export const getCityWifiLocationsCall = async (cityId: string | undefined) => {
  return await performRequest(
    "GET",
    cityId ? `/wifi/location/${cityId}` : "/wifi/location",
    null,
    headers.noHeaders,
    true
  );
};
export const updatePartnerPromotion = async (promoId: any, data: any) => {
  return await performRequest(
    "PUT",
    `/promotion/${promoId}/assign`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const addPartnerPromotion = async (data: PartnerPromotion) => {
  return await performRequest(
    "POST",
    "/partner/promotion",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getNetworkPlans = async () => {
  return await performRequest(
    "GET",
    "/network/plans",
    null,
    headers.noHeaders,
    true
  );
};

export const removeWifiLocation = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/wifi/location/${id}`,
    null,
    headers.appliJson,
    true
  );
};

export const saveNetworkPlan = async (data: NetworkPlans) => {
  return await performRequest(
    "POST",
    "/network/plans",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getCityList = async () => {
  return await performRequest(
    "GET",
    "/content/cities",
    null,
    headers.noHeaders,
    true
  );
};

export const addWifiLocation = async (data: any) => {
  return await performRequest(
    "POST",
    "/wifi/location",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getMyWorldPlans = async () => {
  return await performRequest(
    "GET",
    "/myworld/plans",
    null,
    headers.noHeaders,
    true
  );
};

export const getAllMyWorldPlans = async () => {
  return await performRequest(
    "GET",
    "/myworld/plans",
    null,
    headers.noHeaders,
    true
  );
};

export const getAllMyWorldSuperPlans = async () => {
  return await performRequest(
    "GET",
    "/myworld/superPlans",
    null,
    headers.noHeaders,
    true
  );
};

export const getMyWorldSuperPlans = async () => {
  return await performRequest(
    "GET",
    "/myworld/plans?type=SUPER",
    null,
    headers.noHeaders,
    true
  );
};

export const getMyWorldPlugins = async () => {
  return await performRequest(
    "GET",
    "/myworld/plugins",
    null,
    headers.noHeaders,
    true
  );
};

export const addNewMyWorldPlugin = async (data: MyWorldPlugin) => {
  return await performRequest(
    "POST",
    "/myworld/plugins",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const updateMyWorldPlugin = async (data: MyWorldPlugin) => {
  return await performRequest(
    "PUT",
    `/myworld/plugins/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getMyWorldTiers = async () => {
  return await performRequest(
    "GET",
    "/store/tiers",
    null,
    headers.noHeaders,
    true
  );
};

export const addNewMyWorldTier = async (data: MyWorldTier) => {
  return await performRequest(
    "POST",
    "/store/tiers",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getOffersList = async () => {
  return await performRequest(
    "GET",
    "/offer/item",
    null,
    headers.noHeaders,
    true
  );
};

export const updateOffer = async (data: Offer) => {
  return await performRequest(
    "PUT",
    `/offer/item/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const addNewOffer = async (data: Offer) => {
  return await performRequest(
    "POST",
    "/offer/item",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const updateMyWorldPlan = async (data: MyWorldPlan) => {
  return await performRequest(
    "PUT",
    `/myworld/plans/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};
export const updateMyWorldSuperPlan = async (data: MyWorldSuperPlan) => {
  return await performRequest(
    "PUT",
    `/myworld/superPlans/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const addNewMyWorldPlan = async (data: MyWorldPlan) => {
  return await performRequest(
    "POST",
    "/myworld/plans",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const addNewMyWorldSuperPlan = async (data: MyWorldSuperPlan) => {
  return await performRequest(
    "POST",
    "/myworld/superPlans",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getThemesList = async () => {
  return await performRequest(
    "GET",
    "/themes",
    null,
    headers.noHeaders,
    true
  );
};

export const updateTheme = async (data: any) => {
  return await performRequest(
    "PUT",
    `/themes/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const addNewTheme = async (data: any) => {
  return await performRequest(
    "POST",
    "/themes",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getErrorsList = async () => {
  return await performRequest(
    "GET",
    "/assets/errors",
    null,
    headers.noHeaders,
    true
  );
};

export const addNewError = async (data: Error) => {
  return await performRequest(
    "POST",
    "/assets/errors",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const updateError = async (data: Error) => {
  return await performRequest(
    "PUT",
    `/assets/errors/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getBloodDonationList = async () => {
  return await performRequest(
    "GET",
    "/bd/donateUsers",
    null,
    headers.noHeaders,
    true
  );
};

export const getBloodDonationListWithFilter = async (
  city: any,
  wilaya: any,
  bg: any,
  gender: any,
  startedDate: any,
  endDate: any
) => {
  return await performRequest(
    "GET",
    `/admin/bd/users/history?city=${city}&wilaya=${wilaya}&bg=${bg}&gender=${gender}&startedDate=${startedDate}&endDate=${endDate}`,
    null,
    headers.noHeaders,
    true,
    userBaseUrl
  );
};

export const updateNetworkPlan = async (data: any) => {
  return await performRequest(
    "PUT",
    `/network/plans/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const removeMyWorldPlan = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/myworld/plans/${id}`,
    null,
    headers.appliJson,
    true
  );
};

export const removeMyWorldSuperPlan = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/myworld/superPlans/${id}`,
    null,
    headers.appliJson,
    true
  );
};

export const getWilayaCities = async () => {
  return await performRequest(
    "GET",
    "/content/cities",
    null,
    headers.noHeaders,
    true
  );
};

export const getSimEligibleListCall = async (page: number) => {
  return await performRequest(
    "GET",
    `/eligibilitylist?page=${page}&size=15`,
    null,
    headers.noHeaders,
    true,
    userBaseUrl
  );
};

export const addWilayaCity = async (data: WilayaCity) => {
  return await performRequest(
    "POST",
    "/content/cities",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getWilayaCountries = async () => {
  return await performRequest(
    "GET",
    "/content/country",
    null,
    headers.noHeaders,
    true
  );
};

export const addWilayaCountry = async (data: WilayaCountry) => {
  return await performRequest(
    "POST",
    "/content/country",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getWilayaListCall = async () => {
  return await performRequest("GET", "content/wilayas", null, headers.noHeaders, true);
};

export const addNewWilaya = async (data: Wilaya) => {
  return await performRequest(
    "POST",
    "content/wilayas",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getPromotionsByMerchantId = async (merchantId: string) => {
  return await performRequest(
    "GET",
    `/partner/promotion/merchant/${merchantId}`,
    null,
    headers.noHeaders,
    true
  );
};

export const getVoucherDetails = async (userId: string, voucherId: string) => {
  return await performRequest(
    "GET",
    `/admin/promotion/user/${userId}/voucher/${voucherId}`,
    null,
    headers.noHeaders,
    true,
    userBaseUrl
  );
};

export const generateQRCode = async (userId: string, voucherId: string) => {
  return await performRequest(
    "POST",
    "/admin/promotion/voucher/generate",
    {
      userId: userId,
      voucherId: voucherId,
    },
    headers.noHeaders,
    true,
    userBaseUrl
  );
};

export const deleteOffer = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/offer/item/${id}`,
    null,
    headers.noHeaders,
    true
  );
};

export const deleteAvatarItem = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/store/items/${id}`,
    null,
    headers.noHeaders,
    true
  );
};

export const updateAvatarType = async (data: FinekType) => {
  return await performRequest(
    "PUT",
    `/store/items/type/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const searchBDUserCall = async (mobile: string) => {
  return await performRequest(
    "GET",
    `/admin/bd/user/${mobile}`,
    null,
    headers.noHeaders,
    true,
    userBaseUrl
  );
};

export const updateAsBloodDonatedCall = async (mobile: string) => {
  return await performRequest(
    "POST",
    `/admin/bd/user/donate`,
    {
      mobileNumber: mobile,
    },
    headers.noHeaders,
    true,
    userBaseUrl
  );
};

export const getDecorationAssetsCall = async () => {
  return await performRequest(
    "GET",
    "/assets/deco/asset",
    null,
    headers.noHeaders,
    true
  );
};

export const addNewDecorationAssetCall = async (data: DecorationAsset) => {
  return await performRequest(
    "POST",
    "/assets/deco/asset",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const updateDecorationAssetCall = async (data: DecorationAsset) => {
  return await performRequest(
    "PUT",
    `/assets/deco/asset/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};


export const getAllCarouselCall = async () => {
  return await performRequest(
    "GET",
    "/carousel/items",
    null,
    headers.appliJson,
    true
  );
};

export const addCarouselItemCall = async (data: any) => {
  return await performRequest(
    "POST",
    "/carousel/items",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const updateCarouselItemCall = async (data: any) => {
  return await performRequest(
    "PUT",
    `/carousel/items/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getAllMGMPlansCall = async () => {
  return await performRequest(
    "GET",
    "/myworld/mgm/plans",
    null,
    headers.appliJson,
    true
  );
};

export const addMGMPlansItemCall = async (data: any) => {
  return await performRequest(
    "POST",
    "/myworld/mgm/plans",
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const updateMGMPlansItemCall = async (data: any) => {
  return await performRequest(
    "PUT",
    `/myworld/mgm/plans/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true
  );
};

export const getUserDetailsByMobile = async (mobile: string) => {
  return await performRequest(
    "GET",
    `/customer/info/${mobile}`,
    null,
    headers.noHeaders,
    true
  );
};

export const getUserEventsByMobile = async (mobile: string, startDate: string, endDate: string) => {
  return await performRequest(
    "GET",
    `/customer/events/${mobile}?startDate=${startDate}&endDate=${endDate}`,
    null,
    headers.noHeaders,
    true
  );
};

export const getUserTransactionsByMobile = async (mobile: string, startDate: string, endDate: string) => {
  return await performRequest(
    "GET",
    `/customer/transactions/${mobile}?startDate=${startDate}&endDate=${endDate}`,
    null,
    headers.noHeaders,
    true
  );
};

export const getSummaryStats = async (startDate: string, endDate: string) => {
  return await performRequest(
    "GET",
    `/stats/summary?startDate=${startDate}&endDate=${endDate}`,
    null,
    headers.noHeaders,
    true
  );
};

export const updatePlanStatus = async (
  id: string,
  status: string
) => {
  return await performRequest(
    "PUT",
    `/myworld/plans/${id}/status`,
    {
      status: status
    },
    headers.noHeaders,
    true
  );
};

export const updateSuperPlanStatus = async (
  id: string,
  status: string
) => {
  return await performRequest(
    "PUT",
    `/myworld/superPlans/${id}/status`,
    {
      status: status
    },
    headers.noHeaders,
    true
  );
};

export const updateOfferStatus = async (
  id: string,
  status: string
) => {
  return await performRequest(
    "PUT",
    `/offer/item/${id}/status`,
    {
      status: status
    },
    headers.noHeaders,
    true
  );
};

export const getEligibilityByMobile = async (mobile: string) => {
  return await performRequest(
    "GET",
    `/eligibilitynumber/eligible/${mobile}`,
    null,
    headers.noHeaders,
    true
  );
};

export const getQuizPool = async (page: number) => {
  return await performRequest(
    "GET",
    `/questions?page=${page}&size=10`,
    null,
    headers.noHeaders,
    true,
    quizBaseUrl
  );
};

export const getSingleQuizPool = async (quizId: any) => {
  return await performRequest(
    "GET",
    `/questions/${quizId}`,
    null,
    headers.noHeaders,
    true,
    quizBaseUrl
  );
};

export const uploadQuizCsvCall = async (file: any) => {
  console.log("file", file)
  const data = new FormData();
  data.append("file", file);
  return await performRequest(
    "POST",
    "/questions/upload",
    data,
    headers.csvHeaders,
    true,
    quizBaseUrl
  );
};

export const addQuizPool = async (data: QuizPoolModel) => {
  return await performRequest(
    "POST",
    "/questions",
    JSON.stringify(data),
    headers.appliJson,
    true,
    quizBaseUrl
  );
};

export const getQuizSession = async (page: number) => {
  return await performRequest(
    "GET",
    `/session?page=${page}&size=10`,
    null,
    headers.noHeaders,
    true,
    quizBaseUrl
  );
};

export const getSingleSession = async (quizSessionId: any) => {
  return await performRequest(
    "GET",
    `/session/${quizSessionId}`,
    null,
    headers.noHeaders,
    true,
    quizBaseUrl
  );
};

export const getSessionResult = async (quizSessionId: any, page: number) => {
  return await performRequest(
    "GET",
    `/session/results/${quizSessionId}?page=${page}&size=10`,
    null,
    headers.noHeaders,
    true,
    quizBaseUrl
  );
};

export const getLeaderboardResult = async (quizSessionId: any) => {
  return await performRequest(
    "GET",
    `/session/leaderboard/v2/${quizSessionId}`,
    null,
    headers.noHeaders,
    true,
    quizBaseUrl
  );
};

export const updateQuizStatus = async (
  id: string,
  status: string
) => {
  return await performRequest(
    "PUT",
    `/questions/status/${id}`,
    {
      status: status
    },
    headers.noHeaders,
    true,
    quizBaseUrl
  );
};

export const updateQuizSessionStatus = async (
  id: string,
  status: string
) => {
  return await performRequest(
    "PUT",
    `/session/status/${id}`,
    {
      status: status
    },
    headers.noHeaders,
    true,
    quizBaseUrl
  );
};

export const updateQuizPool = async (data: any) => {
  return await performRequest(
    "PUT",
    `/questions/${data.id}`,
    JSON.stringify(data),
    headers.appliJson,
    true,
    quizBaseUrl
  );
};

export const getMatrix = async () => {
  return await performRequest(
    "GET",
    `/eligibility/config/matrix`,
    null,
    headers.noHeaders,
    true,
    baseUrl
  );
};


export const getMatrixServiceAccountList = async (page: number) => {
  return await performRequest(
    "GET",
    `/eligibility/config/service/account/pagination?page=${page}&size=10`,
    null,
    headers.noHeaders,
    true,
    baseUrl
  );
};

export const updateMatrix = async (data: any) => {
  return await performRequest(
    "PUT",
    `/eligibility/config/matrix`,
    JSON.stringify(data),
    headers.appliJson,
    true,
    baseUrl
  );
};

export const updateMatrixServiceList = async (data: any) => {
  return await performRequest(
    "PUT",
    `/eligibility/config/service/account`,
    JSON.stringify(data),
    headers.appliJson,
    true,
    baseUrl
  );
};

export const addServiceList = async (data: EligibilityMatrixServiceAccountList[]) => {
  console.log("api", data)
  return await performRequest(
    "POST",
    "/eligibility/config/service/account",
    JSON.stringify(data),
    headers.appliJson,
    true,
    baseUrl
  );
};

export const removeEligibilityMatrix = async (id: string) => {
  return await performRequest(
    "DELETE",
    `/eligibility/config/service/account/${id}`,
    null,
    headers.appliJson,
    true,
    baseUrl
  );
};

export const sendNotification = async (data: NotificationModel, file: any) => {
  const formData = new FormData();
  formData.append("file", file);
  data.title.forEach((item, index) => {
    formData.append(`title[${index}].language`, item.language);
    formData.append(`title[${index}].text`, item.text);
  })
  data.body.forEach((body, index) => {
    formData.append(`body[${index}].language`, body.language);
    formData.append(`body[${index}].text`, body.text);
  })
  return await performRequest(
    "POST",
    "/notify/segmented",
    formData,
    headers.notificationHeaders,
    true,
    baseUrl,
  );
};

export const uploadPubGFile = async (file: any) => {
  console.log("file", file)
  const data = new FormData();
  data.append("file", file);
  return await performRequest(
    "POST",
    "/pubgpromotion/upload",
    data,
    headers.notificationHeaders,
    true,
    baseUrl
  );
};

export const getRedeemCodeDetails = async () => {
  return await performRequest(
    "GET",
    `/pubgpromotion/redeem-code-details`,
    null,
    headers.noHeaders,
    true,
    baseUrl
  );
};

export const getRedeemCodeLevel = async () => {
  return await performRequest(
    "GET",
    `pubgpromotion/level/redeem-code-details`,
    null,
    headers.noHeaders,
    true,
    baseUrl
  );
};

export const getMessageTemplate = async () => {
  return await performRequest(
    "GET",
    `/segmented-notification/template/all`,
    null,
    headers.noHeaders,
    true,
    baseUrl
  );
};

export const getCustomerBase = async () => {
  return await performRequest(
    "GET",
    `/segmented-notification/msisdn-lists/`,
    null,
    headers.noHeaders,
    true,
    baseUrl
  );
};

export const addMessageTemplates = async (data: NotificationModel) => {
  return await performRequest(
    "POST",
    "/segmented-notification/template",
    JSON.stringify(data),
    headers.appliJson,
    true,
    baseUrl
  );
};

export const getExistingTemplates = async () => {
  return await performRequest(
    "GET",
    `/segmented-notification/msisdn-lists/`,
    null,
    headers.noHeaders,
    true,
    baseUrl
  );
};

export const addMsisdnList = async (data: CustomerBaseModel) => {
  return await performRequest(  
    "POST",
    "/segmented-notification/msisdn-lists",
    data,
    headers.noHeaders,
    true,
    baseUrl
  );
};

export const updateMessageTemplates = async (data: NotificationModel) => {
  return await performRequest(
    "PUT",
    `/segmented-notification/template`,
    JSON.stringify(data),
    headers.appliJson,
    true,
    baseUrl
  );
};