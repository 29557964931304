import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import dataCardStyles from "./data_card_styles";
import time from "../../../../services/time";

interface IProps {
    cardData: Object;
    title: string;
}
export function UserDetailsCard(props: IProps) {
    const dataCardStyle = dataCardStyles();
    const [open, setOpen] = useState<{ key: string, open: boolean }>({ key: '', open: false })
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [dialogData, setDialogData] = useState<{ head: string, data: Object[] }>({ head: '', data: [{}] })
    useEffect(() => {

    }, [props])

    const handleClose = () => {
        setDialogOpen(false)
        setDialogData({ head: '', data: [{}] })
    }

    const handleValue = (value: any) => {

        let pattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/
        let datePattern = /\d{4}-[01]\d-[0-3]\dT.*[+]/
        if (pattern.test(value)) {
            return (<img src={value} alt="" width="60px" />)
        }
        else if (datePattern.test(value)) {
            return time.timeStringToDate(value)
        }
        else {
            return handleEmpty(value)
        }
    }

    const handleEmpty = (value: any) => {
        let output: any
        if (value === '' || value === null) {
            output = "-"
        }
        else if (typeof value === 'boolean') {
            if (value) {
                output = 'True'
            }
            else {
                output = 'False'
            }
        }
        else {
            output = value
        }
        return output

    }

    const formatKey = (value: string) => {
        let output: string
        if (!!value) {
            output = value.match(/([A-Z]?[^A-Z]*)/g)!.slice(0, -1).join(" ")
            output = output.replace('I D', 'ID')
            output = output.replace('4 G', ' 4G')
            output = output.replace('3 G', ' 3G')

            if (value === "phone1") {
                output = "Phone"
            }

            if (value === "itemImageUrl") {
                output = "Item Preview"
            }

            if (value === "limitedAccountListInformations") {
                output = "Limited Account List"
            }

            if (value === "unlimitedAccountListInformations") {
                output = "Unlimited Account List"
            }
        }
        else {
            output = "unknown"
        }

        return output

    }
    return (
        <Paper className={dataCardStyle.container} elevation={3}>
            <TableContainer component={Paper}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={dataCardStyle.header} align="center" colSpan={2}>
                                {props.title}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(props.cardData).map((key: string, i) => {
                                let value = Reflect.get(props.cardData, key)
                                if (Array.isArray(value)) {
                                    if (key === "account" || key === "digitalBundles") {
                                        //skip
                                    }
                                    else {
                                        return (
                                            <>
                                                <TableRow
                                                    className={clsx(i % 2 === 1 && dataCardStyle.oddRowColor)}
                                                    hover
                                                    key={i}
                                                    onClick={() => setOpen({ key: key, open: open.key === key ? !open.open : true })}
                                                >
                                                    <TableCell className={dataCardStyle.rowTitle} align='left'>
                                                        {formatKey(key)}
                                                    </TableCell>
                                                    <TableCell className={dataCardStyle.tableData} align='right'>
                                                        <IconButton size="small">
                                                            {open.key === key && open.open === true ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                                                        <Collapse in={open.key === key && open.open === true} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                {
                                                                    value.length === 0 ?
                                                                        <div className={dataCardStyle.emptyTable}>
                                                                            This Field Is Empty
                                                                </div>

                                                                        :
                                                                        <Table size="small">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    {
                                                                                        Object.keys(value[0]).map(key => {
                                                                                            return (
                                                                                                <TableCell className={dataCardStyle.rowTitle}>{formatKey(key)}</TableCell>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {value.map((row, i) => (
                                                                                    <TableRow key={i}>
                                                                                        {
                                                                                            Object.keys(row).map(key => {
                                                                                                return (
                                                                                                    <TableCell className={dataCardStyle.tableData}>
                                                                                                        {handleValue(Reflect.get(row, key))}
                                                                                                    </TableCell>
                                                                                                )

                                                                                            })
                                                                                        }
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                }
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    }
                                }
                                else if (typeof value === 'object' && value !== null) {
                                    if (key === "dynamicBundlesPackage") {
                                        //skip
                                    }


                                }
                                else {
                                    return (
                                        <TableRow
                                            className={clsx(i % 2 === 1 && dataCardStyle.oddRowColor)}
                                            hover
                                            key={i}
                                        >
                                            <TableCell className={dataCardStyle.rowTitle} align='left'>
                                                {formatKey(key)}
                                            </TableCell>
                                            <TableCell className={dataCardStyle.tableData} align='left'>
                                                {handleValue(value)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }

                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={dialogOpen}
                onClose={handleClose}
            >
                <DialogTitle >{formatKey(dialogData.head)}</DialogTitle>
                <DialogContent>
                    {
                        dialogData.data.length === 0 ?
                            <div className={dataCardStyle.emptyTable}>
                                This Field Is Empty
                                                                </div>

                            :
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {
                                            Object.keys(dialogData.data[0]).map(key => {
                                                return (
                                                    <TableCell className={dataCardStyle.rowTitle}>{formatKey(key)}</TableCell>
                                                )

                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogData.data.map((row: Object, i) => (
                                        <TableRow key={i}>
                                            {
                                                Object.keys(row).map(key => {
                                                    return (
                                                        <TableCell className={dataCardStyle.tableData}>
                                                            {handleValue(Reflect.get(row, key))}
                                                        </TableCell>
                                                    )

                                                })
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleClose()

                    }} color="primary" autoFocus>
                        ok
                        </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

