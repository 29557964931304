import React, { useEffect, useState } from "react";
import MyModal from "../../components/my_modal/my_modal";
import useStyles from "./network_plans_styles";
import NetworkPlans from "../../models/network_plans";
import { Box, MenuItem, TextField } from "@material-ui/core";
import clsx from "clsx";
import LangAndTextEditTabs from "../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelLabel from "../../components/model_label/model_label";
import ModelBtn from "../../components/model_btn/model_btn";
import LangAndText from "../../models/lang_and_text";
import validityUnits from "./validity_units";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import {
  updateNetworkPlan,
  uploadAssetCall,
} from "../../services/api_service/api_service";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import ModelFieldWrapper from "../../components/model_field_wrapper/model_field_wrapper";


interface Props {
  isShow: boolean;
  setShow: Function;
  selected: NetworkPlans | undefined;
  reloadData: Function;
}
export default function NetworkPlanUpdateModel(props: Props) {
  const [selected, setSelected] = useState<NetworkPlans>();

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const nameChange = (name: LangAndText[]) => {
    if (selected) {
      const prev = selected;
      prev.name = name;
      setSelected({ ...prev });
    }
  };

  const subHeadingChange = (subHeading: LangAndText[]) => {
    if (selected) {
      const prev = selected;
      prev.subHeading = subHeading;
      setSelected({ ...prev });
    }
  };

  const descriptionChange = (description: LangAndText[]) => {
    if (selected) {
      const prev = selected;
      prev.description = description;
      setSelected({ ...prev });
    }
  };

  const planIdChange = (event: any) => {
    if (selected) {
      const prev = selected;
      prev.planId = event.target.value;
      setSelected({ ...prev });
    }
  };
  const priceChange = (event: any) => {
    if (selected) {
      const prev = selected;
      prev.price = Number(event.target.value);
      setSelected({ ...prev });
    }
  };

  const unitChange = (event: any) => {
    if (selected) {
      const prev = selected;
      prev.validity.unit = event.target.value;
      setSelected({ ...prev });
    }
  };
  const countChange = (event: any) => {
    if (selected) {
      const prev = selected;
      prev.validity.count = Number(event.target.value);
      setSelected({ ...prev });
    }
  };

  const onIconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const value = data.data.url;
          const prev = selected;
          if (prev) {
            prev.imageUrl = value;
            setSelected({ ...prev });
          }
        }
      });
    }
  };

  const onSave = () => {
    if (selected) {
      selected.planName = selected.name;
      updateNetworkPlan(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully Updated.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Update Network Plan"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selected && (
          <IconUploadBtn
            mediaType={"IMAGE"}
            mediaUrl={selected.imageUrl}
            onUpload={onIconUpload}
            isEditable={true}
            allowVideo={false}
            height={120}
          />
        )}
        <div className={classes.column}>
          <LangAndTextEditTabs
            label="Name"
            data={selected?.name}
            setData={nameChange}
            isEditable={true}
          />

          <LangAndTextEditTabs
            label="Sub Heading"
            data={selected?.subHeading}
            setData={subHeadingChange}
            isEditable={true}
          />

          <LangAndTextEditTabs
            label="Description"
            data={selected?.description}
            setData={descriptionChange}
            isEditable={true}
          />

          <ModelFieldWrapper>
            <ModelLabel title={"Other Fields"} noMargin />
            <Box height={15} />
            <TextField
              label={"Plan Id"}
              value={selected?.planId}
              variant="outlined"
              className={clsx(classes.textField, classes.fullWidth)}
              style={{ width: "100%" }}
              onChange={planIdChange}
            />
            <Box height={10} />
            <TextField
              type="number"
              label={"Price"}
              value={selected?.price}
              variant="outlined"
              className={clsx(classes.textField, classes.fullWidth)}
              style={{ width: "100%" }}
              onChange={priceChange}
              InputProps={{inputProps:{min:0}}}
            />

            <div className={classes.row}>
              <TextField
                variant="outlined"
                className={classes.textField}
                style={{ width: "200px" }}
                value={selected?.validity.unit ? selected.validity.unit : ""}
                label="Validity Unit"
                onChange={unitChange}
                select
              >
                {validityUnits.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant="outlined"
                type="number"
                className={classes.textField}
                value={selected?.validity.count}
                label="Validity Count"
                onChange={countChange}
                InputProps={{inputProps:{min:0}}}
              />
            </div>
          </ModelFieldWrapper>
        </div>
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Close"} onClick={closeModal} />
        <ModelBtn title={"Save"} onClick={onSave} />
      </div>
    </MyModal>
  );
}
