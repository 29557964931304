import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import tableStyles from "../../../common_classes/table";
import ModelBtn from "../../../components/model_btn/model_btn";
import clsx from "clsx";
import { removeMyWorldPlan } from "../../../services/api_service/api_service";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import clonedeep from 'lodash.clonedeep';
import MGMPlanModel from "./mgm_plans_model";
import MGMPlanViewModel from "./mgm_plans_view_model";
import MGMPlan from "../../../models/mgm_plan";

interface Props {
  plans: MGMPlan[];
  reloadData: Function;
}
export default function MGMPlansTable(props: Props) {
  const tableStyle = tableStyles();
  const [showModel, setShowModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MGMPlan>();

  const [showViewModel, setShowViewModel] = useState(false);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const showConfMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showConfirmMessage
  );
  const onUpdate = (item: MGMPlan) => {
    setSelectedItem(clonedeep(item));
    setShowModel(true);
  };

  const onView = (item: MGMPlan) => {
    setSelectedItem(item);
    setShowViewModel(true);
  };

  const onDelete = (item: MGMPlan) => {
    showConfMessage({
      head: "Are you sure?",
      body: "This action cannot be undone",
      onConfirm: () => {
        removeMyWorldPlan(item.id).then((data) => {
          if (data?.success) {
            props.reloadData();
          } else {
            showMessage({
              head: "Failed!",
              body: "Cannot remove plan. Please try again later.",
            });
          }
        });
      },
    });
  };
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>Ex Points</TableCell>
            <TableCell className={tableStyle.header}>Bundle Id</TableCell>
            <TableCell className={tableStyle.header}>Bundle Code</TableCell>
            <TableCell className={tableStyle.header}>Validity</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.plans.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell className={tableStyle.tableData}>
                {item.planName}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.exPoints}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.bundleId}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.bundleCode}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.validity?.toLowerCase()}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title="View" onClick={() => onView(item)} />
                <ModelBtn title="Update" onClick={() => onUpdate(item)} />
                <ModelBtn title="Delete" onClick={() => onDelete(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <MGMPlanModel
        isShow={showModel}
        setShow={setShowModel}
        selected={selectedItem}
        reloadData={props.reloadData}
      />
      <MGMPlanViewModel
        isShow={showViewModel}
        setShow={setShowViewModel}
        selected={selectedItem}
      />
    </TableContainer>
  );
}
