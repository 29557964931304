export default class LeaderBoard {
    id: string;
    sessionId: string;
    score: number;
    correctQuiz: number;
    totalQuiz: number;
    userId: string;
    userName: string;
    msisdn: string;
    startTime: string;
    submitTime: string;
    elapsedTimeServer: string;
    elapsedTimeApp: string;
    template: string;
    
    constructor(data: any) {
        this.id = data.id;
        this.sessionId = data.sessionId;
        this.score = data.score;
        this.correctQuiz = data.correctQuiz;
        this.totalQuiz = data.totalQuiz;
        this.userId = data.userId;
        this.userName = data.userName;
        this.msisdn = data.msisdn;
        this.startTime = data.startTime;
        this.submitTime = data.submitTime;
        this.elapsedTimeServer = data.elapsedTimeServer;
        this.elapsedTimeApp = data.elapsedTimeApp;
        this.template = data.template;
    }
}

