import React from "react";
import WifiLocation from "../../models/wifi_location";
import MyModal from "../../components/my_modal/my_modal";
import useStyles from "./wifi_page_styles";
import clsx from "clsx";
import {  Paper } from "@material-ui/core";
import SimpleMap from "./wifi_map";
import ModelBtn from "../../components/model_btn/model_btn";

interface Props {
  selected: WifiLocation | undefined;
  isShow: boolean;
  setShow: Function;
}
export default function WifiModel(props: Props) {
  const classes = useStyles();
  const closeModal = () => {
    props.setShow(false);
  };
  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
       
      title={"wifi location"}
      setShow={props.setShow}
    >
      <Paper className={classes.paper}>
        <SimpleMap
          lat={props.selected ? props.selected.location.lat : 0}
          lon={props.selected ? props.selected.location.lon : 0}
        />
        <div className={classes.label}>{props.selected?.name}</div>
      </Paper>
      <div>
        <div className={clsx(classes.row, classes.right)}>
         
          <ModelBtn title={'Close'} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
