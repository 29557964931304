import React from "react";
import { Drawer, Divider, List } from "@material-ui/core";
import useStyles from "./my_drawer_style";
import MainItemList from "../items_list/listItems";
import MyNavigator from "../../services/navigation";
import { useStoreState } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import  { getRouteUser } from "../../services/route_name/route_users";

interface Props {
  currentRoute: string;
}

export default function MyDrawer(props: Props) {
  const {pushNamed} = MyNavigator()
  const classes = useStyles();
  const authUser = useStoreState((state:StoreModel)=>state.keyCloakUserModel.authUser)
  const route = getRouteUser(authUser)
  const goToHome=()=>{
    pushNamed(route.firstRoute)
  }
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper:classes.drawerPaper,
      }}
    >
      <div onClick={goToHome} className={classes.toolbarIcon}>
        <div className={classes.ibiza} >IBIZA</div>
      </div>
      <Divider />
      <List>
        <MainItemList currentRoute={props.currentRoute}/>
      </List>
    </Drawer>
  );
}
