import React, { useEffect, useState } from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import { Card, Tab, Tabs } from "@material-ui/core";
import useStyles from "../createQuestions/create_question_style";
import Content from "../../../models/content";
import PageTab from "../../../components/page_tab/page_tab";
import UpdQuestionTextField from "./update_text_fied";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import QuizPoolModel, { emptyQuestionPool } from "../../../models/quizPool";
import { updateQuizPool } from "../../../services/api_service/api_service";
import UpdateQuestionOthers from "./update_questions_other_fields"
interface Props {
    onSubmit: Function
    item: QuizPoolModel | null
}

export default function UpdateQuestion(props: Props) {
    const classes = useStyles();
    const [selected, setSelected] = useState<QuizPoolModel | null>(null)

    useEffect(() => {
        setSelected(props.item)
    }, []);
    
    const saveModel = () => {
        if (selected) {
            updateQuizPool(selected).then((data) => {
                console.log("data", data)
                if (data?.success) {
                    props.onSubmit(false)
                } 
            });
        }
    };
    return (
        <Card className={classes.cardSize}>
            <AddItemBtn title="Back" onClick={() => { props.onSubmit() }} />
            <PageTab
                tabsData={[
                    {
                        title: "English",
                        component: <UpdQuestionTextField language="EN" data={selected} onChange={setSelected}></UpdQuestionTextField>
                    },
                    {
                        title: "Arabic",
                        component: <UpdQuestionTextField language="AR" data={selected} onChange={setSelected}></UpdQuestionTextField>
                    },
                    {
                        title: "French",
                        component: <UpdQuestionTextField language="FR" data={selected} onChange={setSelected}></UpdQuestionTextField>
                    },
                ]}
            />
            <UpdateQuestionOthers language="FR" data={selected} onChange={setSelected}></UpdateQuestionOthers>
            <div className="row mt-3">
                <div className="col-md-9">
                    <AddItemBtn title="Update Questions" onClick={saveModel} />
                </div>
            </div>
        </Card>

        // </CommonPage>
    );
}
