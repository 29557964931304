import React, { useState, useEffect } from "react";
import {
  Card,
  TextField,
} from "@material-ui/core";
import useStyles from "./finek_items_style";
import FinekItem from "../../../models/finek_item";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";
import DetailItem from "../../../components/detail_item/detail_item";

interface Props {
  selectedItem: FinekItem | undefined;
  isShow: boolean;
  setShow: Function;
}
export default function FinacItemModel(props: Props) {
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState<FinekItem>();

  useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem, props.isShow]);

  const closeModal = () => {
    props.setShow(false);
  };

  return selectedItem ? (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"View Avatar Item"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <IconUploadBtn
          mediaUrl={selectedItem.iconUrl}
          mediaType={"IMAGE"}
          onUpload={null}
          allowVideo={false}
          height={120}
          isEditable={false}
        />
        <ModelFieldWrapper>
          <ModelLabel title={"Coins"} noMargin />
          <div className={classes.price}>{selectedItem.price}</div>
        </ModelFieldWrapper>

        <ModelLabel title={"Assets"} />
        {selectedItem?.assets.map((item, index) => (
          <Card key={index} elevation={3} className={classes.assetCard}>
            <div className={classes.centeredRow} >
              <TextField
                label={"Asset Name"}
                value={item.assetName}
                className={classes.textFieldHalf}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label={"Asset Type"}
                InputProps={{ readOnly: true }}
                value={item.type}
                className={classes.textFieldHalf}
                variant="outlined"
              />

              <TextField
                label={"Version"}
                type="number"
                value={item.version}
                className={classes.textFieldHalf}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </div>

            <div key={index} className={classes.assetRow}>
              <div className={classes.assetItem}>
                <IconUploadBtn
                  mediaUrl={item.ios.url}
                  mediaType={"FILE"}
                  onUpload={null}
                  allowVideo={false}
                  height={70}
                  isEditable={false}
                  index={index}
                  allowAnyFile
                />
                <div className={classes.boldText}>IOS</div>
              </div>

              <div className={classes.assetItem}>
                <IconUploadBtn
                  mediaUrl={item.android.url}
                  mediaType={"FILE"}
                  onUpload={null}
                  allowVideo={false}
                  height={70}
                  isEditable={false}
                  index={index}
                  allowAnyFile
                />
                <div className={classes.boldText}>ANDROID</div>
              </div>
            </div>
          </Card>
        ))}

        <LangAndTextEditTabs
          label="Name"
          data={selectedItem.name}
          setData={null}
          isEditable={false}
        />
        <LangAndTextEditTabs
          label="Description"
          data={selectedItem.description}
          setData={null}
          isEditable={false}
        />

        <ModelFieldWrapper>
          <ModelLabel title={"Eligible Level"} noMargin />
          <DetailItem
            title="Level"
            value={selectedItem.eligibleLevelItem?.level}
          />
          <DetailItem
            title="Ex Points"
            value={selectedItem.eligibleLevelItem?.exPoints}
          />
          <DetailItem
            title="Name"
            value={selectedItem.eligibleLevelItem?.name}
          />
        </ModelFieldWrapper>

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />

        </div>
      </div>
    </MyModal>
  ) : (
    <div />
  );
}
