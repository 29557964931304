import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card:{
        display: "flex",
        flexDirection: "row",
        margin: "3px 0px",
        padding: "5px",
        alignItems:'center'
      },
      image:{ height: "100px", width: "100px" },
      line:{
        width: "2px",
        backgroundColor: "#c0c0c0",
        height: "100px",
        margin: "5px 10px",
      },
      detailOuter:{ display: "flex", flexDirection: "row", marginBottom: "3px" },
      keyName:{ fontWeight: "bold", color: myColors.appBarColor },
      colen:{ margin: "0px 5px", color: myColors.appBarColor },
      valueName:{ fontWeight: "bold", color: myColors.drawerColor }
  })
);

export default useStyles;
