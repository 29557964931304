import React, { useState } from "react";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import FinekType from "../../../models/finek_type";
import tableStyles from "../../../common_classes/table";
import clsx from "clsx";
import FinekTypeUpdateModel from "./finek_type_update_model";
import ModelBtn from "../../../components/model_btn/model_btn";
import clonedeep from "lodash.clonedeep";
import FinekTypeViewModel from "./finek_type_view_model";

interface Props {
  types: FinekType[];
  reloadData: Function;
}
export default function FinekTypesTable(props: Props) {
  const [isShowModel, setShowModel] = useState(false);
  const [isShowUpdateModel, setShowUpdateModel] = useState(false);
  const [selected, setSelected] = useState<FinekType>();
  const tableStyle = tableStyles();

  const onUpdate = (item: FinekType) => {
    setSelected(clonedeep(item));
    setShowUpdateModel(true);
  };
  const onView = (item: FinekType) => {
    setSelected(item);
    setShowModel(true);
  };
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Image</TableCell>
            <TableCell className={tableStyle.header}>Type Id</TableCell>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>Description</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.types.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell>
                {item.imageUrl && (
                  <img
                    height={"50px"}
                    src={item.imageUrl}
                    alt={item.name[0]?.text}
                  />
                )}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.typeId}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.name[0]?.text}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.description[0]?.text}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title="View" onClick={() => onView(item)} />
                <ModelBtn title="Update" onClick={() => onUpdate(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <FinekTypeViewModel
        isShow={isShowModel}
        setShow={setShowModel}
        selected={selected}
      />

      <FinekTypeUpdateModel
        isShow={isShowUpdateModel}
        setShow={setShowUpdateModel}
        selected={selected}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
