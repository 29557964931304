import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React, { useState } from "react";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../components/model_btn/model_btn";
import cloneDeep from "lodash.clonedeep";
import CarouselModel from "../../models/carousel";
import CarouselUpdateModel from "./carousel_update_model";
import useStyles from './carousel_style'
import { carouselTypeColors } from "./carousel_data";

interface Props {
  carousels: CarouselModel[];
  reloadData: Function;
}

export default function CarouselTable(props: Props) {
  const tableStyle = tableStyles();
  const classes = useStyles()
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CarouselModel>();

  const onUpdate = (item: CarouselModel) => {
    setSelectedItem(cloneDeep(item));
    setShowUpdateModel(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Image</TableCell>

            <TableCell className={tableStyle.header}>Name</TableCell>

            <TableCell className={tableStyle.header}>Type</TableCell>
            <TableCell className={tableStyle.header}>Status</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.carousels.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell>
                {item.bannerUrl ? (
                  <img
                    height="50px"
                    width={"50px"}
                    src={item.bannerUrl}
                    alt="carousel media"
                  />
                ) : (
                  <div></div>
                )}
              </TableCell>

              <TableCell className={tableStyle.tableData}>
                {item.name}
              </TableCell>

              <TableCell className={tableStyle.tableData}>
                <div className={classes.typeChip} style={{
                  backgroundColor:carouselTypeColors[item.type]
                }}>{item.type}</div>
              </TableCell>
              <TableCell
                className={clsx(
                  tableStyle.tableData,
                  item.active ? tableStyle.colored : tableStyle.redColored
                )}
              >
                {item.active ? "Active" : "Inactive"}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"Update"} onClick={() => onUpdate(item)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <CarouselUpdateModel
        isShow={showUpdateModel}
        setShow={setShowUpdateModel}
        selectedCarousel={selectedItem}
        reloadData={props.reloadData}
      />
    </TableContainer>
  );
}
