import React, { useEffect, useState } from "react";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import CommonPage from "../../../components/common_page/common_page";
import PageTab from "../../../components/page_tab/page_tab";
import MyWorldSuperPlan from "../../../models/my_world_super_plan";
import { getAllMyWorldSuperPlans } from "../../../services/api_service/api_service";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import MyWorldSuperPlansAddModel from "./my_world_super_plans_add_model";
import MyWorldSuperPlansTable from "./my_world_super_plans_table";

export default function MyWorldSuperPlansPage() {
  const [plans, setPlans] = useState<MyWorldSuperPlan[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const showAddModel = () => {
    setShowAddModel(true);
  };

  const getSuperPlans = () => {
    getAllMyWorldSuperPlans().then((data) => {
      if (data?.success) {
        const pl: MyWorldSuperPlan[] = [];
        data.data.forEach((x: any) => pl.push(new MyWorldSuperPlan(x)));
        setPlans(pl);
      }
    });
  };
  useEffect(() => {
    getSuperPlans();
  }, []);
  return <CommonPage currentRoute={nestedRouteNameObject1.myWorldSuperPlans} parentRoutes={[routeNameObjects.myWorld]}>
    <AddItemBtn title='Add New Super Plan' onClick={showAddModel} />

    {plans.length > 0 && (
      <PageTab
        tabsData={[
          {
            title: "Daily Plans",
            component: (
              <MyWorldSuperPlansTable
                plans={plans.filter(
                  (p) => p.validity.toLocaleLowerCase() === "daily"
                )}
                reloadData={getSuperPlans}
              />
            ),
          },
          {
            title: "Weekly Plans",
            component: (
              <MyWorldSuperPlansTable
                plans={plans.filter(
                  (p) => p.validity.toLocaleLowerCase() === "weekly"
                )}
                reloadData={getSuperPlans}
              />
            ),
          },
          {
            title: "Monthly Plans",
            component: (
              <MyWorldSuperPlansTable
                plans={plans.filter(
                  (p) => p.validity.toLocaleLowerCase() === "monthly"
                )}
                reloadData={getSuperPlans}
              />
            ),
          },
        ]}
      />
    )}
    <MyWorldSuperPlansAddModel reloadData={getSuperPlans} isShow={isShowAddModel} setShow={setShowAddModel} />
  </CommonPage>;
}
