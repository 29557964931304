import { Card } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./model_field_wrapper_style";

export default function ModelFieldWrapper(props: { children: any }) {
  const classes = useStyles();
  const [isHover, setHover] = useState(false);
  return (
    <Card
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      elevation={isHover ? 10 : 3}
      className={classes.card}
    >
      {props.children}
    </Card>
  );
}
