export const emptyUserInformation: UserInformation = {
    firstName: '',
    lastName: '',
    birthDate: '',
    sex: '',
    city: '',
    title: null,
    companyName: '',
    street: '',
    zip: null,
    languageCode: null,
    countryId: null,
    phone1: null,
    taxNbr: null,
    state: '',
    maritalStatus: null,
    identityTypeCode: null,
    identityValue: '',
};

class UserInformation {
    firstName: string;
    lastName: string;
    birthDate: string;
    sex: string;
    city: string;
    title: null;
    companyName: string;
    street: string;
    zip: null;
    languageCode: null;
    countryId: null;
    phone1: null;
    taxNbr: null;
    state: string;
    maritalStatus: null;
    identityTypeCode: null;
    identityValue: string;

    constructor(data: any) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.birthDate = data.birthDate;
        this.sex = data.sex;
        this.city = data.city;
        this.title = data.title;
        this.companyName = data.companyName;
        this.street = data.street;
        this.zip = data.zip;
        this.languageCode = data.languageCode;
        this.countryId = data.countryId;
        this.phone1 = data.phone1;
        this.taxNbr = data.taxNbr;
        this.state = data.state;
        this.maritalStatus = data.maritalStatus;
        this.identityTypeCode = data.identityTypeCode;
        this.identityValue = data.identityValue;
    }
}

export default UserInformation;