import React, { useState, useEffect } from "react";
import useStyles from "./offers_style";
import clsx from "clsx";
import { useStoreActions, Actions } from "easy-peasy";
import MyModal from "../../components/my_modal/my_modal";
import { StoreModel } from "../../store models/model";
import {
  updateOffer,
  uploadAssetCall,
} from "../../services/api_service/api_service";
import Offer from "../../models/offer";
import Content from "../../models/content";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelBtn from "../../components/model_btn/model_btn";
import LangAndHeaderEditTabs from "../../components/lang_and_text_edit/lang_and_header_edit_tabs";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedOffer: Offer | undefined;
  reloadData: Function;
}
export default function OffersUpdateModel(props: Props) {
  const [selected, setSelected] = useState<Offer>();

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    setSelected(props.selectedOffer);
  }, [props.selectedOffer]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if (selected) {
      updateOffer(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully Updated.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  const iconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const selectedFileType = selectedFile.type.split("/")[0].toUpperCase();

      if (selectedFileType === 'IMAGE') {
        let img = new Image();
        img.src = window.URL.createObjectURL(selectedFile);
        img.onload = () => {
          const height = img.height;
          const width = img.width;

          if (height === 426 && width === 640) {
            uploadAssetCall(selectedFile).then((data) => {
              if (data?.success) {
                const cat = selected;
                if (cat) {
                  cat.mediaUrl = data.data.url;
                  cat.mediaType = selectedFileType;
                  setSelected({ ...cat });
                }
              }
            });
          } else {
            showMessage({
              head: "Failed to upload image!",
              body: "Selected image is not 640px * 426px",
            });
          }
        }
      }
      else {
        uploadAssetCall(selectedFile).then((data) => {
          if (data?.success) {
            const cat = selected;
            if (cat) {
              cat.mediaUrl = data.data.url;
              cat.mediaType = selectedFileType;
              setSelected({ ...cat });
            }
          }
        });
      }

    }
    // if (event.target.files.length > 0) {
    //   const selectedFile = event.target.files[0];
    //   const selectedFileType = selectedFile.type.split("/")[0].toUpperCase();
    //   uploadAssetCall(selectedFile).then((data) => {
    //     if (data?.success) {
    //       const cat = selected;
    //       if (cat) {
    //         cat.mediaUrl = data.data.url;
    //         cat.mediaType = selectedFileType;
    //         setSelected({ ...cat });
    //       }
    //     }
    //   });
    // }
  };

  const contentChange = (content: Content[]) => {
    const off = selected;
    if (off) {
      off.content = content;
      setSelected({ ...off });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Update Offer"}
      setShow={props.setShow}
    >
      <div>
        {selected && (
          <>
            <IconUploadBtn
              mediaUrl={selected.mediaUrl}
              mediaType={selected.mediaType}
              onUpload={iconUpload}
              allowVideo={true}
              height={120}
              isEditable={true}
            />
            <p className={classes.imageSizeText}>
              This image should be 640px * 426px
            </p>
          </>
        )}
        {selected && (
          <LangAndHeaderEditTabs
            data={selected.content}
            isEditable={true}
            label={"Content"}
            setData={contentChange}
          />
        )}

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
