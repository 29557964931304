import React, { useCallback, useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import BloodDonation from "../../models/blood_donation";
import {
  getBloodDonationListWithFilter,
  getWilayaCities,
  getWilayaListCall,
} from "../../services/api_service/api_service";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../services/route_name/route_name_objects";
import BloodDonationTable from "./blood_donation_table";
import useStyles from "./blood_donation_styles";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import { Box, Card, MenuItem, TextField } from "@material-ui/core";
import WilayaCity from "../../models/wilaya_city";
import Wilaya from "../../models/wilaya";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import dateFormat from "dateformat";
import { CalendarToday } from "@material-ui/icons";
import ModelBtn from "../../components/model_btn/model_btn";

export default function BloodDonationPage() {
  const getLastMonthDate = (date: Date) => {
    const thisMonth = date.getMonth();
    if (thisMonth === 0) {
      date.setFullYear(date.getFullYear() - 1);
      date.setMonth(11);
    } else {
      date.setMonth(date.getMonth() - 1);
    }
    return date;
  };

  const classes = useStyles();

  const [isCitiesLoaded,setIsCitiesLoaded] = useState(false)

  const [city, setCity] = useState("");
  const [cityList, setCityList] = useState<WilayaCity[]>([]);
  const [wilaya, setWilaya] = useState("");
  const [wilayaList, setWilayaList] = useState<Wilaya[]>([]);
  const [bg, setBg] = useState("");
  const [gender, setGender] = useState("");
  const [startDate, setStartDate] = useState(getLastMonthDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [isShowDatePicker, setShowPicker] = useState(false);

  const bgList = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  const genderList = [
    { name: "Male", value: "M" },
    { name: "Female", value: "F" },
  ];

  const [bloodDonations, setBloodDonations] = useState<BloodDonation[]>([]);
  const getBloodDonation = useCallback(() => {
    getBloodDonationListWithFilter(
      city,
      wilaya,
      bg,
      gender,
      dateFormat(startDate, "yyyy-mm-dd HH:MM:ss"),
      dateFormat(endDate, "yyyy-mm-dd HH:MM:ss")
    ).then((data) => {
      if (data?.success) {
        const off: BloodDonation[] = [];
        data.data.forEach((x: any) => off.push(new BloodDonation(x)));
        setBloodDonations(off);
      }
    });
  }, [city, wilaya, bg, gender, startDate, endDate]);

  const getAllCities = () => {
    getWilayaCities().then((data) => {
      if (data?.success) {
        const cit: WilayaCity[] = [];
        data.data.forEach((x: any) => cit.push(new WilayaCity(x)));
        setCityList(cit);
      }
    });
  };

  const getWilayaList = () => {
    getWilayaListCall().then((data) => {
      if (data?.success) {
        const cit: Wilaya[] = [];
        data.data.forEach((x: any) => cit.push(new Wilaya(x)));
        setWilayaList(cit);
      }
    });
  };

  const handleSelect = (ranges: any) => {
    setShowPicker(false);
    setStartDate(ranges.range1.startDate);
    setEndDate(ranges.range1.endDate);
  };

  useEffect(() => {
    if (!isCitiesLoaded) {
      getAllCities();
      getWilayaList();
      setIsCitiesLoaded(true)
    }

    getBloodDonation();
    getLastMonthDate(new Date());
  }, [getBloodDonation,isCitiesLoaded]);

  const filter = (
    <div className={classes.row}>
      <div className={classes.filterRow1}>
        <TextField
          label="City"
          variant="outlined"
          value={city ? city : "empty"}
          onChange={(event) =>
            setCity(event.target.value === "empty" ? "" : event.target.value)
          }
          select
          className={classes.selectField}
        >
          <MenuItem value={"empty"}>{"All"}</MenuItem>
          {cityList.map((c, index) => (
            <MenuItem key={index} value={c.name[0].text}>
              {c.name[0].text}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Wilaya"
          variant="outlined"
          value={wilaya ? wilaya : "empty"}
          onChange={(event) =>
            setWilaya(event.target.value === "empty" ? "" : event.target.value)
          }
          select
          className={classes.selectField}
        >
          <MenuItem value={"empty"}>{"All"}</MenuItem>
          {wilayaList.map((c, index) => (
            <MenuItem key={index} value={c.name[0].text}>
              {c.name[0].text}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Blood Group"
          variant="outlined"
          value={bg ? bg : "empty"}
          onChange={(event) =>
            setBg(event.target.value === "empty" ? "" : event.target.value)
          }
          select
          className={classes.selectField}
        >
          <MenuItem value={"empty"}>{"All"}</MenuItem>
          {bgList.map((c, index) => (
            <MenuItem key={index} value={c}>
              {c}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Gender"
          variant="outlined"
          value={gender ? gender : "empty"}
          onChange={(event) =>
            setGender(event.target.value === "empty" ? "" : event.target.value)
          }
          select
          className={classes.selectField}
        >
          <MenuItem value={"empty"}>{"All"}</MenuItem>
          {genderList.map((c, index) => (
            <MenuItem key={index} value={c.value}>
              {c.name}
            </MenuItem>
          ))}
        </TextField>
        <div style={{ width: "260px" }}>
          <div
            className={classes.dateShow}
            onClick={() => {
              setShowPicker(!isShowDatePicker);
            }}
          >
            <CalendarToday />
            <Box width={10} />
            <div>{`${dateFormat(startDate, "mmm dd, yyyy")} - ${dateFormat(
              endDate,
              "mmm dd, yyyy"
            )}`}</div>
          </div>
          {isShowDatePicker && (
            <Card elevation={5} className={classes.datePicker}>
              <DateRangePicker
                ranges={[
                  {
                    startDate: startDate,
                    endDate: endDate,
                  },
                ]}
                onChange={handleSelect}
              />
            </Card>
          )}
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <AddItemBtn
          width={100}
          title="Search"
          onClick={() => {
            getBloodDonation();
          }}
        />
        <div>
          <ModelBtn
            height={40}
            width={100}
            title="Reset"
            onClick={() => {
              setCity("");
              setWilaya("");
              setBg("");
              setGender("");
              setStartDate(getLastMonthDate(new Date()));
              setEndDate(new Date());
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.bloodDonation}
      parentRoutes={[routeNameObjects.services]}
    >
      {filter}
      {bloodDonations.length > 0 && (
        <BloodDonationTable bloodDonations={bloodDonations} />
      )}
    </CommonPage>
  );
}
