import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import useStyles from "./notification_template_style";
import NotificationTemplateGridItems from "./notification_template_grid_item"
import NotificationModel from "../../models/notification";
import {
    sendNotification, getMessageTemplate
} from "../../services/api_service/api_service";
import { Radio, RadioGroup } from "@material-ui/core";

interface Props {
    onChange: Function
}
export default function TemplatesGrid(props: Props) {
    const classes = useStyles();
    const [templates, setTemplates] = useState<NotificationModel[]>([]);

    const getTemplates = () => {
        getMessageTemplate().then((data) => {
            if (data?.success) {
                const notificationTemplates: NotificationModel[] = [];
                data?.data.forEach((x: any) => notificationTemplates.push(new NotificationModel(x)));
                setTemplates(notificationTemplates);
            }
        });
    };

    useEffect(() => {
        getTemplates()
    }, []);

    const handleChange = (event: any) => {
        const data = event.target.value
        if (data) {
            const selectedItem = templates.filter(tem => tem.id === data)
            if(selectedItem.length){
                props.onChange(selectedItem[0])
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={handleChange}
                >
                    {templates.map((value, index) => (
                        <NotificationTemplateGridItems
                            key={index}
                            messageTemplates={value}
                            reloadData={getTemplates}
                        />
                    ))}
                </RadioGroup>

            </Grid>
        </Grid>
    );
}
