import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import tableStyles from "../../../common_classes/table";
import clsx from "clsx";
import ModelBtn from "../../../components/model_btn/model_btn";
import cloneDeep from "lodash.clonedeep";
import useStyles from "../question_style";
import { Switch } from "@material-ui/core";
import { questionTypeColors } from "../question_data";
import QuizSessionModel from "../../../models/quiz_Session";
import dateFormat from "dateformat";
import MyNavigator from "../../../services/navigation";
import SessionName from "../../../models/sessionName";
import { updateQuizSessionStatus } from "../../../services/api_service/api_service";

//   import QuestionsViewModel from "./questions_view_modal";
//   import MyNavigator from "../../services/navigation"; 

interface Props {
  quizSession: QuizSessionModel[];
  reloadData: Function;
  editOpen: Function
}

export default function QuizSessionTable(props: Props) {
  const tableStyle = tableStyles();
  const classes = useStyles()
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [isShowViewModel, setShowViewModel] = useState(false);
  const { pushNamed } = MyNavigator()

  const viewQuestions = (id: string) => {
    props.editOpen(id)
  };

  const getName = (items: SessionName[]) => {
    let message = items.find(item => item.language === "EN")
    return message?.message
  }
  const updateStatus = (id: string, status: string) => {
    let newStatus: string = status === "ACTIVE" ? "DEACTIVE" : "ACTIVATE"
    updateQuizSessionStatus(id, newStatus).then((data) => {
      console.log("data", data)
      if (data?.success) {
      }
    });
  }
  
  console.log("getName", getName)
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Quiz Name</TableCell>
            <TableCell className={tableStyle.header}>Quiz Date</TableCell>
            <TableCell className={tableStyle.header}>Time From</TableCell>
            <TableCell className={tableStyle.header}>Time To</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Status
            </TableCell>
            <TableCell className={tableStyle.header}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.quizSession.map((item, index) => (
            <TableRow>
              <TableCell>
                {getName(item.name)}
              </TableCell>
              <TableCell>
                {item.date}
              </TableCell>
              <TableCell>
                {item.startTime}
              </TableCell>
              <TableCell>
                {item.endTime}
              </TableCell>
              <TableCell align="center">
                <Switch key={item.id} id={item.id} defaultChecked={item.status === "ACTIVE" ? true : false} onClick={() => updateStatus(item.id, String(item.status))} />
              </TableCell>
              <TableCell align="center">
                <ModelBtn title={"View"} onClick={() => { viewQuestions(item.id) }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
