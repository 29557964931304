import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import AddItemBtn from "./add_button_style";
import QuestionTable from "./question_pool_table";
import UploadItemBtn from "./upload_btn";
import QuestionUploadModel from "./questions_upload_model";
import MyNavigator from "../../services/navigation";
import QuizPoolModel from "../../models/quizPool";
import { getQuizPool } from "../../services/api_service/api_service";
import MyPaginator from "../../components/my_paginator/my_paginator";
import CreateQuestion from "./createQuestions/create_new_questions";
import UpdateQuestion from "./updateQuestions/update_questions";

export default function QuestionPage() {
    const [quizPool, setQuizList] = useState<QuizPoolModel[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [isShowAddModel, setShowAddModel] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [createOpen, setCreateOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editItem, setEditItem] = useState<QuizPoolModel | null>(null)

    const { pushNamed } = MyNavigator()

    const uploadQuestions = () => {
        setShowAddModel(true)
    };

    const getPlans = () => {
    };

    const handleEditOpen = (item: QuizPoolModel) => {
        setEditOpen(true)
        setEditItem(item)
    }

    const handleEditClose = () => {
        setEditOpen(false)
        setEditItem(null)
    } 

    const getQuizPoolList = (page: number) => {
        getQuizPool(page).then((data) => {
            if (data?.success) {
                const d: QuizPoolModel[] = [];
                data.data?.content?.forEach((x: any) => d.push(new QuizPoolModel(x)));
                setQuizList(d);
                setPageCount(data.data?.totalPages);
            }
        });
    };

    const onPageChange = (page: number) => {
        getQuizPoolList(page - 1);
        setCurrentPage(page)
    };

    useEffect(() => {
        getQuizPoolList(0);
    }, []);

    return (
        <CommonPage
            currentRoute={routeNameObjects.questionsPool}
            parentRoutes={[routeNameObjects.questionsPool]}
            title={createOpen ? "Create Question" : editOpen ? "Edit Question" : "Question Pool"}
        >
            {!createOpen && !editOpen && <>
                <UploadItemBtn title="Upload Questions" onClick={uploadQuestions} />
                <AddItemBtn title="Add Questions" onClick={() => {setCreateOpen(true)}} />
                {quizPool.length > 0 && (
                    <QuestionTable quizPool={quizPool} editOpen={handleEditOpen} reloadData={getQuizPool} currentPage={currentPage} />
                )}
                {quizPool.length > 0 && (
                    <MyPaginator pageCount={pageCount} onChange={onPageChange} />
                )}
            </>}

            {createOpen && <CreateQuestion onSubmit={setCreateOpen}/>}
            {editOpen && <UpdateQuestion onSubmit={handleEditClose} item={editItem}></UpdateQuestion>}
            <QuestionUploadModel
                reloadData={getPlans}
                isShow={isShowAddModel}
                setShow={setShowAddModel}
            />
        </CommonPage>

    );
}
