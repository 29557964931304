import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      marginTop: "10px",
      height: "30px",
      overflowY: "hidden",
    },
    root: {
      flexGrow: 1,
    },
    spaceBetween: {
      justifyContent: "space-between",
    },
    name: {
      fontFamily: "unset",
      fontSize: "18px",
      fontWeight: "bold",
      color: myColors.drawerColor,
      width: "200px",
      height: "30px",
      overflowX: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    type: {
      fontSize: "13px",
      color: myColors.tableDataText,
      width: "200px",
      height: "20px",
      overflowX: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    userType: {
      fontSize: "18px",
      fontWeight: "bold",
      color: myColors.drawerColor,
      float: "left",
      border: " 2px solid",
      borderRadius: "5px",
      padding: " 0px 5px",
      marginBottom: "10px",
      transition: "1s",
      cursor: "default",
      margin: "5px",
      backgroundColor: "white",
     
    },
    line: {
      width: "100%",
      margin: "auto",
      height: "0.5px",
      backgroundColor: "#a7a7a766",
    },
    description: {
      margin: "10px 0px",
      height: "55px",
      overflowY: "hidden",
      fontSize: "13px",
      color: myColors.tableDataText,
    },
    contentPadding: { padding: "5px 10px 10px 10px" },
    gridImage: {
      width: "220px",
      height: "160px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      cursor:'pointer'
    },
    gridCard: {
      width: "220px",
      cursor: "default",
      transition: "0.2s",
      "&:hover": {
        backgroundColor: myColors.oddRowColor,
      },
    },
    addIcon: {
      position: "absolute",
      right: "5px",
      top: "4px",
      height: "25px",
      width: "25px",
      border: `2px solid ${myColors.drawerSideBarColor}`,
      backgroundColor: "white",
      color: myColors.drawerSideBarColor,
      "&:hover": {
        backgroundColor: myColors.drawerSideBarColor,
        color: "white",
        border: `2px solid white`,
      },
    },
    newRow: {
      display: "flex",
      flexDirection: "row",
      // border: `2px solid ${myColors.appBarTextColor}`,
      margin: " 5px",
      padding: "3px",
      borderRadius: "100px",
      justifyContent: "space-between",
    },
    nestedRoundItem: {
      borderRadius: " 50px",
      padding: "1px 5px",
      color: "white",
      maxWidth: "50%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflowX: "hidden",
    },
    categoryName: {
      backgroundColor: myColors.drawerSideBarColor,
      transition: "0.5s",
      border: `2px solid ${myColors.drawerSideBarColor}`,
      // "&:hover": {
      //   backgroundColor: "white",
      //   color: myColors.drawerSideBarColor,
      //   border: `2px solid ${myColors.drawerSideBarColor}`,
      // },
    },
    merchatName: {
      backgroundColor: myColors.drawerColor,
      transition: "0.5s",
      border: `2px solid ${myColors.drawerColor}`,
      // "&:hover": {
      //   backgroundColor: "white",
      //   color: myColors.drawerColor,
      //   border: `2px solid ${myColors.drawerColor}`,
      // },
    },
  })
);

export default useStyles;
