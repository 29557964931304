import React, { useEffect, useState } from "react";
import clsx from "clsx";
import WilayaCity, { emptyWilayaCity } from "../../../models/wilaya_city";
import useStyles from './wilaya_city_style';
import MyModal from "../../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelBtn from "../../../components/model_btn/model_btn";
import LangAndText from "../../../models/lang_and_text";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import { addWilayaCity } from "../../../services/api_service/api_service";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData:Function
}
export default function WilayaCityAddModel(props: Props) {

 const [selected,setSelected] = useState(new WilayaCity(emptyWilayaCity))

  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const showMessage = useStoreActions((actions:Actions<StoreModel>)=>actions.message.showMessage)

  useEffect(()=>{
    if(props.isShow){
      setSelected(new WilayaCity(emptyWilayaCity));
    }
    
  },[props.isShow])

  const nameChange = (name:LangAndText[])=>{
    const prev = selected;
    if(prev){
      prev.name = name
      setSelected({...prev})
    }
  }

  const saveModel = ()=>{
    if(selected.name.length === 0 || !selected.name[0].text){
      showMessage({head:'Failed!',body:'Please add name and try again'})
      return
    }

    addWilayaCity(selected).then(data=>{
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new wilaya city.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    })
  }

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Add Wilaya City"}
      setShow={props.setShow}
    >
      <div>
  
      
          <LangAndTextEditTabs
            data={selected.name}
            isEditable={true}
            label={"Name"}
            setData={nameChange}
          />
      

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"Save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
