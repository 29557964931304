import { Card, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "../notification_template_style";
import FadeIn from "react-fade-in";
import { MoreVert } from "@material-ui/icons";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Radio } from "@material-ui/core";
import NotificationModel from "../../../models/notification";
import NotificationTitle from "../../../models/notificationHeader";
import NotificationBody from "../../../models/notificationMessage";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import UpdateMessageTemplateModal from "../Message_Templates/Update_MessageTemplate/update_messageTemplate_modal"
import clonedeep from "lodash.clonedeep";

interface Props {
    value: NotificationModel;
}
export default function MessageTemplateGridItem(props: Props) {
    const classes = useStyles();
    const [isHover, setHover] = useState(false);
    const [isShowUpdateModel, setShowUpdateModel] = useState(false);
    // const [selectedItemToUpdate, setSelectedItemToUpdate] = useState<NotificationModel>()
    const [selectedItemToUpdate, setSelectedItemToUpdate] = useState<NotificationModel>();
    const [selected, setSelected] = useState<NotificationModel | undefined>(undefined);
    

    const UpdateNotification = (item: NotificationModel) => {
        setSelectedItemToUpdate(item)
        setShowUpdateModel(true)
    };

    const getTitleEN = (items: NotificationTitle[]) => {
        let title = items.find(item => item.language === "EN")
        return title?.text
    }

    const getTitleFR = (items: NotificationTitle[]) => {
        let title = items.find(item => item.language === "FR")
        return title?.text
    }

    const getTitleAR = (items: NotificationTitle[]) => {
        let title = items.find(item => item.language === "AR")
        return title?.text
    }

    const getBodyEN = (items: NotificationBody[]) => {
        let title = items.find(item => item.language === "EN")
        return title?.text
    }

    const getBodyFR = (items: NotificationBody[]) => {
        let title = items.find(item => item.language === "FR")
        return title?.text
    }

    const getBodyAR = (items: NotificationBody[]) => {
        let title = items.find(item => item.language === "AR")
        return title?.text
    }
    
    return (
        <Grid item>
            <Card
                className={classes.messageTemplateGridCard}
            >
                <div className="row">
                    <p style={{ color: "#808080", fontWeight: "bold" }}>{props.value.templateName}</p>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <p style={{ fontWeight: "bold" }}>EN</p>
                    </div>
                    <div className="col-md-11">
                        <input type="text" value={getTitleEN(props.value.title)} className="form-control" placeholder="Title"></input>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <textarea className="form-control" value={getBodyEN(props.value.body)} rows={3}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-auto">
                        <p style={{ fontWeight: "bold" }}>FR</p>
                    </div>
                    <div className="col-md-11">
                        <input type="text" value={getTitleFR(props.value.title)} className="form-control" placeholder="Title"></input>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <textarea className="form-control" value={getBodyFR(props.value.body)} rows={3}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-auto">
                        <p style={{ fontWeight: "bold" }}>AR</p>
                    </div>
                    <div className="col-md-11">
                        <input type="text" value={getTitleAR(props.value.title)} className="form-control" placeholder="Title"></input>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <textarea className="form-control" value={getBodyAR(props.value.body)} rows={3}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <AddItemBtn title="Update" onClick={() => UpdateNotification(props.value)} />
                    </div>
                </div>
            </Card>
            <UpdateMessageTemplateModal
                reloadData={() => { }}
                isShow={isShowUpdateModel}
                setShow={setShowUpdateModel}
                selectedTemplate={selectedItemToUpdate}
            />
        </Grid>
    );
}
