import { Actions, useStoreActions, useStoreState } from "easy-peasy";
import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import MyDialogBox from "../components/dialog_box/dialog_box";
import MyLoader from "../components/my_loader/my_loader";
import KeyCloakService from "../services/keycloak/keycloak_service";
import { RouteNames } from "../services/route_name/route_names";
import { getRouteUser } from "../services/route_name/route_users";
import { StoreModel } from "../store models/model";

export default function Routings() {
  const { checkKeyCloak, keycloak } = KeyCloakService();

  const authUser = useStoreState(
    (state: StoreModel) => state.keyCloakUserModel.authUser
  );

  const isReLogin = useStoreState(
    (state: StoreModel) => state.loader.isReLogin
  );
  const setReLogin = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.setReLogin
  );

  if (isReLogin) {
    setReLogin(false);
    checkKeyCloak();
  }

  const routeUser = getRouteUser(authUser);

  return keycloak.keycloak &&
    keycloak.authenticated &&
    authUser &&
    authUser.isMerchant !== undefined ? (
    <Router>
      <Switch>
        <Fragment>
          <Redirect from="/" exact to={routeUser.firstRoute} />
          {routeUser.routes.map((route, index) => (
            <MyRoute route={route} key={index} />
          ))}
          <Redirect from="*" to={routeUser.firstRoute} />
        </Fragment>
      </Switch>
      <MyLoader />
      <MyDialogBox />
    </Router>
  ) : (
    <div></div>
  );
}

interface MyRouteProps {
  route: RouteNames;
}

function MyRoute(props: MyRouteProps) {
  return (
    <div>
      {props.route.component ? (
        <Route
          exact
          path={props.route.route}
          component={props.route.component}
        />
      ) : (
        <div />
      )}
      {props.route.children.map((nested, index) => (
        <MyRoute key={index} route={nested} />
      ))}
    </div>
  );
}
