import LangAndText from "./lang_and_text";

class City {
  id: string;
  name: LangAndText[] = [];
  constructor(data: any) {
    this.id = data.id;
    data.name.forEach((x: any) => this.name.push(new LangAndText(x)));
  }
}

export default City;
