import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import clsx from 'clsx'
import tableStyles from "../../common_classes/table";
import SimEligible from "../../models/sim_eligible";

interface Props {
  data: SimEligible[];
}
export default function SimEligibleTable(props: Props) {
 
  const tableStyle = tableStyles();

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Id</TableCell>
            <TableCell className={tableStyle.header}>Msisdn</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((item, index) => (
            <TableRow className={clsx(index % 2 === 1 && tableStyle.oddRowColor)} hover key={index}>
              <TableCell className={tableStyle.tableData}>
                {item.id}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.msisdn}
              </TableCell>
              
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
