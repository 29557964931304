class Asset {
  assetName:string
  version:string
  type:string
  ios: AssetSub;
  android: AssetSub;

  constructor(data: any) {
    this.assetName=data.assetName
    this.version=data.version
    this.type=data.type
    this.ios = new AssetSub(data.ios);
    this.android = new AssetSub(data.android);
  }
}

class AssetSub {
  url: string;
 
  constructor(data: any) {
    this.url = data.url;
  }
}

export const emptyAsset = {
  assetName:'',
  version:'',
  type:'',
  ios: {
    url: undefined,
  },
  android: {
    url: undefined
  },
}

export default Asset;
