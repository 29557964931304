import { carouselTypes } from "../pages/carousel/carousel_data";

class CarouselModel {
  id: string;
  bannerUrl: string;
  name: string;
  type: string;
  active: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.bannerUrl = data.bannerUrl;
    this.name = data.name;
    this.type = data.type;
    this.active = data.active;
  }
}

export const emptyCarousel = {
  bannerUrl: "",
  name: "",
  type: carouselTypes[0],
  active: false,
};

export default CarouselModel;
