import React from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";

export default function GamificationPage() {
  return (
    <CommonPage
      currentRoute={routeNameObjects.gamification}
      parentRoutes={[routeNameObjects.gamification]}
    >
    </CommonPage>
  );
}
