import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import clsx from 'clsx'
import tableStyles from "../../../common_classes/table";
import ModelBtn from "../../../components/model_btn/model_btn";
import WilayaCountry from "../../../models/wilaya_country";
import WilayaCountryModel from "./wilaya_country_model";

interface Props {
  countries: WilayaCountry[];
  reloadData: Function;
}
export default function WilayaCountryTable(props: Props) {
  const [isShow, setShow] = useState(false);
  const [selected, setSelected] = useState<WilayaCountry>();
 
 
  const tableStyle = tableStyles();

  const onView = (country: WilayaCountry) => {
    setSelected(country);
    setShow(true);
  };

  
  
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Country Name</TableCell>
            <TableCell
              className={tableStyle.header}
              align="center"
              width="200px"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.countries.map((item, index) => (
            <TableRow className={clsx(index % 2 === 1 && tableStyle.oddRowColor)} hover key={index}>
              <TableCell className={tableStyle.tableData}>
                {item.name[0]?.text}
              </TableCell>
              
              <TableCell width={220} align="center">
                <ModelBtn title={"View"} onClick={() => onView(item)} />
                
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <WilayaCountryModel isShow={isShow} setShow={setShow} selected={selected} />
    </TableContainer>
  );
}
