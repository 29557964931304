import LangAndText from "./lang_and_text";

class FinekType {
  id: string;
  name: LangAndText[] = [];
  typeId: string;
  imageUrl:string;
  description: LangAndText[] = [];

  constructor(data: any) {
    this.id = data.id;
    data.name.forEach((x: any) => this.name.push(new LangAndText(x)));
    this.typeId = data.typeId;
    data.description.forEach((x: any) => this.description.push(new LangAndText(x)));
    this.imageUrl = data.imageUrl
  }
}

export default FinekType;
