import React, { useEffect, useState } from "react";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import CommonPage from "../../components/common_page/common_page";
import Offer from "../../models/offer";
import { getOffersList } from "../../services/api_service/api_service";
import routeNameObjects from "../../services/route_name/route_name_objects";
import OffersAddModel from "./offers_add_model";
import OffersTable from "./offers_table";

export default function OffersPage() {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);

  const getOffers = () => {
    getOffersList().then((data) => {
      if (data?.success) {
        const off: Offer[] = [];
        data.data.forEach((x: any) => off.push(new Offer(x)));
        console.log('Re Loaded')
        setOffers(off);
      }
    });
  };

  useEffect(() => {
    getOffers();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage currentRoute={routeNameObjects.offers} parentRoutes={[routeNameObjects.offers]}>
      <AddItemBtn title="Add New Offer" onClick={showAddModel} />
      {offers.length > 0 && (
        <OffersTable offers={offers} reloadData={getOffers} />
      )}
      <OffersAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getOffers}
      />
    </CommonPage>
  );
}
