import MyWorldPlugin from "./my_world_plugin";

export default class MyWorldSuperPlan {
  id: string;
  price: number;
  exPoints: number;
  name: string;
  planName: string;
  bundleOfferId: number;
  logoUrl: string;
  status: string;
  validity: string;
  limitedPluginsData: { plan: MyWorldPlugin; amount: number }[] = [];
  unLimitedPluginsData: MyWorldPlugin[] = [];
  limitedPlugins: { pluginId: string; amount: number }[] = [];
  unlimitedPlugins: string[] = [];

  constructor(data: any) {
    this.id = data.id;
    this.price = data.price;
    this.exPoints = data.exPoints;
    this.name = data.name;
    this.planName = data.name;
    this.bundleOfferId = data.bundleOfferId;
    this.logoUrl = data.logoUrl;
    this.status = data.status;
    this.validity = data.validity;

    data.limitedPlugins?.forEach((x: any) => {
      this.limitedPluginsData.push({
        plan: new MyWorldPlugin(x.plan),
        amount: x.value,
      });
      this.limitedPlugins.push({ pluginId: x.plan.id, amount: x.value });
    });
    data.unLimitedPlugins?.forEach((x: any) => {
      this.unlimitedPlugins.push(x.id);
      this.unLimitedPluginsData.push(new MyWorldPlugin(x));
    });
    
  }
}

export const emptySuperPlan = {
  id: undefined,
  price: undefined,
  exPoints: undefined,
  name: undefined,
  bundleOfferId: undefined,
  logoUrl: undefined,
  status: undefined,
  validity: undefined,
};
