import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import useStyles from "./merchant_promotion_styles";
import PartnerPromotion from "../../../models/partner_promotion";
import PartnerPromotionCard from "../../../components/partner_promotion_card/partner_promotion_card";
import PartnerPromotionModel from "../../partners/partner_promotions/partner_promotion_model";

interface Props {
  promotions: PartnerPromotion[];
}
export default function MerchantPromotionsGrid(props: Props) {
  const classes = useStyles();

  const [showModel, setShowModel] = useState(false);

  const [selectedItem, setSelectedItem] = useState<PartnerPromotion>();

  const onView = (item: PartnerPromotion) => {
    setSelectedItem(item);
    setShowModel(true);
  };

  
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="flex-start" spacing={2}>
          {props.promotions.map((value, index) => (
            <PartnerPromotionCard
              key={index}
              value={value}
              onView={onView}
            />
          ))}
        </Grid>
      </Grid>

      <PartnerPromotionModel
        isShow={showModel}
        setShow={setShowModel}
        selectedPromotion={selectedItem}
      />
    </Grid>
  );
}
