import React, { useEffect, useState } from "react";
import useStyles from "../questions/question_style";
import MyModal from "../../components/my_modal/my_modal";
import { Button } from "@material-ui/core";
import { StoreModel } from "../../store models/model";
import { Actions, useStoreActions } from "easy-peasy";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import MyNavigator from "../../services/navigation";
import { uploadPubGFile } from "../../services/api_service/api_service";

interface Props {
    isShow: boolean;
    setShow: Function;
}
export default function PubGFileUploadModel(props: Props) {
    const classes = useStyles();
    const [selectFile, setSelectedFile] = useState();

    const { pushNamed } = MyNavigator()
    const pushNotification = () => {
        pushNamed("/notification")
    };

    const closeModal = () => {
        props.setShow(false);
    };

    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    let selectedFile = useState("")
    const handleInputChange = (event: any) => {
        if (event.target.files.length > 0) {
            selectedFile = event.target.files[0];
            // props.parentCallback(event.target.files[0])
        }
        // console.log("dd", props.parentCallback)
    }

    const csvUpload = () => {
        uploadPubGFile(selectedFile).then((data) => {
            if (data?.success) {
                showMessage({ head: 'Success', body: 'You have successfully added CSV file' })
            }
        });
    };

    return (
        <MyModal
            isShow={props.isShow}
            onClose={null}
            title={"Upload File"}
            setShow={props.setShow}
        >
            <div className="row">
                <div className="col-auto">
                    <Button variant="contained" component="label">
                        <input type="file" onChange={handleInputChange} />
                    </Button>
                </div>
                <div className="col-auto">
                    <AddItemBtn title="Upload" onClick={csvUpload} />
                </div>
                {/* <div className="col-auto">
                    <a href="" onClick={downloadFile}>Download sample file</a>
                </div> */}
            </div>
        </MyModal>
    );
}