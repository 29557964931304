export const emptyUserDetails: UserDetails = {
    userName: "",
    msisdn: "",
    userLevel: 0,
    authenticationId: "",
    experiencePoint: 0,
    earnCoins: 0,
    memberRefId: null,
    active: false,
    userMerchandises: null,
    memberReferenceId: "",
    themeId: null,
    ooredooUser: false,
    wilayaId: null,
    digitalBundles: [],
    merchandiseList: [],
    mgmFullList: [],
};

class UserDetails {
    userName: string;
    msisdn: string;
    userLevel: number;
    authenticationId: string;
    experiencePoint: number;
    earnCoins: number;
    memberRefId: null;
    active: boolean;
    userMerchandises: null;
    memberReferenceId: string;
    themeId: null;
    ooredooUser: boolean;
    wilayaId: null;
    digitalBundles: any[];
    merchandiseList: any[];
    mgmFullList: any[];

    constructor(data: any) {
        this.userName = data.userName;
        this.msisdn = data.msisdn;
        this.userLevel = data.userLevel;
        this.authenticationId = data.authenticationId;
        this.experiencePoint = data.experiencePoint;
        this.earnCoins = data.earnCoins;
        this.memberRefId = data.memberRefId;
        this.active = data.active;
        this.userMerchandises = data.userMerchandises;
        this.memberReferenceId = data.memberReferenceId;
        this.themeId = data.themeId;
        this.ooredooUser = data.ooredooUser;
        this.wilayaId = data.wilayaId;
        this.digitalBundles = data.digitalBundles;
        this.merchandiseList = data.merchandiseList;
        this.mgmFullList = data.mgmFullList;
    }
}

export default UserDetails