import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  addBtn: {
    position: "fixed",
    right: "30px",
    bottom: "30px",
    backgroundColor: "#1a3248",
    color: "white",
  },
  textField: {
    margin: "5px 10px",
  },
  modelBtn: {
    marginRight: "3px",
  },
  addOuter: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  right: {
    paddingRight: "10px",
    float: "right",
  },
});
export default useStyles;
