import { Button } from "@material-ui/core";
import React from "react";
import useStyles from "./model_btn_style";

interface Props {
  title: string;
  onClick?: Function;
  width?: any;
  height?:any;
  disabled?:boolean;
}
export default function ModelBtn(props: Props) {
  const classes = useStyles();
  return (
    <Button
    style={{width:props.width?props.width:'90px', height:props.height?props.height:'unset'}}
      className={classes.btn}
      onClick={() => {
        props.onClick && props.onClick();
      }}
      disabled={props.disabled ? props.disabled : false}
    >
      {props.title}
    </Button>
  );
}
