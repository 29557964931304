import React, { useState, useEffect } from "react";
import CommonPage from "../../../components/common_page/common_page";
import EligibleLevel from "../../../models/eligible_level";
import { getFinekLevelsCall } from "../../../services/api_service/api_service";
import FinekLevelsTable from "./finek_levels_table";
import FinekLevelAddModel from "./finek_level_add_model";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../../services/route_name/route_name_objects";

export default function FinekLevelsPage() {
  const [levels, setLevels] = useState<EligibleLevel[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const getLevels = () => {
    getFinekLevelsCall().then((data) => {
      if (data?.success) {
        const lvls: EligibleLevel[] = [];
        data.data.forEach((x: any) => lvls.push(new EligibleLevel(x)));
        setLevels(lvls);
      }
    });
  };

  useEffect(() => {
    getLevels();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.avatarLevels} parentRoutes={[routeNameObjects.avatar]}>
      <AddItemBtn title="Add New Level" onClick={showAddModel} />
      {levels.length > 0 && <FinekLevelsTable levels={levels} reloadData={getLevels} />}
      <FinekLevelAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getLevels}
      />
    </CommonPage>
  );
}
