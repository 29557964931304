import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../../../services/colors";


const dataCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 'auto',
      width: "100%",
      marginTop: 10,
      padding: 10,
    },
    header: {
      textAlign: "center",
      fontSize: "13px",
      textTransform: "capitalize",
      fontWeight: 'bold',
    },
    amount: {
      textAlign: "center",
      fontSize: "30px",
      fontWeight: 'bold',
      marginTop: 10,
      marginBottom: 10,
    }
  })
);

export default dataCardStyles;
