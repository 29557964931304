import React, { useState, useEffect } from "react";
import CommonPage from "../../../components/common_page/common_page";
import { getUserCategoriesCall } from "../../../services/api_service/api_service";
import UserCategory from "../../../models/user_category";
import UserCategoryTable from "./user_category_table";
import UserCategoryAddModel from "./user_category_add_model";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import nestedRouteNameObject2 from "../../../services/route_name/nested_route_name_objects2";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../../services/route_name/nested_route_name_objects1";

export default function UserCategoriesPage() {
  const [userCategories, setCategories] = useState<UserCategory[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);

  const getCategories = () => {
    getUserCategoriesCall().then((data) => {
      if (data?.success) {
        const ct: UserCategory[] = [];
        data.data.forEach((x: any) => ct.push(new UserCategory(x)));
        setCategories(ct);
      }
    });
  };
  useEffect(() => {
    getCategories();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };

  return (
    <CommonPage currentRoute={nestedRouteNameObject2.userCategories} parentRoutes={[routeNameObjects.services,nestedRouteNameObject1.partners]}>
      <AddItemBtn width={210} title='Add New User Category' onClick={showAddModel} />
      {userCategories.length > 0 && <UserCategoryTable Categories ={userCategories} reloadData={getCategories} />}
     
      <UserCategoryAddModel isShow={isShowAddModel} setShow={setShowAddModel} reloadData={getCategories}/>
    </CommonPage>
  );
}
