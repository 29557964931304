import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles({
  tabSelected: { backgroundColor: myColors.drawerSideBarColor },
  tabStyle: {
    color: myColors.appBarColor,
    fontSize: "12px",
    fontWeight: "bold",
  },
  listPadding: {
    padding: '12px',
    overflowY: 'auto'
  }, spacer: { width: "100%", height: "55px" }
});

export default useStyles;
