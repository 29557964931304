import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import SimPickupAddModel from "./sim_pickup_add_model";
import SimEligible from "../../models/sim_eligible";
import { getEligibilityByMobile, getSimEligibleListCall } from "../../services/api_service/api_service";
import SimEligibleTable from "./sim_pickup_table";
import MyPaginator from "../../components/my_paginator/my_paginator";
import useStyles from "./sim_pickup_page_style";
import clsx from "clsx";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import SearchBar from "../../components/search_bar/search_bar";
import { Alert } from "@material-ui/lab";
import * as FileSaver from 'file-saver';

interface Eligibility {
  eligibility: boolean;
  msisdn: string;
  msisdnValidity: boolean;
}
export default function SimPickupPage() {
  const [isShowAddModel, setShowAddModel] = useState(false);
  const classes = useStyles();

  const [simList, setSimList] = useState<SimEligible[]>([]);
  const [pageCount, setPageCount] = useState(0);

  const [mobileNumber, setmobileNumber] = useState<string>('');
  const [searchedItem, setSearchedItem] = useState<Eligibility | null>(null)

  const getSimEligibleList = (page: number) => {
    getSimEligibleListCall(page).then((data) => {
      if (data?.success) {
        const d: SimEligible[] = [];
        data.data?.content?.forEach((x: any) => d.push(new SimEligible(x)));
        setSimList(d);
        setPageCount(data.data?.totalPages);
      }
    });
  };

  const formatMobile = (mobile: string) => {
    let formatted: string = mobile.replace(/\s/g, '')
    formatted = formatted.split("-").join("")
    return formatted
  };

  const searchEligibilityByMobile = (mobile: string) => {
    setSearchedItem(null)
    getEligibilityByMobile(formatMobile(mobile)).then((data) => {
      if (data?.success) {
        let result: Eligibility = data.data
        setSearchedItem(result)
      }
    });
  };

  const showAddModel = () => {
    setShowAddModel(true);
  };

  const onPageChange = (page: number) => {
    getSimEligibleList(page - 1);
  };

  const handleSearch = (mobile: string) => {
    // let temp: string = '213540100391'
    // let temp: string = '213540999203'

    if (mobile === "clear") {
      setmobileNumber('')
      setSearchedItem(null)
    }
    else {
      if (mobileNumber !== mobile) {
        searchEligibilityByMobile(mobile)
      }
    }

  };


  const handleExport = async () => {
    const fileName = `Sample Eligibility List.csv`
    const csvFile = new Blob(['msisdn,\n213540999203,\n213562000189'], { type: 'text/csv;charset=utf-8;' })
    FileSaver.saveAs(csvFile, fileName);

  };

  useEffect(() => {
    getSimEligibleList(0);
  }, []);
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.simPickup}
      parentRoutes={[routeNameObjects.settings]}
    >
      <div className={clsx(classes.row, classes.rowAdd)}>
        <div className={classes.subTitle}>Ibiza Eligible Numbers</div>
        <div className={clsx(classes.row)}>
          <div style={{ padding: 5 }}>
            <AddItemBtn title={"Upload Eligibility List"} onClick={showAddModel} />
          </div>
          <div style={{ padding: 5 }}>
            <AddItemBtn title={"Download Sample"} onClick={handleExport} />
          </div>
        </div>

      </div>


      <div style={{ marginTop: 25, marginBottom: 40, justifyContent: "center", display: "flex" }} >
        <SearchBar onSearch={handleSearch} placeholder="Enter Mobile Number" clearEnabled={true} />
      </div>
      {
        searchedItem &&
        <div>
          {searchedItem.msisdnValidity ?
            searchedItem.eligibility ?
              <Alert severity="success" style={{ margin: "20px" }}>MSISDN number : {searchedItem.msisdn} is eligible!!</Alert>
              :
              <Alert severity="error" style={{ margin: "20px" }}>MSISDN number : {searchedItem.msisdn} is not eligible!!</Alert>
            :
            <Alert severity="error" style={{ margin: "20px" }}>MSISDN number : {searchedItem.msisdn} is not a valid number!!</Alert>
          }
        </div>
      }

      {simList.length > 0 && <SimEligibleTable data={simList} />}
      {simList.length > 0 && (
        <MyPaginator pageCount={pageCount} onChange={onPageChange} />
      )}
      <SimPickupAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={() => { }}
      />
    </CommonPage>
  );
}
