import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs, Switch } from "@material-ui/core";
import PageTab from "../../../components/page_tab/page_tab";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import { useStoreActions, Actions } from "easy-peasy";
import useStyles from "../notification_style";
import NotificationModel, { emptyNotificationModel } from "../../../models/notification";
import MyModal from "../../../components/my_modal/my_modal";
import AddNotificationTemplate from "./add_messageTemplate_textField"
import {
    addMessageTemplates,
} from "../../../services/api_service/api_service";
import { StoreModel } from "../../../store models/model";
import AddTemplateName from "./add_templateName"

interface Props {
    isShow: boolean;
    setShow: Function;
    reloadData: Function;
}
export default function AddMessageTemplateModal(props: Props) {
    const classes = useStyles();
    const [selected, setSelected] = useState<NotificationModel>(emptyNotificationModel);
    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    useEffect(() => {
        setSelected(emptyNotificationModel)
    }, []);

    function onSave() {
        if (!selected.templateName) {
            showMessage({
                head: "Failed!",
                body: "Please enter Template Name and try again",
                color: "red",
            });
            return;
        }
        if (!selected.body.filter(item => item.language === "EN")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter English content and try again",
                color: "red",
            });
            return;
        }
        if (!selected.body.filter(item => item.language === "AR")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic content and try again",
                color: "red",
            });
            return;
        }
        if (!selected.body.filter(item => item.language === "FR")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter French content and try again",
                color: "red",
            });
            return;
        }

        if (!selected.title.filter(item => item.language === "EN")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter English content and try again",
                color: "red",
            });
            return;
        }
        if (!selected.title.filter(item => item.language === "AR")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter Arabic content and try again",
                color: "red",
            });
            return;
        }
        if (!selected.title.filter(item => item.language === "FR")[0].text) {
            showMessage({
                head: "Failed!",
                body: "Please enter French content and try again",
                color: "red",
            });
            return;
        }

        if (selected) {
            addMessageTemplates(selected).then((data) => {
                if (data?.success) {
                    showMessage({ head: 'Success', body: 'Successfully Added Message Template' })
                    window.location.reload()
                }
            })
        }
    }

    return (
        <MyModal
            isShow={props.isShow}
            onClose={null}
            title={"Add Notification Templates"}
            setShow={props.setShow}
        >
            <Card className={classes.cardSize}>
                <AddTemplateName data={selected} onChange={setSelected}></AddTemplateName>
                <PageTab
                    tabsData={[
                        {
                            title: "English",
                            component: <AddNotificationTemplate language="EN" data={selected} onChange={setSelected}></AddNotificationTemplate>
                        },
                        {
                            title: "Arabic",
                            component: <AddNotificationTemplate language="AR" data={selected} onChange={setSelected}></AddNotificationTemplate>
                        },
                        {
                            title: "French",
                            component: <AddNotificationTemplate language="FR" data={selected} onChange={setSelected}></AddNotificationTemplate>
                        },
                    ]}
                />
            </Card >
            <div className="row mt-3">
                <div className="col-auto">
                    <AddItemBtn title="Save To Templates" onClick={onSave} />
                </div>
            </div>
        </MyModal>
    );
}



