import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import SummaryTab from "./components/summaryTab/summaryTab";

export default function SummaryPage() {
  return (
    <CommonPage
      currentRoute={routeNameObjects.summaryStats}
      parentRoutes={[routeNameObjects.summaryStats]}
    >
      <SummaryTab />
    </CommonPage>
  );
}
