import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Box } from "@material-ui/core";
import useStyles from "./network_plans_styles";
import clsx from "clsx";
import MyModal from "../../components/my_modal/my_modal";
import NetworkPlans, { emptyNetworkPlans } from "../../models/network_plans";
import {
  saveNetworkPlan,
  uploadAssetCall,
} from "../../services/api_service/api_service";
import { StoreModel } from "../../store models/model";
import { useStoreActions, Actions } from "easy-peasy";
import validityUnits from "./validity_units";
import LangAndText from "../../models/lang_and_text";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import ModelLabel from "../../components/model_label/model_label";
import ModelBtn from "../../components/model_btn/model_btn";
import LangAndTextEditTabs from "../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelFieldWrapper from "../../components/model_field_wrapper/model_field_wrapper";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function NetworkPlanSaveModel(props: Props) {
  const classes = useStyles();

  const [selected, setSelected] = useState<NetworkPlans>(
    new NetworkPlans(emptyNetworkPlans)
  );

  useEffect(() => {
    setSelected(new NetworkPlans(emptyNetworkPlans));
  }, [props.isShow]);
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );
  const closeModal = () => {
    props.setShow(false);
  };

  const nameChange = (x: LangAndText[]) => {
    const prev = selected;
    prev.name = x;
    setSelected({ ...prev });
  };

  const subHeadingChange = (x: LangAndText[]) => {
    const prev = selected;
    prev.subHeading = x;
    setSelected({ ...prev });
  };

  const descriptionChange = (x: LangAndText[]) => {
    const prev = selected;
    prev.description = x;
    setSelected({ ...prev });
  };

  const planIdChange = (event: any) => {
    const prev = selected;
    prev.planId = event.target.value;
    setSelected({ ...prev });
  };

  const priceChange = (event: any) => {
    const prev = selected;
    prev.price = event.target.value;
    setSelected({ ...prev });
  };

  const unitChange = (event: any) => {
    const prev = selected;
    prev.validity.unit = event.target.value;
    setSelected({ ...prev });
  };

  const countChange = (event: any) => {
    const prev = selected;
    prev.validity.count = event.target.value;
    setSelected({ ...prev });
  };

  const onIconUpload = (event: any) => {
    if (event.target.files.length > 0) {
      uploadAssetCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          const value = data.data.url;
          const prev = selected;
          prev.imageUrl = value;
          setSelected({ ...prev });
        }
      });
    }
  };

  const saveBtnClicked = () => {
    if (!selected.imageUrl) {
      showMessage({ head: "Failed!", body: "Please select image." });
      return;
    }
    if (selected.name.length === 0) {
      showMessage({ head: "Failed!", body: "Please enter name." });
      return;
    }
    if (selected.subHeading.length === 0) {
      showMessage({ head: "Failed!", body: "Please enter sub heading." });
      return;
    }
    if (selected.description.length === 0) {
      showMessage({ head: "Failed!", body: "Please enter description." });
      return;
    }
    if (!selected.planId) {
      showMessage({ head: "Failed!", body: "Please enter plan id." });
      return;
    }
    if (!selected.price) {
      showMessage({ head: "Failed!", body: "Please enter price." });
      return;
    }
    if (!selected.validity.unit) {
      showMessage({ head: "Failed!", body: "Please select validity unit." });
      return;
    }
    if (!selected.validity.count) {
      showMessage({ head: "Failed!", body: "Please enter validity count." });
      return;
    }
    selected.planName = selected.name;
    saveNetworkPlan(selected).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "Successfully added new network plan.",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Add Network Plan"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        {selected && (
          <IconUploadBtn
            mediaUrl={selected.imageUrl}
            mediaType={"IMAGE"}
            onUpload={onIconUpload}
            allowVideo={false}
            height={120}
            isEditable={true}
          />
        )}
        <div className={classes.column}>
          <LangAndTextEditTabs
            label="Name"
            data={selected.name}
            setData={nameChange}
            isEditable={true}
          />
          <LangAndTextEditTabs
            label="Sub Heading"
            data={selected.subHeading}
            setData={subHeadingChange}
            isEditable={true}
          />
          <LangAndTextEditTabs
            label="Description"
            data={selected.description}
            setData={descriptionChange}
            isEditable={true}
          />
          <div style={{ height: "10px" }} />
          <ModelFieldWrapper>
            <ModelLabel title={"Other Fields"} noMargin />
            <Box height={10}/>
            <TextField
              variant="outlined"
              className={clsx(classes.textField,classes.fullWidth)}
              value={selected.planId}
              label="Plan Id"
              onChange={planIdChange}
            />
            <Box height={10}/>
            <TextField
              variant="outlined"
              type="number"
              className={clsx(classes.textField,classes.fullWidth)}
              value={selected.price}
              label="Price"
              onChange={priceChange}
              InputProps={{inputProps:{min:0}}}
            />
            <div className={classes.row}>
              <TextField
                variant="outlined"
                className={classes.textField}
                style={{ width: "200px" }}
                value={selected.validity.unit ? selected.validity.unit : ""}
                label="Validity Unit"
                onChange={unitChange}
                select
              >
                {validityUnits.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant="outlined"
                type="number"
                className={classes.textField}
                value={selected.validity.count}
                label="Validity Count"
                onChange={countChange}
                InputProps={{inputProps:{min:0}}}
              />
            </div>
          </ModelFieldWrapper>
        </div>
      </div>

      <div className={clsx(classes.row, classes.right)}>
        <ModelBtn title={"Close"} onClick={closeModal} />
        <ModelBtn title={"save"} onClick={saveBtnClicked} />
      </div>
    </MyModal>
  );
}
