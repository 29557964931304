export default class BloodDonation{
    id:string
    wilaya:string
    city:string
    bg:string
    registrationDate:string
    status:string
    gender:string
    age:number
    mobileNumber:string
    firstName:string
    constructor(data:any){
        this.id=data.id
        this.wilaya=data.wilaya
        this.city=data.city
        this.bg=data.bg
        this.registrationDate=data.registrationDate
        this.status=data.status
        this.gender=data.gender
        this.age=Number(data.age)
        this.firstName=data.firstName
        this.mobileNumber=data.mobileNumber
    }
}