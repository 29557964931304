import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs, Switch } from "@material-ui/core";
import PageTab from "../../../components/page_tab/page_tab";
import AddItemBtn from "../../../components/add_item_btn/add_item_btn";
import { useStoreActions, Actions } from "easy-peasy";
import useStyles from "../notification_style";
import CustomerBaseModel, { emptyCustomerBase } from "../../../models/customerBase";
import MyModal from "../../../components/my_modal/my_modal";
import {
    addMsisdnList, uploadAssetCall
} from "../../../services/api_service/api_service";
import { StoreModel } from "../../../store models/model";
import AddCustomerModal from "./add_customer_modal"

interface Props {
    isShow: boolean;
    setShow: Function;
    reloadData: Function;
}
export default function AddCustomerModalItem(props: Props) {
    const classes = useStyles();
    const [selected, setSelected] = useState<CustomerBaseModel>(emptyCustomerBase);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [listName, setlistName] = useState("");
    const showMessage = useStoreActions(
        (actions: Actions<StoreModel>) => actions.message.showMessage
    );

    const handleCallback = (child: any) => {
        setFile(child)
        setFileName(child.name)
    }

    const handleListName = (childText: any) => {
        setlistName(childText)
    }

    function onSave() {
        if (selected) {
            uploadAssetCall(file).then((data) => {
                if (data?.success) {
                    const value = data.data.url
                    const prev = selected
                    prev.fileUrl = value
                    prev.listName = listName
                    setSelected({ ...prev });
                    addMsisdnList(prev).then((data) => {
                        if (data?.success) {
                            showMessage({ head: 'Success', body: 'Successfully Added Customer Base' })
                            window.location.reload()
                        }
                    })

                }
            });
        }
    }

    useEffect(() => {
        setSelected(emptyCustomerBase)
    }, []);

    return (
        <MyModal
            isShow={props.isShow}
            onClose={null}
            title={"Add Customer Base"}
            setShow={props.setShow}
        >
            <AddCustomerModal data={selected} onChange={setSelected} parentCallbackFile={handleCallback} parentCallBackListName={handleListName}></AddCustomerModal>
            <div className="row mt-3">
                <div className="col-auto">
                    <AddItemBtn title="Save" onClick={onSave} />
                </div>
            </div>
        </MyModal>
    );
}