import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outer:{
        marginTop:'15px',
        float:'right'
    }
  })
);

export default useStyles;
