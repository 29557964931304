import React, { useState, useEffect } from "react";
import { Card } from "@material-ui/core";
import useStyles from "./theme_style";
import clsx from "clsx";
import MyModal from "../../components/my_modal/my_modal";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import Theme from "../../models/theme";
import LangAndTextEditTabs from "../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelLabel from "../../components/model_label/model_label";
import ModelBtn from "../../components/model_btn/model_btn";
import MyColorPicker from "../../components/color_picker/color_picker";
import ModelFieldWrapper from "../../components/model_field_wrapper/model_field_wrapper";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedTheme: Theme | undefined;
}
export default function ThemeModel(props: Props) {
  const [selected, setSelected] = useState<Theme>();

 
  useEffect(() => {
    setSelected(props.selectedTheme);
  }, [props.selectedTheme]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
      title={"Theme"}
      setShow={props.setShow}
    >
      <div>
        {selected && (
          <LangAndTextEditTabs
            data={selected.themeName}
            isEditable={false}
            label={"Theme Name"}
            setData={null}
          />
        )}

        <ModelFieldWrapper>
          <ModelLabel title={"Theme"} noMargin />

          <div
            className={classes.row}
            style={{ justifyContent: "space-around" }}
          >
            <MyColorPicker
              title={"Theme Colour"}
              selectedColor={selected?.theme?.themeColor}
              setColor={null}
              isEditable={false}
            />
            <MyColorPicker
              title={"Theme Dark Colour"}
              selectedColor={selected?.theme?.themeDarkColor}
              setColor={null}
              isEditable={false}
            />
          </div>
        </ModelFieldWrapper>

        <ModelFieldWrapper>
          <ModelLabel
            title={"Background Images"}
            noMargin
          />

          

          <div className={classes.imageRow}>
            {selected?.theme?.backgroundImages.map((image, index) => (
              <Card key={index} elevation={3} className={classes.imageCard}>
                <img
                  height="100px"
                  width="100px"
                  src={image}
                  alt="background"
                />
              </Card>
            ))}
          </div>
        </ModelFieldWrapper>

        <ModelFieldWrapper>
          <ModelLabel title={"Assets"} noMargin />
          {selected?.assets.map((item, index) => (
            <Card key={index} elevation={5} className={classes.assetCard}>
              <div className={classes.assetRow}>
                <div className={classes.assetItem}>
                  <IconUploadBtn
                    mediaUrl={item.ios.url}
                    mediaType={"FILE"}
                    onUpload={null}
                    allowVideo={false}
                    height={70}
                    isEditable={false}
                    allowAnyFile
                  />
                  <div className={classes.boldText}>IOS</div>
                </div>

                <div className={classes.assetItem}>
                  <IconUploadBtn
                    mediaUrl={item.android.url}
                    mediaType={"FILE"}
                    onUpload={null}
                    allowVideo={false}
                    height={70}
                    isEditable={false}
                    allowAnyFile
                  />
                  <div className={classes.boldText}>ANDROID</div>
                </div>
              </div>
            </Card>
          ))}
        </ModelFieldWrapper>

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
