
import React from "react";
import CommonPage from "../../../components/common_page/common_page";
import routeNameObjects from "../../../services/route_name/route_name_objects";
import BloodDonationSearch from "./blood_donation_search";


export default function BloodDonationSearchPage() {


  return (
    <CommonPage
      currentRoute={routeNameObjects.searchBDUsers}
      parentRoutes={[routeNameObjects.searchBDUsers]}
    >
      <BloodDonationSearch/>
    </CommonPage>
  );
}
