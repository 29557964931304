import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  right: {
    paddingRight: "10px",
    float: "right",
  },
  addOuter:{
    width:'300px'
  },
  rowAdd:{
    width:'100%',
    justifyContent:'space-between'
  },
  subTitle:{
    marginTop: '8px',
    color: '#6c6b6b'
  }
});

export default useStyles;
