import MyWorldPlugin from "./my_world_plugin";

export default class MGMPlan {
  id: string;
  exPoints: number;
  bundleId: number;
  bundleCode: string;
  planName: string;
  limitedPluginsData: { plan: MyWorldPlugin; amount: number,unit :string }[] = [];
  unLimitedPluginsData: MyWorldPlugin[] = [];
  limitedPlugins: { pluginId: string; amount: number,unit:string }[] = [];
  unlimitedPlugins: string[] = [];
  validity: string;

  constructor(data: any) {
    this.id = data.id;
    this.exPoints = data.exPoints;
    this.bundleId = data.bundleId;
    this.bundleCode = data.bundleCode;
    this.planName = data.planName;
    data.limitedPlugins?.forEach((x: any) => {
      this.limitedPluginsData.push({
        plan: new MyWorldPlugin(x.plan),
        amount: x.value,
        unit:x.unit
      });
      this.limitedPlugins.push({ pluginId: x.plan.id, amount: x.value,unit:x.unit });
    });
    data.unLimitedPlugins?.forEach((x: any) => {
      this.unlimitedPlugins.push(x.id);
      this.unLimitedPluginsData.push(new MyWorldPlugin(x));
    });
    this.validity = data.validity;
  }
}

export const emptyMGMPlan = {
  id: undefined,
  price: undefined,
  exPoints: undefined,
  bundleId: undefined,
  bundleCode: undefined,
  planName: undefined,
  validity: undefined,
};
