import { Box, Card, TextField } from "@material-ui/core";
import { Actions, useStoreActions } from "easy-peasy";
import React, { useState } from "react";
import ModelBtn from "../../../components/model_btn/model_btn";
import {
  searchBDUserCall,
  updateAsBloodDonatedCall,
} from "../../../services/api_service/api_service";
import { StoreModel } from "../../../store models/model";
import useStyles from "./blood_donation_search_styles";
import FadeIn from "react-fade-in";
import BloodDonation from "../../../models/blood_donation";

export default function BloodDonationSearch() {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");

  const [user, setUser] = useState<BloodDonation>();
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const clearPhoneNumb = ()=>{
    setPhoneNumber('')
  }

  const search = () => {
    if (!phoneNumber) {
      showMessage({ head: "Failed!", body: "Please enter phone number" });
      return;
    }

    searchBDUserCall(phoneNumber).then((data) => {
      if (data?.success) {
        setUser(new BloodDonation(data.data));
      } else {
        setUser(undefined);
      }
    });
  };
  return (
    <div>
      <Box height={"50px"} />
      <div className={classes.searchRow}>
        <TextField
          label="Phone Number"
          variant="outlined"
          className={classes.textField}
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />

        <div className={classes.btnWrapper}>
          <ModelBtn title={"Search"} onClick={search} />
        </div>
      </div>
      <Box height={"50px"} />
      <div>
        {user ? (
          <UserCard user={user} setUser={setUser} mobile={phoneNumber} clear={clearPhoneNumb}/>
        ) : (
          <div className={classes.placeHolder}>Please search for users</div>
        )}
      </div>
    </div>
  );
}

function UserCard(props: {
  user: BloodDonation;
  setUser: Function;
  mobile: string;
  clear:Function
}) {
  const [hover, setHover] = useState(false);
  const classes = useStyles();
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const markAsDonated = () => {
    updateAsBloodDonatedCall(props.mobile).then((data) => {
      props.setUser(undefined)
      props.clear()
      showMessage({ head: "Success!", body: "Updated the user as blood donated user!" });
    });
  };

  return (
    <FadeIn>
      <Card
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        elevation={hover ? 10 : 3}
        className={classes.card}
      >
        <div className={classes.cardNameColumn}>
          <div className={classes.cardMsidn}>{props.mobile}</div>
        </div>
        <ModelBtn
          title={"Mark as Donated"}
          onClick={markAsDonated}
          width={"150px"}
        />
      </Card>
    </FadeIn>
  );
}
