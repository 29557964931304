import Asset, { emptyAsset } from "./Asset"
import LangAndText, { emptyLangAndText } from "./lang_and_text"
import ThemeItem, { emptyThemeItem } from "./theme_item"

export default class Theme {
    id:string
    themeName:LangAndText[] = []
    theme:ThemeItem | undefined
    assets:Asset[]=[]

    constructor(data:any){
        this.id=data.id
        data.themeName.forEach((x:any)=>this.themeName.push(new LangAndText(x)))
        this.theme = data.theme ? new ThemeItem(data.theme):undefined
        data.assets.forEach((x:any)=>this.assets.push(new Asset(x)))
    }
}

export const emptyTheme = {
    themeName:[
        new LangAndText(emptyLangAndText)
    ],
    theme: new ThemeItem(emptyThemeItem),
    assets: [
        new Asset(emptyAsset)
    ]
}