export default class CustomerBase {
    listName: string;
    fileUrl: string;
  
    constructor(data: any) {
      this.listName = data.listName;
      this.fileUrl = data.fileUrl;
    }
  }
  
  export const emptyCustomerBase = {
    listName: "",
    fileUrl: "",
  };
  