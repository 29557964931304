import React, { useState, useEffect } from "react";
import useStyles from "./error_style";
import clsx from "clsx";
import MyModal from "../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelBtn from "../../components/model_btn/model_btn";
import Error from "../../models/error";
import { TextField } from "@material-ui/core";
import LangAndText from "../../models/lang_and_text";
import { updateError } from "../../services/api_service/api_service";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedError: Error | undefined;
  reloadData: Function;
}
export default function ErrorUpdateModel(props: Props) {
  const [selected, setSelected] = useState<Error>();
  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    if (props.isShow) {
      setSelected(props.selectedError);
    }
  }, [props.selectedError, props.isShow]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const codeChange = (event: any) => {
    const prev = selected;
    if (prev) {
      prev.errorCode = event.target.value;
      setSelected({ ...prev });
    }
  };

  const messageChange = (message: LangAndText[]) => {
    const prev = selected;
    if (prev) {
      prev.messages = message;
      setSelected({ ...prev });
    }
  };

  const saveModel = () => {
    if (selected) {
      if (!selected.errorCode) {
        showMessage({
          head: "Failed!",
          body: "Please enter error code",
          color: "red",
        });
        return;
      }
      if (selected.messages.length === 0 || !selected.messages[0]?.text) {
        showMessage({
          head: "Failed!",
          body: "Please enter error message",
          color: "red",
        });
        return;
      }
      updateError(selected).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully Updated Error.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Update Application Errors"}
      setShow={props.setShow}
    >
      <div>
        {selected && (
          <div className={classes.modelOuter}>
            <div className={classes.row}>
              <TextField
                label="Error Code"
                value={selected.errorCode}
                variant="outlined"
                className={classes.textField}
                style={{ width: "100%" }}
                onChange={codeChange}
              />
            </div>

            <LangAndTextEditTabs
              data={selected.messages}
              isEditable={true}
              label={"Error Message"}
              setData={messageChange}
            />
          </div>
        )}

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"Save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
