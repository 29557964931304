class LocationData {
  lat: number;
  lon: number;

  constructor(data: any) {
    this.lat = data.lat;
    this.lon = data.lon;
  }
}

export default LocationData;
