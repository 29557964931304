import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  addBtn: {
    position: "fixed",
    right: "30px",
    bottom: "30px",
    backgroundColor: "#1a3248",
    color: "white",
  },

  modelLabel: {
    width: "500px",
    backgroundColor: "grey",
    color: "white",
    padding: "5px 0px",
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  greyLabel: {
    color: "grey",
  },
  right: {
    paddingRight: "10px",
    float: "right",
  },
  modelBtn: {
    marginRight: "3px",
  },
  smallBtn: {
    padding: "3px",
  },
  addPhotoIcon: {
    height: "120px",
    width: "120px",
    color: "grey",
  },
  mainIcon: {
    height: "150px",
    width: "150px",
    borderRadius: "10px",
    margin: "auto",
    marginBottom: "10px",
    position: "relative",
  },
  iconEdit: {
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: "grey",
    color: "white",
  },
  price: {
    width: "fit-content",
    margin: "auto",
    padding: "5px 10px",
    border: "2px solid grey",
    borderRadius: "5px",
    fontSize: "20px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  root: {
    flexGrow: 1,
  },
});
export default useStyles;
