import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import MyNavigator from "../../services/navigation";
import QuizPoolModel from "../../models/quizPool";
import { getQuizPool } from "../../services/api_service/api_service";
import MyPaginator from "../../components/my_paginator/my_paginator";
import EligibilityMatrixPage from "./viewEligibilityMatrixPage";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import PageTab from "../../components/page_tab/page_tab";
import UpdateEligibilityMatrixPage from "./updateEligibilityMatrix";
import { getMatrix, getMatrixServiceAccountList, updateMatrix, updateMatrixServiceList } from "../../services/api_service/api_service";
import EligibilityMatrixModel from "../../models/EligibiliyMetrix";
import EligibilityMatrixServiceAccountList, { emptyServiceList } from "../../models/EligibiliyMetrixServiceAccountList";


export default function EligibilityMatrix() {
    const [pageCount, setPageCount] = useState(0);
    const [resultArray, setResultArray] = useState<any>([])
    const [matrix, setmatrix] = useState<EligibilityMatrixModel[] | null>(null);
    const [serviceList, setMatrixServiceList] = useState<EligibilityMatrixServiceAccountList[]>([]);

    const { pushNamed } = MyNavigator()

    const getEligibilityMatrix = () => {
        getMatrix().then((data) => {
            const res = data
            if (data?.success) {
                if (data.data) {
                    let d: EligibilityMatrixModel[] = data.data;
                    setmatrix(d);
                    console.log("d", d)
                }
            }
        });
    };

    const getServiceList = (page: number) => {
        getMatrixServiceAccountList(page).then((data) => {
            const res = data
            console.log("data table", data?.data);
            if (data?.success) {
                const d: EligibilityMatrixServiceAccountList[] = [];
                data.data?.forEach((x: any) => d.push(new EligibilityMatrixServiceAccountList(x)));
                setMatrixServiceList(d);
                // setPageCount(data?.data.totalPages)
            }
        });
    };

    const updateEligibilityMatrix = () => {
        updateMatrix(matrix).then((data) => {
            console.log("data", data)
            if (data?.success) {
                console.log("data", data)
            }
        });
    };

    const updateMatrixService = () => {
        updateMatrixServiceList(serviceList).then((data) => {
            console.log("data", data)
            if (data?.success) {
                // props.onSubmit(false)
            }
        });
    };

    const onPageChange = (page: number) => {
        getServiceList(page - 1);
    };

    useEffect(() => {
        getEligibilityMatrix()
        getServiceList(0)
    }, []);

    console.log("d test", serviceList);

    return (
        <CommonPage
            currentRoute={routeNameObjects.eligibilityMatrix}
            parentRoutes={[routeNameObjects.eligibilityMatrix]}
        >
            <PageTab
                tabsData={[
                    {
                        title: "View",
                        component: <EligibilityMatrixPage matrix={matrix} serviceList={serviceList} reloadData={getMatrix}></EligibilityMatrixPage>
                    },
                    {
                        title: "Edit",
                        component: <UpdateEligibilityMatrixPage data={matrix} onChange={setmatrix} reloadData={getServiceList} onChangeServiceList={setMatrixServiceList} updateServiceList={updateMatrixService} onSubmit={updateEligibilityMatrix} serviceListData={serviceList}></UpdateEligibilityMatrixPage>,
                    },
                ]}
            />
        </CommonPage>
    );
}
