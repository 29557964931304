import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import tableStyles from "../../../common_classes/table";
import ModelBtn from "../../../components/model_btn/model_btn";
import clsx from "clsx";
import { removeMyWorldSuperPlan, updateSuperPlanStatus } from "../../../services/api_service/api_service";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import clonedeep from 'lodash.clonedeep';
import MyWorldSuperPlan from "../../../models/my_world_super_plan";
import MyWorldSuperPlanModel from "./my_world_super_plans_model";
import MyWorldSuperPlanViewModel from "./my_world_super_plans_view_model";

interface Props {
  plans: MyWorldSuperPlan[];
  reloadData: Function;
}
export default function MyWorldSuperPlansTable(props: Props) {
  const tableStyle = tableStyles();
  const [showModel, setShowModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MyWorldSuperPlan>();

  const [showViewModel, setShowViewModel] = useState(false);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const showConfMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showConfirmMessage
  );
  const onUpdate = (item: MyWorldSuperPlan) => {
    setSelectedItem(clonedeep(item));
    setShowModel(true);
  };

  const onView = (item: MyWorldSuperPlan) => {
    setSelectedItem(item);
    setShowViewModel(true);
  };

  const onDelete = (id: string) => {
    showConfMessage({
      head: "Are you sure?",
      body: "This action cannot be undone",
      onConfirm: () => {
        removeMyWorldSuperPlan(id).then((data) => {
          console.log("data", data)

          if (data?.success) {
            props.reloadData();
          } else {
            showMessage({
              head: "Failed!",
              body: "Cannot remove plan. Please try again later.",
            });
          }
        });
      },
    });
  };

  const updateStatus = (id: string, status: string) => {
    let newStatus: string = status === "ACTIVE" ? "DEACTIVATE" : "ACTIVATE"
    updateSuperPlanStatus(id, newStatus).then((data) => {
      if (data?.success) {
        props.reloadData();
      } else {
        showMessage({
          head: "Failed!",
          body: "Cannot update status. Please try again later.",
        });
      }
    });
  }
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={tableStyle.header}>Image</TableCell>
            <TableCell className={tableStyle.header}>Name</TableCell>
            <TableCell className={tableStyle.header}>Price</TableCell>
            <TableCell className={tableStyle.header}>Ex Points</TableCell>
            <TableCell className={tableStyle.header}>Bundle Offer Id</TableCell>
            <TableCell className={tableStyle.header}>Validity</TableCell>
            <TableCell className={tableStyle.header}>Status</TableCell>
            <TableCell className={tableStyle.header} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.plans.map((item, index) => (
            <TableRow
              className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
              hover
              key={index}
            >
              <TableCell>
                {item.logoUrl ? (
                  <img
                    height={"50px"}
                    src={item.logoUrl}
                    alt={item.name}
                  />
                ) : (
                  <div style={{ height: "50px" }} />
                )}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.name}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.price}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.exPoints}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.bundleOfferId}
              </TableCell>
              <TableCell className={tableStyle.tableData}>
                {item.validity?.toLowerCase()}
              </TableCell>
              <TableCell
                className={clsx(tableStyle.tableData, tableStyle.colored)}
                onClick={() => { updateStatus(item.id, item.status) }}
              >
                {item.status?.toLowerCase()}
              </TableCell>
              <TableCell align="center">
                <ModelBtn title="View" onClick={() => onView(item)} />
                <ModelBtn title="Update" onClick={() => onUpdate(item)} />
                <ModelBtn title="Delete" onClick={() => onDelete(item.id)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <MyWorldSuperPlanModel
        isShow={showModel}
        setShow={setShowModel}
        selected={selectedItem}
        reloadData={props.reloadData}
      />
      <MyWorldSuperPlanViewModel
        isShow={showViewModel}
        setShow={setShowViewModel}
        selected={selectedItem}
      />
    </TableContainer>
  );
}
