import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs } from "@material-ui/core";
import { TextField, Switch } from "@material-ui/core";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import EligibilityMatrixModel from "../../models/EligibiliyMetrix";
import EligibilityMatrixServiceAccountList from "../../models/EligibiliyMetrixServiceAccountList";

interface Props {
    matrix: EligibilityMatrixModel[] | null;
    serviceList: EligibilityMatrixServiceAccountList[];
    reloadData: Function;
}

export default function EligibilityMatrixPage(props: Props) {
    const tableStyle = tableStyles();
    const [minValue, setMinValue] = useState(0)

    console.log("props data matrix", props.matrix)

    return (
        <div>
            {props.matrix && props.matrix.map(item => {
                if (item.eligibilityCriteria === "AGE_RANGE") {
                    return <div className="row mt-5">
                        <div className="col-auto">
                            <p style={{ fontWeight: "bold" }}>01</p>
                        </div>
                        <div className="col-auto">
                            <p style={{ fontWeight: "bold" }}>Age Range</p>
                        </div>
                        <div className="col-auto">
                            <Switch checked={item.active} />
                        </div>
                    </div>
                }
            })
            }
            {props.matrix && props.matrix.map(item => {
                if (item.eligibilityCriteria === "AGE_RANGE") {
                    return <div className="row">
                        <div className="col-auto">
                            <label className="label-text">From</label>
                            <input type="text" className="form-control" name="subscriptionCode" value={item.minValue}></input>
                        </div>
                        <div className="col-auto">
                            <label className="label-text">To</label>
                            <input type="text" className="form-control" name="subscriptionCode" value={item.maxValue}></input>
                        </div>
                    </div>
                }
            })
            }
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {props.matrix && props.matrix.map((item, index) => {
                                if (item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE") {
                                    return <div className="row mt-2">
                                        <div className="col-auto">
                                            <p style={{ fontWeight: "bold" }}>02</p>
                                        </div>
                                        <div className="col-auto">
                                            <p style={{ fontWeight: "bold" }}>{item.displayName}</p>
                                        </div>
                                        <div className="col-auto">
                                            <Switch checked={item.active} />
                                        </div>
                                    </div>
                                }
                            })}
                            {props.matrix && props.matrix.map((item, index) => {
                                if (item.eligibilityCriteria === "SERVICE_ACCOUNT_RAGE") {
                                    return <div className="row">
                                        <div className="col-auto">
                                            <label className="label-text">From</label>
                                            <input type="text" className="form-control" name="subscriptionCode" value={item.minValue} ></input>
                                        </div>
                                        <div className="col-auto">
                                            <label className="label-text">To</label>
                                            <input type="text" className="form-control" name="subscriptionCode" value={item.maxValue}></input>
                                        </div>
                                    </div>
                                }
                            })}
                            {props.matrix && props.matrix.map((item, index) => {
                                if (item.eligibilityCriteria === "SERVICE_ACCOUNT_LIST") {
                                    return <div className="row mt-4">
                                        <div className="col-auto">
                                            <p style={{ fontWeight: "bold" }}>03</p>
                                        </div>
                                        <div className="col-auto">
                                            <p style={{ fontWeight: "bold" }}>{item.displayName}</p>
                                        </div>
                                        <div className="col-auto">
                                            <Switch checked={item.active} />
                                        </div>
                                    </div>
                                }
                            })}
                            <TableContainer component={Paper}>
                                <Table stickyHeader size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={tableStyle.header}>Account ID</TableCell>
                                            <TableCell className={tableStyle.header}>Account Name</TableCell>
                                            <TableCell className={tableStyle.header}>
                                                Status
                                            </TableCell>
                                            <TableCell className={tableStyle.header}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.serviceList.map((item, index) => (
                                            <TableRow className={clsx(tableStyle.oddRowColor)} hover >
                                                <TableCell>
                                                    {item.accountId}
                                                </TableCell>
                                                <TableCell>
                                                    {item.accountName}
                                                </TableCell>
                                                <TableCell>
                                                    <Switch key={item.id} id={item.id} checked={item.active} />
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
