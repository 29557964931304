import { Pagination } from "@material-ui/lab";
import React from "react";
import useStyles from "./my_paginator_style";

export default function MyPaginator(props: {
  pageCount: number;
  onChange: Function;
}) {
  const classes = useStyles();
  return (
    <div className={classes.outer}>
      <Pagination
        count={props.pageCount}
        shape="rounded"
        onChange={(event, page) => props.onChange(page)}
      />
    </div>
  );
}
