import React, { useEffect, useState } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import BasicUserDetails from '../../models/user_details/basic_user_details'
import UserDetails, { emptyUserDetails, } from "../../models/user_details/user_deatails";
import SubscriberData, { emptySubscriberData, } from "../../models/user_details/subscriber_data";
import UserInformation, { emptyUserInformation, } from "../../models/user_details/user_information";
import { UserDetailsCard } from './components/basic_data_card/CS_basic_data_card'
import AccountData, { emptyAccountData } from "../../models/user_details/account_data";
import AccountDataCard from "./components/account_data_card/account_data_card";
import { BundleDataCard } from "./components/bundle_data_card/bundle_data_card";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginTop: 10,
      textAlign: "center",
      fontSize: "13px",
      fontWeight: "bold",
      backgroundColor: '#244564',
      color: '#d9d9d9',
      textTransform: "capitalize",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
    }
  })
);

interface IProps {
  data: BasicUserDetails;
}

export default function CSbasicData(props: IProps) {
  const useStyle = useStyles()
  const [userDetailsData, setUserDetailsData] = useState<UserDetails>(emptyUserDetails)
  const [userInformationData, setUserInformationData] = useState<UserInformation>(emptyUserInformation)
  const [userSubscriberData, setUserSubscriberData] = useState<SubscriberData>(emptySubscriberData)
  const [userAccountsData, setUserAccountsData] = useState<AccountData[]>([])
  const [userBundleData, setUserBundleData] = useState<Object[]>([])
  const [userDigitalBundleData, setUserDigitalBundleData] = useState<Object[]>([])

  useEffect(() => {
    if (props.data.userDetails) {
      setUserDetailsData(props.data.userDetails)
    }

    if (props.data.userInformation) {
      setUserInformationData(props.data.userInformation)
    }

    if (props.data.subscriberDataResponse) {
      setUserSubscriberData(props.data.subscriberDataResponse)
    }

    if (props.data.subscriberDataResponse && props.data.subscriberDataResponse.account) {
      setUserAccountsData(props.data.subscriberDataResponse.account)
    }
    if (props.data.subscriberDataResponse && props.data.subscriberDataResponse.dynamicBundlesPackage && props.data.subscriberDataResponse.dynamicBundlesPackage.dynamicBundle) {
      setUserBundleData(props.data.subscriberDataResponse.dynamicBundlesPackage.dynamicBundle)
    }

    if (props.data.userDetails && props.data.userDetails.digitalBundles) {
      setUserDigitalBundleData(props.data.userDetails.digitalBundles)
    }
  }, [props.data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={useStyle.header}>
        Account Details
      </Grid>

      <Grid container item xs={12} direction="row" justify="center"
        alignItems="flex-start" spacing={2}>
        {
          userAccountsData && userAccountsData.length > 0 ?
            userAccountsData.map((item, key) => {
              if (item.account) {
                return (
                  <Grid item xs={3} key={key}>
                    <AccountDataCard data={item} />
                  </Grid>
                )
              }
            })
            :
            <div style={{ textAlign: "center", margin: 10 }}>
              No Account Data Available
            </div>
        }
      </Grid>

      <Grid item xs={12} className={useStyle.header}>
        Dynamic Bundles Packages
      </Grid>

      <Grid container item xs={12} direction="row" justify="center"
        alignItems="flex-start" spacing={2}>
        {userBundleData && userBundleData.length > 0 ?
          userBundleData.map((item: Object, key) => {
            if (userBundleData.length !== 0) {
              return (
                <Grid item xs={3} key={key}>
                  <BundleDataCard data={item} />
                </Grid>
              )
            }

          })
          :
          <div style={{ textAlign: "center", margin: 10 }}>
            No Packages Available
          </div>
        }
      </Grid>

      <Grid item xs={12} className={useStyle.header}>
        Digital Bundles
      </Grid>

      <Grid container item xs={12} direction="row" justify="flex-start"
        alignItems="flex-start" spacing={2}>
        {userDigitalBundleData && userDigitalBundleData.length > 0 ?
          userDigitalBundleData.map((item: Object, key) => {
            if (userDigitalBundleData.length !== 0) {
              return (
                <Grid item xs={6} key={key}>
                  <BundleDataCard data={item} />
                </Grid>
              )
            }

          })
          :
          <Grid container item xs={12} direction="row" justify="center"
            alignItems="flex-start">
            <div style={{ textAlign: "center" }}>
              No Packages Available
            </div>
          </Grid>

        }
      </Grid>
      <Grid container item xs={12} direction="row">
        <UserDetailsCard cardData={userDetailsData} title="User Details" />
      </Grid>
      <Grid container item xs={6} direction="column">
        <UserDetailsCard cardData={userInformationData} title="User Information" />
      </Grid>
      <Grid container item xs={6} direction="column">
        <UserDetailsCard cardData={userSubscriberData} title="User Subscriptions" />
      </Grid>
    </Grid>
  );
}
