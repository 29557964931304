import React from "react";
import { Backdrop } from "@material-ui/core";
import { useStoreState } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import HashLoader from "react-spinners/HashLoader";
import useSTyles from "./my_loader_style";
import myColors from "../../services/colors";
export default function MyLoader() {
  const classes = useSTyles();
  const open = useStoreState((state: StoreModel) => state.loader.isOpen);
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <HashLoader size={60} color={myColors.mainColor} loading={open} />
    </Backdrop>
  );
}
