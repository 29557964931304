import React, { useEffect, useState } from "react";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import CommonPage from "../../components/common_page/common_page";
import Theme from "../../models/theme";
import { getThemesList } from "../../services/api_service/api_service";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../services/route_name/route_name_objects";
import ThemeAddModel from "./theme_add_model";
import ThemesTable from "./theme_table";


export default function ThemePage() {
  const [themes, setThemes] = useState<Theme[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);

  const getThemes = () => {
    getThemesList().then((data) => {
      if (data?.success) {
        const th: Theme[] = [];
        data.data.forEach((x: any) => th.push(new Theme(x)));
        setThemes(th);
      }
    });
  };

  useEffect(() => {
    getThemes();
  }, []);

  const showAddModel = () => {
    setShowAddModel(true);
  };
  return (
    <CommonPage currentRoute={nestedRouteNameObject1.theme} parentRoutes={[routeNameObjects.settings]}>
      <AddItemBtn title="Add New Theme" onClick={showAddModel} />
      {themes.length > 0 && (
        <ThemesTable themes={themes} reloadData={getThemes} />
      )}
      <ThemeAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={getThemes}
      />
    </CommonPage>
  );
}
