import React from "react";
import useStyles from "./partner_promotion_styles";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import PartnerPromotion from "../../../models/partner_promotion";
import LangAndTextEditTabs from "../../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import IconUploadBtn from "../../../components/icon_upload_btn/icon_upload_btn";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";
import DetailItem from "../../../components/detail_item/detail_item";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedPromotion: PartnerPromotion | undefined;
}
export default function PartnerPromotionModel(props: Props) {
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"Promotion"}
      setShow={props.setShow}
    >
      <div>
        {props.selectedPromotion && (
          <div>
            {" "}
            <IconUploadBtn
              mediaUrl={props.selectedPromotion.imageUrl}
              mediaType={"IMAGE"}
              allowVideo={false}
              height={120}
              isEditable={false}
              onUpload={null}
            />
            <LangAndTextEditTabs
              data={props.selectedPromotion.name}
              label="Name"
              isEditable={false}
              setData={null}
            />
            <LangAndTextEditTabs
              data={props.selectedPromotion.description}
              label="Description"
              isEditable={false}
              setData={null}
            />
            <ModelFieldWrapper>
              <ModelLabel title={"Other Details"} noMargin />
              <DetailItem title="Min Amount" value={props.selectedPromotion.minAmount} />
              <DetailItem title="Promotion Amount" value={props.selectedPromotion.promotionAmount} />
              <DetailItem title="Promotion Percentage" value={props.selectedPromotion.promotionPercentage} />
              <DetailItem title="Promotion Subcategory" value={props.selectedPromotion.promotionSubcategory} />
              <DetailItem title="Promotion Type" value={props.selectedPromotion.promotionType} />
              <DetailItem title="Validity Period" value={props.selectedPromotion.validityPeriod} />
              <DetailItem title="User Type" value={props.selectedPromotion.userType?.name} />
              <DetailItem title="Status" value={props.selectedPromotion.status} />
              <DetailItem title="Category" value={props.selectedPromotion.category?.name[0]?.text} />
            </ModelFieldWrapper>
          </div>
        )}

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
