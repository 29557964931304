import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import useStyles from "../notification_template_style";
import MessageTemplateGridItem from "./message_template_grid_item"
import NotificationModel from "../../../models/notification";

interface Props {
    messageTemplates: NotificationModel[];
    reloadData: Function
}
export default function MessageTemplates(props: Props) {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
                <Grid container justify="flex-start" spacing={2}>
                    {props.messageTemplates.map((value, index) => (
                        <MessageTemplateGridItem
                            key={index}
                            value={value}
                        />
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
