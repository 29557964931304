import React from "react";
import GoogleMapReact from "google-map-react";
import useStyles from './wifi_page_styles'

export default function WifiAddModelMap(props) {
  const classes = useStyles()
  const Marker = (props)=>{
    return <div className={classes.marker} >
      <img className={classes.markerIcon} height={30} src={'/images/marker.png'} alt={'marker'} />
    </div>
  }

  return (
    <div className={classes.map}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
        defaultCenter={{
          lat: 36.7537531 ,
          lng: 3.0581965,
     
        }}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals
        draggable={true}
        options={{
          fullscreenControl: false,
          zoomControl: false,
        }}
        onClick={(event) => {
          props.setLat(event.lat)
          props.setLon(event.lng)
        }}
      >
        <Marker lat={props.lat} lng={props.lon} />
      </GoogleMapReact>
    </div>
  );
}

