import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React from "react";
import tableStyles from "../../common_classes/table";
import clsx from "clsx";
import BloodDonation from "../../models/blood_donation";
import dateFormat from "dateformat";

interface Props {
  bloodDonations: BloodDonation[];
}

export default function BloodDonationTable(props: Props) {
  const tableStyle = tableStyles();

  return (
    <div style={{ paddingTop: "10px" }}>
      <TableContainer component={Paper}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className={tableStyle.header}>Name</TableCell>
              <TableCell className={tableStyle.header}>Mobile Number</TableCell>
              <TableCell className={tableStyle.header}>Wiaya</TableCell>
              <TableCell className={tableStyle.header}>City</TableCell>
              <TableCell className={tableStyle.header}>Gender</TableCell>
              <TableCell className={tableStyle.header}>Age</TableCell>
              <TableCell className={tableStyle.header}>Blood Group</TableCell>
              <TableCell className={tableStyle.header}>Status</TableCell>
              <TableCell className={tableStyle.header}>
                Registration Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.bloodDonations.map((item, index) => (
              <TableRow
                className={clsx(index % 2 === 1 && tableStyle.oddRowColor)}
                hover
                key={index}
              >
                <TableCell className={tableStyle.tableData}>
                  {item.firstName}
                </TableCell>
                <TableCell className={tableStyle.tableData}>
                  {item.mobileNumber}
                </TableCell>
                <TableCell className={tableStyle.tableData}>
                  {item.wilaya}
                </TableCell>
                <TableCell className={tableStyle.tableData}>
                  {item.city}
                </TableCell>
                <TableCell className={tableStyle.tableData}>
                  {item.gender === "M"
                    ? "Male"
                    : item.gender === "F"
                    ? "Female"
                    : "Other"}
                </TableCell>
                <TableCell className={tableStyle.tableData}>
                  {item.age}
                </TableCell>
                <TableCell
                  className={clsx(tableStyle.tableData, tableStyle.colored)}
                >
                  {item.bg}
                </TableCell>

                <TableCell
                  className={clsx(tableStyle.tableData, tableStyle.colored)}
                >
                  {item.status}
                </TableCell>
                <TableCell>
                  {dateFormat(item.registrationDate, "dd mmm yyyy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
