import { Card, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import FinekItem from "../../models/finek_item";
import useStyles from "./finek_grid_item_style";
import FadeIn from "react-fade-in";
import { MoreVert } from "@material-ui/icons";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

interface Props {
  value: FinekItem;
  onView: Function;
  onUpdate: Function;
  onDelete: Function;
}
export default function FinekGridItem(props: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isHover, setHover] = useState(false);
  return (
    <Grid item>
      <FadeIn>
        <Card
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={classes.gridCard}
          elevation={isHover ? 10 : 2}
        >
          <IconButton className={classes.addIcon} onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                props.onView(props.value);
              }}
            >
              View
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                props.onUpdate(props.value);
              }}
            >
              Update
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                props.onDelete(props.value);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
          <div onClick={() => props.onView(props.value)}>
            <div
              style={{
                backgroundImage: `url('${props.value.iconUrl}')`,
              }}
              className={classes.gridImage}
            />

            <div className={classes.contentPadding}>
              <div className={classes.name}>{props.value.name[0]?.text}</div>

              <div className={classes.type}>
                {props.value.type?.name[0]?.text}
              </div>

              <div className={classes.line} />
              <div className={classes.description}>
                {props.value.description[0]?.text}
              </div>
              <div
                className={classes.gridPrice}
              ><MonetizationOnIcon />{`${props.value.price}`}</div>
              {/* <ModelBtn
              title={"View"}
              onClick={() => props.onView(props.value)}
              width={"100%"}
            /> */}
            </div>
          </div>
        </Card>
      </FadeIn>
    </Grid>
  );
}
