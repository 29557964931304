import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import routeNameObjects from "./route_name_objects";


export interface RouteNames {
  name: string;
  route: string;
  component: any;
  children: RouteNames[];
  isHide?: boolean;
  nestedLevel: number;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}



export const routeNames: RouteNames[] = [
  // routeNameObjects.signIn,
  // routeNameObjects.home,
  // routeNameObjects.gamification,
  routeNameObjects.avatar,
  routeNameObjects.myWorld,
  // routeNameObjects.networkPlans,
  // routeNameObjects.services,
  routeNameObjects.offers,
  routeNameObjects.settings,
  // routeNameObjects.simPickup,
  // routeNameObjects.wilaya,
  // routeNameObjects.decorationAssets,
  routeNameObjects.carousel,
];

export const merchantRouteNames: RouteNames[] = [
  routeNameObjects.promotions,
  routeNameObjects.voucherDetails,
];

export const quizRouteNames: RouteNames[] = [
  routeNameObjects.questionsPool,
  routeNameObjects.quizSession,
];

export const eligibilityMatrix: RouteNames[] = [
  routeNameObjects.eligibilityMatrix,
];

export const pubG: RouteNames[] = [
  routeNameObjects.pubG,
];

export const notification: RouteNames[] = [
  routeNameObjects.notification,
  routeNameObjects.messageTemplates,
  routeNameObjects.customerBase
];

export const bloodDonationRoutNames: RouteNames[] = [
  routeNameObjects.bloodDonation,
  routeNameObjects.searchBDUsers,
];

export const customerCareRoutNames: RouteNames[] = [
  routeNameObjects.customerService,
];

export const analyzerRoutNames: RouteNames[] = [
  routeNameObjects.summaryStats,
];


