export default class VoucherUser{
    userName:string
    userLevel:number
    msisdn:string
    memberReferenceId:string
    experiencePoint:number
    authenticationId:string
    active:boolean
    constructor(data:any){
        this.userName=data.userName
        this.userLevel=data.userLevel
        this.msisdn=data.msisdn
        this.memberReferenceId=data.memberReferenceId
        this.experiencePoint=data.experiencePoint
        this.authenticationId=data.authenticationId
        this.active=data.active
    }
}