import React, { useState, useEffect } from "react";
import { MenuItem, Select, Box } from "@material-ui/core";
import useStyles from "./partner_promotion_styles";
import clsx from "clsx";
import MyModal from "../../../components/my_modal/my_modal";
import {
  updatePartnerPromotion,
  getUserCategoriesCall,
} from "../../../services/api_service/api_service";
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../../store models/model";
import PartnerPromotion from "../../../models/partner_promotion";
import UserCategory from "../../../models/user_category";
import ModelLabel from "../../../components/model_label/model_label";
import ModelBtn from "../../../components/model_btn/model_btn";
import ModelFieldWrapper from "../../../components/model_field_wrapper/model_field_wrapper";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedPromotion: PartnerPromotion | undefined;
  reloadData: Function;
}
export default function PartnerPromotionUpdateModel(props: Props) {
  const [
    selectedPromotion,
    setSelectedPromotion,
  ] = useState<PartnerPromotion>();

  const [userCategories, setCategories] = useState<UserCategory[]>([]);
  const getCategories = () => {
    getUserCategoriesCall().then((data) => {
      if (data?.success) {
        const ct: UserCategory[] = [];
        data.data.forEach((x: any) => ct.push(new UserCategory(x)));
        setCategories(ct);
      }
    });
  };

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  useEffect(() => {
    if (props.isShow) {
      const selected = props.selectedPromotion;
      if (selected) {
        selected.userTypeId = selected.userType ? selected.userType.id : "";
        selected.partnerCategoryId = selected.category
          ? selected.category.id
          : "";
        setSelectedPromotion({ ...selected });
      }

      getCategories();
    }
  }, [props.selectedPromotion, props.isShow]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

  const saveModel = () => {
    if (selectedPromotion) {
      console.log(selectedPromotion);
      updatePartnerPromotion(selectedPromotion.id, {
        promoId: selectedPromotion.id,
        userGroupId: selectedPromotion.userTypeId,
        featured: selectedPromotion.featured,
        status: selectedPromotion.status,
      }).then((data) => {
        if (data?.success) {
          props.setShow(false);
          props.reloadData();
          showMessage({
            head: "Success!",
            body: "Successfully Updated partner Promotion.",
            color: "green",
          });
        } else {
          showMessage({
            head: "Failed!",
            body: data?.data.title,
            color: "red",
          });
        }
      });
    }
  };

  const onFeaturedChange = (event: any) => {
    const cat = selectedPromotion;
    if (cat) {
      cat.featured = event.target.value;
      setSelectedPromotion({ ...cat });
    }
  };

  const onStatusChange = (event: any) => {
    const cat = selectedPromotion;
    if (cat) {
      cat.status = event.target.value;
      setSelectedPromotion({ ...cat });
    }
  };

  const onUserTypeChange = (userCategory: UserCategory) => {
    const cat = selectedPromotion;
    if (cat) {
      cat.userType = userCategory;
      cat.userTypeId = userCategory.id;
      setSelectedPromotion({ ...cat });
    }
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
      title={"update partner promotion"}
      setShow={props.setShow}
    >
      <div style={{ width: "500px" }}>
        <ModelFieldWrapper>
          <ModelLabel title={"User Type"} noMargin />
          <Box height={10} />
          <Select
            variant="outlined"
            style={{ width: "300px" }}
            value={selectedPromotion?.userType?.id}
          >
            {userCategories.map((type, index) => (
              <MenuItem
                key={index}
                value={type.id}
                onClick={() => onUserTypeChange(type)}
              >
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </ModelFieldWrapper>

        <ModelFieldWrapper>
          <ModelLabel title={"Status"} noMargin />
          <Box height={10} />
          <div>
            <Select
              value={selectedPromotion?.status}
              onChange={onStatusChange}
            >
              {["ACTIVE", "DRAFT", "EXPIRED"].map((val, index) => (
                <MenuItem key={index} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </div>
        </ModelFieldWrapper>

        <ModelFieldWrapper>
          <ModelLabel title={"Featured"} noMargin />
          <Box height={10} />
          <div>
            <Select
              value={selectedPromotion?.featured}
              onChange={onFeaturedChange}
            >
              {[true, false].map((val, index) => (
                <MenuItem key={index} value={val.toString()}>
                  {val.toString()}
                </MenuItem>
              ))}
            </Select>
          </div>
        </ModelFieldWrapper>

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={saveModel} />
        </div>
      </div>
    </MyModal>
  );
}
