import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import {
  getCityWifiLocationsCall,
  getCityList,
} from "../../services/api_service/api_service";
import WifiLocation from "../../models/wifi_location";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import useStyles from "./wifi_page_styles";
import WifiAddModel from "./wifi_add_model";
import City from "../../models/city";
import WifiTable from "./wifi_table";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import routeNameObjects from "../../services/route_name/route_name_objects";

export default function WifiPage() {
  const classes = useStyles();
  const [wifiLocations, setWifiLocations] = useState<WifiLocation[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [isShowAddModel, setShowAddModel] = useState(false);
  const [selectedCity, setSelectedCity] = useState<City>();

  const showAddModel = () => {
    setShowAddModel(true);
  };

  const getCityWifiLocations = (cityId: string | undefined) => {
    getCityWifiLocationsCall(cityId).then((data) => {
      if (data?.success) {
        const loc: WifiLocation[] = [];
        data.data.forEach((x: any) => loc.push(new WifiLocation(x)));
        setWifiLocations(loc);
      }
    });
  };

  const getCities = () => {
    getCityList().then((data) => {
      if (data?.success) {
        const ct: City[] = [];
        data.data.forEach((x: any) => ct.push(new City(x)));
        setCities(ct);
      }
    });
  };

  useEffect(() => {
    getCities();
    getCityWifiLocations(undefined);
  }, []);

  return (
    <CommonPage currentRoute={nestedRouteNameObject1.wifiLocations} parentRoutes={[routeNameObjects.services]}>
      <AddItemBtn title="Add New Location" onClick={showAddModel} />
      <FormControl className={classes.selectCity}>
        <InputLabel style={{ marginLeft: "10px" }}>Select City</InputLabel>
        <Select
          style={{ width: "300px" }}
          value={selectedCity ? selectedCity.id : ""}
          label={"City"}
          variant="outlined"
        >
          <MenuItem
            value={""}
            onClick={() => {
              setSelectedCity(undefined);
              getCityWifiLocations(undefined);
            }}
          >
            All Cities
          </MenuItem>
          {cities.map((city, index) => (
            <MenuItem
              key={index}
              value={city.id}
              onClick={() => {
                setSelectedCity(city);
                getCityWifiLocations(city.id);
              }}
            >
              {city.name[0]?.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <div style={{ display: "flex", flexDirection: "column" }}>
        {wifiLocations.length > 0 ? 
          <WifiTable
            location={wifiLocations}
            reloadData={() => getCityWifiLocations(selectedCity?.id)}
          /> : <div className={classes.noLocations}>{`${'No Locations in'} ${selectedCity?.name[0]?.text}`}</div>
        }
      </div>

      <WifiAddModel
        isShow={isShowAddModel}
        setShow={setShowAddModel}
        reloadData={() => getCityWifiLocations(selectedCity?.id)}
      />
    </CommonPage>
  );
}
