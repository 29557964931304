import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import clsx from "clsx";
import { useStoreActions, Actions } from "easy-peasy";
import useStyles from "./wifi_page_styles";
import { StoreModel } from "../../store models/model";
import MyModal from "../../components/my_modal/my_modal";
import City from "../../models/city";
import {
  getCityList,
  addWifiLocation,
} from "../../services/api_service/api_service";
import WifiAddModelMap from "./wifi_add_model_map.js";
import ModelBtn from "../../components/model_btn/model_btn";

interface Props {
  isShow: boolean;
  setShow: Function;
  reloadData: Function;
}
export default function WifiAddModel(props: Props) {
  const classes = useStyles();

  const [cities, setCities] = useState<City[]>([]);
  const [cityId, setCityId] = useState<any>("");
  const [locationName, setLocationName] = useState<any>("");

  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );

  const getCities = () => {
    getCityList().then((data) => {
      if (data?.success) {
        const ct: City[] = [];
        data.data.forEach((x: any) => ct.push(new City(x)));
        setCities(ct);
      }
    });
  };

  useEffect(() => {
    if(props.isShow){
      setLocationName('')
      setCityId('')
      setLat(0)
      setLon(0)
      getCities();
    }
    
  }, [props.isShow]);

  const closeModal = () => {
    props.setShow(false);
  };

  const onSave = () => {
    if (!locationName) {
      showMessage({ head: "Failed!", body: "Please enter location name." });
      return;
    }
    if (!cityId) {
      showMessage({ head: "Failed!", body: "Please select a city." });
      return;
    }
    if (lat === 0) {
      showMessage({ head: "Failed!", body: "Please select location on map." });
      return;
    }
    addWifiLocation({
      locationName: locationName,
      cityId: cityId,
      location: {
        lat: lat,
        lon: lon,
      },
    }).then((data) => {
      if (data?.success) {
        props.setShow(false);
        props.reloadData();
        showMessage({
          head: "Success!",
          body: "You have successfully added new wi-fi location",
          color: "green",
        });
      } else {
        showMessage({
          head: "Failed!",
          body: data?.data.title,
          color: "red",
        });
      }
    });
  };

  return (
    <MyModal
      isShow={props.isShow}
      onClose={null}
       
      title={"add new location"}
      setShow={props.setShow}
    >
      <div className={classes.addOuter}>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Location Name"
          value={locationName}
          onChange={(event) => setLocationName(event.target.value)}
        />

        <FormControl className={classes.textField}>
          <InputLabel style={{ marginLeft: "10px" }}>Select City</InputLabel>
          <Select
            style={{ width: "100%" }}
            value={cityId}
            label={"CIty"}
            variant="outlined"
            onChange={(event) => {
              setCityId(event.target.value);
            }}
          >
            {cities.map((city, index) => (
              <MenuItem key={index} value={city.id}>
                {city.name[0]?.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <WifiAddModelMap lat={lat} lon={lon} setLat={setLat} setLon={setLon} />
      </div>

      <div>
        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
          <ModelBtn title={"save"} onClick={onSave} />
        </div>
      </div>
    </MyModal>
  );
}
