import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles({
  labelOuter: {
    backgroundColor: myColors.appBarColor,
    padding: "7px",
    borderRadius: "5px",
    fontWeight: "bold",
    color:myColors.appBarTextColor,
    transition:'1s',
    '&:hover':{
      color:myColors.drawerSideBarColor
    },
    position:'relative',
    
  },
 
});

export default useStyles;
