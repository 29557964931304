import { makeStyles } from "@material-ui/core";
import myColors from "../../../services/colors";

const useStyles = makeStyles({

  themeColor: {
    height: "70px",
    width: "70px",
    margin: "auto",
    position: "relative",
  },
  selectField: {
    minWidth: "100px",
    marginRight: '10px',
    marginBottom: '5px'
  },
  cardSize: {
    // height: "415px",
    padding: "20px",
    marginTop: "46px",
  },
  content: {
    maxWidth: "600px",
    display: "block",
    marginBottom: "1rem",
    background: "#32557f",

  },
  editWrapper: {
    bottom: 0,
    height: '35px',
    position: 'absolute',
    right: '-2px'
  },
  outer: { textAlign: "center" },
  title: {
    color: myColors.drawerColor,
    fontWeight: 'bold',
    marginTop: '5px'
  },
  label: {
    float: "right"
  },
  input: {
    width: "154px"
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    lineHeight: "2.5",
    textAlign: "center",
    backgroundColor: myColors.drawerSideBarColor,
    borderRadius: "4px",
    border: "3px",
    padding: "1px 13px 0px 13px"

  },
  tabSelected: { backgroundColor: myColors.drawerSideBarColor },
  tabStyle: {
    color: myColors.appBarColor,
    fontSize: "10px",
    fontWeight: "bold",
  },
  listPadding: {
    padding: '10px',
    height: '400px',
    overflowY: 'auto'
  },
});

export default useStyles;
