import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  addBtn: {
    position: "fixed",
    right: "30px",
    bottom: "30px",
    backgroundColor: "#1a3248",
    color: "white",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  right: {
    paddingRight: "10px",
    float: "right",
  },
  textField: {
    margin: "5px 10px",
  },
  modelBtn: {
    marginRight: "3px",
  },
  addOuter: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
  },
  greyLabel: {
    color: "grey",
  },
  mainIcon: {
    height: "150px",
    width: "150px",
    borderRadius: "10px",
    margin: "auto",
    marginBottom: "10px",
    position: "relative",
  },
  iconEdit: {
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: "grey",
    color: "white",
  },
  smallBtn: {
    padding: "3px",
  },
  addPhotoIcon: {
    height: "120px",
    width: "120px",
    color: "grey",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },

  addIcon: {
    height: "60px",
    width: "60px",
    color: "grey",
  },
  addIconBtn: {
    backgroundColor: "#80808021",
    margin: "auto",
    marginTop: "10px",
    width: "100px",
  },
  updateOuter: {
    width: '500px'
  },
  isActiveOuter: {
    width: '400px',
    margin: 'auto',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
  },
  typeChip: {
    width: 'fit-content',
    // backgroundColor: 'red',
    padding: '2px 10px',
    borderRadius: '10px',
    color: 'white',
    fontWeight: 'bold'
  },
  imageSizeText: { color: "grey" },
});

export default useStyles;
