import React, { useState } from "react";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Collapse, List } from "@material-ui/core";
import useStyles from "./list_items_style";
import { RouteNames } from "../../services/route_name/route_names";
import MyNavigator from "../../services/navigation";
import FadeIn from "react-fade-in";
import { animated, useSpring } from "react-spring";
import { useStoreState } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import  { getRouteUser } from "../../services/route_name/route_users";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

interface MainListProps {
  currentRoute: string;
}

export default function MainItemList(props: MainListProps) {
  const authUser = useStoreState(
    (state: StoreModel) => state.keyCloakUserModel.authUser
  );
  const routeUser = getRouteUser(authUser)
  return (
    <div>
      {routeUser.routes.map((route, index) => (
        <MyItem
          key={index}
          routeDetails={route}
          currentRoute={props.currentRoute}
        />
      ))}
    </div>
  );
}

interface MyItemProps {
  routeDetails: RouteNames;
  currentRoute: string;
}

function MyItem(props: MyItemProps) {
  const classes = useStyles();

  const { pushToPage } = MyNavigator();

  const [isHover, setHover] = useState(false);

  const isSelected = props.currentRoute === props.routeDetails.route;

  const path =
    "M 0,1 L 0,0 L .5,0 C .5 0, .5 .15 .7 .33 C .7 .33, .9 .5, .7 .67 C .7 .67, .5 .85, .5 1 L .5,1 L 0,1 Z";

  const notchAnimation = useSpring({
    transition: "0.1s",
    transform: "translate(0px, 0px)",
    from: { transform: "translate(-100px, 0px)" },
  });

  const getSearchForExpand = () => {
    for (var i = 0; i < props.routeDetails.children.length; i++) {
      const r = props.routeDetails.children[i];
      if (r.route === props.currentRoute) {
        return true;
      }
      for (var j = 0; j < r.children.length; j++) {
        const n = r.children[j];
        if (n.route === props.currentRoute) {
          return true;
        }
      }
    }
    return false;
  };

  const [isExpanded, setExpanded] = useState(getSearchForExpand());

  const onItemClick = (route: RouteNames) => {
    pushToPage(route);
  };
  return props.routeDetails.isHide ? (
    <div />
  ) : (
    <FadeIn>
      <div>
        <div className={classes.listItemOuter}>
          <ListItem
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            button
            onClick={() => {
              if (props.routeDetails.children.length === 0) {
                onItemClick(props.routeDetails);
              } else {
                setExpanded(!isExpanded);
              }
            }}
            className={clsx(classes.listItem, isSelected && classes.selected)}
          >
            <div
              className={clsx(
                classes.sideLine,
                isSelected && classes.sideLineActive
              )}
            />
            <div style={{ width: 10 * (props.routeDetails.nestedLevel + 1) }} />
            <ListItemIcon
              className={clsx(
                classes.listItemText,
                (isHover || isSelected) && classes.listItemTextHover
              )}
            >
              <props.routeDetails.icon />
            </ListItemIcon>
            <ListItemText
              className={clsx(
                classes.listItemText,
                (isHover || isSelected) && classes.listItemTextHover
              )}
              primary={props.routeDetails.name}
            />

            {props.routeDetails.children.length === 0 ? (
              <div />
            ) : isExpanded ? (
              <ArrowDropUp className={classes.listItemTextHover} />
            ) : (
              <ArrowDropDown className={classes.listItemTextHover} />
            )}
          </ListItem>

          {isSelected && (
            <div className={classes.notchOuter}>
              <animated.div style={notchAnimation}>
                <div className={classes.elementToClip}></div>
              </animated.div>
            </div>
          )}

          <svg width="0" height="0">
            <defs>
              <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
                <path d={path} />
              </clipPath>
            </defs>
          </svg>
        </div>

        {props.routeDetails.children.length > 0 && (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {props.routeDetails.children.map((nested1, index1) => (
                <MyItem
                  routeDetails={nested1}
                  key={index1}
                  currentRoute={props.currentRoute}
                />
              ))}
            </List>
          </Collapse>
        )}
      </div>
    </FadeIn>
  );
}
