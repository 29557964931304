import React, { useState, useEffect } from "react";
import useStyles from "./error_style";
import clsx from "clsx";
import MyModal from "../../components/my_modal/my_modal";
import LangAndTextEditTabs from "../../components/lang_and_text_edit/lang_and_text_edit_tabs";
import ModelBtn from "../../components/model_btn/model_btn";
import Error from "../../models/error";
import { TextField } from "@material-ui/core";

interface Props {
  isShow: boolean;
  setShow: Function;
  selectedError: Error | undefined;
  reloadData: Function;
}
export default function ErrorModel(props: Props) {
  const [selected, setSelected] = useState<Error>();

  useEffect(() => {
    setSelected(props.selectedError);
  }, [props.selectedError]);
  const classes = useStyles();

  const closeModal = () => {
    props.setShow(false);
  };

 

  return (
    <MyModal
      isShow={props.isShow}
      onClose={closeModal}
       
      title={"View Application Errors"}
      setShow={props.setShow}
    >
      <div>
        {selected && (
          <div className={classes.modelOuter}>
            <div className={classes.row}>
              <TextField
                label="Error Code"
                value={selected.errorCode}
                variant="outlined"
                className={classes.textField}
                style={{ width: "100%" }}
                InputProps={{ readOnly: true }}
              />
            </div>

            <LangAndTextEditTabs
              data={selected.messages}
              isEditable={false}
              label={"Error Message"}
              setData={null}
            />
          </div>
        )}

        <div className={clsx(classes.row, classes.right)}>
          <ModelBtn title={"Close"} onClick={closeModal} />
        </div>
      </div>
    </MyModal>
  );
}
